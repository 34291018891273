import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { CalcData } from 'types';
import { ContractNameType, LevelNameShowType } from '../..';
import { useGetComplexRowsListQuery } from '../../../../../../api/calculations';
import { LevelSummEstimate } from '../LevelSummEstimate/LevelSummEstimate';
import { Analysis } from './components/Analysis';
import { EstimateLevel } from './components/EstimateLevel';
import { Gencontract } from './components/Gencontract';
import { Subcontract } from './components/Subcontract';
import {
  StyledHeadTd,
  StyledTableBody,
  StyledTableContainer,
  StyledTableHeadAnalysis,
} from './styles';

export type EstimateProps = {
  currentContract: ContractNameType;
  calculation: CalcData;
  levelNameCurrent: LevelNameShowType[];
};

export const Estimate: React.FC<EstimateProps> = ({
  currentContract,
  calculation,
  levelNameCurrent,
}) => {
  const { title, createdAt, id: calculationId } = calculation;

  /********************************* Scroll Body & Head ****************************************/

  const headRef = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLTableRowElement | null>(null);

  const handleScrollHead = (scroll: React.UIEvent<HTMLElement>): void => {
    if (bodyRef.current === null) return;
    const target = scroll.target as HTMLDivElement;
    bodyRef.current.scroll({ left: target.scrollLeft });
  };
  const handleScrollBody = (scroll: React.UIEvent<HTMLElement>): void => {
    if (headRef.current === null) return;
    const target = scroll.target as HTMLDivElement;
    headRef.current.scroll({ left: target.scrollLeft });
  };

  /************************************ Show/Hide Contract ************************************/

  const [isShowGenContract, setIsShowGeneralContract] = useState(false);
  const [isShowSubContract, setIsShowSubcontracting] = useState(false);
  const [isShowAnalysis, setIsShowAnalysis] = useState(false);
  const [countColumns, setCountColumns] = useState('13');
  const [countColumnsAnalysis, setCountColumnsAnalysis] =
    useState<string>('13');

  const styledGencontract =
    '"icon title gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract scroll"';
  const styledSubcontract =
    '"icon title subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract scroll"';
  const styledAnalysis =
    '"icon title analysisEstimate analysisEstimate analysisEstimate analysisEstimate gencontract subcontract scroll"';

  const [styledContract, setStyledContract] = useState(styledGencontract);

  useEffect(() => {
    switch (currentContract) {
      case 'ГЕНПОДРЯД':
        setIsShowSubcontracting(false);
        setIsShowGeneralContract(true);
        setIsShowAnalysis(false);
        setCountColumns('13');
        setCountColumnsAnalysis('13');
        setStyledContract(styledGencontract);
        break;
      case 'СУБПОДРЯД':
        setIsShowSubcontracting(true);
        setIsShowGeneralContract(false);
        setIsShowAnalysis(false);
        setCountColumns('13');
        setCountColumnsAnalysis('13');
        setStyledContract(styledSubcontract);
        break;
      case 'АНАЛИЗ':
        setCountColumns('6');
        setIsShowSubcontracting(true);
        setIsShowGeneralContract(true);
        setIsShowAnalysis(true);
        setStyledContract(styledAnalysis);
        setCountColumnsAnalysis('1');
        break;
      default:
        break;
    }
  }, [currentContract]);

  /***************************** Complex List ****************************************/

  const { data, isFetching, isLoading } = useGetComplexRowsListQuery({
    id: calculationId,
  });

  const isDataLoading = isFetching || isLoading;
  const basePrices = data?.rows ? data?.rows : [];
  const coefficients = data?.coefficients ? data?.coefficients : {};

  /****************************************************************************************/

  return (
    <>
      {!isDataLoading && (
        <>
          <StyledTableContainer
            style={{ flexDirection: 'column' }}
            className="StyledTableContainer">
            <StyledTableHeadAnalysis
              style={{
                gridTemplateColumns: `100px minmax(400px, 1fr) repeat(${countColumns},170px) 13px`,
                gridTemplateAreas: `${styledContract}`,
              }}
              className="head Estimate"
              ref={headRef}
              onScroll={handleScrollHead}>
              <Box sx={{ gridArea: 'icon', height: '100%' }}></Box>
              <StyledHeadTd
                style={{
                  gridArea: 'title',
                  alignItems: 'flex-start',
                  paddingLeft: '12px',
                }}>
                Смета или наименование работ
              </StyledHeadTd>
              {isShowAnalysis && <Analysis />}
              {/* Генподряд */}
              {isShowGenContract && (
                <Gencontract
                  countColumnsAnalysis={countColumnsAnalysis}
                  isShowAnalysis={isShowAnalysis}
                  coefficients={coefficients}
                />
              )}
              {/* Субподряд */}
              {isShowSubContract && (
                <Subcontract
                  countColumnsAnalysis={countColumnsAnalysis}
                  isShowAnalysis={isShowAnalysis}
                  coefficients={coefficients}
                />
              )}
              <Box
                className="scroll"
                sx={{
                  gridArea: 'scroll',
                  boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)',
                  height: '100%',
                  minWidth: '13px',
                }}></Box>
            </StyledTableHeadAnalysis>
            <StyledTableBody
              className="body"
              ref={bodyRef}
              onScroll={handleScrollBody}>
              <LevelSummEstimate
                basePrices={basePrices}
                coefficients={coefficients}
                countRepeat={countColumns}
                isShowGenContract={isShowGenContract}
                isShowSubContract={isShowSubContract}
                isShowAnalysis={isShowAnalysis}></LevelSummEstimate>
              {basePrices.map((element) => {
                return (
                  <EstimateLevel
                    calculationId={calculationId}
                    basePricesLevel={element}
                    coefficients={coefficients}
                    levelNumber={1}
                    key={element.id}
                    isShowGenContract={isShowGenContract}
                    isShowSubContract={isShowSubContract}
                    isShowAnalysis={isShowAnalysis}
                    countRepeat={countColumns}
                    levelNameCurrent={levelNameCurrent}
                  />
                );
              })}
            </StyledTableBody>
          </StyledTableContainer>
        </>
      )}
    </>
  );
};
