import { VORGroupData } from 'api/calculations/types';
import { useMemo } from 'react';

export const useGroupSum = (rowChildren: VORGroupData[]) => {
  const constructionEffortSum = useMemo(
    () =>
      rowChildren
        .reduce((acc, value) => (acc += value.constructionEffort ?? 0), 0)
        ?.toLocaleString('ru-RU', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    [rowChildren],
  );

  const mechanicsEffort = useMemo(
    () =>
      rowChildren
        .reduce((acc, value) => (acc += value.mechanicsEffort ?? 0), 0)
        ?.toLocaleString('ru-RU', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    [rowChildren],
  );

  const mechanicsEffortByUnit = useMemo(
    () =>
      rowChildren
        .reduce((acc, value) => (acc += value.mechanicsEffortByUnit ?? 0), 0)
        ?.toLocaleString('ru-RU', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    [rowChildren],
  );

  const totalWorkCost = useMemo(
    () =>
      rowChildren
        .reduce((acc, value) => (acc += value.totalWorkCost ?? 0), 0)
        ?.toLocaleString('ru-RU', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    [rowChildren],
  );

  const totalWorkCostByUnit = useMemo(
    () =>
      rowChildren
        .reduce((acc, value) => (acc += value.totalWorkCostByUnit ?? 0), 0)
        ?.toLocaleString('ru-RU', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    [rowChildren],
  );

  return {
    constructionEffortSum,

    mechanicsEffort,
    mechanicsEffortByUnit,
    totalWorkCost,
    totalWorkCostByUnit,
  };
};

export type Res = Required<
  Pick<
    VORGroupData,
    | 'constructionEffort'
    | 'constructionEffortByUnit'
    | 'mechanicsEffort'
    | 'mechanicsEffortByUnit'
    | 'totalWorkCost'
    | 'totalWorkCostByUnit'
  >
>;
