import { StatusColors } from 'types';
import {
  CalcDataType,
  CalculationCardStatuses,
  CalculationComlexFilter,
  CalculationComplexTabLabels,
  CalculationStatuses,
  CalculationTabLabels,
  ComplicatedRowResourceRowTag,
  LevelNameCurrentType,
  LevelNameTypeEn,
  UnitTypeEnToRu,
} from './calculation';

export const arrLevelNameEn: LevelNameTypeEn[] = [
  'level1',
  'level2',
  'level3',
  'level4',
];

export const CalculationStatusesTabLabels: {
  [key: string]: CalculationTabLabels;
} = {
  'Все расчеты': 'Все расчеты',
  // 'Базисно-индексный': 'Базисно-индексный',
  // Ресурсный: 'Ресурсный',
  // Интеграция: 'Интеграция',
};

export const CalculationComplexTableTabLabels: Record<
  CalculationComlexFilter,
  CalculationComplexTabLabels
> = {
  'Базовые цены': 'Базовые цены',
  Коэффициенты: 'Коэффициенты',
  'Норм. Трудоемкость': 'Норм. Трудоемкость',
  'Просмотр расчета': 'Просмотр расчета',
};

export const CalculationCardStatusesColors: Record<
  CalculationCardStatuses,
  StatusColors
> = {
  'Базисно-индексный': 'blue',
  Ресурсный: 'orange',
};

export const estimateLevelName: Record<LevelNameTypeEn, LevelNameCurrentType> =
  {
    level1: 'УРОВЕНЬ 1',
    level2: 'УРОВЕНЬ 2',
    level3: 'УРОВЕНЬ 3',
    level4: 'УРОВЕНЬ 4',
  };

export const calculationStatusesArray: CalculationStatuses[] = [
  'Базисно-индексный',
  'Ресурсный',
];

export const unitTypeEnToRu: UnitTypeEnToRu = {
  count: 'шт',
  meter: 'м',
  square_meter: 'м2',
  cubic_meter: 'м3',
  kilogram: 'кг',
  tonn: 'т',
};

export const arrLevelNameRu: LevelNameCurrentType[] = [
  'УРОВЕНЬ 1',
  'УРОВЕНЬ 2',
  'УРОВЕНЬ 3',
  'УРОВЕНЬ 4',
];

export const calcDataTypeNames: Record<CalcDataType, string> = {
  basisManual: 'Базисно-индексный',
  excel: 'Базисно-индексный',
  grandSmeta: 'Базисно-индексный',
  resourceManual: 'Ресурсный',
  tangl: 'Интеграции',
  handbk: 'Ресурсный',
};

export const resourceRowTagNames: Record<ComplicatedRowResourceRowTag, string> =
  {
    mim: 'Машины и Механизмы',
    materials: 'Материалы',
    tzm: 'Затраты труда машинистов',
    tzr: 'Затраты труда рабочих',
    equipments: 'Оборудование',
  };
