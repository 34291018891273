import { ArrowForwardIos } from '@mui/icons-material';
import { Stack } from '@mui/material';
import styled from 'styled-components';

interface StyledArrowProps {
  $open: boolean;
}

export const StyledNodeRow = styled(Stack)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '15px 1050px 295px 575px',
  backgroundColor: theme.palette.bg.selected,
  borderBottom: `1px solid ${theme.palette.legends.gray}`,
  flexDirection: 'row',
  alignItems: 'center',
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
}));

export const StyledBlueTotal = styled('div')`
  display: grid;
  grid-template-columns: 100px 100px;
  justify-content: space-between;
`;

export const StyledArrow = styled(ArrowForwardIos)<StyledArrowProps>`
  cursor: pointer;
  font-size: 17px;
  color: ${({ theme }) => theme.palette.text.dark};
  transform: ${({ $open }) => `rotate(${$open ? 270 : 90}deg)`};
`;
