import EditIcon from '@mui/icons-material/Edit';
import GroupIcon from '@mui/icons-material/Group';
import TableViewIcon from '@mui/icons-material/TableView';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Stack } from '@mui/material';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../Button';
import { ButtonData, UserButtonsProps } from './UserButtons.types';
import { getPricesState } from '../../../store/slices/references/prices/prices';
import { useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';

const UserButtons: React.FC<UserButtonsProps> = ({ role }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAdminPage: boolean =
    location.pathname.split('/')[1] === 'administration';

  const { isCreated } = useSelector(getPricesState);

  const dispatch = useAppDispatch();

  const adminButtonData: ButtonData = {
    text: 'Администрирование',
    icon: <EditIcon />,
    href: '/administration/users',
  };
  const viewButtonData: ButtonData = {
    text: 'Режим просмотра',
    icon: <VisibilityIcon />,
    href: '/calculations',
  };

  const buttonData = isAdminPage ? viewButtonData : adminButtonData;

  const onButtonClick = useCallback(
    (href: string) => {
      if (isCreated) {
        navigate(href);
      } else {
        navigate(href);
      }
    },
    [isCreated],
  );

  return (
    <Box className="UserButtons">
      <Stack direction="row" spacing={1.5}>
        {role === 'admin' ? (
          <Button
            customSize="medium"
            style={{ padding: '8px', minWidth: '40px' }}
            onClick={() => onButtonClick(buttonData.href)}
            className="USERS">
            {buttonData.href === '/calculations' ? (
              <TableViewIcon style={{ fontSize: 24 }} />
            ) : (
              <GroupIcon style={{ fontSize: 24 }} />
            )}
          </Button>
        ) : null}
      </Stack>
    </Box>
  );
};

export default UserButtons;
