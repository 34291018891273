import React from 'react';
import { Stack, Typography } from '@mui/material';
import { CardSubtitle } from '../styles';
import { StyledCardContent } from './styles';
import UserAccesses from './UserAccesses';
import { UserCardContentProps } from './UserCardContent.types';

const UserCardContent: React.FC<UserCardContentProps> = ({ data }) => {
  const { access, company } = data;

  return (
    <StyledCardContent>
      <Stack spacing={1.5}>
        <Stack spacing={0.5}>
          <CardSubtitle>Компания:</CardSubtitle>
          <Typography variant="body2">{company?.userCompanyName}</Typography>
        </Stack>

        <Stack spacing={0.5}>
          <CardSubtitle>Должность:</CardSubtitle>
          <Typography variant="body2">{company?.userPosition}</Typography>
        </Stack>

        {/*   <Stack spacing={0.5}>
          <CardSubtitle>Доступы:</CardSubtitle>
          <UserAccesses access={access} />
        </Stack> */}
      </Stack>
    </StyledCardContent>
  );
};

export default UserCardContent;
