import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useCalcId } from 'hooks/useCalcId';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useBindGroup } from 'pages/FullVor/Table/hooks';
import { FC, MouseEvent, useState } from 'react';
import { useTreeNodeContext } from '../../../../context';
import { RowMenuProps } from './RowMenu.types';
import { BodyText } from './components';
import {
  useLazyGetGroupPositionQuery,
  useLazyUngroupAllLevelsQuery,
} from 'api/calculations';
import { useSnackbar } from 'notistack';

export const RowMenu: FC<RowMenuProps> = ({
  onHover,
  isHandbook,
  onRenameGroup,
  ...rest
}): JSX.Element => {
  const calcID = useCalcId();
  const {
    rowID,
    levelIds,
    rowTitle,
    rowType,
    level,
    childrenIds,
    groupIds,
    onToggleFormVisible,
  } = useTreeNodeContext();
  const { bindGroup, deleteGroup } = useBindGroup();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [ungroupAllLevels] = useLazyUngroupAllLevelsQuery();
  const [getGroupPosition] = useLazyGetGroupPositionQuery();

  const { enqueueSnackbar } = useSnackbar();

  const open = Boolean(anchorEl);

  const menuClickHandler = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenuHandler = () => {
    setAnchorEl(null);
    onHover?.();
  };

  const ungroupHandler = (confirm: boolean) => {
    if (!confirm || !rowID) return;

    bindGroup({
      calcID,
      rowID,
      parentID: levelIds[level - 1] ?? null,
      childrenIds,
      groupIds,
      isHandbook,
    });
  };

  const ungroupAllHandler = (confirm: boolean) => {
    if (!confirm) return;
    ungroupAllLevels({ calcID, isHandbook })
      .unwrap()
      .then(() => {
        enqueueSnackbar('Все уровни успешно разгруппированы', {
          variant: 'success',
        });
        getGroupPosition({ calcID, isHandbook });
      });
  };

  const { ConfirmDialog, openConfirm } = useConfirmDialog({
    handleConfirm: ungroupHandler,
    title: 'Разгруппировать уровень?',
    body: <BodyText title={rowTitle} level={level} />,
    denyButtonText: 'Нет',
  });

  const {
    ConfirmDialog: ConfirmDialogUngroupAll,
    openConfirm: openConfirmDialogUngroupAll,
  } = useConfirmDialog({
    handleConfirm: ungroupAllHandler,
    title: 'Разгруппировать все уровни?',
    body: 'Все уровни будут разгруппированы',
    denyButtonText: 'Нет',
  });

  const onSelectAndMoveClickHandler = () => {
    onToggleFormVisible('move');
  };

  return (
    <>
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          menuClickHandler(e);
        }}
        {...rest}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        PaperProps={{
          style: {
            width: '260px',
          },
        }}
        open={open}
        onClose={closeMenuHandler}
        onClick={(e) => {
          e.stopPropagation();
          closeMenuHandler();
        }}>
        <MenuItem onClick={onSelectAndMoveClickHandler}>
          Выбрать и переместить
        </MenuItem>
        {!rowID && Object.keys(groupIds).length > 0 && (
          <MenuItem onClick={openConfirmDialogUngroupAll}>
            Разгруппировать все уровни
          </MenuItem>
        )}
        {rowType !== 'folded' && (
          <MenuItem onClick={openConfirm}>Разгруппировать</MenuItem>
        )}
        {onRenameGroup && (
          <MenuItem onClick={onRenameGroup}>Редактировать</MenuItem>
        )}
        {isHandbook && rowID && (
          <MenuItem onClick={() => deleteGroup({ calcID, rowID, isHandbook })}>
            Удалить
          </MenuItem>
        )}
      </Menu>
      <ConfirmDialog />
      <ConfirmDialogUngroupAll />
    </>
  );
};
