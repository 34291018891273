import styled from 'styled-components';
import { MenuItem } from '@mui/material';
import { FieldForm } from 'components/FieldForm';

export const MultiSelect = styled(FieldForm)`
  & .MuiSelect-select {
    text-align: left;
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  & .MuiSelect-select {
    padding: 10px 16px;
  }

  & .MuiSvgIcon-root {
    right: 14px;
    font-size: 16px;

    & path {
      fill: ${(props) => props.theme.palette.text.dark};
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(92, 110, 140, 0.3);
    border-radius: 6px;
  }
`;

export const MultiSelectMenuItem = styled(MenuItem)`
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  &.Mui-selected {
    background-color: ${(props) => props.theme.palette.bg.white};
  }

  &.Mui-focusVisible,
  &.Mui-selected:hover {
    background-color: ${(props) => props.theme.palette.bg.gray} !important;
  }

  & .MuiCheckbox-root {
    margin-right: 16px;
    padding: 0;

    & .MuiSvgIcon-root {
      fill: ${(props) => props.theme.palette.primary.main};
    }
  }
`;
