import { LSRFileStatus, LSRTabLabels, LSRTabValue } from './lsr';

export const lsrFileStatusNames: Record<LSRFileStatus, LSRTabLabels> = {
  agreed: 'Согласован',
  not_agreed: 'Не согласован',
  in_progress: 'В работе',
  undefiend: 'Не определен',
  not_selected: 'Не определен',
};

export const topbarTabsLabels: Record<LSRTabValue, LSRTabLabels> = {
  all: 'Все документы',
  ...lsrFileStatusNames,
};
