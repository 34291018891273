import {
  Box,
  ButtonGroup,
  Divider,
  IconButton,
  Popper,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import {
  BorderColorLevelType,
  ColorsBgLevelType,
  ColorsBgRowType,
} from 'types';
import { FieldForm } from '../../../../../components/FieldForm';

export const StyledTableContainer = styled(Box)`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background: #fff;
`;
export const StyledTableHead = styled(Box)`
  display: grid;
  overflow: hidden;
  grid-template-columns:
    100px minmax(320px, 1fr) repeat(10, minmax(140px, 190px))
    30px 13px;
  grid-auto-rows: 60px;
  align-items: center;
  margin-left: -1px;

  height: 100%;
  max-height: 60px;
  background-color: #7890b2;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.15px;
  color: #ffffff;
`;
export const StyledHeadTd = styled(Box)`
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: center;
`;
export const StyledTableBodyContainer = styled(Box)`
  overflow: scroll;
  height: 100%;
  margin-left: -1px;

  &::-webkit-scrollbar {
    box-shadow: inset 1px 0 0 rgba(0, 36, 95, 0.1);
  }
`;
export const StyledLevel = styled(Box)`
  display: grid;
  grid-template-columns:
    100px minmax(240px, 1fr) repeat(10, minmax(140px, 190px))
    30px;
  grid-auto-rows: 50px;
`;

interface StyledLevelTdProps {
  colorsBgLevel?: ColorsBgLevelType;
  borderColorLevel?: BorderColorLevelType;
}

export const StyledLevelTd = styled(Box)<StyledLevelTdProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  height: 100%;
  background-color: ${(props) => {
    return (
      props.theme.palette.bgLevel[props.colorsBgLevel!] ||
      props.theme.palette.bgLevel.level6
    );
  }};
  border-bottom: 1px solid
    ${(props) => {
      return (
        props.theme.palette.borderColorLevel[props.borderColorLevel!] ||
        props.theme.palette.borderColorLevel.level6
      );
    }};
`;

interface StyledRowProps {
  colorBgRow: ColorsBgRowType;
}

interface StyledBodyTdRowProps {
  colorBgRow: ColorsBgRowType;
}

export const StyledTableRow = styled(Box)<StyledRowProps>`
  background-color: ${(props) => props.theme.palette.bg[props.colorBgRow]};
  display: flex;
`;
export const StyledBodyTdRow = styled(Box)<StyledBodyTdRowProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  box-shadow: inset 0 -1px 0 rgba(0, 36, 95, 0.1);
  background-color: ${(props) => props.theme.palette.bg[props.colorBgRow]};
`;
export const StyledBoxScrollShow = styled(Box)`
  &::-webkit-scrollbar {
    box-shadow: inset 1px 0 0 rgba(0, 36, 95, 0.1);
  }
`;

export const StyledBoxRow = styled(Box)`
  width: 100%;
  display: flex;
  background: #0044b41a;
  height: 64px;
  color: #fff;
  align-items: center;
`;

export const StyledFieldForm = styled(FieldForm)`
  width: 100%;
  position: relative;
  & .MuiFilledInput-root {
    border: none;
  }

  & .MuiInputBase-input {
    padding: 4px 11px;
    background: #fff;
    border-radius: 4px;
    color: #2b3648;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid rgba(92, 110, 140, 0.2);

    &::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #9aa2b0;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #9aa2b0;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #9aa2b0;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #9aa2b0;
    }
  }

  .Mui-focused {
    .MuiInputBase-input {
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #ffffff;
      }
    }
  }

  & .MuiOutlinedInput-root {
    &:hover {
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid #0288d1;
      }
    }
  }
  & .Mui-focused {
    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid #0288d1;
    }
  }

  .Mui-error {
    .MuiInputBase-input {
      border: 1px solid red;
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: red;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: red;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: red;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: red;
      }
    }
  }

  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: 12px;
    left: 15px;
    color: #f46b6b;
    margin: 0;
  }
  & .MuiFormHelperText-root.MuiFormHelperText-filled {
    bottom: 14px;
    right: 12px;
    left: revert;
  }
`;

export const StyledFieldFormMin = styled(FieldForm)`
  width: 100%;
  position: relative;
  border-radius: 4px;

  & .MuiFilledInput-root {
    border: none;
    background-color: #fff;
  }

  & .MuiInputBase-input {
    padding: 4px 11px;
    border-radius: 4px;
    color: #2b3648;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    border: 1px solid rgba(92, 110, 140, 0.2);
    background: #ffffff;
    z-index: 1;
    &:hover {
      border: 1px solid #0288d1;
    }

    &::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #ffffff;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #ffffff;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #ffffff;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #ffffff;
    }
  }
  .Mui-focused {
    .MuiInputBase-input {
      &::-webkit-input-placeholder {
        color: #ffffff;
      }
    }
  }

  & .MuiOutlinedInput-root {
    &:hover {
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid #0288d1;
      }
    }
  }
  & .Mui-focused {
    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid #0288d1;
    }
  }

  .Mui-error {
    .MuiInputBase-input {
      border: 1px solid red;
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: red;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: red;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: red;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: red;
      }
    }
  }

  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: 3px;
    left: 15px;
    color: #f46b6b;
    margin: 0;
    z-index: 0;
  }
  & .MuiFormHelperText-root.MuiFormHelperText-filled {
    bottom: 14px;
    left: 120px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  padding: 0;
  // margin-right: 32px;
`;
//Typography
export const StyledTypographyLevel = styled(Typography)`
  color: #5c6e8c;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`;
export const StyledTypographyRow = styled(Typography)`
  color: #2b3648;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`;
export const StyledTypographyForm = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #9aa2b0;
`;
export const StyledPopper = styled(Popper)`
  inset: 0 auto auto -45px !important;
  width: auto;
  height: 32px;
  background: #ffffff;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  text-align: center;
`;
export const StyledButtonGroup = styled(ButtonGroup)`
  height: 32px;
  width: 100%;
  align-items: center;
`;
export const StyledDivider = styled(Divider)`
  margin: revert;
  height: 20px;
  width: 2px;
  background-color: rgba(122, 145, 247, 0.3);
  border-radius: 7px 7px 7px 7px;
  border-right-width: inherit;
`;
