import styled from 'styled-components';
import { AppBar, Box } from '@mui/material';
import { ReactComponent as OfferIcon } from '../../assets/icons/navbar/offer-logo.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/navbar/home.svg';

export const StyledHeader = styled(AppBar)`
  padding: 0 20px;
  background: ${(props) => props.theme.palette.bg.gray};
  box-shadow: none;
`;
export const LogoWrapper = styled(Box)`
  position: relative;
  height: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

/* cursor: pointer;
  &:hover::after {
    display: block;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.04);
  } */

export const OfferLogo = styled(OfferIcon)`
  width: 160px;
  height: 48px;
`;
export const StyledHomeLink = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 8px;
  height: 40px;
  &:hover {
    background-color: rgba(0, 68, 180, 0.04);
  }
`;

export const HomeLogo = styled(HomeIcon)`
  width: 40px;
  height: 40px;
`;
