// Common
import { TableCellProps } from '@mui/material';
import {
  CommonFiltersResponse,
  Filter,
  FiltersVorBody,
  GetFilteredVorPositionsResponse,
  PositionVOR,
} from '../../../../api/calculations/types';
import { FC, RefObject } from 'react';
import { TFunctionSelectPosition, TSelectPositionArr } from '../../Vor.types';
import { IVorPosition } from '../../../../api/calculationDictionary';

export interface PositionsTableProps {
  data: PositionVOR[];
  selectPosition: TSelectPositionArr;
  setSelectPosition: TFunctionSelectPosition;
  delArrId: number[];
  setSelectedVor: any;
  handleOpenModal: () => void;
  checkedData: Record<
    keyof FiltersVorBody,
    Record<keyof CommonFiltersResponse, Filter[]>
  >;
  type: 'group' | 'list';
  handleApplyFilters: () => void;
  checkFilter: (
    type: keyof FiltersVorBody,
    key: keyof CommonFiltersResponse,
    item: Filter | Filter[] | null,
    flag?: boolean,
  ) => void;
  viewFilters: GetFilteredVorPositionsResponse;
  applyedFilter: FiltersVorBody;
  container: RefObject<HTMLDivElement>;
}

export type SelectedPositionType = 'main' | 'extra';
export type SelectedSelectedPositionTypeWithTarget =
  | SelectedPositionType
  | 'target'
  | 'secondary';

// Common columns
export type PositionsTableColumn =
  | PositionsTableColumnWithField
  | PositionsTableColumnCustomCell
  | PositionsTableColumnSelfCell;

export type CommonPositionsTableColumn = TableCellProps & {
  label: string;
};

type PositionsTableColumnWithField = CommonPositionsTableColumn & {
  field: keyof PositionVOR | 'index';
  CustomCell?: never;
  SelfCell?: never;
};

type PositionsTableColumnCustomCell = CommonPositionsTableColumn & {
  CustomCell: FC<CustomCellProps>;
  field?: never;
  SelfCell?: never;
};

type PositionsTableColumnSelfCell = CommonPositionsTableColumn & {
  SelfCell: FC<SelfCellProps>;
  CustomCell?: never;
  field?: never;
};

export interface CustomCellProps {
  vor: PositionVOR | IVorPosition;
}

export interface SelfCellProps
  extends CommonPositionsTableColumn,
    CustomCellProps {}

export const TABLE_CELL_HEIGHT = 44;

export const CHECKBOX_CELL_WIDTH = 35;
export const NUMBER_CELL_WIDTH = 50;
export const LSR_CELL_WIDTH = 200;
export const LSR_NUMBER_CELL_WIDTH = 90;
export const CHAPTER_CELL_WIDTH = 200;
export const HEADER_CELL_WIDTH = 90;
export const CODE_CELL_WIDTH = 200;
export const TITLE_CELL_WIDTH = 250;
export const UNIT_CELL_WIDTH = 100;
export const QUANTITY_CELL_WIDTH = 100;
export const CONSTRUCTIONS_CELL_WIDTH = 100;
export const MECHANICS_CELL_WIDTH = 100;
export const TOTAL_COST_CELL_WIDTH = 120;
