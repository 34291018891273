import { ComplicatedRowResourceRow } from 'types';

export function getTotalWeight(rows: ComplicatedRowResourceRow[] | undefined) {
  if (!rows) return 0;

  return rows.reduce((acc, val) => {
    acc += val?.baseTotal || 0;
    return acc;
  }, 0);
}
