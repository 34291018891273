import { useBindGroupMutation, useDeleteGroupMutation } from 'api/calculations';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import { useSnackbar } from 'notistack';
import { BindGroupPayload } from './hooks.types';

export const useBindGroup = () => {
  const [deleteGroupMutation, deleteGroupResponse] = useDeleteGroupMutation();
  const [bindGroupMutation, bindGroupResponse] = useBindGroupMutation();
  const { enqueueSnackbar } = useSnackbar();

  useMutationHandlers(deleteGroupResponse, undefined, () => {
    enqueueSnackbar('Ошибка при удалении уровня', {
      variant: 'error',
    });
  });

  useMutationHandlers(bindGroupResponse, undefined, () => {
    enqueueSnackbar('Ошибка при разруппировке', {
      variant: 'error',
    });
  });

  const deleteGroup = ({
    isHandbook,
    calcID,
    rowID,
  }: Pick<BindGroupPayload, 'calcID' | 'rowID' | 'isHandbook'>) => {
    deleteGroupMutation({
      calcID,
      groupID: rowID,
      isHandbook,
    });
  };

  const bindGroup = ({
    calcID,
    rowID,
    parentID,
    groupIds,
    childrenIds,
    isHandbook,
  }: BindGroupPayload) => {
    bindGroupMutation({
      params: {
        calcID,
        groupID: parentID ?? rowID,
      },
      body: {
        children: childrenIds,
        groupChildren: isHandbook ? [] : groupIds,
      },
      isHandbook,
    }).then(() => {
      !isHandbook &&
        deleteGroupMutation({
          calcID,
          groupID: rowID,
          recursiveLevelDecrease: true,
        });
    });
  };

  return { bindGroup, deleteGroup };
};
