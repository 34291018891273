import { Typography } from '@mui/material';
import saveIcon from '../../assets/userGuide/save.svg';
import addIcon from '../../assets/userGuide/add.svg';
import deleteIcon from '../../assets/userGuide/delete.svg';
import { ReactComponent as IconDelete } from '../../assets/userGuide/IconDelete.svg';

export const content = [
  {
    title: 'Выбор “Ключевой работы”',
    content: (
      <>
        <Typography>
          Отметьте одну ключевую работу в списке основных работ. При
          необходимости измените или заполните данные, установите единицу
          измерения, а также наименование укрупненной расценки.
        </Typography>
        <Typography>
          После заполнения данных нажмите кнопку "Сохранить".
        </Typography>
      </>
    ),
    img: saveIcon,
  },
  {
    title: 'Добавление позиций в список',
    content: (
      <>
        <Typography>
          Нажмите кнопку “Добавить позиции в список”, окно укрупненной расценки
          закроется и Вы перейдете на несортированный список работ.
        </Typography>
        <Typography>
          Отметьте “Основные и дополнительные работы” в списке. После нажмите
          кнопку “Добавить выбранные позиции в список”.
        </Typography>
      </>
    ),
    img: addIcon,
  },
  {
    title: 'Удаление укрупненной расценки',
    content: (
      <>
        <Typography>
          Для удаления работы или материалов из списка нажмите на кнопку
          <IconDelete
            width={30}
            height={20}
            style={{ position: 'relative', top: '5px' }}
          />
          . После подтверждения позиция будет удалена из списка.
        </Typography>
        <Typography>
          Для удаления всей группы расценки нажмите на кнопку "Удалить группу
          позиций". После подтверждения удаления укрупненная расценка будет
          безвозвратно удалена.
        </Typography>
      </>
    ),
    img: deleteIcon,
  },
];
