interface getPersonInitialsProps {
  lastName: string;
  firstName: string;
  middleName?: string;
}

const getInitial = (personData: string) => {
  if (personData.length === 0) return personData;

  return personData.substring(0, 1).toUpperCase().concat('.');
};

export const getPersonInitials = (personInfo: getPersonInitialsProps) => {
  return `${personInfo.lastName} ${getInitial(personInfo.firstName)}${
    personInfo.middleName ? getInitial(personInfo.middleName) : ''
  }`;
};
