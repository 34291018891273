import styled from 'styled-components';
import { Alert } from '@mui/material';

export const StyledAlert = styled(Alert)`
  width: 100%;
  background: #fff;
  border: 1px solid #6d9adc;
  border-radius: 8px;
  padding: 14px;
  position: relative;
  align-items: center;
  //height: 70px;

  & .MuiAlert-message {
    flex-direction: column;
    padding: 0;
    height: 100%;
  }
  & .MuiTypography-body1 {
    margin: 0;
    padding: 0 18px;
    .MuiTypography-body2 {
      padding-right: 18px;
    }
  }
  & .MuiAlert-icon,
  .MuiAlert-action {
    padding: 0;
    margin: 0;
    height: 100%;
  }
  & .MuiButtonBase-root {
    color: rgba(0, 0, 0, 0.54);
    padding: 4px;
  }
`;
