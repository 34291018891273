import styled from 'styled-components';

export const StyledImg = styled('div')`
  border-radius: 16px;
  height: 100%;
  width: 440px;
  margin-right: 20px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
`;

export const StyledContent = styled('div')`
  overflow: auto;
  height: 100%;
  width: 440px;
`;

export const StyledNotImage = styled('div')`
  border-radius: 16px;
  width: 100%;
  height: 100%;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  letter-spacing: 1px;
`;
