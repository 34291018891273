/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { IHeaderGroupParams } from 'ag-grid-community';
import { useCallback, useEffect, useState } from 'react';
import { Title, Wrapper } from './CustomTableHeader.style';

const CustomTableHeader = (props: IHeaderGroupParams) => {
  const [expandState, setExpandState] = useState<'expanded' | 'collapsed'>(
    'collapsed',
  );

  const expandOrCollapse = useCallback(() => {
    const currentState = props.columnGroup
      .getProvidedColumnGroup()
      .isExpanded();
    props.setExpanded(!currentState);
  }, []);

  const syncExpandButtons = useCallback(() => {
    setExpandState(
      props.columnGroup.getProvidedColumnGroup().isExpanded()
        ? 'expanded'
        : 'collapsed',
    );
  }, []);

  useEffect(() => {
    props.columnGroup
      .getProvidedColumnGroup()
      .addEventListener('expandedChanged', syncExpandButtons);
    syncExpandButtons();
    // return () => {
    //   props.columnGroup.getProvidedColumnGroup().removeEventListener('expandedChanged', syncExpandButtons);
    // };
  }, []);
  return (
    <Wrapper onClick={expandOrCollapse} isOpen={expandState === 'expanded'}>
      <Title isOpen={expandState === 'expanded'}>{props.displayName}</Title>
      <ExpandCircleDownIcon />
    </Wrapper>
  );
};

export default CustomTableHeader;
