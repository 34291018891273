/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

/**
 * функция хелпер принимает объект и массив ключей этого объекта
 * возвращает true если все ключи имеют значение
 * и false если хоть одного не будет
 * @param obj - объект по которому ведется проверка
 * @param keys - массив ключей этого объекта
 * @param full - мод, если активен то ведется проверка на существование всех ключей в этом объекте
 * если мод выключен поиск ведется до первого активного значения в объекте
 *
 * (не знаю на сколько понятно, если что спрашивайте в дискорде или по почте =) )
 */
export function isEmpty<T>(
  obj: T,
  keys: Array<keyof typeof obj>,
  full: boolean = true
): boolean {
  // noinspection LoopStatementThatDoesntLoopJS
  if (full) {
    return keys.every((key) => {
      return obj[key];
    });
  } else {
    let flag = false;
    let key;
    for (key of keys) {
      if (obj[key]) {
        flag = true;
        break;
      }
    }
    return flag;
  }
}
