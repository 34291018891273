import React from 'react';
import { UserManagement } from 'components/UserManagement';
import { PublicUserProfile } from 'types';
import useBreadcrumbs from '../../../../hooks/useBreadcrumbs';

const AddUser: React.FC = () => {
  useBreadcrumbs([{ title: 'Создание пользователя' }]);

  return (
    <>
      <UserManagement profile={{} as PublicUserProfile} />
    </>
  );
};

export default AddUser;
