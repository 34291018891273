import {
  CommonFiltersResponse,
  Filter,
  FiltersVorBody,
  GetFilteredVorPositionsResponse,
  PositionVOR,
} from '../../api/calculations/types';
import { SelectedPositionType } from './components/PositionsTable';
import { IVorPosition } from '../../api/calculationDictionary';

export type VorTableVariant = 'positions' | 'grouped' | 'fullVor';

export type TType =
  | SelectedPositionType
  | 'secondary'
  | 'target'
  | null
  | undefined;

export type TSelectPositionArr = Array<PositionVOR | IVorPosition>;

export type TFunctionSelectPosition = (
  target: PositionVOR[] | PositionVOR,
  type: TType,
  arr: PositionVOR[],
) => void;

export interface VorFilters {
  activeFilters: FiltersVorBody;
  viewFilters: GetFilteredVorPositionsResponse;
}

export const initialFilters: VorFilters = {
  activeFilters: {
    formedFilter: {
      titleIDs: [],
      chapterIDs: [],
      lsrCodeIDs: [],
      lsrNumIDs: [],
      nameIDs: [],
      priceCodeIDs: [],
    },
    rowsFilter: {
      titleIDs: [],
      chapterIDs: [],
      lsrCodeIDs: [],
      lsrNumIDs: [],
      nameIDs: [],
      priceCodeIDs: [],
    },
  },
  viewFilters: {
    formedFilter: {
      titles: [],
      chapters: [],
      lsrCodes: [],
      lsrNums: [],
      names: [],
      priceCodes: [],
    },
    rowsFilter: {
      titles: [],
      chapters: [],
      lsrCodes: [],
      lsrNums: [],
      names: [],
      priceCodes: [],
    },
  },
};
export const initialChecks: Record<
  keyof FiltersVorBody,
  Record<keyof CommonFiltersResponse, Filter[]>
> = {
  formedFilter: {
    lsrNums: [],
    priceCodes: [],
    chapters: [],
    lsrCodes: [],
    names: [],
    titles: [],
  },
  rowsFilter: {
    lsrNums: [],
    priceCodes: [],
    chapters: [],
    lsrCodes: [],
    names: [],
    titles: [],
  },
};
