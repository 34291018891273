import { Card, Divider, Typography } from '@mui/material';
import styled from 'styled-components';
import { StatusColors } from 'types';

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 256px;
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  height: 100%;
  @media (max-width: 780px) {
    margin: 0 auto;
  }

  &:hover {
    cursor: pointer;
    box-shadow:
      0px 10px 13px -6px rgba(0, 0, 0, 0.2),
      0px 20px 31px 3px rgba(0, 0, 0, 0.14),
      0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  }
`;

export const StyledNumberCard = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 110%;
  color: #5c6e8c;
  text-align: right;
`;

export const StyledShortNameCalculation = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #0044b4;
  text-align: left;
  //display: -webkit-box;
  //-webkit-line-clamp: 1;
  //-webkit-box-orient: vertical;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;
export const StyledExtendsName = styled(StyledShortNameCalculation)`
  color: #2b3648;
  line-height: 130%;
  font-weight: 500;
  letter-spacing: 0.1px;
  font-size: 14px;
`;
export const StyledFullNameCalculation = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #2b3648;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface StyledStatusCalculationProps {
  colorstatus: StatusColors;
}

export const StyledStatusCalculation = styled(
  Typography,
)<StyledStatusCalculationProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${(props) => props.theme.palette.status[props.colorstatus]};
`;

export const StyledDivider = styled(Divider)<{ isIntegrate?: boolean }>`
  height: 2px;
  margin-left: 0;
  background-color: ${({ isIntegrate }) =>
    isIntegrate ? '#BA68C8' : '#cbd3f5'};
  border-bottom: none;
  border-radius: 1px;
  margin: 8px 0;
`;
