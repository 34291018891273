import { Box, Fade } from '@mui/material';
import React, { createContext, useCallback, useMemo, useState } from 'react';
import {
  PositionVOR,
  PositionVORWithTarget,
  UnitType,
} from '../../../api/calculations/types';
import { FormModal } from './FormModal/FormModal';
import useConfirmDialog, {
  UseExitConfirmProps,
} from '../../../hooks/useConfirmDialog';
import { UserGuide } from '../../../components/UserGuide/UserGuide';
import { content } from '../../../components/UserGuide/Content';
import { StyledContainer, StyledModal } from './styles';
import { TableModal } from './TableModal';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store/store';
import {
  clearWorks,
  refreshChangeableFieldsState,
} from '../../../store/slices/vor/vor';
import { TSelectPositionArr } from '../Vor.types';
import { IVorPosition } from '../../../api/calculationDictionary';

interface IContext {
  showed: boolean;
  setShowed: (prevState: boolean) => void;
}

export const FormContext = createContext<IContext>({} as IContext);

export interface ModalVORProps {
  isHandbook: boolean;
  selectPosition: TSelectPositionArr;
  resetFilters: () => void;
  mainWorks: (PositionVOR | IVorPosition)[];
  extraWorks: (PositionVOR | IVorPosition)[];
  vorPositions: (PositionVOR | IVorPosition)[];
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  handleDelRow: (delId: PositionVOR | IVorPosition, isEdit?: boolean) => void;
  selectedVor: any;
  isEdit: boolean;
  setSelectedTableVariant: any;
  delArrId: number[];
  dispatchVor: (vor: PositionVORWithTarget, cash: boolean) => void;
  setSelectPosition: any;
}

export const ModalVOR: React.FC<ModalVORProps> = ({
  mainWorks,
  isHandbook,
  extraWorks,
  openModal,
  setOpenModal,
  handleDelRow,
  resetFilters,
  selectedVor,
  isEdit,
  setSelectedTableVariant,
  dispatchVor,
  setSelectPosition,
  selectPosition,
}) => {
  const [showed, setShowed] = useState(true);

  const [openUserGuide, setOpenUserGuide] = React.useState<boolean>(false);
  const [selectUnit, setSelectUnit] = React.useState<UnitType>();
  const {
    mainWorks: mainWorksGrouped,
    extraWorks: extraWorksGrouped,
    initialWorks,
  } = useSelector((state: RootState) => state.vor.table.grouped);

  const dispatch = useAppDispatch();

  /****************************************** ConfirmDialog ********************************/
  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setOpenModal(false);
      setKeyWork({} as PositionVOR);
      setSelectUnit(undefined);
      setSelectPosition([], undefined, selectPosition as PositionVOR[]);
    }
  }, []);
  const close = () => {
    setSelectUnit(undefined);
    [...extraWorks, ...mainWorks].forEach((_) => {
      // console.log(1);
      // dispatchVor(_, true);
    });
    dispatch(clearWorks());
  };
  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
    handleClose() {
      close();
      dispatch(refreshChangeableFieldsState());
    },
  };

  const [keyWorkId, setKeyWorkId] = React.useState<number | null>(null);

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog);

  const [keyWork, setKeyWork] = React.useState<PositionVOR>({} as PositionVOR);

  const handleCloseModal = useCallback(() => {
    if (isEdit && !showed) {
      handleConfirm(true);
      dispatch(clearWorks());
      dispatch(refreshChangeableFieldsState());
    } else {
      openConfirm();
    }
  }, [showed]);

  const handleOpenUserGuide = useCallback(() => {
    setOpenUserGuide(!openUserGuide);
  }, [openUserGuide]);
  const collapsed = useMemo(() => {
    if (isEdit) {
      return {
        extra: extraWorksGrouped,
        main: mainWorksGrouped,
      };
    } else {
      return {
        extra: extraWorks,
        main: mainWorks,
      };
    }
  }, [isEdit, initialWorks.extra, initialWorks.main, extraWorks, mainWorks]);
  console.log(collapsed.extra, collapsed.main);
  return (
    <div>
      <StyledModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition>
        <FormContext.Provider value={{ showed, setShowed }}>
          <Fade in={openModal}>
            <StyledContainer>
              <Box>
                <FormModal
                  isHandbook={isHandbook}
                  selectPosition={selectPosition}
                  setSelectPosition={setSelectPosition}
                  resetFilters={resetFilters}
                  dispatchVor={dispatchVor}
                  handleOpenUserGuide={handleOpenUserGuide}
                  handleCloseModal={handleCloseModal}
                  setSelectedTableVariant={setSelectedTableVariant}
                  setOpenModal={setOpenModal}
                  selectedVor={selectedVor}
                  setSelectUnit={setSelectUnit}
                  selectUnit={selectUnit!}
                  keyWork={keyWork}
                  setKeyWork={setKeyWork}
                  extraWorks={collapsed.extra}
                  mainWorks={collapsed.main}
                  setKeyWorkId={setKeyWorkId}
                  isEdit={isEdit}
                />
                <TableModal
                  isEdit={isEdit}
                  isHandbook={isHandbook}
                  handleDelRow={handleDelRow}
                  extraWorks={collapsed.extra}
                  mainWorks={collapsed.main}
                  keyWorkId={keyWorkId}
                />
              </Box>
            </StyledContainer>
          </Fade>
        </FormContext.Provider>
      </StyledModal>

      <ConfirmDialog />
      <UserGuide
        open={openUserGuide}
        onClose={handleOpenUserGuide}
        setOpenUserGuide={setOpenUserGuide}
        steps={content.filter((step) =>
          isEdit ? true : step.title !== 'Добавление позиций в список',
        )}
      />
    </div>
  );
};
