import { Box, Drawer as MUIDrawer, Stack } from '@mui/material';
import { FC } from 'react';
import { Divider } from '../Divider';
import {
  StyledContentWrapper,
  StyledSubtitle,
  StyledTitle,
  StyledTitleButtonWrapper,
} from './Drawer.styles';
import { DrawerProps } from './Drawer.types';

export const Drawer: FC<DrawerProps> = ({
  title,
  subtitle,
  titleStartButton,
  titleEndButton,
  anchor = 'right',
  children,
  open,
  width = 400,
  ...rest
}) => {
  return (
    <MUIDrawer
      PaperProps={{ sx: { width, overflow: 'hidden' } }}
      anchor={anchor}
      open={open}
      {...rest}>
      <Stack flex="1 1 auto" overflow="auto">
        <Box position="relative">
          {titleStartButton && (
            <StyledTitleButtonWrapper positionTitle={'left'}>
              {titleStartButton}
            </StyledTitleButtonWrapper>
          )}
          <StyledTitle variant="h1">{title}</StyledTitle>
          {titleEndButton && (
            <StyledTitleButtonWrapper>
              {titleEndButton}
            </StyledTitleButtonWrapper>
          )}
        </Box>
        <Divider />
        {subtitle && (
          <>
            <StyledSubtitle variant="subtitle2">{subtitle}</StyledSubtitle>
            <Divider />
          </>
        )}
        <StyledContentWrapper>{children}</StyledContentWrapper>
      </Stack>
    </MUIDrawer>
  );
};
