import { useGetGroupPositionQuery } from 'api/calculations';
import { useCalcId } from 'hooks/useCalcId';
import { useEffect, useMemo, useRef, useState } from 'react';
import { VORGroupData } from '../../../../../api/calculations/types';
import { Head } from '../Head';
import { TreeNodeRow } from '../TreeNodeRow';
import { TreeNodeContextProvider } from '../TreeNodeRow/context';
import { TreeNodeContextConsumer } from '../TreeNodeRow/context/TreeNodeContext/TreeNodeContext.consumer';
import { StyledBody } from './Body.styles';
export type TDataMap = Map<
  number,
  { parentID: number | null; neighbors: VORGroupData[] }
>;
export const Body: React.FC<{ isHandbook: boolean }> = ({ isHandbook }) => {
  const calcID = useCalcId();
  // const groupMap = useRef<Map<number, number[]>>(new Map());
  // const childMap = useRef<Map<number, number[]>>(new Map());
  const [dataMap, setDataMap] = useState<TDataMap>(new Map());

  const { data } = useGetGroupPositionQuery(
    { calcID, isHandbook },
    { skip: !calcID }
  );

  // const [getGroupPosition, { data }] = useLazyGetGroupPositionQuery();

  function calculateDepth(
    group: VORGroupData,
    parentID: number | null,
    neighbors: VORGroupData[]
  ) {
    setDataMap((prevState) => {
      prevState.set(group.id, {
        parentID,
        neighbors
      });
      return prevState;
    });

    if (!group.children || group.children.length === 0) {
      return 1;
    } else {
      let maxDepth = 0;
      for (const child of group.children) {
        const depth = calculateDepth(child, group.id, group.children);
        maxDepth = Math.max(maxDepth, depth);
      }
      return maxDepth + 1;
    }
  }

  const maxDeep = useMemo(() => {
    const result = [];
    if (data?.data) {
      for (const root of data.data) {
        result.push(calculateDepth(root, null, data.data));
      }
    }
    return result;
  }, [data?.data]);
  console.log(dataMap);
  return (
    <TreeNodeContextProvider
      maxDeep={maxDeep}
      dataMap={dataMap}
      levelIds={{}}
      rowID={undefined}
      level={0}
      rowType="folded"
      hasParent={false}
      data={data?.data}
      rowChildren={data?.data}>
      <TreeNodeContextConsumer>
        {({
          level,
          maxDeep,
          dataMap,
          groupIds,
          levelIds: parentIds,
          childrenIds,
          selectedIds,
          formVisible,
          onSetSelectedIds,
          hasParent
        }) => (
          <>
            <Head isHandbook={isHandbook} />
            <StyledBody>
              {data && (
                <TreeNodeRow
                  dataMap={dataMap}
                  isHandbook={isHandbook}
                  maxDeep={maxDeep}
                  levelIds={parentIds}
                  level={level}
                  hasParent={hasParent}
                  rows={data.data}
                  checkboxShowed={formVisible.move}
                  selectedIds={selectedIds}
                  childrenIds={childrenIds}
                  groupIds={groupIds}
                  data={data?.data}
                  onSetSelectedIds={onSetSelectedIds}
                />
              )}
            </StyledBody>
          </>
        )}
      </TreeNodeContextConsumer>
    </TreeNodeContextProvider>
  );
};
