import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { useCalcId } from 'hooks/useCalcId';
import { useRenameLevel, useTransferElement } from 'pages/FullVor/Table/hooks';
import { FC, useState } from 'react';
import { useTreeNodeContext } from '../../../../context';
import { LevelSelect } from '../CreateLevelForm/components';
import { GroupCount } from '../GroupCount';
import { getLevelLeftOffset } from './GroupRow.service';
import {
  StyledGroupRowInfo,
  StyledGroupRowWrapper,
  StyledInput,
  StyledLevelFolder
} from './GroupRow.styles';
import { GroupRowProps } from './GroupRow.types';
import { useGroupSum } from './hooks/useGroupSum';

export const GroupRow: FC<GroupRowProps> = ({
  index,
  parentGroupIds,
  parentChildrenLength,
  rowChildren,
  isHover,
  isEdit,
  onClose,
  isHandbook
}): JSX.Element => {
  const calcID = useCalcId();
  const {
    rowID,
    maxDeep,
    level,
    rowTitle,
    childrenIds,
    groupIds,
    isExpanded,
    levelIds
  } = useTreeNodeContext();
  const { constructionEffortSum, mechanicsEffort, totalWorkCost } =
    useGroupSum(rowChildren);
  const { renameLevel } = useRenameLevel();
  const { transferElement } = useTransferElement(isHandbook);
  const [newTitle, setNewTitle] = useState<string>(rowTitle ?? '');
  const [selectedLevel, setSelectedLevel] = useState(level);

  const isRenameButtonDisabled =
    (!newTitle || newTitle === rowTitle) && selectedLevel === level;
  const isSelectLevelShowed = isEdit && (index !== 0 || level !== 1);
  const toLevelId =
    level < selectedLevel
      ? index - parentChildrenLength > 0
        ? parentGroupIds[index - parentChildrenLength - 1]
        : parentGroupIds[index - parentChildrenLength + 1]
      : (levelIds[selectedLevel - 1] ?? undefined);

  const renameLevelClickHandler = () => {
    if (!rowID || !rowTitle) return;

    if (newTitle !== rowTitle) {
      renameLevel({
        calcID,
        groupID: rowID,
        title: newTitle
      });
    }

    if (selectedLevel !== level) {
      transferElement({
        calcID,
        childrenIds: [],
        groupIds: [rowID],
        to: toLevelId
      });
    }

    onClose();
  };

  const selectLevelHandler = (level: number) => {
    setSelectedLevel(level);
  };
  return (
    <StyledGroupRowWrapper $isHandbook={isHandbook}>
      <StyledGroupRowInfo>
        {isSelectLevelShowed ? (
          <LevelSelect
            // currentLevel={groupIds.length <= 2 ? level - 1 : level}
            currentLevel={parentGroupIds.length > 1 ? level : level - 1}
            isEdit
            ml={`${getLevelLeftOffset(level)}px`}
            selectedLevel={selectedLevel}
            neighborIds={parentGroupIds}
            hideLevelsMore={parentGroupIds.length === 1}
            onSetLevel={selectLevelHandler}
          />
        ) : (
          <StyledLevelFolder
            level={level}
            size="large"
            isExpanded={isExpanded}
            isHover={isHover}
          />
        )}
        {!isEdit ? (
          <>
            <Typography variant="subtitle2">{rowTitle}</Typography>
            <GroupCount
              description="Сгруппировано"
              childrenCount={childrenIds.length}
              groupCount={groupIds.length}
            />
          </>
        ) : (
          <>
            <StyledInput
              onClick={(e) => e.stopPropagation()}
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              fullWidth
            />
            <Stack ml={2} direction="row" spacing={2}>
              <Button
                color="success"
                size="small"
                disableElevation
                disabled={isRenameButtonDisabled}
                onClick={(e) => {
                  e.stopPropagation();
                  renameLevelClickHandler();
                }}>
                Сохранить
              </Button>
              <Button
                size="small"
                disableElevation
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}>
                Отменить
              </Button>
            </Stack>
          </>
        )}
      </StyledGroupRowInfo>
      <Tooltip title={constructionEffortSum}>
        <Box textAlign="right">{constructionEffortSum}</Box>
      </Tooltip>

      <Box></Box>
      <Tooltip title={mechanicsEffort}>
        <Box textAlign="right">{mechanicsEffort}</Box>
      </Tooltip>
      <Box></Box>
      <Tooltip title={totalWorkCost}>
        <Box textAlign="right" pr={2}>
          {totalWorkCost}
        </Box>
      </Tooltip>
    </StyledGroupRowWrapper>
  );
};
