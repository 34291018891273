import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { cloneDeep } from 'lodash';
import { ChangeEvent, FC, useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  calculationDictionaryApi,
  IVorPosition,
} from '../../../../../../api/calculationDictionary';
import { calculationsApi } from '../../../../../../api/calculations';
import { PositionVOR } from '../../../../../../api/calculations/types';
import { useAppDispatch } from '../../../../../../store/store';
import {
  PositionVorContext,
  SelectedPositionType,
} from '../../../PositionsTable';
import { StyledCheckbox } from './CheckboxCell.styles';
import { CheckboxCellProps } from './CheckboxCell.types';

const binarySearch = (
  data: MaybeDrafted<PositionVOR[] | IVorPosition[]>,
  target: PositionVOR | IVorPosition,
  start: number,
  end: number,
): number | undefined => {
  if (end < 1) return 0;
  const middle = Math.floor(start + (end - start) / 2);

  if (target.id === data[middle].id) return middle;
  if (target.id === data[start].id) return start;
  if (target.id === data[end].id) return end;
  if (target.id > data[middle].id)
    return binarySearch(data, target, middle, end);
  if (target.id < data[middle].id)
    return binarySearch(data, target, start, middle);

  return undefined;
};

export const CheckboxCell: FC<CheckboxCellProps> = ({
  iconProps,
  indexVor,
  update,
  vor,
  type,
}) => {
  const { selectPositions } = useContext(PositionVorContext);
  const { calcID: calcIdStr } = useParams<{ calcID: string }>();
  // const {  } = useSelector((state: RootState) => state.vor.table);
  const dispatch = useAppDispatch();
  //
  const calcId = Number(calcIdStr);
  // console.log(indexVor);
  const updateTypeInStore = useCallback(
    (type: SelectedPositionType | null, isShift?: boolean) => {
      const localData = JSON.parse(
        (localStorage.getItem('check') as any) || 'null',
      );
      let firstCheck: number | undefined,
        t: SelectedPositionType | null | undefined;
      // console.log(localData);
      if (localData) {
        firstCheck = localData[0];
        t = localData[1] as typeof type;
      }
      // dispatch(
      //   calculationsApi.util.updateQueryData('getVorPositions', { calcId }, (draft) => {
      //     // const changedVorIndex = [...draft.data].findIndex((thisVor) => thisVor.id === vor.id);
      //     const changedVorIndex: any = binarySearch(draft.data, vor, 0, [...draft.data].length - 1);
      //     if (changedVorIndex !== undefined) {
      //       // const updated = { ...changedVorIndex, type };
      //       draft.data[changedVorIndex].type = type;
      //       update(vor, type);
      //     }
      //   }),
      // );
      // console.log(firstCheck);
      if (typeof firstCheck === 'number' && isShift) {
        // console.log(firstCheck, isShift);
        dispatch(
          calculationsApi.util.updateQueryData(
            'getVorPositions',
            { calcId },
            (draft) => {
              // const firstCheckIndex = [...draft.data].findIndex((thisVor) => thisVor.id === firstCheck!.id);
              // const changedVorIndex: number | undefined = binarySearch(draft.data, vor, 0, [...draft.data].length - 1);
              // const min = Math.min(firstCheck!, (changedVorIndex! || 0));
              // const max = Math.max(firstCheck!, (changedVorIndex || 0));
              const min = Math.min(firstCheck!, indexVor!);
              const max = Math.max(firstCheck!, indexVor!);
              // const count = max - min;
              // console.log(min, max, count);
              // console.log('draft', cloneDeep(draft.data));
              update(
                [
                  ...cloneDeep(draft.data)
                    .slice(min, max! + 1)
                    .map((_) => ({ ..._ })),
                ],
                t ?? null,
              );
              // if (changedVorIndex !== undefined) {
              //   for (let i = 0; i <= count; i++) {
              //     console.log(min + i);
              //     // draft.data[min + i].type = t??null;
              //     update({ ...draft.data[min + i] }, t ?? null);
              //   }
              //   localStorage.setItem("check", JSON.stringify([changedVorIndex, type]));
              // }
            },
          ),
        );
        dispatch(
          calculationDictionaryApi.util.updateQueryData(
            'getHandbookVorPositions',
            { calcID: calcId },
            (draft) => {
              // const firstCheckIndex = [...draft.data.elements].find((thisVor) => thisVor.id === firstCheck!.id);
              // const changedVorIndex: number | undefined = binarySearch([...draft.data.elements], vor, 0, [...draft.data.elements].length - 1);
              const min = Math.min(firstCheck!, indexVor!);
              const max = Math.max(firstCheck!, indexVor!);
              // const count = max - min;
              // console.log(firstCheck,min, max, count,changedVorIndex,t);
              // if (changedVorIndex !== undefined) {
              update(
                [
                  ...cloneDeep(draft.data.elements)
                    .slice(min, max! + 1)
                    .map((_) => ({ ..._ })),
                ],
                t ?? null,
              );
              //   // for (let i = 0; i <= count; i++) {
              //   //   console.log(min + i);
              //   //   // draft.data[min + i].type = t??null;
              //   //   update({ ...draft.data.elements[min + i] }, t ?? null);
              //   // }
              // }
            },
          ),
        );
        localStorage.setItem('check', JSON.stringify([indexVor, type]));
      } else {
        // console.log(indexVor);
        localStorage.setItem('check', JSON.stringify([indexVor, type]));
        update(vor, type);
      }
      // else{
      //       localStorage.setItem("check", JSON.stringify([vor,type]));
      // dispatch(
      //   calculationsApi.util.updateQueryData('getVorPositions', { calcId }, (draft) => {
      //     // const changedVorIndex = [...draft.data].findIndex((thisVor) => thisVor.id === vor.id);
      //     const changedVorIndex = binarySearch(draft.data, vor, 0, [...draft.data].length - 1);
      //     localStorage.setItem("check", JSON.stringify([changedVorIndex,type]));
      //     if (changedVorIndex !== undefined) {
      //       // const updated = { ...changedVorIndex, type };
      //       // draft.data[changedVorIndex].type = type;
      //       update({ ...draft.data[changedVorIndex] }, type);
      //     }
      //   }),
      // );

      // }
    },
    [indexVor],
  );

  const onChange =
    (type: SelectedPositionType) => (e: ChangeEvent<HTMLInputElement>) => {
      const isShift =
        'shiftKey' in e.nativeEvent
          ? (e.nativeEvent.shiftKey as boolean)
          : undefined;
      // e.target.checked ? updateTypeInStore(type,isShift) : updateTypeInStore(null,isShift);
      e.target.checked
        ? updateTypeInStore(type, isShift)
        : updateTypeInStore(null, isShift);
    };

  const checked = useMemo(() => {
    // console.log(selectPositions);
    const find = selectPositions.find((_) => _.id === vor.id);
    // console.log(find, type, vor.id);

    return find?.type === type;
  }, [selectPositions, vor, type]);

  return (
    // <StyledTableCell {...column} style={{ padding: 0, minWidth: '45px', maxWidth: '45px', width: '45px' }} >
    <StyledCheckbox
      key={type || ''}
      checked={checked}
      onChange={onChange(type)}
      icon={<RadioButtonUncheckedIcon {...iconProps} />}
      checkedIcon={<CheckCircleIcon {...iconProps} />}
    />
    // </StyledTableCell >
  );
};
