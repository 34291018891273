import styled from 'styled-components';
import { Stack } from '@mui/material';

export const VorPageWrapper = styled(Stack)`
  margin-top: 10px;
  background-color: ${(props) => props.theme.palette.bg.white};
  border-radius: 16px;
  height: calc(100vh - 100px);

  & .MuiDivider-root {
    height: 4px;
    background-color: rgba(25, 118, 210, 0.08);
  }
`;
