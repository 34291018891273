import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { AlertTitle, Box, Snackbar, Stack, Typography } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps
} from '@mui/material/LinearProgress';
import { useWS } from 'api/web-socket';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Loader } from './icons/Loader';
import { StyledAlert } from './styles';

interface WSCustomSnackbarProps {
  isDrawerOpen: boolean;
  wsType: 'lsr' | 'handbook' | 'calc' | 'handbookCopy';
}

export default function WSCustomSnackbar({
  isDrawerOpen,
  wsType
}: WSCustomSnackbarProps) {
  const {
    connectionStatus,
    getIsError,
    getIsProgress,
    getIsSuccess,
    getPercent
  } = useWS();

  const isSuccess = useMemo(() => getIsSuccess(wsType), [getIsSuccess, wsType]);
  const isError = useMemo(() => getIsError(wsType), [getIsError, wsType]);
  const isProgress = useMemo(
    () => getIsProgress(wsType),
    [getIsProgress, wsType]
  );
  const percent = useMemo(() => getPercent(wsType), [getPercent, wsType]);
  const [open, setOpen] = useState(false);
  const [colorVar, setColor] = useState<string>('');

  useEffect(() => {
    if (isProgress && !isDrawerOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isDrawerOpen, isProgress]);

  useEffect(() => {
    if (connectionStatus !== 'Open') {
      setColor('#F46B6B');
    } else if (isProgress === true) {
      setColor('#2B3648');
    } else if (isSuccess === true) {
      setColor('#2E7D32');
    } else if (isError === true) {
      setColor('#F46B6B');
    }
  }, [connectionStatus, isError, isProgress, isSuccess]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  function LinearProgressPercent(
    props: LinearProgressProps & { value: number }
  ) {
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
      height: '6px',
      borderRadius: '15px',
      boxShadow:
        'inset 0px -1px 1px rgba(52, 89, 254, 0.15), inset 0px 1px 1px rgba(52, 89, 254, 0.15)',
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
          theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#6D9ADC' : '#308fe8'
      }
    }));

    return (
      <Box
        sx={{
          width: '96%',
          position: 'absolute',
          bottom: 0,
          left: '10px'
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <BorderLinearProgress variant="determinate" {...props} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography
              variant="body2"
              style={{ padding: '0 10px' }}
              color="#2B3648">{`${Math.round(props.value)}%`}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={isSuccess ? 6000 : null}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <StyledAlert
          onClose={handleClose}
          variant="outlined"
          icon={
            (isProgress && <Loader />) ||
            (isSuccess && <DoneIcon sx={{ color: '#2E7D32' }} />) ||
            (isError && <ErrorOutlineIcon sx={{ color: '#F46B6B' }} />)
          }>
          <AlertTitle
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '20px',
              letterSpacing: '0.17px',
              color: `${colorVar}`,
              display: 'flex',
              alignItems: 'center'
            }}>
            {isProgress && (
              <>
                <Typography variant="body2" sx={{ lineHeight: '32px' }}>
                  Идет загрузка данных.
                  {percent && percent > 0
                    ? ` Загружено ${Math.floor(percent * 100)}%`
                    : ' Получаем данные о файлах....'}
                </Typography>
              </>
            )}
            {isSuccess && `Данные успешно загружены!`}
            {isError && (
              <Typography variant="body2" sx={{ lineHeight: '44px' }}>
                Не удалось загрузить файлы
              </Typography>
            )}
          </AlertTitle>
          {isProgress && percent && (
            <LinearProgressPercent value={Math.floor(percent * 100)} />
          )}
        </StyledAlert>
      </Snackbar>
    </Stack>
  );
}
