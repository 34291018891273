/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React from 'react';
import { Checkbox, FormLabel, Stack, Typography } from '@mui/material';
import Tooltip from 'components/Tooltip';
import { IShortProject } from 'api/projects/projects.types';

type TProps = React.PropsWithChildren<
  Partial<{ item: IShortProject }> & {
    projectsList: IShortProject[];
    blockedIDs: number[];
    selectedProjects: number[];
    setSelected: (v: number[]) => void;
  }
>;

const CheckItem: React.FC<TProps> = ({
  children,
  item,
  projectsList,
  blockedIDs,
  setSelected: setFieldValue,
  selectedProjects: values
}) => {
  const allFilteredChecked = React.useCallback(
    (values: number[], projectsList: IShortProject[]) =>
      projectsList.every((item) => values.includes(item.id)),
    []
  );

  const someFilteredChecked = React.useCallback(
    (values: number[], projectsList: IShortProject[]) => {
      console.log(projectsList.some((item) => values.includes(item.id)));
      return (
        (!allFilteredChecked(values, projectsList) &&
          projectsList.some((item) => values.includes(item.id))) ||
        (values.length && values.length !== projectsList.length) ||
        false
      );
    },
    [allFilteredChecked]
  );

  const handleSelectAll = (values: number[], projectList: IShortProject[]) => {
    if (allFilteredChecked(values, projectList)) {
      console.log(1);
      setFieldValue(
        values.filter((id) => !projectList.some((el) => el.id === id))
      );
    } else {
      console.log(2);
      const newSelected = projectList.map((item) => item.id);
      setFieldValue([...new Set([...values, ...newSelected])]);
    }
  };
  const handleToggleItem = (id: number) => {
    if (values.includes(id)) {
      setFieldValue(values.filter((itemId) => itemId !== id));
    } else {
      setFieldValue([...values, id]);
    }
  };
  return (
    <Stack
      component={FormLabel}
      sx={{ cursor: 'pointer' }}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}>
      {item ? (
        <Checkbox
          onChange={() => handleToggleItem(item.id)}
          checked={values.includes(item.id) || blockedIDs.includes(item.id)}
          disabled={blockedIDs.includes(item.id)}
          value={item.id}
        />
      ) : (
        <Checkbox
          checked={allFilteredChecked(values, projectsList)}
          onChange={() => handleSelectAll(values, projectsList)}
          indeterminate={someFilteredChecked(values, projectsList)}
        />
      )}
      <Tooltip placement={'left'} title={''}>
        <Typography
          maxWidth={'305.2px'}
          whiteSpace={'nowrap'}
          overflow={'hidden'}
          textOverflow={'ellipsis'}>
          {children}
        </Typography>
      </Tooltip>
    </Stack>
  );
};

export default React.memo(CheckItem);
