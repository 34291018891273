import { PageStyled } from './FullVor.styles';
import { Table } from './Table';
import Topbar from './Topbar/Topbar';

const FullVor: React.FC<{ isHandbook: boolean }> = ({ isHandbook }) => {
  return (
    <PageStyled>
      <Topbar isHandbook={isHandbook} />
      <Table isHandbook={isHandbook} />
    </PageStyled>
  );
};

export default FullVor;
