import React from 'react';
import { AdminNavBarItemProps } from './AdminNavBarItem.types';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { ListItemIcon, ListItemText } from '@mui/material';
import { StyledListItem } from './styles';
import { useSelector } from 'react-redux';
import { getPricesState } from '../../../store/slices/references/prices/prices';
import { useAppDispatch } from '../../../store/store';

class NavBarValue {
  url: string = '';
  selected: boolean = false;
}

const AdminNavBarItem: React.FC<AdminNavBarItemProps> = ({
  label,
  icon: Icon,
  link,
  selectedIcon: SelectedIcon,
}) => {
  const location = useLocation();
  const { isCreated } = useSelector(getPricesState);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const value = new NavBarValue();

  value.url = link;

  value.selected = matchPath(location.pathname, value.url) !== null;

  const listItem = (
    <StyledListItem selected={value.selected}>
      <ListItemIcon>
        {value.selected && SelectedIcon ? (
          <SelectedIcon height="24" width="24" />
        ) : (
          <Icon height="24" width="24" />
        )}
      </ListItemIcon>
      <ListItemText primary={label} />
    </StyledListItem>
  );

  return (
    <>
      <Link
        to={value.url}
        onClick={(e) => {
          if (isCreated) {
            e.preventDefault();
            e.stopPropagation();
            navigate(value.url);
            return;
          }
        }}>
        {listItem}
      </Link>
    </>
  );
};

export default AdminNavBarItem;
