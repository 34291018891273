import { ButtonProps } from '@mui/material';
import { FC } from 'react';
import { StyledCircleButton } from './CircleButton.styles';

export const CircleButton: FC<ButtonProps> = ({
  children,
  ...props
}): JSX.Element => {
  return <StyledCircleButton {...props}>{children}</StyledCircleButton>;
};
