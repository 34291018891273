import { Divider } from 'components/Divider';
import React from 'react';
import { FullUserCardWrapper } from '../../AdminUsers/AdminUsers.styles';
import { ProjectCardProps } from './ProjectCard.types';
import { ProjectCardContent } from './ProjectCardContent';
import { ProjectCardContentData } from './ProjectCardContent/ProjectCardContent.types';
import { ProjectCardHeader } from './ProjectCardHeader';
import { ProjectCardHeaderData } from './ProjectCardHeader/ProjectCardHeader.types';

export const ProjectCard: React.FC<ProjectCardProps> = ({ data }) => {
  const { fullName, shortName, status, type, startDate, endDate } = data;

  const headerData: ProjectCardHeaderData = { status, shortName, fullName };
  const contentData: ProjectCardContentData = { startDate, endDate, type };

  return (
    <FullUserCardWrapper>
      <ProjectCardHeader data={headerData} />
      <Divider rounded />
      <ProjectCardContent data={contentData} />
    </FullUserCardWrapper>
  );
};
