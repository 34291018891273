/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { Box, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Drawer } from '../../../../../../components/Drawer';
import React, { useContext } from 'react';
import { TanglContext } from '../../CalculationDrawerForm';
import { ReactComponent as TanglIcon } from 'assets/icons/tanglIcon.svg';
import RowTangle from './RowTangle';

const TangleDrawer = () => {
  const { models, open, toggle, currentModel } = useContext(TanglContext);

  return (
    <Drawer
      title="&nbsp;"
      open={open}
      titleStartButton={
        <Button
          onClick={() => {
            toggle(false);
          }}
          variant={'text'}>
          <ArrowBackIcon />
          Назад
        </Button>
      }
      titleEndButton={
        <Box
          display={'flex'}
          gap={'14px'}
          fontWeight={500}
          mr={'10px'}
          alignItems={'center'}
          color={(theme) => theme.palette.primary.main}>
          <TanglIcon />
          Интеграция Tangl
        </Box>
      }
      onClose={() => toggle(false)}
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      ModalProps={{
        keepMounted: true,
        disablePortal: true,
      }}>
      <Box
        p={'8px 30px 30px'}
        height={'100%'}
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'column'}>
        <Box>
          {models.map((folder) => (
            <RowTangle
              key={folder.id}
              folder={folder}
              padding={1}
              parent={{ id: folder.id, companyId: folder.companyId }}
            />
          ))}
        </Box>
        <Button
          disabled={!currentModel}
          onClick={() => toggle(false)}
          color={'success'}>
          Выбрать
        </Button>
      </Box>
    </Drawer>
  );
};

export default TangleDrawer;
