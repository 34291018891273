/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 * @author CyrilStrone<77802640+CyrilStrone@users.noreply.github.com>
 */

import { ICoefficientData } from 'api/references/estimates/estimates.types';
import React from 'react';
import { IParametersUpdateValue, Row } from '../../';

export const ListRow = ({
  list,
  itemKey,
  updateValue,
  actId
}: {
  list: ICoefficientData[];
  itemKey: string;
  updateValue: <T extends keyof ICoefficientData>(
    props: IParametersUpdateValue<T>
  ) => void;
  actId?: number;
}) => {
  return (
    <React.Fragment>
      {list?.map((row, index) => {
        return (
          <Row
            updateValue={updateValue}
            itemKey={`${itemKey}.${index}`}
            row={row}
            key={index}
            actId={actId}
          />
        );
      })}
    </React.Fragment>
  );
};
