import { StyledHead } from './Head.styles';

export const Head: React.FC = () => {
  return (
    <StyledHead>
      <div>Наименование сметы и раздела</div>
      <div style={{ paddingLeft: '15px' }}>Коэф. НР</div>
      <div style={{ paddingLeft: '15px' }}>Коэф. СП</div>
    </StyledHead>
  );
};
