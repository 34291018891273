import { Indicator } from 'api/params/params.types';
import { IndicatorSelectValue } from './ChapterItem.types';

export const toggleButtonGroupLabels: Record<Indicator, string> = {
  coeff: 'Коэф.',
  percent: 'Процент',
  sum: 'Сумма',
};

export const toggleButtonGroupButtons: IndicatorSelectValue[] = [
  { label: toggleButtonGroupLabels.coeff, value: 'coeff' },
  { label: toggleButtonGroupLabels.percent, value: 'percent' },
  { label: toggleButtonGroupLabels.sum, value: 'sum' },
];
