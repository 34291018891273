import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PositionVOR,
  PositionVORWithTarget,
  UnitType
} from '../../../api/calculations/types';
import {
  IAddVor,
  IChangeableSector,
  IChangeableSectorWithPayload,
  TableVariant,
  UpdateDeletedRowsPayload,
  VorState
} from './types';

type ChangeFieldType = {
  value?: string;
  isEdit?: boolean;
  field: keyof IChangeableSector;
  replace?: boolean;
};
type ChangeFieldTypeAction = ChangeFieldType[] | ChangeFieldType;
const initChangeableFieldState: IChangeableSector = {
  selectUnit: {
    value: '',
    isEdit: false
  },
  title: {
    value: '',
    isEdit: false
  },
  quantity: {
    value: '',
    isEdit: false
  }
};

const initialState: VorState = {
  table: {
    variant: 'positions',
    isAddingVor: false,
    savingData: null,
    target: null,
    selectedPositions: [],
    vorId: null,

    positions: {
      data: [],
      mainWorks: [],
      extraWorks: []
    },

    changeableFields: {
      title: {
        value: '',
        isEdit: false
      },
      selectUnit: {
        value: '',
        isEdit: false
      },
      quantity: {
        value: '',
        isEdit: false
      }
    },

    grouped: {
      data: [],
      isOneLoad: true,
      selectedVor: {} as PositionVOR,
      initialWorks: {
        main: [],
        extra: []
      },
      mainWorks: [],
      extraWorks: []
    },
    deletedRows: []
  },

  modalVisible: false
};

function addUniqueItems(arr: any, itemsToAdd: any) {
  const temp = [...arr];
  itemsToAdd.forEach((itemToAdd: any) => {
    const existingItem = arr.find((item: any) => item.id === itemToAdd.id); // Поиск элемента с таким же id в массиве

    if (!existingItem) {
      temp.push(itemToAdd); // Добавление элемента в массив, если элемент с таким же id не найден
    }
  });

  return temp;
}

const vorSlice = createSlice({
  name: 'vorSlice',
  initialState,
  reducers: {
    /* changeable sector start */

    refreshChangeableFieldsState(state) {
      state.table.changeableFields = initChangeableFieldState;
    },

    changeField(state, action: PayloadAction<ChangeFieldTypeAction>) {
      if (!Array.isArray(action.payload)) {
        const { field, isEdit, value, replace } = action.payload;
        if (field === 'quantity') {
          if (state.table.changeableFields[field].isEdit) {
            if (replace) {
              state.table.changeableFields[field].value = value || '';
              state.table.changeableFields[field].isEdit = isEdit || false;
            }
          } else {
            state.table.changeableFields[field].value = value || '';
            state.table.changeableFields[field].isEdit = isEdit || false;
          }
        } else {
          state.table.changeableFields[field].value = value || '';
          state.table.changeableFields[field].isEdit =
            isEdit !== undefined
              ? isEdit
              : state.table.changeableFields[field].isEdit;
        }
      } else {
        for (const { field, isEdit, value } of action.payload) {
          if (field !== 'quantity') {
            state.table.changeableFields[field].value = value || '';
            state.table.changeableFields[field].isEdit =
              isEdit !== undefined
                ? isEdit
                : state.table.changeableFields[field].isEdit;
          } else if (!state.table.changeableFields.quantity.isEdit) {
            state.table.changeableFields.quantity.value = value || '';
          }
        }
        // const { field, isEdit, value } = action.payload;
        //
        // state.table.changeableFields[field].value = value || '';
        // state.table.changeableFields[field].isEdit =
        //   isEdit !== undefined ? isEdit : state.table.changeableFields[field].isEdit;
      }
    },

    changeFieldVisible(
      state,
      action: PayloadAction<{
        isEdit?: boolean;
        field: keyof IChangeableSector;
      }>
    ) {
      const { field, isEdit } = action.payload;
      state.table.changeableFields[field].isEdit =
        isEdit !== undefined
          ? isEdit
          : state.table.changeableFields[field].isEdit;
    },

    initFields(state, action: PayloadAction<IChangeableSectorWithPayload>) {
      const {
        selectUnit: payloadUnit,
        quantity: payloadQuantity,
        title: payloadTitle,
        replaced
      } = action.payload;
      const linkState = state.table.changeableFields;
      const selectUnit = {
        value: replaced
          ? payloadUnit.value
          : linkState.selectUnit.value || payloadUnit.value,
        isEdit: payloadUnit.isEdit || linkState.selectUnit.isEdit
      };
      const title = {
        value: replaced
          ? payloadTitle.value
          : linkState.title.value || payloadTitle.value,
        isEdit: payloadTitle.isEdit || linkState.title.isEdit
      };
      const quantity = {
        value: replaced
          ? payloadQuantity.value
          : linkState.quantity.value || payloadQuantity.value,
        isEdit: payloadQuantity.isEdit || linkState.quantity.isEdit
      };
      state.table.changeableFields = {
        selectUnit,
        quantity,
        title
      };
    },

    changeTargetWithChangeFields(
      state,
      action: PayloadAction<
        { value: string; isEdit?: boolean; field: keyof IChangeableSector }[]
      >
    ) {
      const arrFields = action.payload;
      const uploadData: IChangeableSector = JSON.parse(
        JSON.stringify(state.table.changeableFields)
      );
      arrFields.forEach((_) => {
        if (!uploadData[_.field].isEdit) {
          uploadData[_.field] = { ...uploadData[_.field], value: _.value };
          if (_.isEdit !== undefined) {
            uploadData[_.field] = { ...uploadData[_.field], isEdit: _.isEdit };
          }
        }
      });
      state.table.changeableFields = uploadData;
    },

    /* changeable sector end */

    setTarget(
      state,
      { payload }: PayloadAction<typeof initialState.table.target>
    ) {
      state.table.target = payload;
    },

    changeModeVor(
      state,
      { payload }: PayloadAction<{ isEdit: boolean; data: IAddVor | null }>
    ) {
      state.table.isAddingVor = payload.isEdit;
      state.table.savingData = payload.data;
    },

    changeModalState(state) {
      state.table.isAddingVor = false;
    },

    changeSavingData(state, { payload }: PayloadAction<number>) {
      if (state.table.savingData) {
        state.table.savingData = {
          ...state.table.savingData,
          body: {
            ...state.table.savingData.body,
            main: state.table.savingData.body.main.filter((_) => _ !== payload),
            secondary: state.table.savingData.body.secondary.filter(
              (_) => _ !== payload
            )
          }
        };
      }

      if (state.table.target?.id === payload) {
        state.table.target = null;
      }
    },

    setSelectedVor(state, action: PayloadAction<PositionVORWithTarget[]>) {
      const newSelected = [...state.table.selectedPositions, ...action.payload];
      const newSelectedIds = newSelected.map((_) => _.id);
      const setOfNumber = Array.from(new Set(newSelectedIds));
      const setSelected: PositionVORWithTarget[] = [];
      setOfNumber.forEach((_) => {
        const work = newSelected.find((work) => work.id === _);
        work && setSelected.push(work);
      });
      state.table.selectedPositions = setSelected;
    },

    setMainWorks(state, action) {
      state.table.grouped.mainWorks = addUniqueItems(
        state.table.grouped.mainWorks,
        action.payload
      );
      const mainWorks = state.table.grouped.mainWorks;
      const addedIds = action.payload.map((value: PositionVOR) => value.id);
      state.table.grouped.extraWorks = state.table.grouped.extraWorks.filter(
        (value: PositionVOR) => !addedIds.includes(value.id)
      );

      let currentSelect = state.table.target?.unitType;
      if (state.table.changeableFields.selectUnit.isEdit) {
        currentSelect = state.table.changeableFields.selectUnit
          .value as UnitType;
      }
      if (currentSelect) {
        const arr = mainWorks.filter((item: PositionVOR) => {
          if (currentSelect === 'tonn') {
            return (
              item.unitType === currentSelect || item.unitType === 'kilogram'
            );
          }

          if (currentSelect === 'kilogram') {
            return item.unitType === currentSelect || item.unitType === 'tonn';
          }
          return item.unitType === currentSelect;
        });
        const numberValue = arr.reduce((acc, curr) => {
          if (curr.quantity && curr.unitValue) {
            if (curr.unitType === 'tonn' || curr.unitType === 'kilogram') {
              if (currentSelect === 'tonn') {
                if (curr.unitType === 'tonn') {
                  acc += +curr.quantity * +curr.unitValue;
                } else if (curr.unitType === 'kilogram') {
                  acc += (+curr.quantity * +curr.unitValue) / 1000;
                }
              } else if (currentSelect === 'kilogram') {
                if (curr.unitType === 'tonn') {
                  acc += +curr.quantity * +curr.unitValue * 1000;
                } else if (curr.unitType === 'kilogram') {
                  acc += +curr.quantity * +curr.unitValue;
                }
              } else {
                acc += +curr.quantity * +curr.unitValue;
              }
            } else {
              acc += +curr.quantity * +curr.unitValue;
            }
          }

          return acc;
        }, 0);
        let returnValue = numberValue.toString();
        if (returnValue.includes('.')) {
          returnValue = String(parseFloat(returnValue)).replace('.', ',');
        }
        if (!state.table.changeableFields.quantity.isEdit) {
          state.table.changeableFields.quantity.value = returnValue;
        }
      }
    },

    setExtraWorks(state, action) {
      state.table.grouped.extraWorks = addUniqueItems(
        state.table.grouped.extraWorks,
        action.payload
      );
      const addedIds = action.payload.map((value: PositionVOR) => value.id);
      state.table.grouped.mainWorks = state.table.grouped.mainWorks.filter(
        (value: PositionVOR) => !addedIds.includes(value.id)
      );
    },

    addWorks(
      state,
      action: PayloadAction<{
        worksType: 'main' | 'extra';
        data: any[];
      }>
    ) {
      const type = action.payload.worksType;

      if (type === 'main') {
        state.table.grouped.mainWorks.push(...action.payload.data);
        state.table.grouped.initialWorks.main.push(...action.payload.data);
      } else {
        state.table.grouped.extraWorks.push(...action.payload.data);
        state.table.grouped.initialWorks.extra.push(...action.payload.data);
      }

      state.table.grouped.isOneLoad = false;

      state.table.changeableFields = {
        title: { ...state.table.changeableFields.selectUnit, isEdit: false },
        selectUnit: { ...state.table.changeableFields.title, isEdit: false },
        quantity: { ...state.table.changeableFields.quantity, isEdit: false }
      };
      // state.table.target = null
    },

    initAddWorks(
      state,
      action: PayloadAction<{
        extraWorks: PositionVOR[];
        mainWorks: PositionVOR[];
        target?: PositionVORWithTarget;
      }>
    ) {
      const { target, mainWorks, extraWorks } = action.payload;
      state.table.grouped = {
        ...state.table.grouped,
        extraWorks,
        mainWorks
      };
      state.table.grouped.initialWorks = {
        ...state.table.grouped.initialWorks,
        extra: extraWorks,
        main: mainWorks
      };
      if (target && !state.table.target) {
        state.table.target = target;
      }

      state.table.grouped.isOneLoad = false;

      state.table.changeableFields = {
        title: { ...state.table.changeableFields.title, isEdit: false },
        selectUnit: {
          ...state.table.changeableFields.selectUnit,
          isEdit: false
        },
        quantity: { ...state.table.changeableFields.quantity, isEdit: false }
      };
    },

    breakOneLoad(state) {
      state.table.grouped.isOneLoad = false;
    },

    filterWorks(state, action: PayloadAction<number>) {
      const { payload } = action;
      const filter = (item: PositionVOR) => {
        return item.id !== payload;
      };
      // @ts-ignore
      state.table.grouped.extraWorks =
        state.table.grouped.extraWorks.filter(filter);
      state.table.grouped.initialWorks.extra = state.table.grouped.extraWorks;

      state.table.grouped.mainWorks =
        state.table.grouped.mainWorks.filter(filter);
      state.table.grouped.initialWorks.main = state.table.grouped.mainWorks;
    },

    singleClickAddWorks(
      state,
      action: PayloadAction<{ data: PositionVOR; type: any }>
    ) {
      const { data, type } = action.payload;
      if (type === 'extra') {
        state.table.grouped.extraWorks = [
          ...state.table.grouped.extraWorks,
          data
        ];
      }
      if (type === 'main') {
        state.table.grouped.mainWorks = [
          ...state.table.grouped.mainWorks,
          data
        ];
      }
    },

    clearSomeVor(state, action: PayloadAction<number[]>) {
      let newArr = [...state.table.selectedPositions];
      newArr = newArr.filter((_) => {
        return !action.payload.includes(_.id);
      });
      state.table.selectedPositions = newArr;
    },
    clearWorks(state) {
      state.table.grouped.mainWorks = [];
      state.table.grouped.extraWorks = [];
      state.table.grouped.initialWorks = {
        extra: [],
        main: []
      };
      state.table.grouped.isOneLoad = true;
      state.table.target = null;
      state.table.isAddingVor = false;

      state.table.changeableFields = {
        title: { value: '', isEdit: false },
        selectUnit: { value: '', isEdit: false },
        quantity: { value: '', isEdit: false }
      };
    },
    clearSelectedWorks(state) {
      state.table.selectedPositions = [];
      state.table.isAddingVor = false;
    },

    handleTableVariant(state, action: PayloadAction<TableVariant>) {
      state.table.variant = action.payload;
    },

    openModal(state) {
      state.modalVisible = true;
    },

    closeModal(state) {
      state.modalVisible = false;
    },

    setVor(state, action: PayloadAction<number>) {
      state.table.vorId = action.payload;
    },

    clearAddinVor(state) {
      state.table.isAddingVor = false;
    },

    updateDeletedRows(
      state,
      { payload }: PayloadAction<UpdateDeletedRowsPayload>
    ) {
      if (payload.deletePosition?.length) {
        state.table.deletedRows = state.table.deletedRows.filter(
          ({ id }) => !payload.deletePosition?.find((item) => item.id === id)
        );
      }
      if (payload.rows) {
        state.table.deletedRows = addUniqueItems(
          state.table.deletedRows,
          payload.rows
        );
      }
    },

    clearDeletedRows(state) {
      state.table.deletedRows = [];
    }
  }
});

export const {
  clearSomeVor,
  setSelectedVor,
  handleTableVariant,
  openModal,
  closeModal,

  setExtraWorks,
  setMainWorks,

  changeSavingData,
  setTarget,
  clearWorks,
  clearSelectedWorks,

  initAddWorks,
  addWorks,
  filterWorks,
  singleClickAddWorks,
  breakOneLoad,

  setVor,
  clearAddinVor,
  changeModeVor,

  changeField,
  changeFieldVisible,
  initFields,
  changeTargetWithChangeFields,
  refreshChangeableFieldsState,
  changeModalState,
  updateDeletedRows,
  clearDeletedRows
} = vorSlice.actions;
export default vorSlice.reducer;
