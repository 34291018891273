import { Fragment, useMemo } from 'react';
import { Form, FormikProvider } from 'formik';

import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ComplexFormAddRow from '../ComplexFormAddRow';

import {
  StyledFieldForm,
  StyledIconButton,
  StyledLevelTd,
  StyledTypographyLevel,
} from '../styles';
import { theme } from '../../../../../../styles/theme';

import ComplexFormAddLevel from '../ComplexFormAddLevel';
import ComplexRow from '../ComplexRow';
import { LevelPropsType, useComplexLevel } from './useComplexLevel';
import { AddLevelButtons } from '../../../AddLevelButtons/AddLevelButtons';

const ComplexLevel: React.FC<LevelPropsType> = (props) => {
  const {
    level,
    handleCopyLevelClick,
    calculationId,
    levelNumber,
    formRef,
    titleRef,
    levelName,
    isShowNestedLevel,
    handleShowNestedLevel,
    clickAddLevel,
    formik,
    handleSetIsInput,
    isInput,
    title,
    outTotal,
    mainSalary,
    mimExploitation,
    machineOperatorSalary,
    materials,
    basicMaterials,
    auxiliaryMaterials,
    equipment,
    overheads,
    estimatedProfit,
    handleOpenMenuRowClick,
    anchorEl,
    isMenuRowOpened,
    handleClose,
    handleSaveLevel,
    handleSaveAddLevel,
    handleClearForm,
    handleDeleteRowClick,
    handleShowFormAddRow,
    handleShowFormAdjacentLevel,
    handleShowFormNestedLevel,
    isShowFormAddNestedLevel,
    isShowFormAddRow,
    rowId,
    isShowFormAddAdjacentLevel,
    ConfirmDialogConfidence,
    handleFocusOut,
  } = useComplexLevel(props);

  const colorText = theme.palette.text;

  /******************************** Filtered Level/Row *************************************/
  const filteredLevelArr = useMemo(() => {
    if (level.type === 'level') {
      return level.children?.filter((row) => {
        return row.type === 'level';
      });
    }
  }, [level]);

  const filteredRowArr = useMemo(() => {
    if (level.type === 'level') {
      return level.children?.filter((row) => {
        return row.type === 'row';
      });
    }
  }, [level]);

  /************************************************************************/
  return (
    <>
      <Box
        className={`Level-${levelNumber}`}
        style={{
          display: 'grid',
          gridTemplateColumns: '100px 1fr',
          gridAutoRows: '50px',
        }}>
        <StyledLevelTd
          colorsBgLevel={levelName}
          borderColorLevel={levelName}
          style={{
            paddingLeft: '9px',
            justifyContent: 'flex-start',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              // marginRight: levelNumber <= 4 ? `${8 + (levelNumber - 1) * 6}px` : `${8 + 3 * 6}px`,
              marginRight:
                levelNumber <= 5
                  ? `${4 + (levelNumber - 1) * 6}px`
                  : `${4 + 5 * 6}px`,
              minWidth: 24,
            }}>
            {level.children.length > 0 && (
              <StyledIconButton
                onClick={() => handleShowNestedLevel(isShowNestedLevel)}
                size="small">
                {isShowNestedLevel ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </StyledIconButton>
            )}
          </Box>
          <AddLevelButtons
            levelNumber={levelNumber}
            levelName={levelName}
            clickAddLevel={clickAddLevel}
          />
        </StyledLevelTd>
        <FormikProvider value={formik}>
          <Form
            ref={formRef}
            onBlur={isInput ? handleFocusOut : undefined}
            style={{
              display: 'grid',
              gridTemplateColumns:
                'minmax(320px,1fr) repeat(10,minmax(140px, 190px)) 30px',
              gridAutoRows: '50px',
            }}>
            <StyledLevelTd
              colorsBgLevel={levelName}
              borderColorLevel={levelName}
              onDoubleClick={() => handleSetIsInput('title')}
              style={{ justifyContent: 'flex-start', height: '100%' }}>
              {isInput ? (
                <StyledFieldForm
                  ref={titleRef}
                  name="title"
                  placeholder={
                    formik.isValid
                      ? 'Укажите название работ'
                      : 'Поле обязательно к заполнению'
                  }></StyledFieldForm>
              ) : (
                <Tooltip title={title}>
                  <StyledTypographyLevel
                    style={{
                      color: `${levelName === 'level1' ? colorText.main : colorText.gray}`,
                      textAlign: 'left',
                    }}>
                    {title}
                  </StyledTypographyLevel>
                </Tooltip>
              )}
            </StyledLevelTd>
            <StyledLevelTd
              colorsBgLevel={levelName}
              borderColorLevel={levelName}>
              <StyledTypographyLevel
                style={{
                  color: `${levelName === 'level1' ? colorText.main : colorText.gray}`,
                }}>
                {outTotal.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypographyLevel>
            </StyledLevelTd>
            <StyledLevelTd
              colorsBgLevel={levelName}
              borderColorLevel={levelName}>
              <StyledTypographyLevel
                style={{
                  color: `${levelName === 'level1' ? colorText.main : colorText.gray}`,
                }}>
                {mainSalary.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypographyLevel>
            </StyledLevelTd>
            <StyledLevelTd
              className="Эксплуатация МиМ"
              colorsBgLevel={levelName}
              borderColorLevel={levelName}
              style={{
                boxShadow: 'inset 1px 0px 0px rgba(0, 36, 95, 0.1)',
              }}>
              <StyledTypographyLevel
                style={{
                  color: `${levelName === 'level1' ? colorText.main : colorText.gray}`,
                }}>
                {mimExploitation.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypographyLevel>
            </StyledLevelTd>
            <StyledLevelTd
              className="machineOperatorSalary"
              colorsBgLevel={levelName}
              borderColorLevel={levelName}>
              <StyledTypographyLevel
                style={{
                  color: `${levelName === 'level1' ? colorText.main : colorText.gray}`,
                }}>
                {machineOperatorSalary.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypographyLevel>
            </StyledLevelTd>
            <StyledLevelTd
              className="materials"
              colorsBgLevel={levelName}
              borderColorLevel={levelName}
              style={{ boxShadow: 'inset 1px 0px 0px rgba(0, 36, 95, 0.1)' }}>
              <StyledTypographyLevel
                style={{
                  color: `${levelName === 'level1' ? colorText.main : colorText.gray}`,
                }}>
                {materials.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypographyLevel>
            </StyledLevelTd>
            <StyledLevelTd
              className="basicMaterials"
              colorsBgLevel={levelName}
              borderColorLevel={levelName}>
              <StyledTypographyLevel
                style={{
                  color: `${levelName === 'level1' ? colorText.main : colorText.gray}`,
                }}>
                {basicMaterials.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypographyLevel>
            </StyledLevelTd>
            <StyledLevelTd
              className="auxiliaryMaterials"
              colorsBgLevel={levelName}
              borderColorLevel={levelName}
              style={{ boxShadow: 'inset -1px 0px 0px rgb(0 36 95 / 10%)' }}>
              <StyledTypographyLevel
                style={{
                  color: `${levelName === 'level1' ? colorText.main : colorText.gray}`,
                }}>
                {auxiliaryMaterials.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypographyLevel>
            </StyledLevelTd>
            <StyledLevelTd
              className="equipment"
              colorsBgLevel={levelName}
              borderColorLevel={levelName}>
              <StyledTypographyLevel
                style={{
                  color: `${levelName === 'level1' ? colorText.main : colorText.gray}`,
                }}>
                {equipment.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypographyLevel>
            </StyledLevelTd>
            <StyledLevelTd
              className="overheads"
              colorsBgLevel={levelName}
              borderColorLevel={levelName}>
              <StyledTypographyLevel
                style={{
                  color: `${levelName === 'level1' ? colorText.main : colorText.gray}`,
                }}>
                {overheads.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypographyLevel>
            </StyledLevelTd>
            <StyledLevelTd
              className="estimatedProfit"
              colorsBgLevel={levelName}
              borderColorLevel={levelName}>
              <StyledTypographyLevel
                style={{
                  color: `${levelName === 'level1' ? colorText.main : colorText.gray}`,
                }}>
                {estimatedProfit.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypographyLevel>
            </StyledLevelTd>
            <StyledLevelTd
              colorsBgLevel={levelName}
              borderColorLevel={levelName}
              style={{ padding: 0, width: 30 }}>
              <IconButton
                onClick={handleOpenMenuRowClick}
                sx={{ width: 30, height: 30 }}
                size="small">
                <MoreVertIcon />
              </IconButton>
            </StyledLevelTd>
          </Form>
        </FormikProvider>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl.current}
        open={isMenuRowOpened}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {isInput && <MenuItem onClick={handleSaveLevel}>Сохранить </MenuItem>}
        {isInput && (
          <MenuItem onClick={handleSaveAddLevel}>
            Сохранить и доб. статью{' '}
          </MenuItem>
        )}
        {/* {!isInput && <MenuItem onClick={handleCopyLevelClick}>Копировать </MenuItem>} */}
        <MenuItem onClick={handleClearForm}>Очистить </MenuItem>
        <MenuItem onClick={() => handleDeleteRowClick('Delete')}>
          Удалить
        </MenuItem>
      </Menu>
      <ConfirmDialogConfidence />
      {/***************************** level 2 *****************************/}
      {isShowNestedLevel && (
        <>
          {filteredRowArr?.map((rowLevelNext) => {
            return (
              <Fragment key={rowLevelNext.id}>
                {rowLevelNext.type === 'row' && (
                  <ComplexRow
                    key={rowLevelNext.id}
                    row={rowLevelNext}
                    calculationId={calculationId}
                    handleShowFormAddRow={handleShowFormAddRow}></ComplexRow>
                )}
              </Fragment>
            );
          })}
          {isShowFormAddRow && (
            <ComplexFormAddRow
              handleShowFormAddRow={handleShowFormAddRow}
              calculationId={calculationId}
              parentId={rowId}></ComplexFormAddRow>
          )}
          {filteredLevelArr?.map((rowLevelNext) => {
            return (
              <Fragment key={rowLevelNext.id}>
                {rowLevelNext.type === 'level' && (
                  <ComplexLevel
                    key={rowLevelNext.id}
                    level={rowLevelNext}
                    calculationId={calculationId}
                    levelNumber={levelNumber + 1}
                    handleShowFormAdjacent={handleShowFormAdjacentLevel}
                    handleShowFormNested={
                      handleShowFormNestedLevel
                    }></ComplexLevel>
                )}
              </Fragment>
            );
          })}
        </>
      )}
      {isShowNestedLevel && isShowFormAddNestedLevel && (
        <ComplexFormAddLevel
          handleShowFormNestedLevel={handleShowFormNestedLevel}
          handleShowFormAdjacentLevel={handleShowFormAdjacentLevel}
          calculationId={calculationId}
          parentId={rowId}
          levelNumber={levelNumber}></ComplexFormAddLevel>
      )}
      {isShowNestedLevel && isShowFormAddAdjacentLevel && (
        <ComplexFormAddLevel
          handleShowFormNestedLevel={handleShowFormNestedLevel}
          handleShowFormAdjacentLevel={handleShowFormAdjacentLevel}
          calculationId={calculationId}
          parentId={rowId}
          levelNumber={levelNumber}></ComplexFormAddLevel>
      )}
    </>
  );
};

export default ComplexLevel;
