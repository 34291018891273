import styled from 'styled-components';
import { Stack, Box } from '@mui/material';
import { FieldForm } from '../../../../../../components/FieldForm';

export const StyledTableContainer = styled(Box)`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  overflow: hidden;
`;
export const StyledTableHead = styled(Box)`
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 100%;
  max-height: 40px;

  background-color: #7890b2;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #ffffff;
`;
export const StyledTableBody = styled(Box)`
  max-height: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    box-shadow: inset 1px 0px 0px rgba(0, 36, 95, 0.1);
  }
`;

export const StyledHeadRow = styled(Stack)`
  align-items: center;
  width: 100%;
`;
export const StyledHeadTd = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledBodyRow = styled(Stack)`
  align-items: center;
  background: #ffffff;
  height: 64px;
`;
export const StyledBodyRowChild = styled(Stack)`
  align-items: center;
  background: #ffffff;
  height: 48px;
`;
export const BoxBorderStyled = styled(Stack)`
  & :first-child {
    padding-top: 0;
    & :first-child {
      div {
        border-top: 0px solid #d2dcff;
      }
    }
  }
`;
export const StyledTitle = styled(Stack)`
  padding-left: 32px;
  align-items: start;
  min-width: 400px;
  width: 100%;
  height: 100%;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;
export const StyledTitleChild = styled(Stack)`
  padding-left: 32px;
  align-items: start;
  min-width: 400px;
  width: 100%;
  height: 100%;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;
export const StyledBoxChild = styled(Stack)`
  height: 100%;
  justify-content: center;
`;

export const StyledBox = styled(Stack)`
  height: 100%;
  justify-content: center;
`;
export const StyledContractStack = styled(Stack)`
  display: grid;
  grid-template-columns: repeat(3, minmax(165px, 265px));
  grid-column-gap: 20px;
  margin: 0 auto;
`;

export const StyledFieldFormMin = styled(FieldForm)`
  width: 100%;
  height: 32px;

  background: #f6f7fb;
  border-radius: 4px;

  & .MuiInputBase-root.MuiOutlinedInput-root {
    height: 100%;
  }

  span {
    line-height: 12px;
  }

  & .MuiInputBase-input {
    padding: 4px 11px;
    color: #2b3648;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    z-index: 1;

    &:hover {
      border: 2px solid #0044b4;
    }
  }
  .Mui-focused {
    .MuiInputBase-input {
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #ffffff;
      }
    }
  }

  .Mui-error {
    .MuiInputBase-input {
      border: 1px solid red;
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: red;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: red;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: red;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: red;
      }
    }
  }

  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: 3px;
    left: 15px;
    color: #f46b6b;
    margin: 0;
    z-index: 0;
  }
  & .MuiFormHelperText-root.MuiFormHelperText-filled {
    bottom: 14px;
    left: 120px;
  }
`;
