import { StyledDialogHeader } from 'pages/Calculations/components/CalculationBasic/components/ParametersDialog/ParametersDialog.styles';
import styled from 'styled-components';

export const StyledHead = styled(StyledDialogHeader)`
  display: grid;
  grid-template-columns: 1fr 150px 240px;
  padding: 0 52px 8px 60px;
  gap: 20px;
  align-items: center;
`;
