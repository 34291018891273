import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

export const GroupFolder: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="26" height="26" viewBox="0 0 26 26">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8849 20.5452V19.7665V19.7492H23.0075C23.4738 19.7492 23.8537 19.3685 23.8537 18.9014V6.841C23.8537 6.37381 23.4738 5.99314 23.0075 5.99314H13.2324C13.2076 5.92247 13.1828 5.84715 13.1571 5.76908C12.9501 5.13996 12.6842 4.33203 11.8853 4.33203H5.37428C4.90797 4.33203 4.52802 4.7127 4.52802 5.17989V6.3219H3.75085C3.47452 6.3219 3.25 6.54685 3.25 6.8237V18.8841V20.5452C3.25 20.822 3.47452 21.047 3.75085 21.047H21.3841C21.6604 21.047 21.8849 20.822 21.8849 20.5452ZM11.3671 7.98301L10.9354 6.8237C10.8318 6.56415 10.5382 6.33921 10.2791 6.33921H5.25338V5.19719C5.25338 5.12798 5.30519 5.05877 5.39155 5.05877H11.8853C12.2552 5.05877 12.4947 5.87564 12.6469 6.39467C12.6865 6.52983 12.7202 6.6448 12.7488 6.71988H23.0248C23.0938 6.71988 23.1629 6.77179 23.1629 6.8583V18.9187C23.1629 18.9879 23.1111 19.0571 23.0248 19.0571H21.8849V8.48481C21.8849 8.20796 21.6604 7.98301 21.3841 7.98301H11.3671Z"
      />
    </SvgIcon>
  );
};
