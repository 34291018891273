import { Button, buttonClasses } from '@mui/material';
import styled from 'styled-components';

export const StyledCircleButton = styled(Button)`
  border-radius: 50%;
  flex-shrink: 0;
  min-width: auto;
  padding: 0;

  &.${buttonClasses.sizeSmall} {
    width: 32px;
    height: 32px;
  }

  &.${buttonClasses.sizeMedium} {
    width: 42px;
    height: 42px;
  }

  &.${buttonClasses.sizeLarge} {
    width: 52px;
    height: 52px;
  }
`;
