import styled from 'styled-components';
import { Container } from '@mui/material';

export const MainContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  // min-width: 1065px;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
`;

export const InnerContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  align-items: flex-start;
  padding: 0 20px 16px 20px;
  flex: 1 1 auto;
  overflow: auto;
  align-items: stretch;
`;
