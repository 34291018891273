import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Stack, Toolbar } from '@mui/material';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/Button';
import { useAppContext } from '../../layouts/Layout/context';
import { RootState, useAppDispatch } from '../../store/store';
import { theme } from '../../styles/theme';
import { LINK_TO_CORE } from '../../utils/constants';
import Breadcrumbs from '../Breadcrumbs';
import { Divider } from '../Divider';
import Search from './Search';
import UserButtons from './UserButtons';
import UserMenu from './UserMenu';
import {
  HomeLogo,
  LogoWrapper,
  OfferLogo,
  StyledHeader,
  StyledHomeLink,
} from './styles';
import { getPricesState } from '../../store/slices/references/prices/prices';

const Header: React.FC = () => {
  const { profile, onLogout } = useAppContext();

  const { isAddingVor } = useSelector((state: RootState) => state.vor.table);
  const { isCreated } = useSelector(getPricesState);

  const { role } = profile;
  const params = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handlerGoBack = useCallback(() => {
    if (isCreated) {
      navigate(-1);
      return;
    }
    navigate(-1);
  }, [isCreated]);

  const onChangeModule = useCallback(() => {
    window.location.replace(LINK_TO_CORE);
  }, []);

  return (
    <>
      <StyledHeader position="static">
        <Toolbar style={{ padding: 0, height: '100%' }}>
          <Stack
            style={{ height: '100%' }}
            direction="row"
            justifyContent="space-between"
            width="100%"
            alignItems="center">
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <LogoWrapper>
                <OfferLogo />
              </LogoWrapper>
              {!isAddingVor && pathname !== '/projects' && (
                <>
                  <StyledHomeLink>
                    <Link
                      to="/"
                      onClick={(e) => {
                        if (isCreated) {
                          e.preventDefault();
                          e.stopPropagation();
                          navigate('/');
                          return;
                        }
                      }}>
                      <HomeLogo />
                    </Link>
                  </StyledHomeLink>
                  <Button
                    onClick={handlerGoBack}
                    variant="text"
                    type="button"
                    sx={{ fontWeight: 500, fontSize: '13px' }}>
                    <span style={{ display: 'flex', marginRight: '8px' }}>
                      <ArrowBackIcon sx={{ height: '25px', width: '25px' }} />
                    </span>
                    Назад
                  </Button>
                </>
              )}
              <Breadcrumbs />
            </Box>
            <Stack
              direction="row"
              spacing={theme.spacing(2.5)}
              alignItems="center">
              {params['*'] === 'calculations' || params['*'] === 'users' ? (
                <Search />
              ) : null}
              <UserMenu
                onChangeModule={onChangeModule}
                onLogout={onLogout}
                profile={profile}
              />
              <UserButtons role={role} />
            </Stack>
          </Stack>
        </Toolbar>
        <Divider rounded />
      </StyledHeader>
    </>
  );
};

export default Header;
