import styled from 'styled-components';
import { AlignPosition } from '../../Table.types';

interface StyledHeadTdProps {
  $align?: AlignPosition;
}

export const HeadStyled = styled.div<{ $isHandbook: boolean }>`
  width: 3270px;
  display: grid;
  grid-template-columns: ${({ $isHandbook }) => {
    return $isHandbook
      ? '100px 200px 200px 200px 150px 150px 200px 600px 100px 150px 150px 150px 150px 150px 150px 150px 150px'
      : '100px 50px 200px 200px 150px 150px 100px 200px 600px 150px 150px 150px 150px 150px 150px 150px 150px';
  }};
  /* grid-template-columns: ; */
  border-bottom: 1px solid #d2dcff;
  gap: 20px;
`;

export const HeadTdStyled = styled.div<StyledHeadTdProps>`
  text-align: ${({ $align }) => $align};
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 8px;
  font-size: 12px;
  letter-spacing: 0.1px;
  color: #00358c;

  &:first-child {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0;
    width: 94px;
  }

  & + & {
    border-left: 1px solid #d2dcff;
  }
`;
