import * as yup from 'yup';

export const validationRow = yup.object({
  title: yup.string().trim().required(`common:${''}${''}`),
  unit: yup.string().trim().required(`common:${''}${''}`),
  quantity: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,3}(,\d{3})*)|\d+)?([\.\,]\d{1,3})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
  unitPrice: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?([\.\,]\d{1,2})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
});

export const validationTitleEditRow = yup.object({
  title: yup.string().trim().required(`common:${''}${''}`),
});
