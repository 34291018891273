import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  RowClassParams
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef } from 'react';
import Progress from '../components/Progress';
import {
  PageStyled,
  WrapperAgGrid
} from './Calculations/components/Accomplishment/Accomplishment.styles';
import Cell from './Calculations/components/Accomplishment/components/Cell';
import { paramsField } from './Calculations/components/CalculationDirectory/tabs/CalculationTab/CalculationTab.types';
import Level from './Calculations/components/CalculationDirectory/tabs/CalculationTab/components/Level/Level';
import mockData from './test-data.json';

interface IMockData {
  code: string | null;
  costPerUnit: number | null;
  costTotal: number | null;
  fotPerUnit: number | null;
  fotTotal: number | null;
  id: number;
  parentID: number | null;
  kfoCoef: number | null;
  kfoGroup: string | null;
  kfoUnit: string | null;
  kzPerUnit: number | null;
  kzTotal: number | null;
  lvl: number;
  mimPerUnit: number | null;
  mimTotal: number | null;
  mtrPerUnit: number | null;
  mtrTotal: number | null;
  qtyPerUnit: number | null;
  qtyTotal: number | null;
  resourceType: string | null;
  rowType: string;
  title: string | null;
  total: number | null;
  totalPerUnit: number | null;
  tzmPerUnit: number | null;
  tzmTotal: number | null;
  tzPerUnit: number | null;
  tzTotal: number | null;
  unit: string | null;
  workTimePerUnit: number | null;
  workTimeTotal: number | null;
  children: IMockData[];
}

export const TempContext = React.createContext<{
  rows: number[];
  collapse: (event: any) => void;
}>({
  rows: [],
  collapse: (event) => {}
});

function cellRenderer(params: ICellRendererParams<IMockData, any, any>) {
  const props =
    params.colDef?.field && params.colDef.field in paramsField
      ? paramsField[params.colDef.field as keyof typeof paramsField]
      : {};

  return (
    <Cell {...props}>{props?.modify?.(params.value) ?? params.value}</Cell>
  );
}

const Test = () => {
  const Ref = useRef<AgGridReact<IMockData>>(null);

  const columnsDef: (ColDef<IMockData, any> | ColGroupDef<IMockData>)[] = [
    {
      field: 'lvl',
      headerName: '',
      cellRenderer: (params: any) => <Level {...params} />
    },
    // {
    //   field: 'num',
    //   headerName: '№ п/п',
    // },
    {
      field: 'resourceType',
      headerName: 'Тип ресурса',
      width: 188,
      editable: (params) =>
        params.data?.rowType === 'RATE'
          ? !params.data.children.length
          : params.data?.rowType === 'RATE_POSITION',
      cellRenderer
    },
    {
      field: 'code',
      headerName: 'Шифр',
      editable: (params) => params.data?.rowType === 'RATE',
      cellRenderer
    },
    {
      field: 'title',
      headerName: 'Наименование',
      width: 428,
      cellRenderer
    },
    {
      field: 'unit',
      headerName: 'Ед.изм.',
      width: 148,
      editable: (params) => params.data?.rowType !== 'FOLDER',
      cellRenderer
    },
    {
      headerName: 'Количество',
      children: [
        {
          field: 'qtyPerUnit',
          headerName: 'На единицу',
          colSpan: (params: any) => {
            return params.data.rowType === 'LEVEL' ? 2 : 1;
          },
          cellRenderer
        },
        {
          field: 'qtyTotal',
          headerName: 'Всего',
          cellRenderer
        }
      ]
    },
    {
      headerName: 'Стоимость единицы',
      children: [
        {
          field: 'fotPerUnit',
          headerName: 'ФОТ ОР',
          cellRenderer
        },
        {
          field: 'mimPerUnit',
          headerName: 'МиМ',
          cellRenderer
        },
        {
          field: 'mtrPerUnit',
          headerName: 'МТР',
          cellRenderer
        },
        {
          field: 'costPerUnit',
          headerName: 'Всего',
          cellRenderer
        },
        {
          field: 'kzPerUnit',
          headerName: 'КЗ',
          cellRenderer
        },
        {
          field: 'totalPerUnit',
          headerName: 'Всего с КЗ',
          cellRenderer
        }
      ]
    },
    {
      headerName: 'Общая стоимость',
      children: [
        {
          field: 'fotTotal',
          headerName: 'ФОТ ОР',
          cellRenderer
        },
        {
          field: 'mimTotal',
          headerName: 'МиМ',
          cellRenderer
        },
        {
          field: 'mtrTotal',
          headerName: 'МТР',
          cellRenderer
        },
        {
          field: 'costTotal',
          headerName: 'Всего',
          cellRenderer
        },
        {
          field: 'kzTotal',
          headerName: 'КЗ',
          cellRenderer
        },
        {
          field: 'total',
          headerName: 'Всего с КЗ',
          cellRenderer
        }
      ]
    },
    {
      headerName: 'ТЗ',
      children: [
        {
          field: 'tzPerUnit',
          headerName: 'На единицу',
          cellRenderer
        },
        {
          field: 'tzTotal',
          headerName: 'Всего',
          cellRenderer
        }
      ]
    },
    {
      headerName: 'ТЗМ',
      children: [
        {
          field: 'tzmPerUnit',
          headerName: 'На единицу',
          cellRenderer
        },
        {
          field: 'tzmTotal',
          headerName: 'Всего',
          cellRenderer
        }
      ]
    },
    {
      headerName: 'Общее рабочее время',
      children: [
        {
          field: 'workTimePerUnit',
          headerName: 'На единицу',
          cellRenderer
        },
        {
          field: 'workTimeTotal',
          headerName: 'Всего',
          cellRenderer
        }
      ]
    },
    {
      headerName: 'КФО',
      children: [
        {
          field: 'kfoGroup',
          headerName: 'Группа',
          cellRenderer
        },
        {
          field: 'kfoUnit',
          headerName: 'Ед. изм.',
          cellRenderer
        },
        {
          field: 'kfoCoef',
          headerName: 'Коэффициент',
          cellRenderer
        }
      ]
    }
  ];
  const [expandedRows, setExpandedRows] = React.useState<any>([]);
  const [gridApi, setGridApi] = React.useState<any>(null);
  const [hiddenRows, setHiddenRows] = React.useState<number[]>([]);

  const flatChildren = (data: IMockData) => {
    const result: number[] = [];
    const flat = (d: IMockData) => {
      result.push(d.id);
      if (d.children?.length > 0) {
        d.children.forEach(flat);
      }
    };
    result.push(data.id);
    if (data.children?.length) {
      data.children.forEach(flat);
    }
    return result;
  };
  // const toggleChildren = (rowNode: any) => {
  //   console.log(rowNode);
  //   if (rowNode.data.children.length > 0) {
  //     console.log(rowNode.expanded);
  //     rowNode.setExpanded(!rowNode.expanded);
  //     // Ref.current?.api?.refreshClientSideRowModel('group');
  //     Ref.current!.api!.redrawRows();
  //   }
  // };
  const getRowNodeId = (data: any) => {
    return data.id;
  };
  // const onCellClicked = (params: any) => {
  //   console.log(params.column?.getColId());
  //   if (params.column && params.column.getColId() === 'lvl') {
  //     console.log(1);
  //     toggleChildren(params.node);
  //   }
  // };
  const defaultColDef = {
    width: 124,
    resizable: true,
    sortable: true
  };
  // const toggleRow = (rowId: any) => {
  //   console.log('toggle row');
  //   setExpandedRows({
  //     ...expandedRows,
  //     [rowId]: !expandedRows[rowId],
  //   });
  // };
  const filterData = (selectedId: any) => {
    if (!gridApi) return;
    console.log('filter');
    console.log(selectedId);

    // Сбрасываем предыдущий фильтр
    // gridApi.setFilterModel(null);

    // Применяем фильтр к столбцу parentID
    gridApi.setIsExternalFilterPresent(() => {
      console.log(this);
      return false;
    });
    // gridApi.refreshCells({ force: true });
  };
  // const filterData = (selectedId) => {
  //   if (!gridApi) return;
  //
  //   // Сбрасываем предыдущий фильтр
  //   gridApi.setFilterModel(null);
  //
  //   // Применяем фильтр к столбцу parentID
  //   gridApi.setFilterModel({
  //     parentID: {
  //       type: 'equals',
  //       filter: selectedId,
  //     },
  //   });
  // };
  const collapse = (event: any) => {
    setHiddenRows((d) => {
      const copy = [...d];
      const addIndex = copy.findIndex((hrId) => hrId === event.data.id);
      if (addIndex >= 0) {
        console.log(1);
        copy.splice(addIndex, 1);
        return copy;
      } else {
        console.log(2);
        return Array.from(new Set([...flatChildren(event.data), ...copy]));
      }
    });
    // Ref.current?.api?.onFilterChanged();
    // console.log(flatChildren(event.data));
  };
  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };
  // const toggleRow = (rowId: any) => {
  //   if (expandedRows.includes(rowId)) {
  //     console.log('if');
  //     setExpandedRows(expandedRows.filter((id: any) => id !== rowId));
  //   } else {
  //     console.log('else');
  //     setExpandedRows([...expandedRows, rowId]);
  //   }
  // };

  // const isRowExpanded = (row: any) => {
  //   return expandedRows.includes(row.id);
  // };
  //
  // const handleRowClicked = (event: any) => {
  //   if (event.data.children.length) {
  //     console.log(event);
  //     const rowId = event.node.id;
  //     toggleRow(rowId);
  //     event.node.setExpanded(!isRowExpanded(event.node));
  //   }
  // };
  const filteredData = React.useCallback(
    (params: any) => {
      Ref.current?.api.setIsExternalFilterPresent(() => false);
      return params.data?.parentID
        ? !hiddenRows.includes(params.data.parentID)
        : true;
    },
    [hiddenRows, Ref.current]
  );
  useEffect(() => {
    // Ref.current?.api?.setDoesExternalFilterPass((params) => {
    //   return params.data?.parentID ? !hiddenRows.includes(params.data.parentID) : true;
    // });
    Ref.current?.api?.setIsExternalFilterPresent(() => true);
    Ref.current?.api?.onFilterChanged();
    // Ref.current?.api?.refreshCells({ force: true });
    //
    // Ref.current?.api?.refreshCells({ force: true });
  }, [filteredData, Ref.current]);
  // console.log(hiddenRows);
  return (
    <PageStyled>
      <span></span>
      <TempContext.Provider value={{ rows: hiddenRows, collapse }}>
        <WrapperAgGrid className="ag-theme-material reference-prices">
          {/*{isLoading && <Progress />}*/}
          <AgGridReact<IMockData>
            // rowSelection="multiple"
            // groupSelectsChildren={true}
            // groupSelectsFiltered={true}
            ref={Ref}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            maintainColumnOrder={true}
            defaultColDef={defaultColDef}
            columnDefs={columnsDef}
            groupHeaderHeight={40}
            singleClickEdit
            // isRowExpanded={isRowExpanded}
            getRowClass={(params: RowClassParams<IMockData, any>) => {
              return params.data?.rowType === 'LEVEL'
                ? `level-${params.data?.lvl}`
                : 'level-12';
            }}
            gridOptions={{
              // suppressDragLeaveHidesColumns: true,
              suppressAnimationFrame: true,
              navigateToNextHeader: () => null,
              tabToNextHeader: () => null
            }}
            // onRowClicked={handleRowClicked}
            // isRowExpanded={isRowExpanded}
            rowData={mockData as any}
            suppressCellFocus={true}
            getRowId={(params) => {
              return params.data.id.toString();
            }}
            // getRowHeight={(params) => {
            //   if (params.node.rowPinned === 'top') {
            //     return 50;
            //   }
            //   return 55;
            // }}
            rowStyle={{
              padding: '0 !important'
            }}
            onGridReady={onGridReady}
            // onCellClicked={onCellClicked}
            getRowStyle={(params: any) => {
              return params.data.lvl === 1 ? { color: '#0044B4' } : undefined;
            }}
            rowHeight={80}
            headerHeight={40}
            context={{ rows: hiddenRows }}
            doesExternalFilterPass={filteredData}
            loadingOverlayComponent={Progress}
            noRowsOverlayComponent={Progress}></AgGridReact>
        </WrapperAgGrid>
      </TempContext.Provider>
    </PageStyled>
  );
};

export default Test;
