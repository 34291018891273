import { Button, Stack, lighten } from '@mui/material';
import { ToggleButton } from 'components/ToggleButton';
import styled from 'styled-components';

export const TableLegendWrapper = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  background-color: ${(props) => props.theme.palette.bg.white};
  border-radius: 16px 16px 0 0;
  gap: ${({ theme }) => theme.spacing(1)};
  flex-wrap: wrap;
`;

export const ButtonWrapper = styled(Stack)`
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.palette.bg.white};
  gap: 10px;
`;

export const TableLegendFormButton = styled(Button)`
  height: 32px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: initial;
`;

export const StyledToggleButtonWrapper = styled.div(({ theme }) => ({
  position: 'relative',
  height: 'inherit',
  display: 'inline-grid',

  '&:not(:first-child)::after': {
    content: "''",
    position: 'absolute',
    backgroundColor: lighten(theme.palette.primary.light, 0.6),
    width: '1px',
    height: '16px',
    top: '50%',
    left: '-2px',
    transform: 'translateY(-50%)',
  },
}));

export const StyledToggleButton = styled(ToggleButton)`
  color: ${({ theme }) => theme.palette.secondary.dark};
  font-size: 14px;
  height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  line-height: inherit;
`;

export const StyledCount = styled.span`
  font-weight: 600;
  margin-left: 5px;
`;
