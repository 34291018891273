import { FC } from 'react';
import { TreeNodeContext } from './TreeNodeContext';
import { TreeNodeContextConsumerProps } from './TreeNodeContext.types';

export const TreeNodeContextConsumer: FC<TreeNodeContextConsumerProps> = ({
  children,
}) => {
  return (
    <TreeNodeContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error(
            'TreeNodeContextConsumer must be used within a TreeNodeContextConsumerProvider',
          );
        }
        return children(context);
      }}
    </TreeNodeContext.Consumer>
  );
};
