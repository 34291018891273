import styled from 'styled-components';

export const StyledInfoTitle = styled('div')`
  font-size: 12px;
  color: #7890b2;
  margin-bottom: 6px;
`;

export const StyledInfo = styled('div')`
  & + & {
    margin-top: 16px;
  }
`;
