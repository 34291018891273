import { Box, Tooltip } from '@mui/material';
import {
  BasePricesItem,
  Coefficients,
} from '../../../../../../../../api/calculations/types';
import { ReactComponent as RowIcon } from '../../../../../../../../assets/icons/LeveLicon/RowIcon.svg'; // assets/icons/LeveLicon/ FirstLevelicon.svg
import {
  StyledRow,
  StyledRowTd,
  StyledTitleRow,
  StyledTypographyRow,
} from '../styles';

type EstimateRowPropsType = {
  basePricesRow: BasePricesItem;
  calculationId: number;
  coefficients: Coefficients;
  isShowGenContract: boolean;
  isShowSubContract: boolean;
  isShowAnalysis: boolean;
  countRepeat: string;
};

export const EstimateRow: React.FC<EstimateRowPropsType> = ({
  basePricesRow,
  // calculationId,
  coefficients,
  isShowGenContract,
  isShowSubContract,
  isShowAnalysis,
  countRepeat,
}) => {
  const {
    peoplePerHourGen,
    machinesPerHourGen,
    hourlyCostGen,
    peoplePerHourSub,
    machinesPerHourSub,
    hourlyCostSub,
  } = basePricesRow;
  console.log(basePricesRow);
  const {
    mainSalary,
    mimExploitation,
    machineOperatorSalary,
    // materials,
    basicMaterials,
    auxiliaryMaterials,
    equipment,
    overheads,
    estimatedProfit,
  } = coefficients!;

  /************************************** Out number *****************************************/

  const outMainSalaryGen =
    basePricesRow.mainSalary *
    (mainSalary.firstGen ?? 1) *
    (mainSalary.secondGen ?? 1) *
    (mainSalary.thirdGen ?? 1);

  const outMainSalarySub =
    basePricesRow.mainSalary *
    (mainSalary.firstSub ?? 1) *
    (mainSalary.secondSub ?? 1) *
    (mainSalary.thirdSub ?? 1);

  const outMimExploitationGen =
    basePricesRow.mimExploitation *
    (mimExploitation.firstGen ?? 1) *
    (mimExploitation.secondGen ?? 1) *
    (mimExploitation.thirdGen ?? 1);

  const outMimExploitationSub =
    basePricesRow.mimExploitation *
    (mimExploitation.firstSub ?? 1) *
    (mimExploitation.secondSub ?? 1) *
    (mimExploitation.thirdSub ?? 1);

  const outMachineOperatorSalaryGen =
    basePricesRow.machineOperatorSalary *
    (machineOperatorSalary.firstGen ?? 1) *
    (machineOperatorSalary.secondGen ?? 1) *
    (machineOperatorSalary.thirdGen ?? 1);

  const outMachineOperatorSalarySub =
    basePricesRow.machineOperatorSalary *
    (machineOperatorSalary.firstSub ?? 1) *
    (machineOperatorSalary.secondSub ?? 1) *
    (machineOperatorSalary.thirdSub ?? 1);

  const outBasicMaterialsGen =
    basePricesRow.basicMaterials *
    (basicMaterials.firstGen ?? 1) *
    (basicMaterials.secondGen ?? 1) *
    (basicMaterials.thirdGen ?? 1);

  const outBasicMaterialsSub =
    basePricesRow.basicMaterials *
    (basicMaterials.firstSub ?? 1) *
    (basicMaterials.secondSub ?? 1) *
    (basicMaterials.thirdSub ?? 1);

  const outAuxiliaryMaterialsGen =
    basePricesRow.auxiliaryMaterials *
    (auxiliaryMaterials.firstGen ?? 1) *
    (auxiliaryMaterials.secondGen ?? 1) *
    (auxiliaryMaterials.thirdGen ?? 1);

  const outAuxiliaryMaterialsSub =
    basePricesRow.auxiliaryMaterials *
    (auxiliaryMaterials.firstSub ?? 1) *
    (auxiliaryMaterials.secondSub ?? 1) *
    (auxiliaryMaterials.thirdSub ?? 1);

  const outMaterialsGen = outBasicMaterialsGen + outAuxiliaryMaterialsGen;
  const outMaterialsSub = outBasicMaterialsSub + outAuxiliaryMaterialsSub;

  const outEquipmentGen =
    basePricesRow.equipment *
    (equipment.firstGen ?? 1) *
    (equipment.secondGen ?? 1) *
    (equipment.thirdGen ?? 1);

  const outEquipmentSub =
    basePricesRow.equipment *
    (equipment.firstSub ?? 1) *
    (equipment.secondSub ?? 1) *
    (equipment.thirdSub ?? 1);

  const outOverheadsGen =
    basePricesRow.overheads *
    (overheads.firstGen ?? 1) *
    (overheads.secondGen ?? 1) *
    (overheads.thirdGen ?? 1);

  const outOverheadsSub =
    basePricesRow.overheads *
    (overheads.firstSub ?? 1) *
    (overheads.secondSub ?? 1) *
    (overheads.thirdSub ?? 1);

  const outEstimatedProfitGen =
    basePricesRow.estimatedProfit *
    (estimatedProfit.firstGen ?? 1) *
    (estimatedProfit.secondGen ?? 1) *
    (estimatedProfit.thirdGen ?? 1);

  const outEstimatedProfitSub =
    basePricesRow.estimatedProfit *
    (estimatedProfit.firstSub ?? 1) *
    (estimatedProfit.secondSub ?? 1) *
    (estimatedProfit.thirdSub ?? 1);

  const outPeoplePerHourGen =
    peoplePerHourGen *
    (mainSalary.firstGen ?? 1) *
    (mainSalary.secondGen ?? 1) *
    (mainSalary.thirdGen ?? 1);

  const outPeoplePerHourSub =
    peoplePerHourSub *
    (mainSalary.firstSub ?? 1) *
    (mainSalary.secondSub ?? 1) *
    (mainSalary.thirdSub ?? 1);

  const outMachinesPerHourGen =
    machinesPerHourGen *
    (mimExploitation.firstGen ?? 1) *
    (mimExploitation.secondGen ?? 1) *
    (mimExploitation.thirdGen ?? 1);

  const outHourlyCostGen =
    (outMainSalaryGen +
      outMimExploitationGen +
      outOverheadsGen +
      outEstimatedProfitGen) /
    ((peoplePerHourGen ?? 0) *
      (mainSalary.firstGen ?? 1) *
      (mainSalary.secondGen ?? 1) *
      (mainSalary.thirdGen ?? 1) +
      (machinesPerHourGen ?? 0) *
        (mimExploitation.firstGen ?? 1) *
        (mimExploitation.secondGen ?? 1) *
        (mimExploitation.thirdGen ?? 1));

  const outHourlyCostSub =
    (outMainSalarySub +
      outMimExploitationSub +
      outOverheadsSub +
      outEstimatedProfitSub) /
    ((peoplePerHourSub ?? 0) *
      (mainSalary.firstSub ?? 1) *
      (mainSalary.secondSub ?? 1) *
      (mainSalary.thirdSub ?? 1) +
      (machinesPerHourSub ?? 0) *
        (mimExploitation.firstSub ?? 1) *
        (mimExploitation.secondSub ?? 1) *
        (mimExploitation.thirdSub ?? 1));

  const outTotalGen =
    outMainSalaryGen +
    outMimExploitationGen +
    outMaterialsGen +
    outEquipmentGen +
    outOverheadsGen +
    outEstimatedProfitGen;

  const outTotalSub =
    outMainSalarySub +
    outMimExploitationSub +
    outMaterialsSub +
    outEquipmentSub +
    outOverheadsSub +
    outEstimatedProfitSub;

  const outProfit = outTotalGen - outTotalSub;
  const outRentability = (outProfit / outTotalGen) * 100;

  /******************************************************************************************/

  return (
    <Box className="Row" sx={{ background: '#FFFFFF' }}>
      <StyledRow
        style={{
          gridTemplateColumns: `100px minmax(400px, 1fr) repeat(${countRepeat},170px)`,
        }}
        direction="row"
        className="row-grid">
        <StyledRowTd
          style={{
            paddingLeft: '13px',
            justifyContent: 'flex-start',
            width: '100px',
          }}>
          <Box
            sx={{
              minWidth: '40px',
              marginRight: '10px',
            }}></Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              minWidth: 24,
            }}>
            <RowIcon style={{ width: 16, height: 16 }} />
          </Box>
        </StyledRowTd>
        <StyledTitleRow className="title">
          <Tooltip title={basePricesRow.title}>
            <StyledTypographyRow sx={{ marginLeft: '12px' }}>
              {basePricesRow.title}
            </StyledTypographyRow>
          </Tooltip>
        </StyledTitleRow>
        {isShowAnalysis && (
          <>
            <StyledRowTd
              className="hourlyCostGen"
              style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
              <StyledTypographyRow>
                {isFinite(outHourlyCostGen)
                  ? outHourlyCostGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : '0,00'}
              </StyledTypographyRow>
            </StyledRowTd>
            <StyledRowTd
              className="hourlyCostSub"
              style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
              <StyledTypographyRow>
                {isFinite(outHourlyCostSub)
                  ? outHourlyCostSub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : '0,00'}
              </StyledTypographyRow>
            </StyledRowTd>
            <StyledRowTd
              className="profit"
              style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
              <StyledTypographyRow>
                {outProfit.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypographyRow>
            </StyledRowTd>
            <StyledRowTd
              className="rentability"
              style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
              <StyledTypographyRow>
                {isFinite(outRentability)
                  ? outRentability.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : '0,00'}
                {'%'}
              </StyledTypographyRow>
            </StyledRowTd>
          </>
        )}
        {isShowGenContract && (
          <>
            <StyledRowTd
              className="total"
              style={{ boxShadow: 'rgb(0 26 77 / 8%) 0px 1px 250px inset' }}>
              <StyledTypographyRow>
                {outTotalGen.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypographyRow>
            </StyledRowTd>
            {!isShowAnalysis && (
              <>
                <StyledRowTd
                  className="mainSalary"
                  //  style={{ boxShadow: 'inset 1px 0px 0px rgb(0 26 77 / 8%)' }}
                >
                  <StyledTypographyRow>
                    {outMainSalaryGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="mimExploitation"
                  style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {outMimExploitationGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="machineOperatorSalary"
                  style={{ boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {outMachineOperatorSalaryGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="materials"
                  style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {outMaterialsGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="basicMaterials"
                  style={{ boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {outBasicMaterialsGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="auxiliaryMaterials"
                  style={{ boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {outAuxiliaryMaterialsGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="equipment"
                  style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {outEquipmentGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="overheads"
                  style={{ boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {outOverheadsGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="estimatedProfit"
                  style={{ boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {outEstimatedProfitGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="peoplePerHour"
                  style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {(outPeoplePerHourGen ?? 0).toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="machinesPerHour"
                  style={{ boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {(outMachinesPerHourGen ?? 0).toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="hourlyCost"
                  style={{
                    boxShadow: ' inset 0px 0px 0px rgb(0 36 95 / 10%)',
                  }}>
                  <StyledTypographyRow>
                    {hourlyCostGen
                      ? isFinite(hourlyCostGen || 0)
                        ? outHourlyCostGen.toLocaleString('ru-RU', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : '0,00'
                      : '0,00'}
                  </StyledTypographyRow>
                </StyledRowTd>
              </>
            )}
          </>
        )}
        {/* СУБПОДРЯД */}
        {isShowSubContract && (
          <>
            <StyledRowTd
              className="total"
              style={{
                boxShadow: 'rgb(0 26 77 / 8%) 0px 1px 250px inset',
                borderLeft: '1px solid rgb(0 26 77 / 10%)',
              }}>
              <StyledTypographyRow>
                {outTotalSub.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypographyRow>
            </StyledRowTd>
            {!isShowAnalysis && (
              <>
                <StyledRowTd
                  className="mainSalary"
                  //style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}
                >
                  <StyledTypographyRow>
                    {outMainSalarySub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="mimExploitation"
                  style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {outMimExploitationSub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="machineOperatorSalary"
                  style={{ boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {outMachineOperatorSalarySub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="materials"
                  style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {outMaterialsSub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="basicMaterials"
                  style={{ boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {outBasicMaterialsSub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="auxiliaryMaterials"
                  style={{ boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {outAuxiliaryMaterialsSub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="equipment"
                  style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {outEquipmentSub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="overheads"
                  style={{ boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {outOverheadsSub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="estimatedProfit"
                  style={{ boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {outEstimatedProfitSub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="peoplePerHour"
                  style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {(peoplePerHourSub ?? 0).toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="machinesPerHour"
                  style={{ boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)' }}>
                  <StyledTypographyRow>
                    {(machinesPerHourSub ?? 0).toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                </StyledRowTd>
                <StyledRowTd
                  className="hourlyCost"
                  style={{
                    boxShadow: ' inset 0px 0px 0px rgb(0 36 95 / 10%)',
                  }}>
                  <StyledTypographyRow>
                    {isFinite(hourlyCostSub)
                      ? (hourlyCostSub ?? 0).toLocaleString('ru-RU', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : '0,00'}
                  </StyledTypographyRow>
                </StyledRowTd>
              </>
            )}
          </>
        )}
      </StyledRow>
    </Box>
  );
};
