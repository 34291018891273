import { Stack } from '@mui/material';
import styled from 'styled-components';

export const StyledInformation = styled(Stack)`
  height: 44px;
  border-top: 2px solid rgba(25, 118, 210, 0.08);
  grid-area: info;
  box-shadow:
    white 0px -6px 4px 4px,
    rgb(210, 220, 255) 0px 4px 8px 1px;
  z-index: -1;
  position: relative;
  border-bottom: 1px solid #d2dcff;
  align-items: center;
`;

export const StyledSumTitleWrapper = styled.div<{ $span: number }>`
  grid-column: ${({ $span }) => `span ${$span}`};
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

export const StyledTitle = styled.div`
  margin-left: 32px;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #5172a1;
`;

export const StyledIndicatorsWrapper = styled.div`
  //grid-column: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  //justify-content: end;
`;
