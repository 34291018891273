import {
  CoeffType,
  ICoefficientData,
  IUpdateData,
  OrNull
} from 'api/references/estimates/estimates.types';
import {
  IParametersForm,
  IParametersFormActList,
  IParametersFormCoefficient
} from '.';

export function getActListFromLabel(
  actList: IParametersFormActList['actList']
): IParametersForm['actListFromLabel'] {
  const groupedActs: { [key: string]: IParametersFormActList['actList'] } = {};

  actList.forEach((act) => {
    const onYearStr = act.onDate
      ? new Date(act.onDate).getFullYear().toString()
      : 'unknown';

    if (!groupedActs[onYearStr]) {
      groupedActs[onYearStr] = [];
    }

    groupedActs[onYearStr].push(act);
  });

  return Object.entries(groupedActs).map(([year, actList]) => {
    const label = 'Акты за ' + (year !== 'unknown' ? year : 'неизвестный год');

    return {
      label,
      actList,
      year
    };
  });
}

export function hasDifferentValues(
  parent: ICoefficientData,
  child: ICoefficientData
): boolean {
  const keysToCompare: (keyof ICoefficientData)[] = ['fot', 'mim', 'mtr', 'pz'];
  return keysToCompare.some((key) => {
    return parent?.[key] == null ? false : parent?.[key] !== child?.[key];
  });
}

export function checkIsDifferentRecursive(
  coefficient: ICoefficientData
): boolean {
  if (coefficient.children?.length) {
    return coefficient.children.some((child) => {
      const isDifferentOnThisLevel = hasDifferentValues(coefficient, child);
      const isDifferentInChildren = checkIsDifferentRecursive(child);

      return isDifferentOnThisLevel || isDifferentInChildren;
    });
  }
  return false;
}

export function checkIsDifferent(
  coefficients: ICoefficientData[]
): ICoefficientData[] {
  return coefficients.map((coefficient) => {
    const isDifferent = checkIsDifferentRecursive(coefficient);

    return {
      ...coefficient,
      isDifferent,
      children: coefficient.children?.length
        ? checkIsDifferent(coefficient.children)
        : []
    };
  });
}

export function checkGlobalIsDifferentRecursive(
  global: ICoefficientData,
  coefficients: ICoefficientData[]
): boolean {
  if (coefficients?.length) {
    return coefficients.some((child) => {
      const isDifferentOnThisLevel = hasDifferentValues(global, child);
      const isDifferentInChildren = checkIsDifferentRecursive(child);

      return isDifferentOnThisLevel || isDifferentInChildren;
    });
  }
  return false;
}

export function getDifferentActList(
  actListFromLabel: IParametersFormActList[]
) {
  return actListFromLabel.map((labelItem) => {
    const updatedActList = labelItem.actList.map((act) => ({
      ...act,
      coefficient: {
        ...act.coefficient,
        index: act.coefficient?.index
          ? {
              ...act.coefficient.index,
              coefficients: checkIsDifferent(
                act.coefficient.index.coefficients
              ),
              global: {
                ...act.coefficient.index.global,
                isDifferent: checkGlobalIsDifferentRecursive(
                  act.coefficient.index.global,
                  act.coefficient.index.coefficients
                )
              }
            }
          : undefined,
        kz: act.coefficient?.kz
          ? {
              ...act.coefficient.kz,
              coefficients: checkIsDifferent(act.coefficient.kz.coefficients),
              global: {
                ...act.coefficient.kz.global,
                isDifferent: checkGlobalIsDifferentRecursive(
                  act.coefficient.kz.global,
                  act.coefficient.kz.coefficients
                )
              }
            }
          : undefined
      }
    }));

    return { ...labelItem, actList: updatedActList };
  });
}

export function getDifferentCoefficient(
  coefficient: IParametersFormCoefficient
): IParametersFormCoefficient {
  const newCoefficient = {
    ...coefficient,
    index: coefficient?.index
      ? {
          ...coefficient.index,
          coefficients: checkIsDifferent(coefficient.index.coefficients),
          global: {
            ...coefficient.index.global,
            isDifferent: checkGlobalIsDifferentRecursive(
              coefficient.index.global,
              coefficient.index.coefficients
            )
          }
        }
      : undefined,
    kz: coefficient?.kz
      ? {
          ...coefficient.kz,
          coefficients: checkIsDifferent(coefficient.kz.coefficients),
          global: {
            ...coefficient.kz.global,
            isDifferent: checkGlobalIsDifferentRecursive(
              coefficient.kz.global,
              coefficient.kz.coefficients
            )
          }
        }
      : undefined
  };
  return newCoefficient;
}

function multiplyBy100(input: string | number): string {
  let str = typeof input === 'number' ? input.toString() : input;

  str = str.replace(',', '.');

  if (parseFloat(str) === 0) {
    return '0';
  }

  const [integerPart, decimalPart = ''] = str.split('.');

  const paddedDecimalPart = decimalPart.padEnd(2, '0');

  const newIntegerPart =
    integerPart === '0'
      ? paddedDecimalPart.slice(0, 2)
      : integerPart + paddedDecimalPart.slice(0, 2);

  const trimmedIntegerPart = newIntegerPart.replace(/^0+/, '') || '0';

  const newDecimalPart = paddedDecimalPart.slice(2);

  const result = newDecimalPart
    ? `${trimmedIntegerPart}.${newDecimalPart}`
    : trimmedIntegerPart;

  return result.replace('.', ',');
}

function divideBy100(value: string | number) {
  let strValue = String(value);
  strValue = strValue.replace(',', '.');

  if (strValue === '0' || strValue === '0.0') {
    return '0.00';
  }

  const parts = strValue.split('.');
  let integerPart = parts[0];
  let decimalPart = parts[1] || '';

  if (integerPart.length === 0) {
    decimalPart = decimalPart.padStart(3, '0');
    integerPart = '0';
  } else {
    const newIntegerPart =
      integerPart.length > 2
        ? integerPart.slice(0, integerPart.length - 2)
        : '0';

    const newDecimalPart =
      integerPart.length > 2
        ? integerPart.slice(integerPart.length - 2) + decimalPart
        : (integerPart.length === 1 ? '0' + integerPart : integerPart) +
          decimalPart;

    integerPart = newIntegerPart;
    decimalPart = newDecimalPart;
  }

  return `${integerPart}.${decimalPart.padEnd(2, '0')}`;
}

export function getValueParameters(
  value: OrNull<number> | string,
  isPercent: boolean
) {
  if (value !== null) {
    if (isPercent) {
      if (value !== '') {
        const percentValue = multiplyBy100(+value ?? 0);

        return percentValue;
      } else {
        return '';
      }
    } else {
      return value !== '' ? +value : value;
    }
  } else {
    return '';
  }
}

export function setValueParameters(
  value: OrNull<number> | string,
  isPercent: boolean
) {
  if (value) {
    const valueStr = String(value).trim();

    const trimmedValueStr = valueStr.endsWith('%')
      ? valueStr.slice(0, -1).trim()
      : valueStr;

    const formattedValueStr = trimmedValueStr
      .replace(',', '.')
      .replace(/\s/g, '');

    if (formattedValueStr === '') {
      return '';
    }

    const numericValue = parseFloat(formattedValueStr);

    if (isNaN(numericValue)) {
      return '';
    }

    if (numericValue === 0) {
      return 0;
    }
    if (isPercent) {
      const percent = divideBy100(numericValue);

      return percent;
    } else {
      return numericValue;
    }
  } else {
    return '';
  }
}

export function processCoefficients(
  coefficients: ICoefficientData[],
  type: CoeffType
): IUpdateData[] {
  return coefficients.flatMap((coeff) => {
    const currentCoeff: IUpdateData = {
      type: type,
      isPercent: !!coeff?.isPercent,
      deleted:
        (coeff?.pz === null || coeff?.pz === undefined) &&
        (coeff?.fot === null || coeff?.fot === undefined) &&
        (coeff?.mim === null || coeff?.mim === undefined) &&
        (coeff?.mtr === null || coeff?.mtr === undefined),
      fot: coeff?.fot ?? null,
      id: coeff?.id ?? null,
      mim: coeff?.mim ?? null,
      mtr: coeff?.mtr ?? null,
      pz: coeff?.pz ?? null,
      rowID: coeff?.rowID ?? null
    };

    const childCoefficients: IUpdateData[] = coeff.children
      ? processCoefficients(coeff.children, type)
      : [];

    return [currentCoeff, ...childCoefficients];
  });
}
