import { FC, memo } from 'react';
import { TableProps } from './Table.types';
import { Body } from './components';
import { StyledTable } from './Table.styles';

export const Table: FC<TableProps> = memo(
  ({ data, loading, tableColumnsLocal, successWithoutFilters }) => {
    return (
      <StyledTable>
        <Body
          data={data}
          loading={loading}
          successWithoutFilters={successWithoutFilters}
          tableColumns={tableColumnsLocal}
        />
      </StyledTable>
    );
  },
);

Table.displayName = 'ResourcesTable';
