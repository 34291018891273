/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React from 'react';
import CheckItem from './CheckItem';
import { CircularProgress, Divider, Stack, Typography } from '@mui/material';
import CheckItemList from './CheckItemList';
import { IShortProject } from 'api/projects/projects.types';

type TProps = {
  errorData: number[];
  projectsList: IShortProject[];
  isFetching: boolean;
  selectedProjects: number[];
  setSelected: (v: number[]) => void;
};

const CheckItemWrapper: React.FC<TProps> = ({
  errorData,
  projectsList,
  isFetching,
  setSelected,
  selectedProjects: values
}) => {
  // const { values } = useFormikContext<TInitValues>();

  return (
    <Stack display={'flex'} alignItems={'flex-start'} flexDirection={'column'}>
      {(!!projectsList?.length || !!values.length) && (
        <CheckItem
          blockedIDs={errorData}
          selectedProjects={values}
          setSelected={setSelected}
          projectsList={projectsList ?? []}>
          Выбрать все
        </CheckItem>
      )}
      <Divider sx={{ alignSelf: 'stretch' }} />
      {isFetching ? (
        <CircularProgress />
      ) : !projectsList?.length ? (
        <Typography width={'100%'} color={'#5C6E8C'} textAlign={'center'} p={2}>
          Нет результатов
        </Typography>
      ) : (
        <CheckItemList
          selectedProjects={values}
          setSelected={setSelected}
          blockedIDs={errorData}
          list={projectsList}
        />
      )}
    </Stack>
  );
};

export default React.memo(CheckItemWrapper);
