import { Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { BaseTextField } from 'components/BaseTextField';
import { Divider } from 'components/Divider';
import { FC } from 'react';
import { ClearableTextFieldProps } from './ClearableTextField.types';

export const ClearableTextField: FC<ClearableTextFieldProps> = ({
  value,
  nullableValues,
  onClear,
  InputProps,
  ...props
}): JSX.Element => {
  return (
    <BaseTextField
      value={value}
      InputProps={{
        endAdornment:
          value && !nullableValues?.includes(value) ? (
            <Stack direction="row" spacing={0.5}>
              <Divider
                orientation="vertical"
                thickness="1px"
                rounded
                flexItem
              />
              <Close
                fontSize="small"
                color="secondary"
                sx={{ cursor: 'pointer' }}
                onClick={onClear}
              />
            </Stack>
          ) : null,
        ...InputProps,
      }}
      {...props}
    />
  );
};
