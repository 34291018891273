import { ComplicatedRowResourceRow } from 'types';
import { getFormatNumber } from 'utils/getFormatNumber';

export function getTagBaseTotal(subRows: ComplicatedRowResourceRow[]) {
  const total = subRows.reduce((acc, value) => {
    acc += value?.baseTotal ?? 0;
    return acc;
  }, 0);

  return getFormatNumber(total);
}

export function getTagCurrTotal(subRows: ComplicatedRowResourceRow[]) {
  const total = subRows.reduce((acc, value) => {
    acc += value?.currTotal ?? 0;
    return acc;
  }, 0);

  return getFormatNumber(total);
}

export function getCellValue(value: string | number | undefined | null) {
  if (value === undefined || value === null) return null;
  return typeof value === 'number' ? getFormatNumber(value) : value;
}
