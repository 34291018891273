import styled from 'styled-components';
import { Avatar, Card, Typography } from '@mui/material';

export const StyledAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  border-radius: 4px;
`;

interface StyledCardProps {
  textColor?: 'dark';
}

export const StyledCard = styled(Card)<StyledCardProps>`
  padding: 20px;
  border-radius: 8px;
  width: 360px;
  width: 100%;
  min-height: 333px;
  height: 100%;
  box-shadow:
    0 5px 6px -3px rgba(74, 104, 176, 0.08),
    0px 9px 12px 1px rgba(109, 154, 220, 0.08),
    0px 3px 16px 2px rgba(109, 154, 220, 0.08);

  &:hover {
    cursor: pointer;
    box-shadow: ${(props) => props.theme.shadows[20]};
  }

  & .MuiCardHeader-root .MuiTypography-body2 {
    color: ${(props) => props.theme.palette.text.dark};
  }

  & .MuiCardContent-root .MuiTypography-body2 {
    color: ${(props) =>
      props.textColor
        ? props.theme.palette.text.dark
        : props.theme.palette.secondary.gray};
  }
`;

export const CardSubtitle = styled(Typography)`
  font-weight: 700;
  font-size: ${(props) => props.theme.typography.tooltip.fontSize};
  line-height: ${(props) => props.theme.typography.tooltip.lineHeight};
  letter-spacing: ${(props) => props.theme.typography.tooltip.letterSpacing};
  color: ${(props) => props.theme.palette.secondary.gray};
`;
