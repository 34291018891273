import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import styled, { DefaultTheme } from 'styled-components';

export const IntegrateTitle = styled.div`
  padding: 40px 20px 20px 40px;
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 57.6px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #5c6e8c;
`;
export const CustomButton = styled.div`
  padding: 10.5px 12px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  color: #0288d1;
  background: #1976d214;
  cursor: pointer;
  transition: all linear 0.2s;
  border-radius: 6px;
  &:hover {
    background: rgba(25, 118, 210, 0.52);
    color: white;
  }
`;
export const IntegrateContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 20px 40px;
  border-radius: 20px;
  border: 1px solid #edeff3;

  .block {
    padding: 30px;
    display: grid;
    grid-template-columns: 240px 400px;
    grid-auto-rows: min-content;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    text-align: left;
    gap: 251px;
    .title {
      font-weight: 500;
      line-height: 18.2px;
      color: #2b3648;
      margin: 0;
    }
    .description {
      font-weight: 400;
      line-height: 19.6px;
      color: #5c6e8c;
      margin: 0;
    }
    .flex {
      display: flex;
      flex-direction: column;
      gap: 8px;
      &-row {
        flex-direction: row;
        align-items: center;
        gap: 16px;
      }
      &-between {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .icon-title {
    //styleName: Заголовки/h3;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.09px;
    color: #2b3648;
    margin: 0;
    &-success {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;

      color: #19681d;
      svg path {
        fill: #19681d;
      }
    }
    &-error {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      color: #f46b6b;
      svg path {
        fill: #f46b6b;
      }
    }
    &-info {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      color: #0288d1;
    }
  }
  .MuiFormLabel-root {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    text-align: left;
    color: #5c6e8c;
    padding-left: 9px;
    padding-bottom: 4px;
  }
  .MuiFormHelperText-root {
    position: absolute;
    top: -25px;
    right: 0;
  }
  .Mui-error {
    color: #f46b6b;
  }
`;
export const IntegrateTangleIcon = styled.section`
  padding: 30px;
`;

type StatusColor = 'blue' | 'red' | 'green';

const getColorByTanglStatus = (
  theme: DefaultTheme,
  statusColor: StatusColor,
) => {
  switch (statusColor) {
    case 'blue':
      return '#0288D1';
    case 'green':
      return '#19681D';
    default:
      return '#F46B6B';
  }
};

export const StyledButtonTanglSetup = styled(Button)`
  background: ${({ theme }) => '#1976D214'};
  color: ${({ theme }) => '#0288D1'};
  box-shadow: none;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  width: 280px;
  padding: 7.5px 8px;
  text-transform: unset;

  &:hover {
    background: ${({ theme }) => '#1976D214'};
    color: ${({ theme }) => '#0288D1'};
    box-shadow: none;
  }
`;

export const StyledItemWrapper = styled.div<{
  $verticalGap?: number;
  $width?: number;
}>`
  display: grid;
  grid-row-gap: ${({ $verticalGap = 18 }) => `${$verticalGap}px`};
  width: ${({ $width }) => ($width ? `${$width}px` : 'unset')};
`;

export const StyledTanglStatusNameWrapper = styled.div<{
  $statusColor: StatusColor;
}>`
  display: grid;
  grid-template-columns: repeat(2, max-content) 1fr;
  align-items: center;
  width: 280px;
  min-height: 40px;

  svg,
  path {
    fill: ${({ theme, $statusColor }) =>
      getColorByTanglStatus(theme, $statusColor)};
  }
`;

export const StyledTanglStatusName = styled.div<{ $statusColor: StatusColor }>`
  margin-left: 9px;

  color: ${({ theme, $statusColor }) =>
    getColorByTanglStatus(theme, $statusColor)};
`;

export const StyledTanglHeaderButton = styled(Button)`
  font-size: 14px;
  font-weight: 400;
  text-transform: unset;
  padding: 8px 5px;
  width: 94px;
  height: 30px;
  margin-left: auto;
`;

export const StyledPromt = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => '#5C6E8C'};
  width: 280px;
`;

// @ts-ignore
export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    padding-right: 0;
    background: ${({ theme }) => '#F6F7FB'};
  }

  .MuiOutlinedInput-input {
    padding: 10px 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #2b3648;
    border-radius: 8px;

    &::placeholder {
      font-size: 14px;
    }
  }

  .MuiButton-root {
    min-width: 20px;
    min-height: 20px;
    padding: 8px;
    border-radius: 0;

    .MuiSvgIcon-root {
      fill: ${({ theme }) => '#9AA2B0'};
    }

    &:hover {
      background: none;
    }

    .MuiButton-startIcon {
      margin: 0;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => `${'#2B3648'}1F`};
    border-radius: 8px;
  }
`;

export const StyledCaption = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => '#5C6E8C'};
`;

export const StyledButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;

  .MuiButton-root {
    font-size: 14px;
    font-weight: 500;
    padding: 7.5px 16px;
    width: 134px;
  }
`;

export const StyledLoaderWrapper = styled.div`
  width: 545px;
  margin-top: 30px;
`;
