import { TabData } from 'components/Tabs/Tabs.types';
import { LSRTabValue } from 'types';

export const topbarTabs: TabData<LSRTabValue>[] = [
  { label: 'Все документы', value: 'all' },
  { label: 'Согласован', value: 'agreed' },
  { label: 'Не согласован', value: 'not_agreed' },
  { label: 'В работе', value: 'in_progress' },
  { label: 'Не определён', value: 'undefiend' },
];
