import { StyledBody } from './Body.styles';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Estimate } from '../Estimete';
import { GetCoefficientsResponse } from '../../../../../../../../../../../../api/params/params.types';

export const Body: React.FC = () => {
  const { control } = useFormContext<GetCoefficientsResponse>();

  const { fields } = useFieldArray({
    name: 'nrsp',
    control,
  });

  return (
    <StyledBody>
      {fields.map((es, index) => (
        <Estimate key={es.recordID} parentIndex={index} />
      ))}
    </StyledBody>
  );
};
