/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React from 'react';
import { Stack } from '@mui/material';
import {
  StyledFieldFormDesc,
  StyledFieldFormTitle
} from '../../../../CalculationDrawerForm/CalculationDrawerForm.styles';

const TextFieldForm = () => {
  return (
    <Stack mb={2}>
      <Stack spacing={2}>
        <Stack spacing={2}>
          <StyledFieldFormTitle
            version="calculation"
            name="title"
            placeholder="Укажите краткое название расчета"
            multiline
            minRows={1}
          />
          <StyledFieldFormDesc
            version="calculation"
            name="description"
            placeholder="Укажите полное название расчета или его описание"
            multiline
            minRows={2}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default React.memo(TextFieldForm);
