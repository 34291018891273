/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { Box } from '@mui/material';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCalculationDictionaryState } from 'store/slices/calculationDictionary/calculationDictionary';
import { getPricesState } from 'store/slices/references/prices/prices';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { isNull } from 'lodash';

export interface MyCustomHeaderProps {
  menuIcon: string;
  displayName: string;
  column: {
    colDef: {
      field: string;
    };
  };
  context: any;
  showBtn: boolean;
}

const HeaderComponent: FC<MyCustomHeaderProps> = (props) => {
  // const { changeDialog } = useContext(PricesReferenceContext);
  const { activeFilters: activeFiltersPrice } = useSelector(getPricesState);
  const { activeFilters: activeFiltersCalculation } = useSelector(
    getCalculationDictionaryState,
  );
  console.log(props);
  const activeFilters = useMemo(() => {
    return props?.context?.changeDialog
      ? activeFiltersCalculation
      : activeFiltersPrice;
  }, [
    props?.context?.changeDialog,
    activeFiltersCalculation,
    activeFiltersPrice,
  ]);

  const name = useMemo(() => {
    if (
      props.column.colDef.field === 'kfoName' ||
      props.column.colDef.field === 'kfoGroups'
    ) {
      return 'groups';
    } else {
      return props.column.colDef.field + 's';
    }
  }, [props.column.colDef.field]);

  const isActive = () => {
    const collapse = localStorage.getItem('collapse');
    if (!isNull(collapse)) {
      return JSON.parse(collapse);
    }
    return false;
    // return !!activeFilters?.[name === 'kfoGroups'?'groups':name as keyof typeof activeFilters]?.length;
  };

  const onClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    // if (props.column && props.column.colDef && props.column.colDef.field) {
    //   if (props.column.colDef.field === 'kfoName') {
    //     props?.context?.changeDialog?.('groups' as any) || changeDialog?.('groups' as any);
    //   } else {
    //     props?.context?.changeDialog?.((props.column.colDef.field + 's') as any) ||
    //     changeDialog((props.column.colDef.field + 's') as any);
    //   }
    // }
  };
  return (
    <Box
      position={'relative'}
      width={'100%'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}>
      {props.displayName}
      {props.showBtn && (
        <Box position={'absolute'} right={5}>
          {/*<IconButton tabIndex={-1} onClick={onClick}>*/}
          <ArrowDropDownCircleIcon
            fontSize={'medium'}
            onClick={props.context.setCollapseColumns}
            color={'primary'}
            sx={{
              opacity: 0.6,
              cursor: 'pointer',
              transition: 'rotate linear 0.2s',
              rotate: isActive() ? '270deg' : '90deg',
            }}
          />
          {/*<FilterAltIcon fontSize={'small'} sx={{ fill: isActive ? '#0044B4' : '#9AA2B0' }} />*/}
          {/*</IconButton>*/}
        </Box>
      )}
    </Box>
  );
};

export default HeaderComponent;
