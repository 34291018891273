/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 * @author CyrilStrone<77802640+CyrilStrone@users.noreply.github.com>
 */

import ClearIcon from '@mui/icons-material/Clear';
import { Box, Typography } from '@mui/material';
import {
  ICoefficientData,
  OrNull
} from 'api/references/estimates/estimates.types';
import { ToggleButton } from 'components/ToggleButton';
import { ToggleButtonGroup } from 'components/ToggleButtonGroup';
import { Field, FieldProps, useFormikContext } from 'formik';
import { PopperComponent } from 'pages/Administration/AdminReferences/Prices/useTableData';
import { StyledClearableTextField } from 'pages/Calculations/components/CalculationBasic/components/ParametersDialog/components/TabComponent/TabComponent.styles';
import React, { useMemo, useRef } from 'react';
import { NumericFormat } from 'react-number-format';
import {
  Badge,
  DelimetrRow,
  getValueParameters,
  InformIcon,
  IParametersForm,
  IParametersFormCoefficient,
  IParametersUpdateValue,
  ListRow,
  setValueParameters,
  Table,
  TTab
} from '../../';
import { clearData, validate } from '../../../helper';

type TProps = {
  closeForm: () => void;
  tab: TTab;
  coefficient?: IParametersFormCoefficient;
  actId?: number;
  modifyKey: 'index' | 'kz';
  updateValue: <T extends keyof ICoefficientData>(
    props: IParametersUpdateValue<T>
  ) => void;
  itemKey: string;
};

export const CoefficientsTable: React.FC<TProps> = ({
  coefficient,
  modifyKey,
  updateValue,
  actId,
  itemKey
}) => {
  const { setFieldValue, getFieldProps } = useFormikContext<IParametersForm>();

  const global = coefficient?.[modifyKey]?.global;
  const coefficients = coefficient?.[modifyKey]?.coefficients;

  const isPercent = !!global?.isPercent;
  const isDisabledPZ = !!global?.fot || !!global?.mim || !!global?.mtr;
  const isDisabledRest = !!global?.pz;

  const refPz = useRef<OrNull<HTMLElement>>(null);
  const refFot = useRef<OrNull<HTMLElement>>(null);
  const refMim = useRef<OrNull<HTMLElement>>(null);
  const refMtr = useRef<OrNull<HTMLElement>>(null);

  const HelpIcon = useMemo(() => {
    return global?.isDifferent ? <InformIcon /> : null;
  }, [global]);

  return (
    <Table>
      <Typography whiteSpace={'nowrap'} variant={'body2'}>
        Наименование
      </Typography>
      <Typography whiteSpace={'nowrap'} variant={'body2'} align={'center'}>
        К-т / %
      </Typography>
      <Typography whiteSpace={'nowrap'} variant={'body2'} align={'center'}>
        ПЗ
      </Typography>
      <Typography whiteSpace={'nowrap'} variant={'body2'} align={'center'}>
        ФОТ ОР
      </Typography>
      <Typography whiteSpace={'nowrap'} variant={'body2'} align={'center'}>
        МиМ
      </Typography>
      <Typography whiteSpace={'nowrap'} variant={'body2'} align={'center'}>
        МТР
      </Typography>
      <span></span>
      <DelimetrRow />

      {global && (
        <>
          <Box gap={'4px'} display={'flex'} alignItems={'center'}>
            <Badge isGlobal>ALL</Badge>
            Глобальный коэффициент
          </Box>
          <Field name={`${itemKey}.global.isPercent`}>
            {() => (
              <ToggleButtonGroup
                exclusive
                fullWidth
                onChange={() => {
                  updateValue({
                    actID: actId,
                    isGlobal: true,
                    key: 'isPercent',
                    value: !isPercent
                  });
                }}>
                {['К', '%'].map((groupButton) => {
                  const selected =
                    groupButton === 'К' ? !isPercent : !!isPercent;
                  return (
                    <ToggleButton
                      key={groupButton}
                      value={groupButton}
                      selected={selected}>
                      {groupButton}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            )}
          </Field>
          <Field
            name={`${itemKey}.global.pz`}
            validateOnChange={true}
            validate={validate}>
            {({
              field,
              meta
            }: FieldProps<OrNull<number> | string, IParametersForm>) => (
              <>
                <NumericFormat
                  {...field}
                  value={getValueParameters(field.value, isPercent)}
                  onChange={(e) => {
                    updateValue({
                      actID: actId,
                      isGlobal: true,
                      key: 'pz',
                      value: setValueParameters(e.target.value, isPercent)
                    });
                  }}
                  InputProps={{
                    disabled: isDisabledPZ,
                    endAdornment: null,
                    ref: refPz
                  }}
                  customInput={StyledClearableTextField}
                  placeholder={isPercent ? '%' : 'К'}
                  suffix={isPercent ? '%' : ''}
                  allowNegative={false}
                  thousandSeparator={' '}
                  decimalSeparator=","
                  decimalScale={isPercent ? 13 : 15}
                  onClear={() => setFieldValue(field.name, '')}
                />
                <PopperComponent message={meta.error} refItem={refPz.current} />
              </>
            )}
          </Field>
          <Field
            name={`${itemKey}.global.fot`}
            validateOnChange={true}
            validate={validate}
            shouldUpdate={(nextProps: any, prevProps: any) =>
              nextProps.value !== prevProps.value
            }>
            {({
              field,
              meta
            }: FieldProps<OrNull<number> | string, IParametersForm>) => (
              <>
                <NumericFormat
                  {...field}
                  value={getValueParameters(field.value, isPercent)}
                  allowNegative={false}
                  onChange={(e) => {
                    updateValue({
                      actID: actId,
                      isGlobal: true,
                      key: 'fot',
                      value: setValueParameters(e.target.value, isPercent)
                    });
                  }}
                  InputProps={{
                    disabled: isDisabledRest,
                    endAdornment: null,
                    ref: refFot
                  }}
                  customInput={StyledClearableTextField}
                  thousandSeparator={' '}
                  placeholder={isPercent ? '%' : 'К'}
                  suffix={isPercent ? '%' : ''}
                  decimalSeparator=","
                  decimalScale={isPercent ? 13 : 15}
                  onClear={() => setFieldValue(field.name, '')}
                />
                <PopperComponent
                  message={meta.error}
                  refItem={refFot.current}
                />
              </>
            )}
          </Field>
          <Field
            name={`${itemKey}.global.mim`}
            validateOnChange={true}
            validate={validate}>
            {({
              field,
              meta
            }: FieldProps<OrNull<number> | string, IParametersForm>) => (
              <>
                <NumericFormat
                  {...field}
                  value={getValueParameters(field.value, isPercent)}
                  onChange={(e) => {
                    updateValue({
                      actID: actId,
                      isGlobal: true,
                      key: 'mim',
                      value: setValueParameters(e.target.value, isPercent)
                    });
                  }}
                  InputProps={{
                    disabled: isDisabledRest,
                    endAdornment: null,
                    ref: refMim
                  }}
                  customInput={StyledClearableTextField}
                  placeholder={isPercent ? '%' : 'К'}
                  suffix={isPercent ? '%' : ''}
                  allowNegative={false}
                  thousandSeparator={' '}
                  decimalSeparator=","
                  decimalScale={isPercent ? 13 : 15}
                  onClear={() => setFieldValue(field.name, '', true)}
                />
                <PopperComponent
                  message={meta.error}
                  refItem={refMim.current}
                />
              </>
            )}
          </Field>
          <Field
            name={`${itemKey}.global.mtr`}
            validateOnChange={true}
            validate={validate}>
            {({
              field,
              meta
            }: FieldProps<OrNull<number> | string, IParametersForm>) => (
              <>
                <NumericFormat
                  {...field}
                  value={getValueParameters(field.value, isPercent)}
                  onChange={(e) => {
                    updateValue({
                      actID: actId,
                      isGlobal: true,
                      key: 'mtr',
                      value: setValueParameters(e.target.value, isPercent)
                    });
                  }}
                  InputProps={{
                    disabled: isDisabledRest,
                    endAdornment: null,
                    ref: refMtr
                  }}
                  customInput={StyledClearableTextField}
                  placeholder={isPercent ? '%' : 'К'}
                  suffix={isPercent ? '%' : ''}
                  allowNegative={false}
                  thousandSeparator={' '}
                  decimalSeparator=","
                  decimalScale={isPercent ? 13 : 15}
                  onClear={() => {
                    setFieldValue(field.name, '');
                  }}
                />
                <PopperComponent
                  message={meta.error}
                  refItem={refMtr.current}
                />
              </>
            )}
          </Field>
          <Box
            position={'relative'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}>
            <>
              {HelpIcon}
              <ClearIcon
                sx={{ position: 'relative', right: '-15px' }}
                onClick={() => {
                  const update = {
                    ...getFieldProps(`${itemKey}.global`).value,
                    ...clearData
                  };
                  setFieldValue(`${itemKey}.global`, update, true);
                }}
                cursor={'pointer'}
                color={'secondary'}
              />
            </>
          </Box>
        </>
      )}

      {!!coefficients && (
        <ListRow
          updateValue={updateValue}
          itemKey={`${itemKey}.coefficients`}
          list={coefficients}
          actId={actId}
        />
      )}
    </Table>
  );
};
