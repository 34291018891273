import { Delete, Visibility } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MenuItem } from '../../Table.types';

export const rowMenuItems: MenuItem[] = [
  {
    action: 'open',
    icon: <Visibility color="primary" />,
    name: 'Открыть в режиме просмотра',
  },

  {
    action: 'download',
    icon: <FileDownloadIcon color="primary" />,
    name: 'Скачать файл',
  },

  {
    action: 'delete',
    icon: <Delete color="primary" />,
    name: 'Удалить файл',
  },
];
