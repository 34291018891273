/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 * @author CyrilStrone<77802640+CyrilStrone@users.noreply.github.com>
 */

import { Typography } from '@mui/material';
import { FieldArray } from 'formik';
import {
  ActCol,
  ActRow,
  ActTable
} from 'pages/Calculations/components/CalculationBasic/components/ParametersDialog/components/ActsTab/ActsTab.style';
import React from 'react';
import { CalcData } from 'types';
import {
  EditableRowAct,
  IParametersForm,
  IParametersFormAct,
  IParametersUpdateDate
} from '../../';

export const ActsTab: React.FC<{
  isActs: boolean;
  calculation?: CalcData;
  values: IParametersForm;
  actualActList: IParametersFormAct[];
  updateIsRemoved: (id: number, newRemoved: boolean) => void;
  updateDate: (props: IParametersUpdateDate) => void;
}> = ({
  calculation,
  values,
  isActs,
  actualActList,
  updateIsRemoved,
  updateDate
}) => {
  return (
    <ActTable>
      <ActRow isIntegrate={!!calculation?.integrationInfo} border paddingTop>
        <ActCol head>Сформирован</ActCol>
        <ActCol head align={'center'}>
          Начало периода
        </ActCol>
        <span />
        <ActCol head align={'center'}>
          Окончание периода
        </ActCol>
        <span />
        {calculation?.integrationInfo && (
          <ActCol head align={'flex-start'}>
            Статус
          </ActCol>
        )}
      </ActRow>
      <FieldArray name={'acts.actual'}>
        {() => {
          return values.actListFromLabel.map((group, groupIndex) => {
            return (
              <React.Fragment key={groupIndex}>
                {!!group.actList.find((e) => !e.isRemoved) && (
                  <Typography
                    style={{ cursor: 'default' }}
                    whiteSpace={'nowrap'}
                    textOverflow={'ellipsis'}
                    width={'100%'}
                    overflow={'hidden'}
                    pt={2}
                    fontSize={14}
                    color={'#7890b2'}>
                    {group.label || 'Общие'}
                  </Typography>
                )}
                <FieldArray name={`actListFromLabel.${groupIndex}.actList`}>
                  {() => {
                    return group.actList.map((row, rowIndex) =>
                      !row.isRemoved ? (
                        <EditableRowAct
                          updateDate={updateDate}
                          key={rowIndex}
                          updateIsRemoved={updateIsRemoved}
                          actualActList={actualActList}
                          calculation={calculation}
                          isActs={isActs}
                          parentIndex={groupIndex}
                          rowIndex={rowIndex}
                          row={row}
                        />
                      ) : null
                    );
                  }}
                </FieldArray>
              </React.Fragment>
            );
          });
        }}
      </FieldArray>
    </ActTable>
  );
};
