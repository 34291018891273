import { SideMenuItem, SideMenuNames } from '../../ParametersDialog.types';

export const menuItemLabels: Record<SideMenuNames, string> = {
  act: 'Акты по периодам',
  nrsp: 'Коэффициенты к НР и СП',
  toTotals: 'Коэффициенты к итогам сметы',
  indexes: 'Индексы изменения сметной стоимости',
  limitedCosts: 'Лимитированные затраты',
};

export const menuItems: SideMenuItem[] = [
  {
    label: menuItemLabels.act,
    value: 'act',
  },
  {
    label: menuItemLabels.nrsp,
    value: 'nrsp',
  },
  {
    label: menuItemLabels.toTotals,
    value: 'toTotals',
  },
  {
    label: menuItemLabels.indexes,
    value: 'indexes',
  },
  {
    label: menuItemLabels.limitedCosts,
    value: 'limitedCosts',
  },
];
export const menuItemLabelsExecuted: Record<SideMenuNames, string> = {
  act: 'Акты по периодам',
  nrsp: 'Коэффициенты к НР и СП',
  toTotals: 'Коэффициенты к итогам акта',
  indexes: 'Индексы изменения сметной стоимости',
  limitedCosts: 'Лимитированные затраты',
};

export const menuItemsExecuted: SideMenuItem[] = [
  {
    label: menuItemLabelsExecuted.act,
    value: 'act',
  },
  {
    label: menuItemLabelsExecuted.nrsp,
    value: 'nrsp',
  },
  {
    label: menuItemLabelsExecuted.toTotals,
    value: 'toTotals',
  },
  {
    label: menuItemLabelsExecuted.indexes,
    value: 'indexes',
  },
  {
    label: menuItemLabelsExecuted.limitedCosts,
    value: 'limitedCosts',
  },
];
