import styled, { css } from 'styled-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
} from '@mui/material';
import { TitledWrapperPrimaryProps } from './TitledWrapper.types';
import { ScrollableContainer } from 'styles/global/ScrollableContainer';

interface WrapperBorderProps
  extends Pick<TitledWrapperPrimaryProps, 'bordered'> {}

export const BorderProps = css`
  padding: 10px;
  border: ${(props) => `1px solid ${props.theme.palette.bg.gray}`};
  border-radius: 8px;
`;
export const HoverBorderProps = css`
  ${BorderProps};

  &:hover {
    border-color: ${(props) => props.theme.palette.primary.light};
    cursor: pointer;
  }
`;

export const RegularBoxWrapper = styled(Box)<WrapperBorderProps>`
  ${(props) => props.bordered === 'border' && BorderProps}
  ${(props) => props.bordered === 'hover' && HoverBorderProps}
`;

export const RegularStackWrapper = styled(Stack)<WrapperBorderProps>`
  ${(props) => props.bordered === 'border' && BorderProps};
  ${(props) => props.bordered === 'hover' && HoverBorderProps};
  gap: 6px;
`;

export const RegularScrollableWrapper = styled(
  ScrollableContainer,
)<WrapperBorderProps>`
  ${(props) => props.bordered === 'border' && BorderProps};
  ${(props) => props.bordered === 'hover' && HoverBorderProps};
  gap: 6px;
  padding-right: 20px;
`;

export const TitledWrapperAccordion = styled(Accordion)`
  box-shadow: none;

  &::before {
    display: none;
  }
`;

export const TitledWrapperAccordionSummary = styled(AccordionSummary)`
  min-height: 20px;
  padding: 0 4px 0 10px;
  background-color: transparent;

  &.Mui-expanded {
    min-height: 20px;
  }

  & .MuiAccordionSummary-content {
    margin: 0;
  }
`;

export const TitledWrapperAccordionDetails = styled(AccordionDetails)`
  margin-bottom: 8px;

  padding: 0;
  padding-top: 4px;
`;
