import React, { useContext } from 'react';
import { PublicUserProfile, UserCompanyInfo } from 'types';

export type AppContextType = {
  profile: PublicUserProfile;
  currentCompanyId: UserCompanyInfo['companyID'];
  onLogout: () => void;
};

const AppContext = React.createContext<AppContextType>({} as AppContextType);

const AppProvider = AppContext.Provider;

const useAppContext = () => useContext(AppContext);

export { AppProvider, useAppContext };
