import * as yup from 'yup';

import { format, isDate } from 'date-fns';

export const validationCalculation = yup.object({
  customer: yup.string().trim().required('common:errors.required'),
  description: yup.string().trim().required('common:errors.required'),
  title: yup.string().trim().required('common:errors.required'),
});
export const validationTitleEditRow = yup.object({
  title: yup.string().trim().required(`common:${''}${''}`),
});
