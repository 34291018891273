import styled from 'styled-components';
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import { FieldForm } from '../../../../components/FieldForm';

export const StyledContainerForm = styled(Box)`
  padding: 20px 20px 40px 50px;

  width: 1860px;
  /* max-width: 1860px;
  min-width: 1675px;
  width: 100%;
  */
`;

export const StyledSelectMenuItem = styled(MenuItem)`
  background-color: ${(props) => props.theme.palette.bg.gray};

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  &.Mui-focusVisible {
    background-color: ${(props) => props.theme.palette.bg.gray};
  }

  &.select-item-button {
    color: ${(props) => props.theme.palette.text.gray};
    background-color: ${(props) => props.theme.palette.primary.light};
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      background-color: ${(props) => props.theme.palette.primary.main};
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
`;

export const StyledButtonContainer = styled(Stack)`
  width: 250px;
  min-width: 250px;
  height: 140px;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    width: 270px;
    background: rgba(25, 118, 210, 0.08);
    border-radius: 6px;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    justify-content: flex-start;
    padding: 7px 10px;
    height: 36px;
  }
`;
export const StyledFieldForm = styled(FieldForm)`
  &.MuiTypography-root {
    color: #5c6e8c;
  }
`;
export const StyledTypography = styled(Typography)`
  line-height: 18px;
  text-align: start;
  color: ${(props) => props.theme.palette.text.dark};

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const StyledText = styled(Stack)`
  width: 200px;
`;
export const StyledBox = styled(Box)`
  width: 200px;
  height: 36px;
  padding: 8px 16px;
  color: #9aa2b0;
  text-overflow: ellipsis;
  border-radius: 8px;
  background-color: #f6f7fb;
  border: 1px solid rgba(92, 110, 140, 0.1);
`;
export const StyledLabel = styled(Box)`
  padding-left: 16px;
  width: 200px;
  text-align: left;
  margin-bottom: 6px;
  color: rgb(92, 110, 140);
  font-weight: 400;
  font-size: 12px;
  line-height: 110%;
  letter-spacing: 0;
`;
