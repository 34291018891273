export interface ResourceTableCol {
  field: string;
  value: string;
  width: number;
  align: 'left' | 'center' | 'right';
  multicol?: {
    field: string;
    value: string;
    align: 'start' | 'center' | 'end' | 'right' | 'left';
  }[];
  hide?: boolean;
  flex?: number;
}

export let tableColumns: ResourceTableCol[] = [
  {
    field: 'index',
    value: '№',
    align: 'center',
    width: 80,
  },

  {
    field: 'lsrCode',
    value: 'Шифр ЛСР',
    align: 'center',
    width: 250,
  },
  {
    field: 'code',
    value: 'Код',
    align: 'center',
    width: 100,
  },
  {
    field: 'title',
    value: 'Наименование',
    align: 'center',
    width: 500,
    flex: 1,
  },
  {
    field: 'unit',
    value: 'Ед. изм',
    align: 'center',
    width: 100,
  },
  {
    field: 'quantity',
    value: 'Кол-во',
    align: 'center',
    width: 100,
  },

  {
    field: 'mainBaseTotal',
    value: 'Стоимость в базовых ценах',
    align: 'center',
    multicol: [
      {
        field: 'baseTotalByUnit',
        value: 'На единицу',
        align: 'center',
      },
      { field: 'baseTotal', value: 'Всего', align: 'center' },
    ],
    width: 400,
  },

  {
    field: 'mainCurrTotal',
    value: 'Стоимость в текущих ценах',
    align: 'center',
    multicol: [
      {
        field: 'currTotalByUnit',
        value: 'На единицу',
        align: 'center',
      },
      { field: 'currTotal', value: 'Всего', align: 'center' },
    ],
    width: 400,
  },

  {
    field: 'weight',
    value: 'Уд. вес %',
    align: 'left',
    width: 100,
  },
  {
    field: 'repeatCount',
    value: 'Кол-во повторений',
    align: 'left',
    width: 100,
  },
  {
    field: 'rdStamp',
    value: 'Марка РД',
    align: 'left',
    width: 100,
  },
  {
    field: 'documentCode',
    value: 'Шифр документа',
    align: 'left',
    width: 230,
  },
];
