import { Box, Typography } from '@mui/material';
import { FC, memo, useMemo } from 'react';
import { ComplicatedRowResourceRow, resourceRowTagNames } from 'types';
import { getTotalWeight } from '../../Row.service';
import {
  getCellValue,
  getTagBaseTotal,
  getTagCurrTotal,
} from './GroupedRow.service';
import {
  StyledSubRowsBody,
  StyledTagRow,
  StyledTotal,
} from './GroupedRow.styles';
import { GroupedRowProps } from './GroupedRow.types';

export const GroupedRow: FC<GroupedRowProps> = memo(
  ({ type, rows, tableColumns, groupWeight }): JSX.Element => {
    const weight = groupWeight ?? getTotalWeight(rows);
    const headerWidth = useMemo(
      () =>
        tableColumns.reduce((accumulator, current) => {
          if (current?.multicol) {
            const multicolWidths = current.multicol
              .map(() => `${current.width / 2}px`)
              .join(' ');
            return `${accumulator} ${multicolWidths}`;
          }
          if (current.field === 'title') {
            return current?.width
              ? accumulator + ' ' + `minmax(${current.width}px,auto)`
              : accumulator;
          } else {
            return current?.width
              ? accumulator + ' ' + current.width + 'px'
              : accumulator;
          }
        }, ''),
      [tableColumns],
    );

    const titleGridArea = tableColumns.length === 9 ? '1/1/2/6' : '1/1/2/7';

    return (
      <Box>
        <StyledTagRow $template={headerWidth}>
          <Typography
            padding={(theme) =>
              `${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(6.5)}`
            }
            gridArea={titleGridArea}
            textAlign="left"
            variant="body2">
            {resourceRowTagNames[type]}
          </Typography>

          <div />
          <StyledTotal>
            <div></div>
            <Typography variant="body2" textAlign="center">
              {getTagBaseTotal(rows)}
            </Typography>
          </StyledTotal>
          <div />
          <StyledTotal>
            <div></div>
            <Typography variant="body2" textAlign="center">
              {getTagCurrTotal(rows)}
            </Typography>
          </StyledTotal>

          <div></div>
        </StyledTagRow>
        {[...rows]
          .sort((a, b) => ((a.code ?? '') > (b.code ?? '') ? 1 : -1))
          .map((row, index) => {
            const fixedRow: any = {};
            for (const column of tableColumns) {
              if (column.multicol) {
                for (const multicol of column.multicol) {
                  fixedRow[multicol.field] =
                    row[multicol.field as keyof ComplicatedRowResourceRow];
                }
                continue;
              }
              fixedRow[column.field] =
                row[column.field as keyof ComplicatedRowResourceRow];
            }

            return (
              <StyledSubRowsBody $template={headerWidth} key={row.id}>
                {tableColumns.reduce((acc, column) => {
                  if (column.multicol) {
                    for (const multi of column.multicol) {
                      const cellValue =
                        fixedRow[
                          multi.field as keyof ComplicatedRowResourceRow
                        ];
                      acc.push(
                        <Box
                          key={multi.field}
                          width={column.width}
                          flex={column.flex}
                          justifySelf={multi.align}
                          textAlign={multi.align}
                          overflow="hidden"
                          textOverflow="ellipsis"
                          px={1.25}>
                          {{
                            index: index + 1,
                            weight:
                              weight === 0
                                ? 0
                                : getCellValue(
                                    Math.abs(fixedRow.baseTotal / weight) * 100,
                                  ),
                            repeatCount: cellValue,
                          }[multi.field as string] ?? getCellValue(cellValue)}
                        </Box>,
                      );
                    }
                  } else {
                    const cellValue =
                      fixedRow[column.field as keyof ComplicatedRowResourceRow];
                    acc.push(
                      <Box
                        key={column.field}
                        width={column.width}
                        flex={column.flex}
                        textAlign={
                          column.field === 'title' ? 'left' : column.align
                        }
                        overflow="hidden"
                        textOverflow="ellipsis"
                        px={1.25}>
                        {{
                          index: index + 1,
                          weight:
                            weight === 0
                              ? 0
                              : getCellValue(
                                  Math.abs(fixedRow.baseTotal / weight) * 100,
                                ),
                          repeatCount: cellValue,
                        }[column.field] ?? getCellValue(cellValue)}
                      </Box>,
                    );
                  }

                  return acc;
                }, [] as any[])}
              </StyledSubRowsBody>
            );
          })}
      </Box>
    );
  },
);

GroupedRow.displayName = 'GroupedRow';
