import { Button } from '@mui/material';
import styled from 'styled-components';

export const TopbarStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  border-bottom: 4px solid rgba(25, 118, 210, 0.08);
`;

export const ActionsStyled = styled('div')``;

export const ButtonStyled = styled(Button)`
  font-weight: 400;
  text-transform: none;
  height: 32px;
  font-size: 16px;
  letter-spacing: 0.15px;
`;

export const ButtonAutoStyled = styled(ButtonStyled)`
  color: #0044b4;
  background: rgba(25, 118, 210, 0.08);
`;

export const DateTextStyled = styled('div')`
  font-size: 14px;
  letter-spacing: 0.17px;
  color: #5c6e8c;
`;

export const HrStyled = styled('div')`
  width: 2px;
  height: 18px;
  margin: 0px 10px 0 10px;
  background: rgba(25, 118, 210, 0.08);
  border-radius: 1px;
`;
