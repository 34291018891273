import {
  FocusEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { Form, FormikProvider } from 'formik';
import {
  StyledFieldForm,
  StyledFieldFormMin,
  StyledIconButton,
  StyledRow,
  StyledTypographyRow,
} from '../styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ReactComponent as RowIcon } from '../../../../../../assets/icons/LeveLicon/RowIcon.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers';

import { useForm } from '../../../../../../hooks/useForm';
import { validationRow } from './validation';
import { RowAddFormData } from './RowAddForm.types';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { StyledRowTd } from '../styles';
import { useCreateRowMutation } from '../../../../../../api/calculations';

export type FormAddRowProps = {
  calculationId: number;
  parentId: number;
  handleShowFormAddRow: (isShowFormRowAdd: boolean) => void;
};

const FormAddRow: React.FC<FormAddRowProps> = ({
  calculationId,
  parentId,
  handleShowFormAddRow,
}) => {
  const [isAddSave, setIsAddSave] = useState('');

  /************************************ добавить первый уровень ************************************/
  const [addRow, addRowResponse] = useCreateRowMutation();

  const initialValues: RowAddFormData = useMemo(
    () => ({
      price: 0,
      quantity: 0,
      title: '',
      unit: '',
      unitPrice: 0,
    }),
    [], //calculation
  );

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationRow,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const { values, handleSubmit } = formik;

  const outQuantityNum = parseFloat(
    (values.quantity ?? 0).toString().replace(',', '.').replace(' ', ''),
  );
  const outUnitPriceNum = parseFloat(
    (values.unitPrice ?? 0).toString().replace(',', '.').replace(' ', ''),
  );
  const outPriceNum = outQuantityNum * outUnitPriceNum;

  const onSubmit = useCallback(
    (values: RowAddFormData) => {
      addRow({
        id: calculationId,
        parent: parentId, // id parent row
        price: outPriceNum, //outPrice,
        quantity: outQuantityNum,
        title: values.title,
        type: 'row',
        unit: values.unit, // ед.изм
        unitPrice: outUnitPriceNum,
      });
    },
    [outPriceNum], //calculation
  );

  useMutationHandlers(addRowResponse, () => {
    handleShowFormAddRow(false);
    if (isAddSave === 'SaveAdd') {
      handleShowFormAddRow(true);
      setIsAddSave('');
      handleClearForm();
    } else {
      handleShowFormAddRow(false);
      setIsAddSave('');
    }
  });

  /************************************ Row Menu ************************************/

  const anchorEl = useRef<null | HTMLElement>(null);

  const handleClose = () => {
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  const handleSaveAddRow = () => {
    setIsAddSave('SaveAdd');
    anchorEl.current = null;
    setMenuRowOpened(false);
    handleSubmit();
  };
  const handleSaveRow = () => {
    handleSubmit();
  };

  const handleClearForm = () => {
    formik.resetForm({
      values: {
        price: 0,
        quantity: 0,
        title: '',
        unit: '',
        unitPrice: 0,
      },
    });
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  const handleDeleteFormAddRow = () => {
    handleShowFormAddRow(false);
  };
  const [isMenuRowOpened, setMenuRowOpened] = useState(false);

  const handleOpenMenuRowClick = (event: React.MouseEvent<HTMLElement>) => {
    anchorEl.current = event.currentTarget;
    if (event.currentTarget) setMenuRowOpened(true);
  };

  /************************************** onKeyDown Enter **********************************/

  const enterPressHandler = (e: { key: string }) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  /************************************** Focus Out **********************************/

  const formRef = useRef<HTMLFormElement>(null);

  const handleFocusOut: FocusEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      // @ts-ignore
      if (event.relatedTarget && formRef.current?.contains(event.relatedTarget))
        return;

      if (!isMenuRowOpened) {
        return handleSubmit();
      }
    },
    [isMenuRowOpened],
  );

  /*************************************************************************/

  return (
    <>
      <StyledRow colorBgRow={'shades'}>
        <StyledRowTd
          style={{
            width: '100px',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginRight: '26px',
              minWidth: '24px',
            }}>
            {false && (
              <StyledIconButton size="small">
                <KeyboardArrowUpIcon />
              </StyledIconButton>
            )}
          </Box>
          <IconButton disabled sx={{ width: 32, height: 32 }} size="small">
            <RowIcon style={{ width: 16, height: 16 }} />
          </IconButton>
        </StyledRowTd>
        <FormikProvider value={formik}>
          <Form
            ref={formRef}
            onBlur={handleFocusOut}
            style={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns:
                'minmax(206px,1fr) repeat(4, minmax(135px, 206px)) 30px',
              gridAutoRows: '50px',
            }}
            onKeyDown={enterPressHandler}>
            <StyledRowTd style={{ justifyContent: 'flex-start' }}>
              <StyledFieldForm
                autoFocus
                version="calculation"
                name="title"
                placeholder={
                  formik.isValid
                    ? 'Укажите название работ'
                    : 'Поле обязательно к заполнению'
                }></StyledFieldForm>
            </StyledRowTd>
            <StyledRowTd>
              <StyledFieldFormMin
                sx={{
                  '& .MuiFilledInput-input': {
                    '&.MuiInputBase-input': {
                      '&.MuiInputBase-input': {
                        textAlign: 'center',
                      },
                    },
                  },
                }}
                version="calculation"
                name="unit"
                placeholder={
                  formik.isValid ? '' : 'Поле обязательно'
                }></StyledFieldFormMin>
            </StyledRowTd>
            <StyledRowTd>
              <StyledFieldFormMin
                value={values.quantity}
                version="calculation"
                name="quantity"
                placeholder={
                  formik.isValid ? '' : 'Поле обязательно'
                }></StyledFieldFormMin>
            </StyledRowTd>
            <StyledRowTd>
              <StyledFieldFormMin
                value={values.unitPrice}
                version="calculation"
                name="unitPrice"
                placeholder={
                  formik.isValid ? '' : 'Поле обязательно'
                }></StyledFieldFormMin>
            </StyledRowTd>
            <StyledRowTd>
              <StyledTypographyRow style={{ textAlign: 'right' }}>
                {outPriceNum.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypographyRow>
            </StyledRowTd>
            <StyledRowTd style={{ padding: 0, width: 30 }}>
              <IconButton
                onClick={handleOpenMenuRowClick}
                sx={{ width: 30, height: 30 }}
                size="small">
                <MoreVertIcon />
              </IconButton>
            </StyledRowTd>
          </Form>
        </FormikProvider>
      </StyledRow>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl.current}
        open={isMenuRowOpened}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={handleSaveRow}>Сохранить </MenuItem>
        <MenuItem onClick={handleSaveAddRow}>Сохранить и доб. статью </MenuItem>
        <MenuItem onClick={handleClearForm}>Очистить </MenuItem>
        <MenuItem onClick={handleDeleteFormAddRow}>Удалить </MenuItem>
      </Menu>
    </>
  );
};

export default FormAddRow;
