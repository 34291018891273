/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import styled from '@emotion/styled';
import { BaseTextField } from '../../../../../../components/BaseTextField';

export const Search = styled(BaseTextField)`
  margin: 10px 0;

  .MuiInputBase-root {
    background: none !important;
    width: 100%;
    border-color: #d1d8fa;
  }
`;
