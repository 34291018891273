/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React from 'react';
import ErrorState from './components/ErrorState';
import SuccessState from './components/SuccessState';
import { WSMessageHandbookCopyResult } from 'api/web-socket';
import { IShortProject } from 'api/projects/projects.types';

type TProps = {
  errorData: number[];
  allProjects?: IShortProject[];
  repeat: () => void;
  close: () => void;
  response: TData;
};

type TData = null | WSMessageHandbookCopyResult;
const UploadInfo: React.FC<TProps> = ({
  close,
  repeat,
  allProjects,
  response,
  errorData
}) => {
  if (!response || !response.copied) return null;
  return response.failed.length ? (
    <ErrorState
      errorData={errorData}
      allProjects={allProjects}
      repeat={repeat}
      close={close}
    />
  ) : (
    <SuccessState close={close} />
  );
};

export default React.memo(UploadInfo);
