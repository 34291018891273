/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React, { PropsWithChildren } from 'react';
import { StyledTooltip } from '../CellEditorSergey/CellEditor.style';
import { Cell as C, CellWrapper } from './Cell.style';

export interface ICell extends PropsWithChildren {
  float?: 'left' | 'center' | 'right';
  click?: () => void;
  isError?: boolean;
  isNum?: boolean;
  isHide?: boolean;
  showTooltip?: boolean;
  weight?: boolean;
  color?: string;
  className?: string;
  originData?: string | number | null;
  style?: React.CSSProperties;
}

const Cell: React.FC<ICell> = ({
  weight = false,
  children,
  isHide,
  isError,
  float = 'left',
  click,
  showTooltip = true,
  originData = undefined,
  className,
  color,
  style,
}) => {
  return (
    <CellWrapper
      className={className}
      style={style}
      color={color}
      isError={isError}
      onClick={() => click?.()}>
      <StyledTooltip
        title={
          showTooltip
            ? originData !== undefined
              ? originData || ''
              : children || ''
            : ''
        }
        PopperProps={{
          disablePortal: false,
        }}>
        <C color={color} isNum={false} float={float} weight={weight}>
          {isHide ? null : children}
        </C>
      </StyledTooltip>
    </CellWrapper>
  );
};

export default Cell;
