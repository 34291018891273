/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import styled from 'styled-components';

export const Wrapper = styled.div<{ isOpen: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  svg {
    transform-origin: center center;
    rotate: ${({ isOpen }) => (isOpen ? '90deg' : '-90deg')};
    transition: rotate linear 0.2s;
    path {
      fill: #0288d1;
    }
  }
`;
export const Title = styled.div<{ isOpen: boolean }>`
  background: ${({ isOpen }) => (isOpen ? '#0288d1' : '#1976d214')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isOpen }) => (isOpen ? '#FFF' : '#0044b4')};
  font-family: 'Roboto', sans-serif;
  line-height: 1.8em;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  //line-height: normal;
  border-radius: 6px;
`;
