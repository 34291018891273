import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import { Box, Stack, Typography } from '@mui/material';
import { ColoredTitle } from 'components/ColoredTitle';
import React from 'react';
import { UserRolesColors, userRolesEnToRu } from 'types';
import { formatMaskPhone } from '../../../../../components/PhoneFieldForm/utils';
import { CardSubtitle, StyledAvatar } from '../styles';
import { UserCardHeaderProps } from './UserCardHeader.types';
import { StyledCardHeader } from './styles';

const UserCardHeader: React.FC<UserCardHeaderProps> = ({ data }) => {
  const {
    email,
    firstName,
    middleName,
    avatarPreview,
    lastName,
    phone,
    role: roleEn,
  } = data;
  const formattedName = (
    <>
      {lastName}
      <br />
      {firstName} {middleName}
    </>
  );
  const role = userRolesEnToRu[roleEn];

  const formattedPhone = () => {
    return phone ? formatMaskPhone(phone) : phone;
  };

  return (
    <StyledCardHeader
      avatar={
        <Box>
          <ColoredTitle body={role} color={UserRolesColors[roleEn]} />
          <StyledAvatar src={avatarPreview}>
            <PersonSharpIcon sx={{ width: '80px', height: '80px' }} />
          </StyledAvatar>
        </Box>
      }
      title={
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          {formattedName}
        </Typography>
      }
      subheader={
        <Stack spacing={0.5}>
          <CardSubtitle>Контакты:</CardSubtitle>
          <Typography variant="body2">{formattedPhone()}</Typography>
          <Typography variant="body2">{email}</Typography>
        </Stack>
      }
    />
  );
};

export default UserCardHeader;
