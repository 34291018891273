/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import styled from 'styled-components';

export const Cell = styled.div<{
  float: 'center' | 'left' | 'right';
  isNum?: boolean;
  weight?: boolean;
  color?: string;
}>`
  width: 100%;
  color: ${({ color }) => color + ' !important' || 'inherit'};
  max-height: 40px;
  vertical-align: center;
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: ${({ float }) => float};

  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 140%;
  ${({ weight }) => {
    return weight
      ? `
      color: rgb(92, 110, 140);
      font-weight: 700;
      `
      : `font-weight: 400;`;
  }};
  ${({ isNum }) =>
    isNum
      ? 'white-space: nowrap;'
      : `
    white-space: normal;
     word-wrap: break-word;
     display: -webkit-box;
     -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
   `}//word-wrap: break-word;
  //display: -webkit-box;
  //-webkit-line-clamp: 2;
  //-webkit-box-orient: vertical;
`;

export const CellWrapper = styled.div<{ isError?: boolean; color?: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isError, color }) =>
    isError ? 'red' : color + ' !important' || 'inherit'};
`;

const NumericFormatWithRef = forwardRef<HTMLInputElement, NumericFormatProps>(
  (props, ref) => <NumericFormat {...props} getInputRef={ref} />,
);
NumericFormatWithRef.displayName = 'NumericFormatWithRef';

export const InputEdit = styled(NumericFormatWithRef)`
  all: unset;
  padding: 4px 8px;
  text-align: center;
  height: 100%;
  //width: inherit;
  width: calc(100% - 16px);
  outline: none;
  background: transparent;
`;
