import { Box, Stack } from '@mui/material';
import { BasePricesItem } from '../../../../../../api/calculations/types';
import FunctionsIcon from '@mui/icons-material/Functions';
import { StyledLevelTd, StyledTypographyLevel } from './styles';

interface LevelSummBasePricePropsType {
  basePrices: BasePricesItem[];
}

export const LevelSummBasePrice: React.FC<LevelSummBasePricePropsType> = ({
  basePrices,
}) => {
  /***************************** Out Summ level  ****************************************/

  //   basePrices.
  let initialValue = 0;
  let sumTotal = basePrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.total,
    initialValue,
  );
  let sumMainSalary = basePrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.mainSalary,
    initialValue,
  );
  let sumMimExploitation = basePrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.mimExploitation,
    initialValue,
  );
  let sumMachineOperatorSalary = basePrices.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.machineOperatorSalary,
    initialValue,
  );

  let sumBasicMaterials = basePrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.basicMaterials,
    initialValue,
  );

  let sumAuxiliaryMaterials = basePrices.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.auxiliaryMaterials,
    initialValue,
  );

  let sumMaterials = sumAuxiliaryMaterials + sumBasicMaterials;

  let sumEquipment = basePrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.equipment,
    initialValue,
  );

  let sumOverheads = basePrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.overheads,
    initialValue,
  );

  let sumEstimatedProfit = basePrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.estimatedProfit,
    initialValue,
  );

  /************************************************************************/

  return (
    <Box
      className="Level-Summ"
      style={{
        display: 'grid',
        gridTemplateColumns: '100px 1fr',
        gridAutoRows: '50px',
      }}>
      <StyledLevelTd
        style={{
          paddingLeft: '9px',
          justifyContent: 'flex-start',
          border: 'none',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginRight: `11px`,
            minWidth: 24,
          }}></Box>
        <FunctionsIcon></FunctionsIcon>
      </StyledLevelTd>
      <Stack
        style={{
          display: 'grid',
          gridTemplateColumns:
            'minmax(320px,1fr) repeat(10,minmax(140px, 190px)) 30px',
          gridAutoRows: '50px',
        }}>
        <StyledLevelTd
          style={{
            justifyContent: 'flex-start',
            height: '100%',
            border: 'none',
          }}>
          <StyledTypographyLevel style={{ textAlign: 'left' }}>
            Итого
          </StyledTypographyLevel>
        </StyledLevelTd>
        <StyledLevelTd style={{ border: 'none' }}>
          <StyledTypographyLevel>
            {sumTotal.toLocaleString('ru-RU', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </StyledTypographyLevel>
        </StyledLevelTd>
        <StyledLevelTd style={{ border: 'none' }}>
          <StyledTypographyLevel>
            {sumMainSalary.toLocaleString('ru-RU', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </StyledTypographyLevel>
        </StyledLevelTd>
        <StyledLevelTd
          className="Эксплуатация МиМ"
          style={{
            boxShadow: 'inset 1px 0px 0px rgba(0, 36, 95, 0.1)',
            border: 'none',
          }}>
          <StyledTypographyLevel>
            {sumMimExploitation.toLocaleString('ru-RU', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </StyledTypographyLevel>
        </StyledLevelTd>
        <StyledLevelTd
          className="machineOperatorSalary"
          style={{ border: 'none' }}>
          <StyledTypographyLevel>
            {sumMachineOperatorSalary.toLocaleString('ru-RU', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </StyledTypographyLevel>
        </StyledLevelTd>
        <StyledLevelTd
          className="materials"
          style={{
            boxShadow: 'inset 1px 0px 0px rgba(0, 36, 95, 0.1)',
            border: 'none',
          }}>
          <StyledTypographyLevel>
            {sumMaterials.toLocaleString('ru-RU', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </StyledTypographyLevel>
        </StyledLevelTd>
        <StyledLevelTd className="basicMaterials" style={{ border: 'none' }}>
          <StyledTypographyLevel>
            {sumBasicMaterials.toLocaleString('ru-RU', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </StyledTypographyLevel>
        </StyledLevelTd>
        <StyledLevelTd
          className="auxiliaryMaterials"
          style={{
            boxShadow: 'inset -1px 0px 0px rgb(0 36 95 / 10%)',
            border: 'none',
          }}>
          <StyledTypographyLevel>
            {sumAuxiliaryMaterials.toLocaleString('ru-RU', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </StyledTypographyLevel>
        </StyledLevelTd>
        <StyledLevelTd className="equipment" style={{ border: 'none' }}>
          <StyledTypographyLevel>
            {sumEquipment.toLocaleString('ru-RU', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </StyledTypographyLevel>
        </StyledLevelTd>
        <StyledLevelTd className="overheads" style={{ border: 'none' }}>
          <StyledTypographyLevel>
            {sumOverheads.toLocaleString('ru-RU', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </StyledTypographyLevel>
        </StyledLevelTd>
        <StyledLevelTd className="estimatedProfit" style={{ border: 'none' }}>
          <StyledTypographyLevel>
            {sumEstimatedProfit.toLocaleString('ru-RU', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </StyledTypographyLevel>
        </StyledLevelTd>
        <StyledLevelTd
          style={{ padding: 0, width: 30, border: 'none' }}></StyledLevelTd>
      </Stack>
    </Box>
  );
};
