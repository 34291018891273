import { FC } from 'react';
import { TabFormWrapper } from './ManagementForm.styles';
import { FormLeftBlock } from '../FormLeftBlock';
import { FormRightBlock } from '../FormRightBlock';

export const ManagementForm: FC = () => {
  return (
    <TabFormWrapper>
      <FormLeftBlock />
      <FormRightBlock />
    </TabFormWrapper>
  );
};
