import styled from 'styled-components';
import { Box } from '@mui/material';

export const StyledGridContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  gap: 20px 20px;
  padding-bottom: 20px;
`;
export const StyledUploadStatusCard = styled(Box)`
  width: 100%;
  height: 300px;
  padding: 16px;
  text-align: center;
  background-color: #f6f7fb;
  border-radius: 8px;
`;
