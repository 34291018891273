/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SuccessState: React.FC<{ close: () => void }> = ({ close }) => {
  return (
    <Stack
      display="grid"
      height={'100%'}
      gridTemplateColumns={'1fr'}
      p={2}
      gridTemplateRows={'1fr min-content'}>
      <Stack
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}>
        <Stack
          maxWidth={'308px'}
          display={'flex'}
          textAlign={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
          alignItems={'center'}>
          <CheckCircleIcon fontSize={'large'} sx={{ color: '#14B8A6' }} />
          <Typography
            pt={1}
            fontSize={'16px'}
            sx={{ fontWeight: 500 }}
            variant={'caption'}
            color={'#7890B2'}>
            УСПЕШНО СОЗДАНО
          </Typography>
        </Stack>
      </Stack>
      <Button fullWidth onClick={close}>
        закрыть
      </Button>
    </Stack>
  );
};

export default React.memo(SuccessState);
