import { FC } from 'react';
import { Table } from './components';
import { StyledInner } from './NRSPCoefficientForm.styles';

export const NRSPCoefficientForm: FC = (): JSX.Element => {
  return (
    <StyledInner>
      <Table />
    </StyledInner>
  );
};
