import styled from 'styled-components';
import { Box, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const StyledSearchBox = styled(Box)`
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-left: 0;
`;

export const StyledSearch = styled(TextField)`
  background: ${(props) => props.theme.palette.bg.white};
  border-radius: 4px;

  & .MuiInputBase-formControl {
    padding: ${(props) =>
      `${props.theme.spacing(1.25)} ${props.theme.spacing(1)}`};
  }

  & input {
    width: 112px;
    padding: 0;
    transition: ${(props) => props.theme.transitions.create('width')};

    &:focus {
      width: 200px;
    }

    &::placeholder {
      font-weight: ${(props) => props.theme.typography.body1.fontWeight};
      font-size: ${(props) => props.theme.typography.body1.fontSize};
      line-height: ${(props) => props.theme.typography.body1.lineHeight};
      letter-spacing: ${(props) => props.theme.typography.body1.letterSpacing};
      text-transform: ${(props) => props.theme.typography.body1.textTransform};
      color: ${(props) => props.theme.palette.secondary.gray};
    }
  }

  & fieldset {
    border-color: transparent;
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  margin-top: 2px;
  font-size: 24px;
  color: ${(props) => props.theme.palette.secondary.dark};
`;
