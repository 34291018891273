import { alpha, Box, InputBase } from '@mui/material';
import styled from 'styled-components';

export const StyledCreateFormWrapper = styled(Box)`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(8)}`};
  text-align: left;
  background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.08)};
`;

export const StyledInput = styled(InputBase)`
  border: 1px solid rgba(92, 110, 140, 0.2);
  padding-left: 8px;
  padding-left: 8px;
  border-radius: 4px;
  background-color: white;
  width: 350px;
  margin-left: ${({ theme }) => theme.spacing(2)};
  margin-right: ${({ theme }) => theme.spacing(2)};
`;
