import { theme } from 'styles/theme';
import { LSRFileStatus } from 'types';

export const selectStatusColors: Record<LSRFileStatus, string> = {
  agreed: theme.palette.legends.lightgreen,
  in_progress: theme.palette.legends.purple,
  not_agreed: theme.palette.legends.red,
  undefiend: theme.palette.legends.disabled,
  not_selected: theme.palette.legends.disabled,
};
