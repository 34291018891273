/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React from 'react';
import { IShortProject } from 'api/projects/projects.types';
import CheckItem from './CheckItem';

const CheckItemList: React.FC<{
  list: IShortProject[];
  blockedIDs: number[];
  selectedProjects: number[];
  setSelected: (v: number[]) => void;
}> = ({ list, blockedIDs, selectedProjects, setSelected }) => {
  return (
    <React.Fragment>
      {list.map((item) => {
        return (
          <CheckItem
            blockedIDs={blockedIDs}
            item={item}
            selectedProjects={selectedProjects}
            setSelected={setSelected}
            projectsList={list}
            key={String(item?.shortName) + item.id}>
            {item.shortName}
          </CheckItem>
        );
      })}
    </React.Fragment>
  );
};

export default React.memo(CheckItemList);
