export const ToTotalsFields = [
  'recordTitle',
  'isPercent',
  'pz',
  'oz',
  'em',
  'zm',
  'mt',
];
export const IndexesFields = [
  'recordTitle',
  'coeff',
  'oz',
  'em',
  'zm',
  'mt',
  'smr',
];
