import React, { FC } from 'react';
import { FormItemProps } from './FormItem.types';
import { Box, Stack, Typography } from '@mui/material';

export const FormItem: FC<FormItemProps> = ({ title, input }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="start"
      width="100%"
      spacing={1}>
      <Box marginTop="11px" minWidth={170} textAlign="left">
        <Typography variant="body2">{title}:</Typography>
      </Box>

      {input}
    </Stack>
  );
};
