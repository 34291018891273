/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import { LabelsGroup } from 'api/params/params.types';
import { isAfter, isBefore } from 'date-fns';
import { isSameDate } from 'pages/Calculations/components/Accomplishment/components/ActDialog/utils';
import { ReactElement } from 'react';
import { ActList, TActStats } from 'types';
import { isEmpty } from 'utils/isEmpty';
import BigNumber from 'bignumber.js';
import { ICoefficientData } from 'api/references/estimates/estimates.types';

export function saveLevels(levels: any[], key: string = 'levels') {
  localStorage.setItem(key, JSON.stringify(levels));
}

export function getSaveLevels(key: string = 'levels'): any[] | null {
  const levels = JSON.parse(localStorage.getItem(key) ?? 'null');
  return levels;
}
export function getDisabledDates(
  acts: LabelsGroup[]
): { start: Date; end: Date; id: number }[] {
  if (!acts) return [];
  return acts.reduce((acc: { start: Date; end: Date; id: number }[], item) => {
    acc.push(
      ...item.fields.flatMap((dates) => ({
        start: new Date(dates.startDate || ''),
        end: new Date(dates.endDate || ''),
        id: dates.id
      }))
    );

    return acc;
  }, []);
}
export function checkDates(data: ActList, acts: LabelsGroup[]) {
  /* Получаем актуальный список дат, все кроме входящей даты */
  const disabledDates = getDisabledDates(acts);
  const filteredDisabledDates = disabledDates.filter((d) => d.id !== data.id);

  /**
   * Объект для формирования ошибок
   * start - попадает сюда, если есть ошибка в поле "Дата начала"
   * end - попадает сюда, если есть ошибка в поле "Дата окончания"
   * on - попадает сюда, если есть ошибка в поле "Сформирован на дату"
   * split - попадает сюда, если даты перепутаны местами, например, дата начала идет после даты конца
   */
  const errorText: {
    start: null | ReactElement;
    end: null | ReactElement;
    on: null | ReactElement;
    split: null | ReactElement;
  } = { start: null, end: null, on: null, split: null };

  if (
    (data.startDate && isNaN((data.startDate as Date).getTime())) ||
    data.startDate === null
  ) {
    errorText.start = <span>Дата начала некорректна</span>;
  }
  if (
    (data.endDate && isNaN((data.endDate as Date).getTime())) ||
    data.endDate === null
  ) {
    errorText.end = <span>Дата окончания некорректна</span>;
  }
  if (
    (data.onDate && isNaN((data.onDate as Date).getTime())) ||
    data.onDate === null
  ) {
    errorText.on = <span>Дата формирования некорректна</span>;
  }
  if (
    data.startDate &&
    !isNaN((data.startDate as Date).getTime()) &&
    data.endDate &&
    !isNaN((data.endDate as Date).getTime()) &&
    data.onDate &&
    !isNaN((data.onDate as Date).getTime())
  ) {
    for (const { start, end } of filteredDisabledDates) {
      if (
        (!errorText.start &&
          (isSameDate(data.startDate as Date, start) ||
            isAfter(data.startDate as Date, start)) &&
          (isSameDate(data.startDate as Date, end) ||
            isBefore(data.startDate as Date, end))) ||
        (isBefore(data.startDate as Date, start) &&
          isAfter(data.endDate as Date, end))
      ) {
        errorText.start = <span>Дата начала пересекает существующие акты</span>;
      }
      if (
        !errorText.end &&
        (isSameDate(data.endDate as Date, start) ||
          isAfter(data.endDate as Date, start)) &&
        (isSameDate(data.endDate as Date, end) ||
          isBefore(data.endDate as Date, end))
      ) {
        errorText.end = (
          <span>Дата окончания пересекает существующие акты</span>
        );
      }
      if (
        !errorText.on &&
        isSameDate(data.startDate as Date, data.endDate as Date)
      ) {
        errorText.on = <span>Даты не могут быть установлены одним днем</span>;
      }
      if (
        !errorText.split &&
        (isBefore(data.endDate as Date, data.startDate as Date) ||
          isAfter(data.startDate as Date, data.endDate as Date))
      ) {
        errorText.split = (
          <span>Дата начала не может идти после даты окончания</span>
        );
      }
    }
  }

  if (isEmpty(errorText, ['start', 'end', 'on', 'split'], false)) {
    return errorText;
  } else {
    return {} as typeof errorText;
  }
}
export function operation(
  num: string | number | null,
  value: string,
  onlyPercent = false
) {
  if (!num) return num;

  const val =
    typeof num === 'string'
      ? Number(
          num
            .trim()
            .replace(',', '')
            .replace(/[\s\%]/g, '')
        )
      : num;
  if (onlyPercent) {
    if (value === '%') {
      return val * 100;
    } else {
      return val / 100;
    }
  } else {
    if (value === '%') {
      return val * 100;
    } else {
      return val / 100;
    }
  }
}

export function getBaseName(str: string): string {
  return str.replace(/^(.+)\.\w+$/gi, '$1');
}

export function validate(value: string | number) {
  const maxNum = BigNumber('999999.999999999999999');
  const minNum = BigNumber('-999999.999999999999999');
  if (!value) return;
  let num: string | number = value;
  if (typeof value === 'string') {
    num = value?.replace(',', '.')?.replace(/\s/g, '') || '0';
  } else {
    num = value?.toString() || '0';
  }
  if (maxNum.lt(num)) {
    return 'Максимальное значение - 999 999,999999999999999';
  }
  if (minNum.gt(num)) {
    return 'Минимальное знаение - -999 999,999999999999999';
  }
}
export function findNodeByRowID(
  root: ICoefficientData | null,
  rowID: number
): ICoefficientData | null {
  if (!root) {
    return null;
  }

  if (root.id! === rowID || root.rowID! === rowID) {
    return root;
  }

  if (root.children) {
    for (let i = 0; i < root.children.length; i++) {
      const found = findNodeByRowID(root.children[i], rowID);
      if (found) {
        return found;
      }
    }
  }

  return null;
}
export function getDefaultNode(
  node: ICoefficientData,
  isPZ = false
): { [key: string]: any } {
  return {
    // isPercent: node.isPercent || false,
    pz: node.pz || '',
    fot: node.fot || '',
    mim: node.mim || '',
    mtr: node.mtr || ''
  };
}
export function findAndReplaceNodeInTreeArray(
  treeArray: ICoefficientData[],
  rowID: number,
  newNode: ICoefficientData
): void {
  for (let i = 0; i < treeArray.length; i++) {
    const root = treeArray[i];
    const node = findNodeByRowID(root, rowID);
    if (node) {
      Object.assign(node, newNode);
    }
  }
}
export function toValue(val: string | number | null): string | null {
  val = val?.toString()?.trim() || null;
  if (val === '' || val === null) return null;
  console.log(val);
  console.log(val.replace(/,/gi, '.').replace(/[\s\%]/gi, ''));
  return val.replace(/,/gi, '.').replace(/[\s\%]/gi, '') ?? null;
}
export function getInputProps(
  disabled: boolean,
  showEndAdornment: boolean,
  isEmptyValue: boolean
) {
  return {
    disabled,
    endAdornment: null
    // ...(showEndAdornment
    //   ? {
    //     endAdornment: (
    //       <Tooltip title={'Коэффициент удален из-за изменения на нижних уровнях'} placement="top">
    //         <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    //           <path
    //             d="M8.00016 2C11.6668 2 14.6668 4.38667 14.6668 7.33333C14.6668 10.28 11.6668 12.6667 8.00016 12.6667C7.1735 12.6667 6.38016 12.5467 5.64683 12.3333C3.70016 14 1.3335 14 1.3335 14C2.88683 12.4467 3.1335 11.4 3.16683 11C2.0335 10.0467 1.3335 8.75333 1.3335 7.33333C1.3335 4.38667 4.3335 2 8.00016 2ZM7.3335 9.33333V10.6667H8.66683V9.33333H7.3335ZM7.3335 8H8.66683V4H7.3335V8Z"
    //             fill="#0044B4"
    //           />
    //         </svg>
    //       </Tooltip>
    //     ),
    //   }
    //   : {}),
  };
}
export const clearData = {
  fot: '',
  isPercent: false,
  mim: '',
  mtr: '',
  pz: ''
};
export const statuses: Record<TActStats, { title: string; color: string }> = {
  CLOSED: { color: '#2E7D32', title: 'Период закрыт' },
  COLLECTING: { color: '#A14DE7', title: 'Получение факта' },
  PREPARED: { color: '#FF9800', title: 'Период изменен' },
  NEW: { color: 'white', title: '' },
  REOPENED: { color: '#FF9800', title: 'Период изменен' }
};
