import { lsrFileStatusNames } from 'types';
import { StatusOption } from './Table.types';

export const statusOptions: StatusOption[] = [
  {
    label: lsrFileStatusNames.agreed,
    value: 'agreed',
  },
  {
    label: lsrFileStatusNames.not_agreed,
    value: 'not_agreed',
  },
  {
    label: lsrFileStatusNames.in_progress,
    value: 'in_progress',
  },
  {
    label: lsrFileStatusNames.undefiend,
    value: 'undefiend',
  },
];
