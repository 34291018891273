import { Box } from '@mui/material';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  StyledBox,
  StyledContractStack,
  StyledBodyRow,
  StyledTitle,
  StyledTitleChild,
  StyledFieldFormMin,
} from '../../styles';
import { Form, FormikProvider } from 'formik';

import { CoefficientsFormData } from './CoefficientsForm.types';
import { useForm } from '../../../../../../../../hooks/useForm';
import { useEditCoefficientsMutation } from '../../../../../../../../api/calculations';
import { CoefficientNameItemType } from '../..';
import { Coefficients } from '../../../../../../../../api/calculations/types';
import { validationСoefficientRow } from './validation';

export type СoefficientsPropsType = {
  calculationId: number;
  coefficientNameItem: CoefficientNameItemType;
  coefficients: Coefficients;
};

export const СoefficientRow: React.FC<СoefficientsPropsType> = ({
  calculationId,
  coefficientNameItem,
  coefficients,
}) => {
  const {
    coefficientName,
    coefficientTitle,
    childCoefficient = '2px',
  } = coefficientNameItem;
  const { firstGen, firstSub, secondGen, secondSub, thirdGen, thirdSub } =
    coefficients[coefficientName];

  /********************************* Edit Coefficients ******************************/

  const [editCoefficients, editCoefficientsResponse] =
    useEditCoefficientsMutation();

  const initialValues: CoefficientsFormData = useMemo(
    () => ({
      firstGen: firstGen ?? '',
      firstSub: firstSub ?? '',
      secondGen: secondGen ?? '',
      secondSub: secondSub ?? '',
      thirdGen: thirdGen ?? '',
      thirdSub: thirdSub ?? '',
    }),
    [], //
  );

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationСoefficientRow,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values: CoefficientsFormData) => {
      onSubmit(values);
    },
  });

  const onSubmit = useCallback(
    (values: CoefficientsFormData) => {
      editCoefficients({
        calculationId,
        title: coefficientName,
        firstGen:
          values.firstGen != null
            ? parseFloat(
                values.firstGen.toString().replace(',', '.').replace(' ', ''),
              )
            : null,
        firstSub:
          values.firstSub != null
            ? parseFloat(
                values.firstSub.toString().replace(',', '.').replace(' ', ''),
              )
            : null,
        secondGen:
          values.secondGen != null
            ? parseFloat(
                values.secondGen.toString().replace(',', '.').replace(' ', ''),
              )
            : null,
        secondSub:
          values.secondSub != null
            ? parseFloat(
                values.secondSub.toString().replace(',', '.').replace(' ', ''),
              )
            : null,
        thirdGen:
          values.thirdGen != null
            ? parseFloat(
                values.thirdGen.toString().replace(',', '.').replace(' ', ''),
              )
            : null,
        thirdSub:
          values.thirdSub != null
            ? parseFloat(
                values.thirdSub.toString().replace(',', '.').replace(' ', ''),
              )
            : null,
      });
    },
    [], //calculation
  );

  const { values, handleSubmit } = formik;

  /************************************** onKeyDown Enter **********************************/
  const formRef = useRef<HTMLFormElement>(null);

  const enterPressHandler = () => {
    handleSubmit();
  };

  /************************************** Out number *****************************************/

  const handleChange = () => {
    setTimeout(function () {
      handleSubmit();
    }, 500);
  };

  let valueFirstGen = values.firstGen
    .toString()
    .replace('.', ',')
    .replace(' ', '');
  let valueSecondGen = values.secondGen
    .toString()
    .replace('.', ',')
    .replace(' ', '');
  let valueThirdGen = values.thirdGen
    .toString()
    .replace('.', ',')
    .replace(' ', '');
  let valueFirstSub = values.firstSub
    .toString()
    .replace('.', ',')
    .replace(' ', '');
  let valueSecondSub = values.secondSub
    .toString()
    .replace('.', ',')
    .replace(' ', '');
  let valueThirdSub = values.thirdSub
    .toString()
    .replace('.', ',')
    .replace(' ', '');

  /****************************************************************************************/

  return (
    <Box>
      <StyledBodyRow direction="row" className="body-row">
        <Box sx={{ minWidth: '32px', paddingTop: '16px' }}></Box>
        {childCoefficient === '0px' ? (
          <StyledTitleChild>
            <li style={{ paddingLeft: '10px' }}>{coefficientTitle}</li>
          </StyledTitleChild>
        ) : (
          <StyledTitle sx={{ borderTop: `${childCoefficient} solid #D2DCFF` }}>
            {coefficientTitle}
          </StyledTitle>
        )}
        <FormikProvider value={formik}>
          <Form
            ref={formRef}
            onSubmit={formik.handleSubmit}
            onChange={handleChange}
            onKeyDown={(e: { key: string }) => {
              if (e.key === 'Enter') {
                enterPressHandler();
              }
            }}
            style={{ display: 'flex', height: '64px', width: '100%' }}>
            <StyledBox
              sx={{
                minWidth: '600px',
                width: '100%',
                padding: '0 32px',
                borderTop: `${childCoefficient} solid #D2DCFF`,
              }}>
              <StyledContractStack className="GeneralContract" direction="row">
                <StyledFieldFormMin
                  placeholder="—"
                  value={valueFirstGen}
                  name="firstGen"
                />
                <StyledFieldFormMin
                  placeholder="—"
                  value={valueSecondGen}
                  name="secondGen"></StyledFieldFormMin>
                <StyledFieldFormMin
                  placeholder="—"
                  value={valueThirdGen}
                  name="thirdGen"></StyledFieldFormMin>
              </StyledContractStack>
            </StyledBox>
            <StyledBox
              sx={{
                minWidth: '600px',
                width: '100%',
                padding: '0 32px',
                borderTop: `${childCoefficient} solid #D2DCFF`,
              }}>
              <StyledContractStack className="Subcontracting" direction="row">
                <StyledFieldFormMin
                  placeholder="—"
                  value={valueFirstSub}
                  name="firstSub"></StyledFieldFormMin>
                <StyledFieldFormMin
                  placeholder="—"
                  value={valueSecondSub}
                  name="secondSub"></StyledFieldFormMin>
                <StyledFieldFormMin
                  placeholder="—"
                  value={valueThirdSub}
                  name="thirdSub"></StyledFieldFormMin>
              </StyledContractStack>
            </StyledBox>
          </Form>
        </FormikProvider>
        <Box sx={{ minWidth: '32px', paddingTop: '16px' }}></Box>
      </StyledBodyRow>
    </Box>
  );
};
