import { boolean, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const formValidate = object({
  credentials: object({
    username: string().required('Обязательное поле'),
    password: string().required('Обязательное поле'),
    secret: string().required('Обязательное поле'),
    clientID: string().required('Обязательное поле'),
  }),
  isEnabled: boolean().required(),
});
export const resolver = yupResolver(formValidate);
