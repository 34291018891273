import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

export const SortIcon: FC<SvgIconProps & { direction?: 'left' | 'right' }> = (
  props,
) => {
  return (
    <SvgIcon {...props} width="17" height="17" viewBox="0 0 17 17">
      {props.direction === 'left' ? (
        <>
          <path
            d="M1.3335 10.2859L4.28905 13.3335V11.0478H9.48164V9.52397H4.28905V7.23826L1.3335 10.2859Z"
            fill="#0044B4"
          />
          <path
            d="M11.7111 4.95317H6.51855V6.47698H11.7111V8.7627L14.6667 5.71508L11.7111 2.66746V4.95317Z"
            fill="#DADADA"
          />
        </>
      ) : props.direction === 'right' ? (
        <>
          <path
            d="M1.8335 10.7859L4.78905 13.8335V11.5478H9.98164V10.024H4.78905V7.73826L1.8335 10.7859Z"
            fill="#DADADA"
          />
          <path
            d="M12.2111 5.45317H7.01855V6.97698H12.2111V9.2627L15.1667 6.21508L12.2111 3.16746V5.45317Z"
            fill="#0044B4"
          />
        </>
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.78881 13.834L1.83325 10.7864L4.78881 7.73875V10.0245H9.9814V11.5483H4.78881V13.834ZM7.01846 5.45363H12.211V3.16791L15.1666 6.21553L12.211 9.26315V6.97744L7.01846 6.97744L7.01846 5.45363Z"
          fill="currentColor"
        />
      )}
    </SvgIcon>
  );
};
