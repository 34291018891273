import { Delete } from '@mui/icons-material';
import { ChangeStatus } from 'assets/SVG/ChangeStatus';
import { MenuItem } from '../../Table.types';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export const columnMenuItems: MenuItem[] = [
  {
    action: 'status',
    icon: <ChangeStatus color="primary" />,
    name: 'Присвоить статус',
  },

  {
    action: 'download',
    icon: <FileDownloadIcon color="primary" />,
    name: 'Скачать файлы',
  },

  {
    action: 'delete',
    icon: <Delete color="primary" />,
    name: 'Удалить файлы',
  },
];
