import {
  Input,
  inputClasses,
  Select,
  selectClasses,
  SelectProps,
} from '@mui/material';
import styled from '@mui/styled-engine-sc';
import { ReactElement } from 'react';
import { LSRFileStatus } from 'types';
import { selectStatusColors } from './StatusSelect.constants';

interface StyledStatusSelectProps {
  $status: LSRFileStatus;
}

export const StyledStatusSelect = styled(Select)<StyledStatusSelectProps>(
  ({ theme, $status }) => ({
    position: 'relative',

    '&:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: 0,
      width: '130px',
      height: '24px',
      backgroundColor: selectStatusColors[$status],
      borderRadius: theme.spacing(0.5),
      transform: 'translateY(-50%)',
      zIndex: 1,
    },
  }),
) as <T>(props: SelectProps<T> & StyledStatusSelectProps) => ReactElement;

export const StyledStatusSelectInput = styled(Input)(({ theme }) => ({
  [`& .${selectClasses.select}.${inputClasses.input}`]: {
    color: theme.palette.text.white,
    fontSize: '12px',
    fontWeight: '700',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    width: '130px',
    zIndex: 2,
  },
}));
