import { Stack, Typography } from '@mui/material';
import { ReactComponent as EmptyImage } from 'assets/empty.svg';
import { FC } from 'react';

export const EmptyRowsOverlay: FC = (): JSX.Element => {
  return (
    <Stack height="100%" justifyContent="center" alignItems="center">
      <Typography variant="h2">Данные в реестре отсутствуют.</Typography>
      <EmptyImage />
    </Stack>
  );
};
