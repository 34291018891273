/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import { api } from '../api';
import {
  IChangeStatusTangleRequest,
  IConnectTangleRequest,
  ICreateTanglRequest,
  ITangleData,
  ITangleProjectResponse,
  ITangleStatusResponse,
  ITangleTableResponse,
} from './tangl.types';

export const tangleApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCredentials: build.query<ITangleData, unknown>({
      query: () => ({
        url: '/company/settings/integrations/tangl',
        method: 'GET',
      }),
      providesTags: ['CredentialsTangle'],
    }),
    checkStatusConnection: build.query<ITangleStatusResponse, void>({
      query: () => ({
        url: '/integrations/external/tangl/check',
        method: 'GET',
      }),
      // providesTags: ['StatusConnectTangl'],
    }),
    getStatusIntegrate: build.query<{ status: boolean | null }, void>({
      query: () => ({
        url: '/company/settings/integrations/tangl/available',
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: ['ConnectTangl'],
    }),
    getModelList: build.query<ITangleProjectResponse[], void>({
      query: () => ({
        url: '/integrations/external/tangl/model-list',
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: ['ConnectTangl'],
    }),
    getTanglTable: build.query<
      { data: ITangleTableResponse[]; total: number },
      number | string
    >({
      query: (calcID) => ({
        url: `/calculation/${calcID}/tangl/get`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: ['ConnectTangl'],
    }),
    connectToTangle: build.mutation<ITangleData, IConnectTangleRequest>({
      query: (body) => ({
        url: '/company/settings/integrations/tangl',
        method: 'POST',
        body,
      }),
    }),
    changeStatusTangl: build.mutation<ITangleData, IChangeStatusTangleRequest>({
      query: ({ body, params }) => ({
        url: '/company/settings/integrations/tangl/change-status',
        method: 'POST',
        params,
        body,
      }),
      invalidatesTags: ['ConnectTangl'],
    }),
    deleteAccTangle: build.mutation<void, void>({
      query: () => ({
        url: '/company/settings/integrations/tangl',
        method: 'DELETE',
      }),
      invalidatesTags: ['ConnectTangl'],
    }),
    createTanglProject: build.mutation<
      ITangleTableResponse,
      ICreateTanglRequest
    >({
      query: ({ body, params }) => ({
        url: '/integrations/external/tangl/create-calc',
        method: 'POST',
        body,
        params,
      }),
      invalidatesTags: (result) => (result ? ['Calculations'] : []),
    }),
  }),
  overrideExisting: true,
});

export const {
  useConnectToTangleMutation,
  useDeleteAccTangleMutation,
  useGetCredentialsQuery,
  useCheckStatusConnectionQuery,
  useChangeStatusTanglMutation,
  useLazyCheckStatusConnectionQuery,
  useLazyGetCredentialsQuery,
  useGetStatusIntegrateQuery,
  useGetModelListQuery,
  useCreateTanglProjectMutation,
  useGetTanglTableQuery,
} = tangleApi;
