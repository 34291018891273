import styled from 'styled-components';
import { Box, Typography, IconButton } from '@mui/material';

export const StyledTypographyLevel = styled(Typography)`
  color: ${(props) => props.theme.palette.text.main};
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  text-align: center;
  font-weight: 500;
`;
export const StyledLevelTd = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  height: 100%;
`;
