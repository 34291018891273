import styled from 'styled-components';
import { Box, CardHeader } from '@mui/material';

export const StyledProjectCardHeader = styled(CardHeader)`
  position: relative;
  padding: 0 0 12px 0;

  .MuiCardHeader-content {
    align-self: flex-end;
    text-align: left;
  }
`;

export const ProjectCardFullNameWrapper = styled(Box)`
  height: 40px;

  & .MuiTypography-root {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
