import { Drawer } from 'components/Drawer';
import { wsContext } from 'contexts/ws';
import { useProjectId } from 'hooks/useProjectId';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useGetCalculationByIdQuery } from '../../../../api/calculations';
import Progress from '../../../../components/Progress';
import { CalculationDrawerForm } from '../CalculationDrawerForm';
import { CalculationDrawerProps } from './CalculationDrawer.types';

const CalculationDrawer: React.FC<CalculationDrawerProps> = ({
  calculationId,
  open,
  onClose
}) => {
  const [isDeleteClicked, setIsDeleteClicked] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const projectID = useProjectId();
  const cWS = useContext(wsContext);

  const {
    data: calculationData,
    isLoading,
    isFetching
  } = useGetCalculationByIdQuery(
    {
      projectID,
      calcID: calculationId!
    },
    {
      skip: !calculationId
    }
  );

  const currentCalculation = calculationData;

  const onDrawerClose = useCallback((dirty: boolean, immediately = false) => {
    onClose(dirty, immediately);
  }, []);

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty);
  }, []);

  useEffect(() => {
    if (!open) {
      setIsDeleteClicked(false);
    }
  }, [open]);

  return (
    <Drawer
      title={calculationId ? 'Редактирование расчета' : 'Добавить расчет'}
      anchor="right"
      open={open}
      onClose={() =>
        onDrawerClose(cWS.socketCalc.isUploaded === 'loading' ? false : dirty)
      }>
      {calculationId ? (
        !(isLoading || isFetching) ? (
          <CalculationDrawerForm
            onClose={onDrawerClose}
            onFormChange={onFormChange}
            calculation={currentCalculation}
          />
        ) : (
          <Progress />
        )
      ) : (
        <CalculationDrawerForm
          onClose={onDrawerClose}
          onFormChange={onFormChange}
          calculation={currentCalculation}
        />
      )}
    </Drawer>
  );
};

export default CalculationDrawer;
