import { Stack } from '@mui/material';
import {
  useAddProjectPreviewMutation,
  useCreateProjectMutation,
  useDeleteProjectPreviewMutation,
  useGetProjectDashboardQuery,
  useUpdateProjectMutation
} from 'api/projects';
import { Form, FormikProvider } from 'formik';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useForm } from 'hooks/useForm';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import { useSnackbar } from 'notistack';
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DirtyFormForGlobalConfirm,
  ProjectCreateResponsible,
  ProjectFinType,
  ProjectResponsible,
  ProjectStatus,
  ProjectType
} from 'types';
import { DATE_FORMAT } from 'utils/constants';
import { formatDateToString, formatToDate } from 'utils/formatDate';
import { validationProject } from './ProjectManagement.validation';
import {
  ProjectFormData,
  ProjectManagementTabs
} from './ProjectManagement.types';
import { ManagementForm } from './components/ManagementForm';
import { ManagementLegend } from './components/ManagementLegend';
import { MapForm } from './components/MapForm';
import { mapResponsible } from './mappers/mapResponsibleToContractor';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers';

const getCustomerFromResponsible = (
  responsible: ProjectResponsible[] | undefined
) => {
  if (!responsible) return null;

  const contractor = responsible.find((res) => res.type === 'customer');

  if (!contractor) return null;

  return mapResponsible(contractor);
};

const getContractorsFromResponsible = (
  responsible: ProjectResponsible[] | undefined
) => {
  if (!responsible) return [{ data: '', companyName: '' }];

  const contractors = responsible.filter((res) => res.type !== 'customer');

  if (contractors.length === 0) return [{ data: '', companyName: '' }];

  return contractors.map(({ companyName, id, userName }) => ({
    companyName,
    data: `${id}&${userName}`
  }));
};

export const ProjectManagement: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { projectId: projectIdString } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const projectID = Number(projectIdString);

  useBreadcrumbs(
    projectID
      ? [{ title: 'Редактирование проекта' }]
      : [{ title: 'Создание проекта' }]
  );

  const { data: projectRes } = useGetProjectDashboardQuery(
    {
      projectID
    },
    {
      skip: !projectID,
      refetchOnMountOrArgChange: true
    }
  );

  const { project, addressData, responsible, images } = projectRes || {};
  const { id, shortName, fullName, startDate, endDate, status, type, finType } =
    project || {};
  const { address, latitude, longitude, region } = addressData || {};

  console.log(projectRes);

  const [currentTab, setCurrentTab] = useState<ProjectManagementTabs>('info');
  const onTabChange = (value: ProjectManagementTabs) => {
    value !== currentTab && setCurrentTab(value);
  };

  const initialValues: ProjectFormData = useMemo(
    () => ({
      shortName: shortName || '',
      fullName: fullName || '',
      status: status || '',
      type: type || '',
      finType: finType || '',
      startDate: formatToDate(startDate),
      endDate: formatToDate(endDate),
      initialImages: images || [],
      imagesForCreate: [],
      imagesIdsToDelete: [],
      addressData: {
        address: { value: address || '', data: '', unrestricted_value: '' },
        latitude: latitude || '',
        longitude: longitude || '',
        region: region || ''
      },
      customer: getCustomerFromResponsible(responsible),
      contractors: getContractorsFromResponsible(responsible)
    }),
    [projectRes]
  );

  const [createProject, createProjectResponse] = useCreateProjectMutation();
  const [editProject, editProjectResponse] = useUpdateProjectMutation();
  const [uploadImagesToProject, uploadImagesToProjectResponse] =
    useAddProjectPreviewMutation();
  const [deleteImagesFromProject, deleteImagesFromProjectResponse] =
    useDeleteProjectPreviewMutation();

  const onSubmit = useCallback(
    (values: ProjectFormData) => {
      const { address, ...otherAddressData } = values.addressData || {};
      console.log(1);
      const customer: ProjectCreateResponsible = {
        companyName: values.customer?.companyName || '',
        type: 'customer',
        ...(values.customer?.data &&
          isNaN(Number(values.customer?.data?.split('&')?.[0])) &&
          values.customer?.data?.split('&')?.[1] && {
            id: Number(values.customer?.data.split('&')[0])
          }),
        ...(values.customer?.data &&
          values.customer?.data?.split('&')?.[1] && {
            userName: values.customer?.data.split('&')[1]
          })
      };

      console.log(2);

      let showErrorContractors = false;

      const contractors = values.contractors
        .filter((contractor) => {
          if (contractor.companyName && !contractor?.data?.length) {
            showErrorContractors = true;
          }
          return contractor?.data?.length !== 0;
        })
        .map((contractor) => ({
          companyName: contractor?.companyName,
          type: 'contractor',
          ...(contractor?.data &&
            Number(contractor?.data?.split('&')?.[0]) &&
            contractor?.data?.split('&')?.[1] && {
              userID: Number(contractor?.data?.split('&')?.[0])
            }),
          userName: contractor?.data?.split('&')?.[1] ?? undefined
        }));

      console.log(3);

      // if (showErrorContractors) {
      //   return
      // }

      const addressData = {
        ...otherAddressData,
        region: values.addressData?.region || 'Республика Адыгея',
        address: address?.value || ''
      };
      console.log(4);

      const dataForRequest: any = {
        fullName: values.fullName,
        shortName: values.shortName.trim(),
        startDate: formatDateToString(values.startDate!, DATE_FORMAT),
        endDate: formatDateToString(values.endDate!, DATE_FORMAT),
        status: values.status as ProjectStatus,
        type: values.type as ProjectType,
        finType: values.finType as ProjectFinType,
        ...addressData,
        responsible: [customer, ...contractors]
      };
      console.log(dataForRequest);
      console.log('id>>>', id);
      if (id) {
        editProject({
          params: {
            projectID: id
          },
          body: dataForRequest
        });
      } else {
        createProject({ body: dataForRequest });
      }
    },
    [project]
  );

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationProject,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values);
      setTimeout(() => setSubmitting(false), 1000);
    }
  });

  const { values, isValid, isSubmitting, dirty } = formik;

  useEffect(() => {
    const dirtyFormType: DirtyFormForGlobalConfirm = dirty ? 'project' : '';
    localStorage.setItem('dirtyForm', dirtyFormType);
  }, [dirty]);

  useMutationHandlers(createProjectResponse, (data) => {
    enqueueSnackbar('Проект успешно добавлен.', { variant: 'success' });
    navigate(-1);

    if (values.imagesForCreate.length) {
      uploadImagesToProject({
        params: { projectID: data.id },
        body: values.imagesForCreate
      });
    }
  });

  useMutationHandlers(editProjectResponse, (data) => {
    if (data) {
      if (values.imagesIdsToDelete.length) {
        deleteImagesFromProject({
          projectID,
          body: { data: values.imagesIdsToDelete }
        });
        return;
      }

      if (values.imagesForCreate.length) {
        uploadImagesToProject({
          params: {
            projectID: data.id
          },
          body: values.imagesForCreate
        });
      }

      enqueueSnackbar('Проект успешно изменен.', { variant: 'success' });
      navigate(-1);
    } else {
      enqueueSnackbar('Проект с таким наименованием уже существует.', {
        variant: 'error'
      });
    }
  });

  useMutationHandlers(deleteImagesFromProjectResponse, () => {
    if (values.imagesForCreate.length) {
      uploadImagesToProject({
        params: {
          projectID
        },
        body: values.imagesForCreate
      });
    }

    navigate(-1);
  });

  useMutationHandlers(uploadImagesToProjectResponse, () => {
    navigate(-1);
  });

  useEffect(() => {
    if (isSubmitting && currentTab === 'additional' && !isValid) {
      enqueueSnackbar(
        'Не заполнены обязательные поля на вкладке “О проекте”.',
        { variant: 'error' }
      );
    }
  }, [isSubmitting]);

  const ContentByTab: Record<ProjectManagementTabs, ReactNode> = {
    info: <ManagementForm />,
    additional: <MapForm />
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
      <FormikProvider value={formik}>
        <Stack flex={1} component={Form}>
          <ManagementLegend tabValue={currentTab} onTabChange={onTabChange} />

          {ContentByTab[currentTab]}
        </Stack>
      </FormikProvider>
    </LocalizationProvider>
  );
};
