import { ReactComponent as FirstLevelicon } from '../../../../assets/icons/LeveLicon/FirstLevelicon.svg';
import { ReactComponent as FirstLevNew } from '../../../../assets/icons/LeveLicon/FirstLevNew.svg';
import { ReactComponent as SecondLevelicon } from '../../../../assets/icons/LeveLicon/SecondLevelicon.svg';
import { ReactComponent as FiveLevelicon } from '../../../../assets/icons/LeveLicon/FivethLevelicon.svg';
import { ReactComponent as ThirdLevelicon } from '../../../../assets/icons/LeveLicon/ThirdLevelicon.svg';
import { ReactComponent as FourthLevelicon } from '../../../../assets/icons/LeveLicon/FourthLevelicon.svg';
import { ReactComponent as OtherLevelicon } from '../../../../assets/icons/LeveLicon/OtherLevelicon.svg';
import { SvgSecondLev, SvgThirdLev } from '../../../../assets/SVG/SVG';
import { Box } from '@mui/material';

export type LevelNameType =
  | 'level1'
  | 'level2'
  | 'level3'
  | 'level4'
  | 'level5';

export const LevelIcon: React.FC<{ levelName: string; type?: 'new' }> = ({
  levelName,
}) => {
  switch (levelName) {
    case 'level1':
      return <FirstLevelicon style={{ width: 19, height: 15 }} />;
    case 'level2':
      return <SecondLevelicon style={{ width: 19, height: 15 }} />;
    case 'level3':
      return <ThirdLevelicon style={{ width: 19, height: 15 }} />;
    case 'level4':
      return <FourthLevelicon style={{ width: 19, height: 15 }} />;
    case 'level5':
      return <FiveLevelicon style={{ width: 19, height: 15 }} />;
    default:
      return (
        <Box
          position={'relative'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}>
          <OtherLevelicon style={{ width: 19, height: 15 }} />
          <Box
            position={'absolute'}
            zIndex={1}
            color={'white'}
            fontSize={9}
            left={0}
            bottom={0}
            right={3}
            top={2}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}>
            {levelName.replace(/\D/g, '')}
          </Box>
        </Box>
      );
  }
};

export const LevelIconNew: React.FC<{
  levelName: LevelNameType;
  style: any;
}> = ({ levelName, style }) => {
  switch (levelName) {
    case 'level1':
      return <FirstLevNew style={{ ...style, width: 22, height: 17 }} />;
    case 'level2':
      return (
        <SvgSecondLev
          style={{
            ...style,
            width: 17,
            height: 18,
            fill: !!style.fill ? style.fill : '#5291DA',
          }}
        />
      );
    case 'level3':
      return (
        <SvgThirdLev
          style={{
            ...style,
            width: 16,
            height: 17,
            fill: !!style.fill ? style.fill : '#B78BAC',
          }}
        />
      );
    default:
      return <></>;
  }
};
