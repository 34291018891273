/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import styled from 'styled-components';
import { ClearableTextField } from '../../../../../../components/ClearableTextField';
import { MenuItem } from '@mui/material';

export const PageStyled = styled.div`
  width: 100%;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 20px;
  overflow-y: auto;
  overflow-x: auto;
  display: grid;
  height: 100%;
  grid-template-rows: min-content 1fr;
`;

export const SetupTable = styled.div<{ $border?: boolean }>`
  display: flex;
  align-items: center;
  padding: 14px 20px 14px 0;
  justify-content: space-between;
  ${({ $border }) =>
    $border ? 'border-bottom: 1px solid rgb(210, 220, 255);' : ''}
`;

export const SetupRow = styled(SetupTable)`
  padding: 0;
  height: 32px;
  justify-content: flex-start;

  svg {
    cursor: pointer;
  }
`;

export const SetupItem = styled.div<{ hideBefore?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;

  color: #5c6e8c;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  &:before {
    content: '';
    height: 18px;
    width: 2px;

    margin: 7px 0 7px 10px;

    background-color: ${({ hideBefore }) =>
      hideBefore ? 'transparent' : '#1976d214'};
  }
`;

export const StyledSelect = styled(ClearableTextField)<{
  width?: number;
  hideBorder?: boolean;
  smallText?: boolean;
}>`
  width: ${({ width }) => (width ? width + 'px' : '214px')};

  & .MuiInputBase-root {
    background: ${({ hideBorder }) => (hideBorder ? '#fff' : '#1976d214')};
    ${({ smallText }) =>
      smallText &&
      `
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
      `}
  }

  & .MuiOutlinedInput-notchedOutline {
    ${({ hideBorder }) => hideBorder && 'border:none;'};
  }
`;
export const PRStyledSelect = styled(ClearableTextField)<{
  width?: number;
  hideBorder?: boolean;
  smallText?: boolean;
}>`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  background: rgb(246, 247, 251);

  & .MuiInputBase-root {
    background: ${({ hideBorder }) => (hideBorder ? '#fff' : '#1976d214')};
    background: rgb(246, 247, 251);
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 0;
    ${({ smallText }) =>
      smallText &&
      `
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
      `}
  }

  & .MuiOutlinedInput-notchedOutline {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 0;
    //background: rgb(246, 247, 251);
    ${({ hideBorder }) => hideBorder && 'border:none;'};
  }
`;
export const StyledMenuItem = styled(MenuItem)`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
