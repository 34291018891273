import { Tooltip as MuiTooltip, Typography } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import React from 'react';
import { theme } from '../../styles/theme';

const Tooltip: React.FC<TooltipProps> = ({ title, children, ...props }) => {
  return (
    <MuiTooltip
      title={
        title ? (
          <Typography
            variant="tooltip"
            style={{
              textTransform: 'initial',
              color: theme.palette.text.white
            }}>
            {title}
          </Typography>
        ) : (
          ''
        )
      }
      arrow
      enterDelay={0}
      leaveDelay={0}
      componentsProps={{
        tooltip: {
          style: {
            padding: '8px 12px',
            marginTop: 6,
            backgroundColor: theme.palette.secondary.dark,
            whiteSpace: 'pre-line'
          }
        },
        arrow: {
          style: {
            color: theme.palette.secondary.dark
          }
        }
      }}
      {...props}>
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
