import { StyledDialogHeader } from 'pages/Calculations/components/CalculationBasic/components/ParametersDialog/ParametersDialog.styles';
import styled from 'styled-components';

export const StyledHead = styled(StyledDialogHeader)`
  display: grid;
  grid-template-columns: 1fr 1fr 115px 115px 115px 115px 115px 115px 30px;
  justify-content: space-around;
  padding: 0 0 10px 0;
  text-align: center;
`;
