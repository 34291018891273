import styled from 'styled-components';
import { StyledCardContent } from '../../UserCard/UserCardContent/styles';
import InfoRow from 'components/InfoRow';

export const StyledProjectCardContent = styled(StyledCardContent)`
  padding: 10px 0 0 0 !important;
`;

export const TypeInfoRow = styled(InfoRow)`
  height: 40px;

  & .info-row-title {
    min-width: fit-content;
  }
`;
