import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useUpdateLSRFileStatusMutation } from 'api/lsr';
import { useProjectId } from 'hooks/useProjectId';
import { FC } from 'react';
import { LSRFileStatus } from 'types';
import { statusOptions } from '../../Table.constants';
import { useUpdateTableLoading } from '../../hooks';
import {
  StyledStatusSelect,
  StyledStatusSelectInput,
} from './StatusSelect.styles';
import { StatusSelectProps } from './StatusSelect.types';
import { checkValue } from './StatusSelect.service';

export const StatusSelect: FC<StatusSelectProps> = ({
  fileID,
  value,
}): JSX.Element => {
  const projectID = useProjectId();
  const [updateLSRFile, { isLoading, isUninitialized }] =
    useUpdateLSRFileStatusMutation();

  useUpdateTableLoading(isLoading, isUninitialized);

  const changeStatusHandler = (e: SelectChangeEvent<LSRFileStatus>) => {
    updateLSRFile({
      params: { projectID },
      body: { status: e.target.value as LSRFileStatus, fileID },
    });
  };

  return (
    <StyledStatusSelect
      $status={value}
      value={checkValue(value)}
      input={<StyledStatusSelectInput disableUnderline />}
      displayEmpty
      onChange={changeStatusHandler}>
      {statusOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </StyledStatusSelect>
  );
};
