import { ToggleButtonGroupProps } from '@mui/material';
import { FC } from 'react';
import { StyledToggleButtonGroup } from './ToggleButtonGroup.styles';

export const ToggleButtonGroup: FC<ToggleButtonGroupProps> = ({
  children,
  ...props
}): JSX.Element => {
  return (
    <StyledToggleButtonGroup size="small" exclusive {...props}>
      {children}
    </StyledToggleButtonGroup>
  );
};
