/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import { Box, Typography } from '@mui/material';
import {
  useWS,
  useWSHandbook,
  WSRequestBodyHandbkUpload
} from 'api/web-socket';
import { FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { changeCreated } from 'store/slices/references/prices/prices';
import { api } from '../../../../../../api/api';
import Button from '../../../../../../components/Button';
import { Drawer } from '../../../../../../components/Drawer';
import { wsContext } from '../../../../../../contexts/ws';
import { useForm } from '../../../../../../hooks/useForm';
import { useAppDispatch } from '../../../../../../store/store';
import CopyUploadForm from './components/CopyUploadForm';
import { WarnBlock } from './ImportDrawer.style';

interface IImportProps {
  open: boolean;
  toggleDrawer: (b?: boolean) => void;
}

interface UploadFile {
  b64Content: string;
  name: string;
}

export interface IErrorData {
  address: string;
  msg: string;
}

const ImportDrawer: React.FC<IImportProps> = ({ toggleDrawer, open }) => {
  const dispatch = useAppDispatch();
  const [errorData, setErrorData] = useState<IErrorData[] | null>(null);
  const cWS = React.useContext(wsContext);

  const { connectionStatus } = useWS();
  const {
    uploadFileHandbookProgress,
    uploadFileHandbookStatus,
    uploadFileHandbookError,
    uploadFileHandbook,
    clearAll
  } = useWSHandbook();

  const { enqueueSnackbar } = useSnackbar();

  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [progressTime, setProgressTime] = useState<string>('');

  const [done, setDone] = useState(false);

  const [disabledDrag, setDisabledDrag] = useState(false);

  const [uploadFile, setUploadFile] = React.useState<UploadFile | null>(null);

  // const resetAndCloseDrawer = () => {
  //   formik.resetForm();
  //   setUploadFile(null);
  //   toggleDrawer(false);
  // };

  const handleRefresh = useCallback(() => {
    dispatch(api.util.invalidateTags(['PriceReference']));
  }, [dispatch]);

  const onSubmit = React.useCallback(
    (values: { files: File[] }) => {
      if (connectionStatus !== 'Open') {
        enqueueSnackbar(
          'Установка соединения. Повторите попытку через несколько секунд.',
          {
            variant: 'error',
            autoHideDuration: 3000
          }
        );
      }
      if (values.files.length !== 0 && uploadFile) {
        const data: WSRequestBodyHandbkUpload = {
          file: uploadFile
        };
        uploadFileHandbook(data);
      }
    },
    [connectionStatus, enqueueSnackbar, uploadFile, uploadFileHandbook]
  );

  const { formik } = useForm<{ files: File[] }>({
    enableReinitialize: true,
    initialValues: { files: [] },
    onSubmit
  });
  const { values, dirty } = formik;
  const [error, setError] = React.useState<boolean>(false);
  const [, setFormData] = React.useState<FormData | null>(null);

  React.useEffect(() => {
    if (values.files && values.files.length) {
      const base64File = { b64Content: '', name: '' };
      const file = values.files[0];
      const reader = new FileReader();
      reader.onload = ({ target }) => {
        const base64String = (target!.result as string)
          .replace('data:', '')
          .replace(/^.+,/, '');

        base64File.b64Content = base64String;
        base64File.name = file.name;
      };
      reader.readAsDataURL(file);
      setUploadFile(base64File);
    }
  }, [values]);

  React.useLayoutEffect(() => {
    if (open) {
      setProgressTime('');
      setProgressPercent(0);
    } else {
      setDisabledDrag(false);
      setDone(false);
    }
  }, [open]);

  useEffect(() => {
    if (uploadFileHandbookProgress) {
      setProgressTime(uploadFileHandbookProgress.remaining);
      setProgressPercent(Math.floor(uploadFileHandbookProgress.percent * 100));
    }
  }, [uploadFileHandbookProgress]);

  useEffect(() => {
    if (uploadFileHandbookStatus) {
      setErrorData(null);
      setProgressPercent(0);
      setProgressTime('');
      handleRefresh();
      setDone(true);
      setDisabledDrag(true);
      dispatch(changeCreated(false));
      clearAll();
    }
  }, [clearAll, dispatch, handleRefresh, uploadFileHandbookStatus]);

  useEffect(() => {
    if (uploadFileHandbookError) {
      uploadFileHandbookError.errors &&
        setErrorData(uploadFileHandbookError.errors);
      setError(true);
      setProgressPercent(0);
      setProgressTime('');
    }
  }, [uploadFileHandbookError]);

  return (
    <Drawer title={'Импорт'} open={open} onClose={() => toggleDrawer(false)}>
      <FormikProvider value={formik}>
        <Box
          onSubmit={formik.handleSubmit}
          component={'form'}
          display="grid"
          gridTemplateColumns={'1fr'}
          height={'100%'}
          gridTemplateRows={'1fr min-content'}
          px={'20px'}>
          <div>
            <WarnBlock>
              <Typography
                variant="buttonMedium"
                align={'center'}
                color="#ED6C02">
                ВНИМАНИЕ!
              </Typography>
              <Typography
                variant="buttonMedium"
                align={'center'}
                textTransform={'none'}
                color={'#2B3648'}>
                При загрузке файла, данные будут полностью перезаписаны.
              </Typography>
            </WarnBlock>
            <CopyUploadForm
              disabledClick={disabledDrag}
              done={done}
              wsProgress={progressPercent}
              wsTime={progressTime}
              errorsMSG={errorData}
              error={error}
              setError={setError}
              setFormData={setFormData}
              format={{
                'application/vnd.ms-excel': ['.xls'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  ['.xlsx']
              }}
              mode={'xml'}
              isLoading={cWS.socketImportHandbook.isUploaded === 'loading'}
              isWS={true}
            />
          </div>
          <Box
            display={'grid'}
            gridTemplateColumns={done ? '1fr' : '1fr 1fr'}
            gap={'16px'}
            pb={'20px'}>
            {done ? (
              <>
                <Button
                  fullWidth={true}
                  sx={{ '&': { maxWidth: '900px !important' } }}
                  type={'button'}
                  color={'success'}
                  onClick={() => toggleDrawer(false)}>
                  Готово
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={
                    !values.files?.length ||
                    !dirty ||
                    cWS.socketImportHandbook.isUploaded === 'loading'
                  }
                  type={'submit'}
                  color={'success'}>
                  обработать
                </Button>
                <Button onClick={() => toggleDrawer(false)}>закрыть</Button>
              </>
            )}
          </Box>
        </Box>
      </FormikProvider>
    </Drawer>
  );
};

export default ImportDrawer;
