/**
 * @author Mr_FazbiozZz[mr.fabiozzz@gmail.com]
 */
import { ReactComponent as RowIcon } from '../../../../../../assets/icons/LeveLicon/RowIcon.svg';
import { RowType } from '../../../../../../api/references/estimates/estimates.types';
import { BadgeLevel } from '../Level/Level';
import React from 'react';

export const LevelIcon: React.FC<{
  levelName: number | null;
  type?: RowType;
}> = ({ levelName, type }) => {
  if (type === 'RATE') return <RowIcon style={{ width: 19, height: 15 }} />;

  switch (levelName) {
    case 1:
      // return <FirstLevelicon style={{ width: 19, height: 15 }} />;
      return <BadgeLevel>1</BadgeLevel>;
    case 2:
      // return <SecondLevelicon style={{ width: 19, height: 15 }} />;
      return <BadgeLevel>2</BadgeLevel>;
    case 3:
      // return <ThirdLevelicon style={{ width: 19, height: 15 }} />;
      return <BadgeLevel>3</BadgeLevel>;
    case 4:
      return <RowIcon style={{ width: 13.3, height: 13.3 }} />;
    default:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.6663 1.66602H4.99967C4.08301 1.66602 3.34134 2.41602 3.34134 3.33268L3.33301 16.666C3.33301 17.5827 4.07467 18.3327 4.99134 18.3327H14.9997C15.9163 18.3327 16.6663 17.5827 16.6663 16.666V6.66602L11.6663 1.66602ZM14.9997 16.666H4.99967V3.33268H10.833V7.49935H14.9997V16.666Z"
            fill="#5C6E8C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8337 9.16602H9.16699V11.666H6.66699V13.3327H9.16699V15.8327H10.8337V13.3327H13.3337V11.666H10.8337V9.16602Z"
            fill="#5C6E8C"
          />
        </svg>
      );
  }
};

export default LevelIcon;
