import { InsertDriveFileOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import styled from 'styled-components';

export const StyledStatsInfoPaper = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: theme.palette.bg.white,
  border: '1px solid transparent',
  borderRadius: theme.spacing(2.5),
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2.5),
  width: '300px',

  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}));

export const StyledTotalIcon = styled(InsertDriveFileOutlined)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 16px;
`;

export const StyledStatsInfoValuesBox = styled.div(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
  border: `1px solid ${theme.palette.bg.gray}`,
  borderRadius: theme.spacing(2.5),
  flex: '1 1 auto',
}));

export const StyledStatsInfoItem = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;

  & + & {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }
`;

export const StyledStatsInfoItemValue = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.dark};
`;
