import { ToggleButtonOption } from './TableLegend.types';

export const toggleButtons: ToggleButtonOption[] = [
  {
    value: 'positions',
    label: 'Позиция сметы'
  },
  {
    value: 'grouped',
    label: 'Укрупненные расценки'
  },
  {
    value: 'fullVor',
    label: 'Ведомость укрупненных расценок'
  }
];
