import { api } from 'api/api';
import {
  DeleteLSRFileRequest,
  DeleteLSRFilesRequest,
  GetLSRFilesRequest,
  GetLSRFilesResponse,
  UpdateLSRFilesStatusRequest,
  UpdateLSRFileStatusRequest,
  UploadLSRFilesRequest,
} from './lsr.types';

export const lsr = api.injectEndpoints({
  endpoints: (build) => ({
    uploadLSRFiles: build.mutation<void, UploadLSRFilesRequest>({
      query: ({ params: { projectID, ...params }, body }) => ({
        url: `/project/${projectID}/lsr/files/upload`,
        method: 'POST',
        params,
        body,
      }),
      invalidatesTags: (result) =>
        result !== undefined ? ['LSR', 'ProjectDashboard'] : [],
    }),
    getLSRFiles: build.query<GetLSRFilesResponse, GetLSRFilesRequest>({
      query: ({ projectID }) => ({
        url: `/project/${projectID}/lsr/files/list`,
        method: 'GET',
      }),
      providesTags: ['LSR'],
    }),
    deleteLSRFile: build.mutation<void, DeleteLSRFileRequest>({
      query: ({ projectID, fileID }) => ({
        url: `/project/${projectID}/lsr/files/${fileID}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: (result) =>
        result !== undefined ? ['LSR', 'ProjectDashboard'] : [],
    }),
    deleteLSRFiles: build.mutation<void, DeleteLSRFilesRequest>({
      query: ({ projectID, fileIDS }) => ({
        url: `/project/${projectID}/lsr/files/batch/delete-request`,
        method: 'POST',
        body: { ids: fileIDS },
      }),
      invalidatesTags: (result) =>
        result !== undefined ? ['LSR', 'ProjectDashboard'] : [],
    }),
    downloadLSRFiles: build.mutation<any, DeleteLSRFilesRequest>({
      query: ({ projectID, fileIDS }) => ({
        url: `/project/${projectID}/lsr/files/batch/download`,
        method: 'POST',
        body: { ids: fileIDS },
        responseHandler: 'text',
      }),
      invalidatesTags: (result) =>
        result !== undefined ? ['LSR', 'ProjectDashboard'] : [],
    }),
    updateLSRFileStatus: build.mutation<void, UpdateLSRFileStatusRequest>({
      query: ({ params, body }) => ({
        url: `/project/${params.projectID}/lsr/files/change-status`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result) =>
        result !== undefined ? ['LSR', 'ProjectDashboard'] : [],
    }),
    updateLSRFilesStatus: build.mutation<void, UpdateLSRFilesStatusRequest>({
      query: ({ params, body }) => ({
        url: `/project/${params.projectID}/lsr/files/batch/change-statuses`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) =>
        result !== undefined ? ['LSR', 'ProjectDashboard'] : [],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLSRFilesQuery,
  useUploadLSRFilesMutation,
  useUpdateLSRFileStatusMutation,
  useUpdateLSRFilesStatusMutation,
  useDeleteLSRFileMutation,
  useDeleteLSRFilesMutation,
  useDownloadLSRFilesMutation,
} = lsr;
