import React, { useCallback, useMemo, useState } from 'react';
import { useField } from 'formik';
import { AvatarFieldFormProps } from './AvatarFieldForm.types';
import { Stack } from '@mui/material';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import CropModal from '../CropModal';
import { ManageUserAvatar } from './styles';

const AvatarFieldForm: React.FC<AvatarFieldFormProps> = ({
  fieldProps,
  needCrop,
  placeholder,
  isEditUser,
  ...props
}) => {
  const [{ value: formValue }, , { setValue }] = useField({
    name: props.name,
    ...fieldProps,
  });

  const [isOpenCroppedDialog, setIsOpenCroppedDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleSetValue = useCallback(
    (blob: Blob) => {
      const reader = new FileReader();
      reader.onload = ({ target }) => {
        setIsOpenCroppedDialog(false);
        setValue(new Blob([target!.result as ArrayBuffer]));
      };
      if (blob) {
        reader.readAsArrayBuffer(blob);
      }
    },
    [setValue],
  );

  const handleChangeAvatar: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const file = e?.target?.files?.[0] || null;
        if (needCrop) {
          setSelectedFile(file);
          setIsOpenCroppedDialog(true);
        } else {
          if (file) {
            handleSetValue(file);
          }
        }
      },
      [needCrop, handleSetValue],
    );

  const handleCancelCrop = useCallback(() => {
    setIsOpenCroppedDialog(false);
    setSelectedFile(null);
  }, []);

  const src = useMemo(() => {
    return formValue instanceof Blob
      ? URL.createObjectURL(formValue)
      : formValue;
  }, [formValue]);

  return (
    <>
      <label htmlFor="upload-user-file">
        <input
          style={{ display: 'none' }}
          accept="image/*"
          id="upload-user-file"
          type="file"
          onChange={handleChangeAvatar}
          disabled={isEditUser}
          onClick={(e: React.BaseSyntheticEvent) => (e.target.value = '')}
        />
        <ManageUserAvatar src={src} variant="rounded" isDisabled={isEditUser}>
          <Stack alignItems="center">
            <PersonSharpIcon sx={{ width: '100px', height: '100px' }} />

            {!isEditUser ? placeholder : null}
          </Stack>
        </ManageUserAvatar>
      </label>
      <CropModal
        file={selectedFile}
        isOpen={isOpenCroppedDialog}
        onSave={handleSetValue}
        onClose={handleCancelCrop}
        onCancel={handleCancelCrop}
      />
    </>
  );
};

export default AvatarFieldForm;
