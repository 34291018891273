import styled from 'styled-components';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';

export const EmptyPageWrapper = styled(Stack)`
  width: 100%;
  padding: 107px 0 50px;
`;

export const EmptyLink = styled(Link)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  color: ${(props) => props.theme.palette.primary.main};
`;
