import React, { Fragment, useMemo } from 'react';
import { Divider, OptionSwitch, WrapperSwitch } from './SwitchBtn.styles';

const SwitchBtn: React.FC<any> = ({ options, value, onClick, disableCurr }) => {
  const lastIndex = useMemo(() => {
    return options.length - 1;
  }, [options]);
  return (
    <WrapperSwitch disabled={disableCurr}>
      {options.map((option: any, index: number) => {
        return (
          <Fragment key={index + option.value}>
            <OptionSwitch
              disabled={disableCurr && option.value === 'curr'}
              selected={value === option.value}
              onClick={() => {
                if (disableCurr) {
                  if (option.value !== 'curr') {
                    onClick?.(option);
                  }
                } else if (value !== option.value) {
                  onClick?.(option);
                }
              }}>
              {option.name}
            </OptionSwitch>
            {index !== lastIndex && <Divider />}
          </Fragment>
        );
      })}
    </WrapperSwitch>
  );
};

export default SwitchBtn;
