import { MenuItem, Modal, Stack } from '@mui/material';
import { LSRFileStatus, lsrFileStatusNames } from 'types';
import { statusOptions } from '../../Table.constants';
import {
  StyledContent,
  StyledStatusSelectModal,
  StyledTitle,
} from './AddStatusModal.styles';
import { AddStatusModalProps } from './AddStatusModal.types';
import Button from 'components/Button';
import { useEffect } from 'react';

const AddStatusModal: React.FC<AddStatusModalProps> = ({
  open,
  selectedStatus,
  addStatuses,
  close,
  handleSelectStatus,
}) => {
  function bugPreventer(event: MouseEvent) {
    event.preventDefault();
  }

  useEffect(() => {
    document.addEventListener('mousedown', bugPreventer);
    return () => {
      document.removeEventListener('mousedown', bugPreventer);
    };
  }, []);

  return (
    <Modal open={open} onClose={close}>
      <StyledContent flexGrow={1}>
        <StyledTitle>Присвоить статус</StyledTitle>
        <Stack flexGrow={1}>
          <StyledStatusSelectModal
            displayEmpty
            fullWidth
            value={selectedStatus}
            onChange={(e) =>
              handleSelectStatus(e.target.value as LSRFileStatus)
            }
            renderValue={(selected) =>
              selected === null
                ? 'Выберите статус'
                : lsrFileStatusNames[selected]
            }>
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </StyledStatusSelectModal>

          <Stack
            flexGrow={1}
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
            gap="10px">
            <Button fullWidth color="success" onClick={addStatuses}>
              Присвоить
            </Button>
            <Button fullWidth onClick={close}>
              Отмена
            </Button>
          </Stack>
        </Stack>
      </StyledContent>
    </Modal>
  );
};

export default AddStatusModal;
