import { useCallback, useMemo } from 'react';
import {
  StyledClearableTextField,
  StyledEstimate,
  StyledGridBox,
  StyledTitle,
} from './Estimate.styles';
import { EstimateProps } from './Estimate.types';
import { IconButton, Stack } from '@mui/material';
import {
  GetCoefficientsResponse,
  IEstimate as EstimateType,
  NrspChapter,
} from 'api/params/params.types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Delete } from '@mui/icons-material';
import { NumericFormat } from 'react-number-format';
import Tooltip from 'components/Tooltip';

export const Estimate: React.FC<EstimateProps> = ({
  arrow = true,
  parentIndex,
  index,
}) => {
  const { control, setValue } = useFormContext<GetCoefficientsResponse>();
  const fields = useWatch({
    name: 'nrsp',
    control,
  });

  const guard = (obj: EstimateType | NrspChapter): obj is EstimateType => {
    return 'chapters' in obj;
  };

  const namespace = useMemo(() => {
    return !arrow
      ? `nrsp.${parentIndex}.chapters.${index}`
      : `nrsp.${parentIndex}`;
  }, [arrow]);

  /**
   * добавил в зависимость loading
   * для более корректного обновления tempEstimate
   */
  const tempEstimate = useWatch({
    name: `nrsp.${parentIndex}`,
  });

  // Поправить функцию удаления
  const handleRemove = useCallback(
    (updatableEstimate: EstimateType | NrspChapter) => {
      let copyFields = JSON.parse(JSON.stringify(fields));

      const currentParent = copyFields[parentIndex];

      currentParent.nr = '';
      currentParent.sp = '';

      updatableEstimate.sp = null;
      updatableEstimate.nr = null;

      // update(parentIndex, currentParent);
      setValue(`${namespace}` as any, currentParent, { shouldDirty: true });
    },
    [fields, parentIndex, index, namespace, arrow],
  );

  return !tempEstimate ? null : (
    <StyledEstimate sx={arrow ? { padding: '0 52px' } : { padding: '0 54px' }}>
      <StyledGridBox
        sx={
          !guard(tempEstimate)
            ? { paddingLeft: '30px', height: 40 }
            : { height: 40 }
        }>
        <StyledTitle>
          <Tooltip
            title={
              guard(tempEstimate)
                ? tempEstimate.fileTitle
                : tempEstimate.chapterTitle
            }>
            <span>
              {guard(tempEstimate)
                ? tempEstimate.fileTitle
                : tempEstimate.chapterTitle}
            </span>
          </Tooltip>
        </StyledTitle>
        <Controller
          name={`nrsp.${parentIndex}.nr` as any}
          control={control}
          render={({ field: { ref, ...props }, fieldState }) => (
            <NumericFormat
              {...props}
              inputRef={ref}
              isAllowed={(values) => {
                const { formattedValue } = values;
                return /^(\d{0,6})(?:,\d{0,15})?$/.test(formattedValue);
                // return floatValue < MAX_LIMIT;
              }}
              value={tempEstimate.nr}
              customInput={StyledClearableTextField}
              placeholder="К"
              decimalSeparator=","
              decimalScale={15}
              error={!!fieldState.error}
              onClear={() => props.onChange('')}
              sx={{ marginLeft: '0px' }}
            />
          )}
        />
        <Stack direction="row" gap={'20px'}>
          <Controller
            name={`nrsp.${parentIndex}.sp` as any}
            control={control}
            render={({ field: { ref, ...props }, fieldState }) => (
              <NumericFormat
                {...props}
                inputRef={ref}
                value={tempEstimate.sp}
                customInput={StyledClearableTextField}
                isAllowed={(values) => {
                  const { formattedValue } = values;
                  return /^(\d{0,6})(?:,\d{0,15})?$/.test(formattedValue);
                  // return floatValue < MAX_LIMIT;
                }}
                placeholder="К"
                decimalSeparator=","
                decimalScale={15}
                error={!!fieldState.error}
                onClear={() => props.onChange('')}
              />
            )}
          />
          <IconButton
            color="warning"
            onClick={() => handleRemove(tempEstimate)}>
            <Delete />
          </IconButton>
        </Stack>
      </StyledGridBox>
    </StyledEstimate>
  );
};
