import { api } from '../api';
import { UserProfile } from 'types';

export const profileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProfile: build.query<UserProfile, void>({
      query: () => '/profile/get',
      providesTags: ['Profile'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetProfileQuery } = profileApi;
