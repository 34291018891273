import { Autocomplete, Stack, Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledMoveGroupFrom = styled(Stack)`
  background: rgba(25, 118, 210, 0.2);
  height: 50px;
`;

export const StyledText = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.17px;
  color: #0044b4;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    width: 350px;
    background-color: white;
    height: 40px;
    font-size: 14px;
    padding: 0 8px;
  }
` as typeof Autocomplete;
