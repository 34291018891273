import { Box } from '@mui/material';
import { useRef } from 'react';

import { CalcData } from 'types';
import { ContractNameType } from '../..';
import { useGetComplexRowsListQuery } from '../../../../../../api/calculations';
import { LaborIntensityLevel } from './components/LaborIntensityLevel';
import {
  StyledGridBox,
  StyledHeadTd,
  StyledTableBody,
  StyledTableContainer,
  StyledTableHead,
} from './styles';

export type LaborIntensityProps = {
  currentContract: ContractNameType;
  calculation: CalcData;
};

export const LaborIntensity: React.FC<LaborIntensityProps> = ({
  currentContract,
  calculation,
}) => {
  const { id: calculationId } = calculation;

  /********************************* Scroll Body & Head ****************************************/

  const headRef = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLTableRowElement | null>(null);

  const handleScrollHead = (scroll: React.UIEvent<HTMLElement>): void => {
    if (bodyRef.current === null) return;
    const target = scroll.target as HTMLDivElement;
    bodyRef.current.scroll({ left: target.scrollLeft });
  };
  const handleScrollBody = (scroll: React.UIEvent<HTMLElement>): void => {
    if (headRef.current === null) return;
    const target = scroll.target as HTMLDivElement;
    headRef.current.scroll({ left: target.scrollLeft });
  };

  /*****************************Complex List****************************************/

  const { data, isFetching, isLoading } = useGetComplexRowsListQuery({
    id: calculationId,
  });
  const isDataLoading = isFetching || isLoading;
  const basePrices = data?.rows ? data?.rows : [];

  /****************************************************************************************/

  return (
    <>
      {!isDataLoading && (
        <StyledTableContainer className="LaborIntensity">
          <StyledTableHead
            className="HEAD"
            ref={headRef}
            onScroll={handleScrollHead}>
            <Box sx={{ minWidth: '100px' }}></Box>
            <StyledHeadTd
              style={{
                minWidth: '400px',
                width: '100%',
                height: '48px',
                justifyContent: 'center',
                alignItems: 'flex-start',
                paddingLeft: '12px',
              }}>
              Смета или наименование работ
            </StyledHeadTd>
            <StyledHeadTd sx={{ minWidth: '636px', width: '100%' }}>
              <Box sx={{ textTransform: 'uppercase' }}>Генподряд</Box>
              <StyledGridBox direction="row">
                <Box>
                  Норм. трудоемкость <br /> чел.- час
                </Box>
                <Box>
                  Норм. трудоемкость <br /> машин. - час
                </Box>
                <Box>
                  Отпускная стоимость часа, <br /> без НДС
                </Box>
              </StyledGridBox>
            </StyledHeadTd>
            <Box></Box>
            <StyledHeadTd sx={{ minWidth: '636px', width: '100%' }}>
              <Box sx={{ textTransform: 'uppercase' }}>Субподряд</Box>
              <StyledGridBox direction="row">
                <Box>
                  Норм. трудоемкость <br /> чел.- час
                </Box>
                <Box>
                  Норм. трудоемкость <br /> машин. - час
                </Box>
                <Box>
                  Отпускная стоимость часа, <br /> без НДС
                </Box>
              </StyledGridBox>
            </StyledHeadTd>
            <StyledHeadTd
              style={{
                minWidth: 13,
                color: '#7890B2',
                padding: 0,
                border: 'none',
              }}></StyledHeadTd>
          </StyledTableHead>
          <StyledTableBody
            className="body"
            ref={bodyRef}
            onScroll={handleScrollBody}>
            {basePrices?.map((element) => {
              return (
                <LaborIntensityLevel
                  key={element.id}
                  calculationId={calculationId}
                  levelNumber={1}
                  level={element}
                />
              );
            })}
          </StyledTableBody>
        </StyledTableContainer>
      )}
    </>
  );
};
