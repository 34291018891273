import { Box, Tooltip } from '@mui/material';
import {
  BasePricesItem,
  Coefficients,
} from '../../../../../../api/calculations/types';
import FunctionsIcon from '@mui/icons-material/Functions';
import {
  StyledTitle,
  StyledLevel,
  StyledLevelTd,
  StyledTypography,
} from './styles';

interface LevelSummEstimatePropsType {
  basePrices: BasePricesItem[];
  coefficients: Coefficients;
  countRepeat: string;
  isShowGenContract: boolean;
  isShowSubContract: boolean;
  isShowAnalysis: boolean;
}

export const LevelSummEstimate: React.FC<LevelSummEstimatePropsType> = ({
  basePrices,
  coefficients,
  countRepeat,
  isShowGenContract,
  isShowSubContract,
  isShowAnalysis,
}) => {
  const {
    mainSalary,
    mimExploitation,
    materials,
    basicMaterials,
    auxiliaryMaterials,
    equipment,
    overheads,
    estimatedProfit,
    machineOperatorSalary,
  } = coefficients!;

  /************************************** Out number *****************************************/

  const isData = basePrices.length > 0;

  let initialValue = 0;

  const sumMainSalary = basePrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.mainSalary,
    initialValue,
  );
  const outMainSalaryGen =
    sumMainSalary *
    (mainSalary.firstGen ?? 1) *
    (mainSalary.secondGen ?? 1) *
    (mainSalary.thirdGen ?? 1);

  const outMainSalarySub =
    sumMainSalary *
    (mainSalary.firstSub ?? 1) *
    (mainSalary.secondSub ?? 1) *
    (mainSalary.thirdSub ?? 1);

  const sumMimExploitation = basePrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.mimExploitation,
    initialValue,
  );

  const outMimExploitationGen =
    sumMimExploitation *
    (mimExploitation.firstGen ?? 1) *
    (mimExploitation.secondGen ?? 1) *
    (mimExploitation.thirdGen ?? 1);

  const outMimExploitationSub =
    sumMimExploitation *
    (mimExploitation.firstSub ?? 1) *
    (mimExploitation.secondSub ?? 1) *
    (mimExploitation.thirdSub ?? 1);

  const sumMachineOperatorSalary = basePrices.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.machineOperatorSalary,
    initialValue,
  );

  const outMachineOperatorSalaryGen =
    sumMachineOperatorSalary *
    (machineOperatorSalary.firstGen ?? 1) *
    (machineOperatorSalary.secondGen ?? 1) *
    (machineOperatorSalary.thirdGen ?? 1);

  const outMachineOperatorSalarySub =
    sumMachineOperatorSalary *
    (machineOperatorSalary.firstSub ?? 1) *
    (machineOperatorSalary.secondSub ?? 1) *
    (machineOperatorSalary.thirdSub ?? 1);

  const sumBasicMaterials = basePrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.basicMaterials,
    initialValue,
  );

  const outBasicMaterialsGen =
    sumBasicMaterials *
    (basicMaterials.firstGen ?? 1) *
    (basicMaterials.secondGen ?? 1) *
    (basicMaterials.thirdGen ?? 1);

  const outBasicMaterialsSub =
    sumBasicMaterials *
    (basicMaterials.firstSub ?? 1) *
    (basicMaterials.secondSub ?? 1) *
    (basicMaterials.thirdSub ?? 1);

  const sumAuxiliaryMaterials = basePrices.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.auxiliaryMaterials,
    initialValue,
  );

  const outAuxiliaryMaterialsGen =
    sumAuxiliaryMaterials *
    (auxiliaryMaterials.firstGen ?? 1) *
    (auxiliaryMaterials.secondGen ?? 1) *
    (auxiliaryMaterials.thirdGen ?? 1);

  const outAuxiliaryMaterialsSub =
    sumAuxiliaryMaterials *
    (auxiliaryMaterials.firstSub ?? 1) *
    (auxiliaryMaterials.secondSub ?? 1) *
    (auxiliaryMaterials.thirdSub ?? 1);

  const outMaterialsGen = outAuxiliaryMaterialsGen + outBasicMaterialsGen;
  const outMaterialsSub = outBasicMaterialsSub + outAuxiliaryMaterialsSub;

  const sumEquipment = basePrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.equipment,
    initialValue,
  );

  const outEquipmentGen =
    sumEquipment *
    (equipment.firstGen ?? 1) *
    (equipment.secondGen ?? 1) *
    (equipment.thirdGen ?? 1);

  const outEquipmentSub =
    sumEquipment *
    (equipment.firstSub ?? 1) *
    (equipment.secondSub ?? 1) *
    (equipment.thirdSub ?? 1);

  const sumOverheads = basePrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.overheads,
    initialValue,
  );

  const outOverheadsGen =
    sumOverheads *
    (overheads.firstGen ?? 1) *
    (overheads.secondGen ?? 1) *
    (overheads.thirdGen ?? 1);

  const outOverheadsSub =
    sumOverheads *
    (overheads.firstSub ?? 1) *
    (overheads.secondSub ?? 1) *
    (overheads.thirdSub ?? 1);

  const sumEstimatedProfit = basePrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.estimatedProfit,
    initialValue,
  );

  const outEstimatedProfitGen =
    sumEstimatedProfit *
    (estimatedProfit.firstGen ?? 1) *
    (estimatedProfit.secondGen ?? 1) *
    (estimatedProfit.thirdGen ?? 1);

  const outEstimatedProfitSub =
    sumEstimatedProfit *
    (estimatedProfit.firstSub ?? 1) *
    (estimatedProfit.secondSub ?? 1) *
    (estimatedProfit.thirdSub ?? 1);

  const sumPeoplePerHourGen = basePrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.peoplePerHourGen,
    initialValue,
  );

  const outPeoplePerHourGen =
    sumPeoplePerHourGen *
    (mainSalary.firstGen ?? 1) *
    (mainSalary.secondGen ?? 1) *
    (mainSalary.thirdGen ?? 1);

  const sumPeoplePerHourSub = basePrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.peoplePerHourSub,
    initialValue,
  );
  const outPeoplePerHourSub =
    sumPeoplePerHourSub *
    (mainSalary.firstSub ?? 1) *
    (mainSalary.secondSub ?? 1) *
    (mainSalary.thirdSub ?? 1);

  const sumMachinesPerHourGen = basePrices.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.machinesPerHourGen,
    initialValue,
  );

  const outMachinesPerHourGen =
    sumMachinesPerHourGen *
    (mimExploitation.firstGen ?? 1) *
    (mimExploitation.secondGen ?? 1) *
    (mimExploitation.thirdGen ?? 1);

  const sumMachinesPerHourSub = basePrices.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.machinesPerHourSub,
    initialValue,
  );
  const outMachinesPerHourSub =
    sumMachinesPerHourSub *
    (mimExploitation.firstSub ?? 1) *
    (mimExploitation.secondSub ?? 1) *
    (mimExploitation.thirdSub ?? 1);

  const outHourlyCostGen =
    (outMainSalaryGen +
      outMimExploitationGen +
      outOverheadsGen +
      outEstimatedProfitGen) /
    ((outPeoplePerHourGen ?? 0) + (outMachinesPerHourGen ?? 0));

  const outHourlyCostSub =
    (outMainSalarySub +
      outMimExploitationSub +
      outOverheadsSub +
      outEstimatedProfitSub) /
    ((outPeoplePerHourSub ?? 0) + (outMachinesPerHourSub ?? 0));

  const outTotalGen =
    outMainSalaryGen +
    outMimExploitationGen +
    outMaterialsGen +
    outEquipmentGen +
    outOverheadsGen +
    outEstimatedProfitGen;

  const outTotalSub =
    outMainSalarySub +
    outMimExploitationSub +
    outMaterialsSub +
    outEquipmentSub +
    outOverheadsSub +
    outEstimatedProfitSub;

  const outProfit = outTotalGen - outTotalSub;

  const outRentability = (outProfit / outTotalGen) * 100;

  /***************************** Out Summ level  ****************************************/

  return (
    <Box className="Level-Sum">
      <StyledLevel
        style={{
          gridTemplateColumns: `100px minmax(400px, 1fr) repeat(${countRepeat},170px)`,
        }}
        direction="row"
        className="Level-grid">
        <StyledLevelTd
          className="icon"
          style={{
            paddingLeft: '9px',
            justifyContent: 'flex-start',
            borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              minWidth: 24,
            }}></Box>
          <Box sx={{ marginLeft: `5px` }}>
            <FunctionsIcon></FunctionsIcon>
          </Box>
        </StyledLevelTd>
        <StyledTitle
          className="title"
          style={{
            borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
          }}>
          <Tooltip title={'Итого'}>
            <StyledTypography style={{ textAlign: 'left', marginLeft: 12 }}>
              Итого
            </StyledTypography>
          </Tooltip>
        </StyledTitle>
        {/* Analysis */}
        {isShowAnalysis && (
          <>
            <StyledLevelTd
              className="hourlyCostGen"
              style={{
                boxShadow: `inset 1px 0px 0px rgb(0 36 95 / 10%)`,
                borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
              }}>
              <StyledTypography>
                {isFinite(outHourlyCostGen)
                  ? outHourlyCostGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : '0,00'}
              </StyledTypography>
            </StyledLevelTd>
            <StyledLevelTd
              className="hourlyCostSub"
              style={{
                boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)',
                borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
              }}>
              <StyledTypography>
                {isFinite(outHourlyCostSub)
                  ? outHourlyCostSub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : '0,00'}
              </StyledTypography>
            </StyledLevelTd>
            <StyledLevelTd
              className="profit"
              style={{
                boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)',
                borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
              }}>
              <StyledTypography>
                {outProfit.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypography>
            </StyledLevelTd>
            <StyledLevelTd
              className="rentability"
              style={{
                boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)',
                borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
              }}>
              <StyledTypography>
                {isFinite(outRentability)
                  ? outRentability.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : '0,00'}
                {'%'}
              </StyledTypography>
            </StyledLevelTd>
          </>
        )}
        {/* ГЕНПОДРЯД */}
        {isShowGenContract && (
          <>
            <StyledLevelTd
              className="total"
              style={{
                boxShadow: 'rgb(0 26 77 / 8%) 0px 1px 250px inset',
                borderLeft: '1px solid rgba(0, 26, 77, 0.1)',
                borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
              }}>
              <StyledTypography>
                {outTotalGen.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypography>
            </StyledLevelTd>
            {!isShowAnalysis && (
              <>
                <StyledLevelTd
                  className="mainSalary"
                  style={{
                    borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
                  }}>
                  <StyledTypography>
                    {outMainSalaryGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="mimExploitation"
                  style={{
                    boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)',
                    borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
                  }}>
                  <StyledTypography>
                    {outMimExploitationGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="machineOperatorSalary"
                  style={{
                    borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
                  }}>
                  <StyledTypography>
                    {outMachineOperatorSalaryGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="materials"
                  style={{
                    boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)',
                    borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
                  }}>
                  <StyledTypography>
                    {outMaterialsGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="basicMaterials"
                  style={{ borderBottom: '1px solid rgba(0, 26, 77, 0.2)' }}>
                  <StyledTypography>
                    {outBasicMaterialsGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="auxiliaryMaterials"
                  style={{ borderBottom: '1px solid rgba(0, 26, 77, 0.2)' }}>
                  <StyledTypography>
                    {outAuxiliaryMaterialsGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="equipment"
                  style={{
                    boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)',
                    borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
                  }}>
                  <StyledTypography>
                    {outEquipmentGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="overheads"
                  style={{ borderBottom: '1px solid rgba(0, 26, 77, 0.2)' }}>
                  <StyledTypography>
                    {outOverheadsGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="estimatedProfit"
                  style={{ borderBottom: '1px solid rgba(0, 26, 77, 0.2)' }}>
                  <StyledTypography>
                    {outEstimatedProfitGen.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="peoplePerHour"
                  style={{
                    boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)',
                    borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
                  }}>
                  <StyledTypography>
                    {(outPeoplePerHourGen ?? 0).toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="machinesPerHour"
                  style={{ borderBottom: '1px solid rgba(0, 26, 77, 0.2)' }}>
                  <StyledTypography>
                    {(outMachinesPerHourGen ?? 0).toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="hourlyCost"
                  style={{ borderBottom: '1px solid rgba(0, 26, 77, 0.2)' }}>
                  <StyledTypography>
                    {isFinite(outHourlyCostGen)
                      ? outHourlyCostGen.toLocaleString('ru-RU', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : '0,00'}
                  </StyledTypography>
                </StyledLevelTd>
              </>
            )}
          </>
        )}
        {/* СУБПОДРЯД */}
        {isShowSubContract && (
          <>
            <StyledLevelTd
              className="total"
              style={{
                boxShadow: 'rgb(0 26 77 / 8%) 0px 1px 250px inset',
                borderLeft: '1px solid rgb(0 26 77 / 10%)',
                borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
              }}>
              <StyledTypography>
                {outTotalSub.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypography>
            </StyledLevelTd>
            {!isShowAnalysis && (
              <>
                <StyledLevelTd
                  className="mainSalary"
                  style={{ borderBottom: '1px solid rgba(0, 26, 77, 0.2)' }}>
                  <StyledTypography>
                    {outMainSalarySub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="mimExploitation"
                  style={{
                    boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
                    borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
                  }}>
                  <StyledTypography>
                    {outMimExploitationSub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="machineOperatorSalary"
                  style={{ borderBottom: '1px solid rgba(0, 26, 77, 0.2)' }}>
                  <StyledTypography>
                    {outMachineOperatorSalarySub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="materials"
                  style={{
                    boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
                    borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
                  }}>
                  <StyledTypography>
                    {outMaterialsSub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="basicMaterials"
                  style={{ borderBottom: '1px solid rgba(0, 26, 77, 0.2)' }}>
                  <StyledTypography>
                    {outBasicMaterialsSub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="auxiliaryMaterials"
                  style={{ borderBottom: '1px solid rgba(0, 26, 77, 0.2)' }}>
                  <StyledTypography>
                    {outAuxiliaryMaterialsSub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="equipment"
                  style={{
                    boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)',
                    borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
                  }}>
                  <StyledTypography>
                    {outEquipmentSub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="overheads"
                  style={{
                    borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
                  }}>
                  <StyledTypography>
                    {outOverheadsSub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="estimatedProfit"
                  style={{ borderBottom: '1px solid rgba(0, 26, 77, 0.2)' }}>
                  <StyledTypography>
                    {outEstimatedProfitSub.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="peoplePerHour"
                  style={{
                    boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)',
                    borderBottom: '1px solid rgba(0, 26, 77, 0.2)',
                  }}>
                  <StyledTypography>
                    {(outPeoplePerHourSub ?? 0).toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="machinesPerHour"
                  style={{ borderBottom: '1px solid rgba(0, 26, 77, 0.2)' }}>
                  <StyledTypography>
                    {(outMachinesPerHourSub ?? 0).toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypography>
                </StyledLevelTd>
                <StyledLevelTd
                  className="hourlyCost"
                  style={{ borderBottom: '1px solid rgba(0, 26, 77, 0.2)' }}>
                  <StyledTypography>
                    {isFinite(outHourlyCostSub)
                      ? outHourlyCostSub.toLocaleString('ru-RU', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : '0,00'}
                  </StyledTypography>
                </StyledLevelTd>
              </>
            )}
          </>
        )}
      </StyledLevel>
    </Box>
  );
};
