import {
  FocusEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { Form, FormikProvider } from 'formik';
import {
  StyledFieldForm,
  StyledFieldFormMin,
  StyledTypographyRow,
} from '../styles';
import { ReactComponent as RowIcon } from '../../../../../../assets/icons/LeveLicon/RowIcon.svg'; // assets/icons/LeveLicon/ FirstLevelicon.svg
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useCreateComplexRowMutation } from '../../../../../../api/calculations';
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers';

import { useForm } from '../../../../../../hooks/useForm';
import { validationRow } from './validation';
import { RowAddFormData } from './RowAddForm.types';
import { StyledBodyTdRow, StyledTableRow } from '../styles';

export type ComplexFormAddRowPropsType = {
  calculationId: number;
  parentId: number;
  handleShowFormAddRow: (isShowFormRowAdd: boolean) => void;
};

const ComplexFormAddRow: React.FC<ComplexFormAddRowPropsType> = ({
  calculationId,
  parentId,
  handleShowFormAddRow,
}) => {
  const [isAddSave, setIsAddSave] = useState('');

  /************************************ добавить уровень ************************************/
  const [addComplexRow, addComplexRowResponse] = useCreateComplexRowMutation();

  const initialValues: RowAddFormData = useMemo(
    () => ({
      auxiliaryMaterials: 0, //вспомогательные материалы
      basicMaterials: 0, // основные материалы
      equipment: 0, //оборудование
      estimatedProfit: 0, // сметная прибыль
      machineOperatorSalary: 0, // зарплата механизаторов
      mainSalary: 0, //основная зарплата
      materials: 0, // материалы
      mimExploitation: 0, //Эксплуатация МиМ
      overheads: 0, //накладные расходы
      title: '',
      total: 0,
    }),
    [], //calculation
  );

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationRow,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const { values, handleSubmit } = formik;

  /************************************ Calculate Row  ************************************/
  const outMaterials =
    parseFloat(
      values.basicMaterials.toString().replace(',', '.').replace(' ', ''),
    ) +
    parseFloat(
      values.auxiliaryMaterials.toString().replace(',', '.').replace(' ', ''),
    );
  const outTotal =
    parseFloat(values.equipment.toString().replace(',', '.').replace(' ', '')) +
    parseFloat(
      values.estimatedProfit.toString().replace(',', '.').replace(' ', ''),
    ) +
    parseFloat(
      values.machineOperatorSalary
        .toString()
        .replace(',', '.')
        .replace(' ', ''),
    ) +
    parseFloat(
      values.mainSalary.toString().replace(',', '.').replace(' ', ''),
    ) +
    outMaterials +
    parseFloat(
      values.mimExploitation.toString().replace(',', '.').replace(' ', ''),
    ) +
    parseFloat(values.overheads.toString().replace(',', '.').replace(' ', ''));

  const onSubmit = useCallback(
    (values: RowAddFormData) => {
      addComplexRow({
        id: calculationId,
        auxiliaryMaterials: parseFloat(
          values.auxiliaryMaterials
            .toString()
            .replace(',', '.')
            .replace(' ', ''),
        ),
        basicMaterials: parseFloat(
          values.basicMaterials.toString().replace(',', '.').replace(' ', ''),
        ),
        equipment: parseFloat(
          values.equipment.toString().replace(',', '.').replace(' ', ''),
        ),
        estimatedProfit: parseFloat(
          values.estimatedProfit.toString().replace(',', '.').replace(' ', ''),
        ),
        machineOperatorSalary: parseFloat(
          values.machineOperatorSalary
            .toString()
            .replace(',', '.')
            .replace(' ', ''),
        ),
        mainSalary: parseFloat(
          values.mainSalary.toString().replace(',', '.').replace(' ', ''),
        ),
        materials: outMaterials,
        mimExploitation: parseFloat(
          values.mimExploitation.toString().replace(',', '.').replace(' ', ''),
        ),
        overheads: parseFloat(
          values.overheads.toString().replace(',', '.').replace(' ', ''),
        ),
        parent: parentId, // id parent row
        title: values.title,
        total: outTotal,
        type: 'row',
      });
    },
    [outTotal],
  );

  useMutationHandlers(addComplexRowResponse, () => {
    handleShowFormAddRow(false);
    if (isAddSave === 'SaveAdd') {
      handleShowFormAddRow(true);
      setIsAddSave('');
    }
  });

  /************************************ Row Menu ************************************/

  const anchorEl = useRef<null | HTMLElement>(null);

  const handleClose = () => {
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  const handleSaveAddRow = () => {
    setIsAddSave('SaveAdd');
    anchorEl.current = null;
    setMenuRowOpened(false);
    handleSubmit();
  };

  const handleSaveRow = () => {
    handleSubmit();
  };

  const handleClearForm = () => {
    formik.resetForm({
      values: {
        title: '',
        auxiliaryMaterials: 0,
        basicMaterials: 0,
        equipment: 0,
        estimatedProfit: 0,
        machineOperatorSalary: 0,
        mainSalary: 0,
        materials: 0,
        mimExploitation: 0,
        overheads: 0,
        total: 0,
      },
    });
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  const handleDeleteFormAddRow = () => {
    handleShowFormAddRow(false);
  };
  const [isMenuRowOpened, setMenuRowOpened] = useState(false);

  const handleOpenMenuRowClick = (event: React.MouseEvent<HTMLElement>) => {
    anchorEl.current = event.currentTarget;
    if (event.currentTarget) setMenuRowOpened(true);
  };

  /************************************** onKeyDown Enter **********************************/

  const enterPressHandler = (e: { key: string }) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  /******************************** Focus Out *************************************/

  const formRef = useRef<HTMLFormElement>(null);

  const handleFocusOut: FocusEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      // @ts-ignore
      if (event.relatedTarget && formRef.current?.contains(event.relatedTarget))
        return;

      if (!isMenuRowOpened) {
        return handleSubmit();
      }
    },
    [isMenuRowOpened],
  );
  /*************************************************************************/

  return (
    <>
      <StyledTableRow colorBgRow={'shades'}>
        <StyledBodyTdRow style={{ width: '100px' }} colorBgRow={'shades'}>
          <Box
            sx={{
              minWidth: '40px',
              display: 'flex',
              justifyContent: 'center',
              marginRight: '10px',
            }}></Box>
          <IconButton disabled sx={{ width: 32, height: 32 }} size="small">
            <RowIcon style={{ width: 16, height: 16 }} />
          </IconButton>
        </StyledBodyTdRow>
        <FormikProvider value={formik}>
          <Form
            ref={formRef}
            onBlur={handleFocusOut}
            style={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns:
                'minmax(320px,1fr) repeat(10,minmax(140px, 190px)) 30px',
              gridAutoRows: '50px',
            }}
            onKeyDown={enterPressHandler}>
            <StyledBodyTdRow
              colorBgRow={'shades'}
              style={{ justifyContent: 'flex-start' }}>
              <StyledFieldForm
                type="text"
                autoFocus
                version="calculation"
                name="title"
                placeholder={
                  formik.isValid
                    ? 'Укажите название работ'
                    : 'Поле обязательно к заполнению'
                }></StyledFieldForm>
            </StyledBodyTdRow>
            <StyledBodyTdRow colorBgRow={'shades'}>
              <StyledTypographyRow style={{ textAlign: 'center' }}>
                {outTotal.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypographyRow>
            </StyledBodyTdRow>
            <StyledBodyTdRow colorBgRow={'shades'}>
              <StyledFieldFormMin
                version="calculation"
                value={values.mainSalary
                  .toLocaleString('ru-RU')
                  .replace(/\s/g, '')}
                name="mainSalary"
                placeholder={
                  formik.isValid ? '' : 'Поле обязательно'
                }></StyledFieldFormMin>
            </StyledBodyTdRow>
            <StyledBodyTdRow
              colorBgRow={'shades'}
              style={{ boxShadow: 'inset 1px -1px 0px rgb(0 36 95 / 10%)' }}>
              <StyledFieldFormMin
                value={values.mimExploitation
                  .toLocaleString('ru-RU')
                  .replace(/\s/g, '')}
                name="mimExploitation"
                placeholder={
                  formik.isValid ? '' : 'Поле обязательно'
                }></StyledFieldFormMin>
            </StyledBodyTdRow>
            <StyledBodyTdRow colorBgRow={'shades'}>
              <StyledFieldFormMin
                value={values.machineOperatorSalary
                  .toLocaleString('ru-RU')
                  .replace(/\s/g, '')}
                name="machineOperatorSalary"
                placeholder={
                  formik.isValid ? '' : 'Поле обязательно'
                }></StyledFieldFormMin>
            </StyledBodyTdRow>
            <StyledBodyTdRow
              colorBgRow={'shades'}
              style={{ boxShadow: 'inset 1px -1px 0px rgb(0 36 95 / 10%)' }}>
              <StyledTypographyRow style={{ textAlign: 'center' }}>
                {outMaterials.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </StyledTypographyRow>
            </StyledBodyTdRow>
            <StyledBodyTdRow colorBgRow={'shades'}>
              <StyledFieldFormMin
                value={values.basicMaterials
                  .toLocaleString('ru-RU')
                  .replace(/\s/g, '')}
                name="basicMaterials"
                placeholder={
                  formik.isValid ? '' : 'Поле обязательно'
                }></StyledFieldFormMin>
            </StyledBodyTdRow>
            <StyledBodyTdRow
              colorBgRow={'shades'}
              style={{ boxShadow: 'inset -1px -1px 0px rgb(0 36 95 / 10%)' }}>
              <StyledFieldFormMin
                value={values.auxiliaryMaterials
                  .toLocaleString('ru-RU')
                  .replace(/\s/g, '')}
                version="calculation"
                name="auxiliaryMaterials"
                placeholder={
                  formik.isValid ? '' : 'Поле обязательно'
                }></StyledFieldFormMin>
            </StyledBodyTdRow>
            <StyledBodyTdRow colorBgRow={'shades'}>
              <StyledFieldFormMin
                value={values.equipment
                  .toLocaleString('ru-RU')
                  .replace(/\s/g, '')}
                name="equipment"
                placeholder={
                  formik.isValid ? '' : 'Поле обязательно'
                }></StyledFieldFormMin>
            </StyledBodyTdRow>
            <StyledBodyTdRow colorBgRow={'shades'}>
              <StyledFieldFormMin
                value={values.overheads
                  .toLocaleString('ru-RU')
                  .replace(/\s/g, '')}
                name="overheads"
                placeholder={
                  formik.isValid ? '' : 'Поле обязательно'
                }></StyledFieldFormMin>
            </StyledBodyTdRow>
            <StyledBodyTdRow colorBgRow={'shades'}>
              <StyledFieldFormMin
                value={values.estimatedProfit
                  .toLocaleString('ru-RU')
                  .replace(/\s/g, '')}
                version="calculation"
                name="estimatedProfit"
                placeholder={
                  formik.isValid ? '' : 'Поле обязательно'
                }></StyledFieldFormMin>
            </StyledBodyTdRow>
            <StyledBodyTdRow
              colorBgRow={'shades'}
              style={{ padding: 0, width: 30 }}>
              <IconButton
                onClick={handleOpenMenuRowClick}
                sx={{ width: 30, height: 30 }}
                size="small">
                <MoreVertIcon />
              </IconButton>
            </StyledBodyTdRow>
          </Form>
        </FormikProvider>
      </StyledTableRow>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl.current}
        open={isMenuRowOpened}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={handleSaveRow}>Сохранить </MenuItem>
        <MenuItem onClick={handleSaveAddRow}>Сохранить и доб. статью </MenuItem>
        <MenuItem onClick={handleClearForm}>Очистить </MenuItem>
        <MenuItem onClick={handleDeleteFormAddRow}>Удалить </MenuItem>
      </Menu>
    </>
  );
};

export default ComplexFormAddRow;
