import styled from 'styled-components';
import { PhoneFieldForm } from 'components/PhoneFieldForm';

export const StyledPhoneFieldForm = styled(PhoneFieldForm)`
  & .MuiFilledInput-root {
    border-radius: 8px;
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  & .MuiFilledInput-input {
    padding: 10px 16px;
    color: ${(props) => props.theme.palette.secondary.gray};
    text-overflow: ellipsis;
    border-radius: 8px;
  }
`;
