import {
  useClearEmptyLevelsMutation,
  useTransferElementToTheGroupMutation,
} from 'api/calculations';
import { useCallback } from 'react';
import { TransferElementPayload } from './hooks.types';

export const useTransferElement = (isHandbook: boolean) => {
  const [transferElementMutation] = useTransferElementToTheGroupMutation();
  const [clearEmptyLevelsMutation] = useClearEmptyLevelsMutation();

  const transferElement = useCallback(
    ({ calcID, to, childrenIds, groupIds }: TransferElementPayload) => {
      transferElementMutation({
        params: { calcID },
        body: {
          childrenID: childrenIds,
          groupID: groupIds,
          to,
        },
        isHandbook,
      }).then(() => {
        // clearEmptyLevelsMutation({ calcID });
      });
    },
    [],
  );

  return { transferElement };
};
