import { Box } from '@mui/material';
import { FC } from 'react';
import { GroupedRow } from '../Row';
import { GroupedSubRowsRowsEntries } from '../Row/Row.types';
import { IntervalRowProps } from './IntervalRow.types';

export const IntervalRow: FC<IntervalRowProps> = ({
  groupedRowByTag,
  tableColumns,
}): JSX.Element => {
  return (
    <Box>
      {(Object.entries(groupedRowByTag) as GroupedSubRowsRowsEntries)
        .sort()
        .reverse()
        .map(([key, subRows]) => (
          <GroupedRow
            key={key}
            rows={subRows}
            type={key}
            tableColumns={tableColumns}
          />
        ))}
    </Box>
  );
};
