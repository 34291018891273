import { FolderOpen } from '@mui/icons-material';
import { darken, outlinedInputClasses, Select } from '@mui/material';
import styled from 'styled-components';

export const StyledStatusSelect = styled(Select)(({ theme }) => ({
  [`& .${outlinedInputClasses.input}`]: {
    backgroundColor: theme.palette.bg.shades,
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
  },

  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: darken(theme.palette.bg.shades, 0.1),
  },
})) as typeof Select;

export const StyledFolderIcon = styled(FolderOpen)`
  color: ${({ theme }) => theme.palette.primary.light};
`;
