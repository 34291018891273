import { UploadType } from 'pages/Lsr/components/WSCustomSnackbar/WSCustomSnackbar.types';

export class WSContextState {
  socketLsr: SocketData = new SocketData();
  socketCalc: SocketData = new SocketData();
  socketImportHandbook: SocketData = new SocketData();
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface WSContextValue extends WSContextState {}

class SocketData {
  isDrawerOpen?: boolean;
  projectIDInit?: number;
  isUploaded: UploadType = 'start';
}
