import { Chip } from '@mui/material';
import styled from 'styled-components';

export const StyledTypeChip = styled(Chip)(({ theme }) => ({
  color: 'white',
  lineHeight: '24px',
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.spacing(0.5),
  textTransform: 'uppercase',
  cursor: 'unset',
}));
