/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { CalcID } from '../../types';
import { api } from '../api';
import {
  ICalculationDictionary,
  ICalculationDictionaryResponse,
  ICalculationDictionaryResponseMutate,
  ICreateCalcRowRequest,
  ICreateVorFromPositionRequest,
  ICreateVorFromPositionResponse,
  IDeleteRateWithRatePositions,
  IRequestFilterCalculationDictionary,
  IResponseFilterCalculation,
  IUpdateCalcRowRequest,
  IUploadResponse,
  IVorEditRequest,
  IVorPositionsResponse,
  TCaclID
} from './calculationDictionaries.types';

export const calculationDictionaryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCacl: build.query<
      ICalculationDictionaryResponseMutate,
      { calcID: TCaclID; params?: { [key: string]: any } }
    >({
      query: ({ calcID, params }) => ({
        // url: `/calculation/${calcID}/handbook/get`,
        url: `/calculation/${calcID}/handbook/v2/get`,
        method: 'GET',
        params
      }),

      transformResponse(
        response: ICalculationDictionaryResponse
      ): ICalculationDictionaryResponseMutate {
        const result: ICalculationDictionary[] = [];

        function getFolderID(id: number) {
          const folder = response.rows.find((item) => item.id === id);
          if (folder) {
            if (folder?.rowType !== 'FOLDER') {
              getFolderID(folder.parentID!);
            }
            return folder;
          }
        }

        function flatten(children: ICalculationDictionary[]) {
          for (const child of children) {
            if (child.rowType !== 'FOLDER') {
              const folder = getFolderID(child.parentID!);
              if (folder) {
                child.lvl = folder.lvl;
              }
            }
            result.push({ ...child });
            if (child.children && child.children.length > 0) {
              flatten(child.children);
            }
          }
        }

        const total: ICalculationDictionary = {
          base: {
            costPerUnit: null,
            costTotal: response.total.base.cost || null,
            fotPerUnit: null,
            fotTotal: response.total.base.fot || null,
            kzPerUnit: null,
            kzTotal: response.total.base.kz || null,
            mimPerUnit: null,
            mimTotal: response.total.base.mim || null,
            mtrPerUnit: null,
            mtrTotal: response.total.base.mtr || null,
            total: response.total.base.total || null,
            totalPerUnit: null
          },
          curr: {
            costPerUnit: null,
            costTotal: response.total.curr.cost || null,
            fotPerUnit: null,
            fotTotal: response.total.curr.fot || null,
            kzPerUnit: null,
            kzTotal: response.total.curr.kz || null,
            mimPerUnit: null,
            mimTotal: response.total.curr.mim || null,
            mtrPerUnit: null,
            mtrTotal: response.total.curr.mtr || null,
            total: response.total.curr.total || null,
            totalPerUnit: null
          },
          basis: null,
          note: null,
          calcID: 0,
          children: [],
          code: null,
          id: -2500,
          kfoCoef: null,
          kfoGroup: null,
          kfoUnit: null,
          lvl: null,
          num: null,
          order: 1,
          parentID: null,
          qtyPerUnit: null,
          qtyTotal: null,
          qtyUnlinked: true,
          resourceType: null,
          rowType: 'FOLDER',
          title: null,
          tzmPerUnit: null,
          tzmTotal: response.total.tzm,
          tzPerUnit: null,
          tzTotal: response.total.tz,
          unit: null,
          workTimePerUnit: null,
          workTimeTotal: response.total.workTime
        };
        response.rows
          .filter((_) => _.lvl === 1 && _.rowType === 'FOLDER')
          .forEach((item) => {
            result.push(item);
            flatten(item.children || []);
          });

        return { rows: result, total };
      },
      providesTags: ['CalculationDictionary']
    }),
    createRowHandbk: build.mutation<
      ICalculationDictionary,
      { body: ICreateCalcRowRequest; calcID: number }
    >({
      query: ({ body, calcID }) => {
        console.log(calcID, body);
        return {
          url: `/calculation/${calcID}/handbook/row/add`,
          method: 'POST',
          body
        };
      },
      // invalidatesTags: ['Users'],
      invalidatesTags: [
        'CalculationDictionary',
        'CalculationCoefficients',
        'HandbookVor',
        'ExecutionList',
        'HandbookExecutionList'
      ]
    }),
    updateRowHandbk: build.mutation<
      ICalculationDictionary,
      { body: IUpdateCalcRowRequest; calcID: TCaclID; rowID: number }
    >({
      query: ({ body, calcID, rowID }) => {
        console.log(calcID, body);
        return {
          url: `/calculation/${calcID}/handbook/row/${rowID}/update`,
          method: 'PATCH',
          body
        };
      },
      // invalidatesTags: ['Users'],
      invalidatesTags: [
        'CalculationCoefficients',
        'CalculationDictionary',
        'HandbookVor',
        'HandbookExecutionList',
        'ExecutionList'
      ]
    }),
    toggleRowHandbkUnlinkQuantity: build.mutation<
      { unlinked: boolean },
      { calcID: TCaclID; rowID: number }
    >({
      query: ({ calcID, rowID }) => {
        return {
          url: `/calculation/${calcID}/handbook/row/${rowID}/unlink-quantity`,
          method: 'POST'
        };
      },
      // invalidatesTags: ['Users'],
      invalidatesTags: [
        'CalculationCoefficients',
        'CalculationDictionary',
        'HandbookVor'
      ]
    }),
    deleteRatesWithPositions: build.mutation<any, IDeleteRateWithRatePositions>(
      {
        query: ({ calcID, folderID, ...body }) => {
          console.log(calcID);
          return {
            url: `/calculation/${calcID}/handbook/row/${folderID}/rate/resources/delete`,
            method: 'PATCH',
            body
          };
        },
        invalidatesTags: [
          'CalculationDictionary',
          'CalculationCoefficients',
          'HandbookVor',
          'ExecutionList',
          'HandbookExecutionList'
        ]
        // invalidatesTags: ['Users'],
      }
    ),
    deleteFolderCalculation: build.mutation<
      undefined,
      { calcID: TCaclID; folderID: number; force?: boolean }
    >({
      query: ({ calcID, folderID, force }) => {
        console.log(calcID, folderID, force);
        return {
          // url: `/calculation/${calcID}/handbook/folder/${folderID}/delete`,
          url: `/calculation/${calcID}/handbook/row/${folderID}/folder/delete`,
          method: 'DELETE',
          params: {
            force
          }
        };
      },
      invalidatesTags: (result, error) => {
        if ((result as any)?.error || error) {
          return [];
        }
        return [
          'CalculationDictionary',
          'CalculationCoefficients',
          'HandbookVor',
          'HandbookExecutionList',
          'ExecutionList'
        ];
      }
    }),
    deleteRateCalculation: build.mutation<
      undefined,
      { calcID: TCaclID; rateID: number; force?: boolean }
    >({
      query: ({ calcID, rateID, force }) => {
        console.log(calcID, rateID, force);
        return {
          url: `/calculation/${calcID}/handbook/row/${rateID}/rate/delete`,
          method: 'DELETE',
          params: {
            force
          }
        };
      },
      invalidatesTags: (result, error) => {
        if ((result as any)?.error || error) {
          return [];
        }
        return [
          'CalculationDictionary',
          'CalculationCoefficients',
          'HandbookVor',
          'HandbookExecutionList',
          'ExecutionList'
        ];
      }
    }),
    insertRate: build.mutation<
      ICalculationDictionary,
      {
        calcID: TCaclID;
        folderID: number;
        insertAfterID: number | null;
        handbkRateID: number;
      }
    >({
      query: ({ calcID, folderID, handbkRateID, insertAfterID }) => {
        console.log(calcID, folderID);
        return {
          // url: `/calculation/${calcID}/handbook/folder/${folderID}/handbook/insert`,
          url: `/calculation/${calcID}/handbook/row/${folderID}/folder/from-handbook/add`,
          method: 'POST',
          params: {
            handbkRateID,
            insertAfterID
          }
        };
      },
      invalidatesTags: [
        'CalculationDictionary',
        'CalculationCoefficients',
        'PriceReferenceKFOGroup',
        'HandbookExecutionList',
        'ExecutionList'
      ]
    }),
    applyFilterData: build.mutation<
      ICalculationDictionary[],
      IRequestFilterCalculationDictionary
    >({
      query: ({ calcID, body }) => {
        return {
          url: `/calculation/${calcID}/handbook/filters/get-filtered-rows`,
          method: 'POST',
          body
        };
      }

      // invalidatesTags: ['CalculationDictionary'],
    }),
    getFilterData: build.query<
      IResponseFilterCalculation,
      IRequestFilterCalculationDictionary
    >({
      query: ({ calcID, body }) => {
        return {
          url: `/calculation/${calcID}/handbook/filters/get-narrowed-filter-data`,
          method: 'POST',
          body
        };
      },
      keepUnusedDataFor: 0
      // invalidatesTags: ['CalculationDictionary'],
    }),
    exportCalculation: build.query<string, TCaclID>({
      query: (calcID) => {
        return {
          url: `/calculation/${calcID}/handbook/export`,
          method: 'GET',
          responseHandler: 'text'
        };
      }
      // invalidatesTags: ['CalculationDictionary'],
    }),
    sortTable: build.mutation<void, TCaclID>({
      query: (calcID) => {
        return {
          url: `/calculation/${calcID}/handbook/resources/resort`,
          method: 'POST'
        };
      },
      invalidatesTags: ['CalculationDictionary']
    }),

    // укрупненные
    getHandbookVorPositions: build.query<IVorPositionsResponse, CalcID>({
      query: ({ calcID }) => ({
        url: `/calculation/${calcID}/handbook/vor/v2/get`,
        method: 'GET'
      }),
      providesTags: ['HandbookVor']
      // invalidatesTags: ['CalculationCoefficients', 'CalculationDictionary'],
    }),
    editHandbookVorPositions: build.mutation<
      IVorPositionsResponse,
      IVorEditRequest
    >({
      query: ({ calcID, vorID, body }) => ({
        url: `/calculation/${calcID}/handbook/vor/${vorID}/edit`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['HandbookVor']
      // invalidatesTags: ['CalculationCoefficients', 'CalculationDictionary'],
    }),
    createHandbookVorPositions: build.mutation<
      IVorPositionsResponse,
      IVorEditRequest
    >({
      query: ({ calcID, body }) => ({
        url: `/calculation/${calcID}/handbook/vor/create`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['HandbookVor']
      // invalidatesTags: ['CalculationCoefficients', 'CalculationDictionary'],
    }),
    createHandbookVorFromPosition: build.mutation<
      ICreateVorFromPositionResponse,
      ICreateVorFromPositionRequest
    >({
      query: ({ calcID, body }) => ({
        url: `/calculation/${calcID}/handbook/vor/batch-create`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['HandbookVor']
    }),

    /* экспорт расчета */
    downloadHandbvookCalc: build.query<string, number>({
      query: (calcID) => ({
        url: `/calculation/${calcID}/handbook/export`,
        method: 'GET',
        responseHandler: 'text'
      })
    }),

    /* импорт расчета */

    uploadHandbvookCalc: build.mutation<
      IUploadResponse,
      CalcID & { body: { file: FormData } }
    >({
      query: ({ calcID, body }) => ({
        url: `/calculation/${calcID}/handbook/import`,
        method: 'POST',
        body
      }),
      invalidatesTags: [
        'HandbookVor',
        'CalculationDictionary',
        'CalculationCoefficients'
      ]
    }),
    getTemplateCalc: build.query<string, void>({
      query: () => ({
        url: '/calculation/handbook/example',
        method: 'GET',
        responseHandler: 'text'
      })
    })
  }),
  overrideExisting: false
});

export const {
  useGetCaclQuery,
  useDeleteRatesWithPositionsMutation,
  useDeleteFolderCalculationMutation,
  useDeleteRateCalculationMutation,
  useCreateRowHandbkMutation,
  useUpdateRowHandbkMutation,
  useToggleRowHandbkUnlinkQuantityMutation,
  useInsertRateMutation,

  useLazyGetCaclQuery,

  useApplyFilterDataMutation,
  useGetFilterDataQuery,
  useLazyGetFilterDataQuery,

  useSortTableMutation,
  useLazyExportCalculationQuery,

  // укрупненные
  useGetHandbookVorPositionsQuery,
  useEditHandbookVorPositionsMutation,
  useCreateHandbookVorPositionsMutation,
  useCreateHandbookVorFromPositionMutation,

  // экспорт расчета
  useLazyDownloadHandbvookCalcQuery,
  useUploadHandbvookCalcMutation,
  useLazyGetTemplateCalcQuery
} = calculationDictionaryApi;
