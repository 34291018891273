/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 * @author CyrilStrone<77802640+CyrilStrone@users.noreply.github.com>
 */

import {
  ICoefficientData,
  OrNull
} from 'api/references/estimates/estimates.types';
import { useFormikContext } from 'formik';
import useConfirmDialog from 'hooks/useConfirmDialog';
import {
  HandbookContext,
  TTabType
} from 'pages/Calculations/components/CalculationDirectory/handbook.services';
import { useCallback, useContext, useMemo, useState } from 'react';
import { ActList, CalcData } from 'types';
import {
  ActsTab,
  CoefficientsTable,
  CoeffParameters,
  getDifferentActList,
  getDifferentCoefficient,
  Header,
  IParametersForm,
  IParametersFormCoefficient,
  IParametersUpdateDate,
  IParametersUpdateValue,
  Menu,
  TTab,
  WrapperParameters
} from '../';

export const Form = ({
  closeForm,
  selectedTab,
  isActs,
  calculation,
  values,
  currentAct,
  calcID
}: {
  values: IParametersForm;
  closeForm: () => void;
  isActs: boolean;
  calculation?: CalcData;
  selectedTab: TTabType | null;
  calcID: number;
  currentAct: OrNull<ActList>;
  open: boolean;
}) => {
  const { dirty, setValues } = useFormikContext<IParametersForm>();

  const [tab, setTab] = useState<TTab>(isActs ? 'acts' : 'indirect_costs');
  const changeTab = useCallback((str: TTab) => setTab(str), []);
  const { changeAct, actList: actListOriginal } = useContext(HandbookContext);

  const actList = values.actListFromLabel.flatMap((e) => e.actList);
  const removedActList = actList.filter((e) => e.isRemoved);
  const actualActList = actList.filter((e) => !e.isRemoved);
  const currentActItem = actList.find((e) => e.id === currentAct?.id);
  const currentActListIndex = values.actListFromLabel.findIndex(
    (e) => e.year === currentActItem?.year
  );
  const currentActListItemIndex = values.actListFromLabel
    .find((e) => e.year === currentActItem?.year)
    ?.actList.findIndex((e) => e.id === currentActItem?.id);

  const disabled = useMemo(() => {
    if (selectedTab !== 'executed') return false;
    if (!actList?.length) return true;
    return actList.length === removedActList.length;
  }, [actList.length, removedActList.length, selectedTab]);

  const updateIsSynchronized = useCallback(
    (id: number, newSynchronized: boolean) => {
      if (isActs) {
        const updatedActListFromLabel = values.actListFromLabel.map(
          (labelItem) => {
            const updatedActList = labelItem.actList.map((act) => {
              if (act.id === id) {
                return { ...act, isSynchronized: newSynchronized };
              }
              return act;
            });

            return { ...labelItem, actList: updatedActList };
          }
        );

        setValues({ ...values, actListFromLabel: updatedActListFromLabel });
      }
    },
    [isActs, setValues, values]
  );

  const updateIsRemoved = useCallback(
    (id: number, newRemoved: boolean) => {
      const updatedActListFromLabel = values.actListFromLabel.map(
        (labelItem) => {
          const updatedActList = labelItem.actList.map((act) => {
            if (act.id === id) {
              return { ...act, isRemoved: newRemoved };
            }
            return act;
          });
          return { ...labelItem, actList: updatedActList };
        }
      );
      if (id === currentAct?.id) {
        const newActList = updatedActListFromLabel.flatMap((e) => e.actList);
        const candidate = newActList.find((a) => a.id && !a.isRemoved);
        changeAct?.(candidate ?? null);
      }
      setValues({ ...values, actListFromLabel: updatedActListFromLabel });
    },
    [changeAct, currentAct?.id, setValues, values]
  );

  const updateDate = useCallback(
    (props: IParametersUpdateDate) => {
      const updatedActListFromLabel = values.actListFromLabel.map(
        (labelItem) => {
          const updatedActList = labelItem.actList.map((act) => {
            if (act.id === props.id) {
              return {
                ...act,
                startDate:
                  props.startDate !== undefined
                    ? props.startDate
                    : act.startDate,
                endDate:
                  props.endDate !== undefined ? props.endDate : act.endDate
              };
            }
            return act;
          });
          return { ...labelItem, actList: updatedActList };
        }
      );
      if (props.id === currentAct?.id) {
        const newActList = updatedActListFromLabel.flatMap((e) => e.actList);
        const candidate = newActList.find((a) => a.id && !a.isRemoved);
        changeAct?.(candidate ?? null);
      }
      setValues({ ...values, actListFromLabel: updatedActListFromLabel });
    },
    [changeAct, currentAct?.id, setValues, values]
  );

  const modifyKey = useMemo(() => {
    return tab === 'value_changes' ? 'index' : 'kz';
  }, [tab]);

  const itemKey = isActs
    ? `actListFromLabel.${currentActListIndex}.actList.${currentActListItemIndex}.coefficient.${modifyKey}`
    : `coefficient.${modifyKey}`;

  const updateValue = useCallback(
    <T extends keyof ICoefficientData>(props: IParametersUpdateValue<T>) => {
      const keys1 = ['fot', 'mim', 'mtr'];
      const keys2 = ['pz'];
      const isKeyInKeys1 = keys1.includes(props.key as string);
      const isKeyInKeys2 = keys2.includes(props.key as string);

      const updateChildren = (
        children: ICoefficientData[],
        newValue: IParametersUpdateValue<T>['value']
      ): ICoefficientData[] => {
        return children.map((child) => ({
          ...child,
          [props.key]: newValue,
          ...(isKeyInKeys1 &&
            keys2.reduce((acc, key) => ({ ...acc, [key]: null }), {})),
          ...(isKeyInKeys2 &&
            keys1.reduce((acc, key) => ({ ...acc, [key]: null }), {})),
          children: updateChildren(child.children!, newValue)
        }));
      };

      const updateRowById = (
        coefficients: ICoefficientData[],
        rowID: number,
        newValue: IParametersUpdateValue<T>['value']
      ): ICoefficientData[] => {
        return coefficients.map((coefficient) => {
          if (coefficient.rowID === rowID) {
            return {
              ...coefficient,
              [props.key]: newValue,
              ...(isKeyInKeys1 &&
                keys2.reduce((acc, key) => ({ ...acc, [key]: null }), {})),
              ...(isKeyInKeys2 &&
                keys1.reduce((acc, key) => ({ ...acc, [key]: null }), {})),
              children: updateChildren(coefficient.children!, newValue)
            };
          }

          if (coefficient.children!.length > 0) {
            return {
              ...coefficient,
              children: updateRowById(coefficient.children!, rowID, newValue)
            };
          }

          return coefficient;
        });
      };

      const updatedActListFromLabel = isActs
        ? values.actListFromLabel.map((labelItem) => {
            const updatedActList = labelItem.actList.map((act) => {
              if (act.id === props.actID) {
                return {
                  ...act,
                  isSynchronized: false,
                  coefficient: {
                    ...act.coefficient,
                    [modifyKey]: {
                      ...act.coefficient?.[modifyKey],
                      ...(props.isGlobal
                        ? {
                            global: {
                              ...act.coefficient?.[modifyKey]?.global,
                              [props.key]: props.value,
                              ...(isKeyInKeys1 &&
                                keys2.reduce(
                                  (acc, key) => ({ ...acc, [key]: null }),
                                  {}
                                )),
                              ...(isKeyInKeys2 &&
                                keys1.reduce(
                                  (acc, key) => ({ ...acc, [key]: null }),
                                  {}
                                ))
                            },
                            coefficients: updateChildren(
                              act.coefficient?.[modifyKey]?.coefficients || [],
                              props.value
                            )
                          }
                        : props.rowID
                          ? {
                              coefficients: updateRowById(
                                act.coefficient?.[modifyKey]?.coefficients ||
                                  [],
                                props.rowID,
                                props.value
                              )
                            }
                          : {})
                    }
                  }
                };
              }
              return act;
            });
            return { ...labelItem, actList: updatedActList };
          })
        : null;

      const updatedCoefficient: IParametersFormCoefficient | null = !isActs
        ? {
            ...values.coefficient,
            [modifyKey]: {
              ...values.coefficient?.[modifyKey],
              ...(props.isGlobal
                ? {
                    global: {
                      ...values.coefficient?.[modifyKey]?.global,
                      [props.key]: props.value,
                      ...(isKeyInKeys1 &&
                        keys2.reduce(
                          (acc, key) => ({ ...acc, [key]: null }),
                          {}
                        )),
                      ...(isKeyInKeys2 &&
                        keys1.reduce(
                          (acc, key) => ({ ...acc, [key]: null }),
                          {}
                        ))
                    },
                    coefficients: updateChildren(
                      values.coefficient?.[modifyKey]?.coefficients || [],
                      props.value
                    )
                  }
                : props.rowID
                  ? {
                      coefficients: updateRowById(
                        values.coefficient?.[modifyKey]?.coefficients || [],
                        props.rowID,
                        props.value
                      )
                    }
                  : {})
            }
          }
        : null;

      if (isActs && updatedActListFromLabel) {
        const differentActList = getDifferentActList(updatedActListFromLabel);
        setValues({ ...values, actListFromLabel: differentActList });
      } else if (!isActs && updatedCoefficient) {
        const differentCoefficient = updatedCoefficient
          ? getDifferentCoefficient(updatedCoefficient)
          : null;
        setValues({
          ...values,
          coefficient: {
            index: differentCoefficient?.index,
            kz: differentCoefficient?.kz
          }
        });
      }
    },
    [isActs, modifyKey, setValues, values]
  );

  const { openConfirm, ConfirmDialog } = useConfirmDialog({
    handleConfirm: (confirm) => {
      if (confirm) {
        if (currentAct?.id && actListOriginal) {
          const find = actListOriginal.actList.find(
            (e) => e.id === currentAct.id
          );
          if (find) changeAct(find);
        }
        closeForm();
      }
    }
  });

  const handleCloseForm = useCallback(() => {
    if (dirty) {
      openConfirm();
    } else {
      closeForm();
    }
  }, [closeForm, dirty, openConfirm]);

  const coefficient = isActs
    ? currentActItem?.coefficient
      ? currentActItem?.coefficient
      : undefined
    : values.coefficient
      ? values.coefficient
      : undefined;

  const isGlobalCoefficient = !!coefficient?.[modifyKey]?.global;

  return (
    <WrapperParameters>
      <Header
        updateIsSynchronized={updateIsSynchronized}
        actList={actList}
        removedActList={removedActList}
        disabled={disabled}
        isActs={isActs}
        selectedTab={selectedTab}
        close={handleCloseForm}
        currentActItem={currentActItem}
        isCoefficient={isGlobalCoefficient}
      />
      <Menu
        disabled={disabled}
        currentAct={currentAct}
        isActs={isActs}
        selectedTab={selectedTab}
        tab={tab}
        setTab={changeTab}
        isCoefficient={isGlobalCoefficient}
      />
      <CoeffParameters>
        {tab === 'acts' ? (
          <ActsTab
            updateDate={updateDate}
            updateIsRemoved={updateIsRemoved}
            actualActList={actualActList}
            isActs={isActs}
            calculation={calculation}
            values={values}
          />
        ) : (
          <CoefficientsTable
            closeForm={closeForm}
            itemKey={itemKey}
            tab={tab}
            coefficient={coefficient}
            actId={isActs ? currentActItem?.id : undefined}
            modifyKey={modifyKey}
            updateValue={updateValue}
          />
        )}
      </CoeffParameters>
      <ConfirmDialog />
    </WrapperParameters>
  );
};
