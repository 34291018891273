import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

export const ChangeStatus: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
      <path d="M10.8334 15.8335C10.8334 16.1168 10.8667 16.3918 10.9084 16.6668H3.33335C2.89133 16.6668 2.4674 16.4912 2.15484 16.1787C1.84228 15.8661 1.66669 15.4422 1.66669 15.0002V5.00016C1.66669 4.07516 2.40835 3.3335 3.33335 3.3335H8.33335L10 5.00016H16.6667C17.1087 5.00016 17.5326 5.17576 17.8452 5.48832C18.1578 5.80088 18.3334 6.2248 18.3334 6.66683V11.5085C17.825 11.2168 17.2667 11.0168 16.6667 10.9085V6.66683H3.33335V15.0002H10.9084C10.8667 15.2752 10.8334 15.5502 10.8334 15.8335ZM18.3334 16.6668V13.3335H16.6667V16.6668H15.4167L17.5 19.1668L19.5834 16.6668H18.3334ZM14.1667 12.5002L12.0834 15.0002H13.3334V18.3335H15V15.0002H16.25L14.1667 12.5002Z" />
    </SvgIcon>
  );
};
