import { EventAvailable } from '@mui/icons-material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { Box, Stack, Typography } from '@mui/material';
import React, { memo } from 'react';
import { formatDateToString, getFormatDate } from 'utils/formatDate';
import { getPersonInitials } from 'utils/getPersonInitials';
import { FileTypeChip } from 'pages/Calculations/components/FileTypeChip';
import {
  StyledCard,
  StyledDivider,
  StyledExtendsName,
  StyledFullNameCalculation,
  StyledShortNameCalculation,
} from './CalculationCard.styles';
import { CalculationCardProps } from './CalculationCard.types';
import Tooltip from '../../../../components/Tooltip';
import { ReactComponent as CloudGearIcon } from 'assets/icons/integrateSuccess.svg';
import { EPragmaModules } from '../../../../types';
import { ReactComponent as CardTangleModelIcon } from 'assets/icons/cardTangleIcon.svg';
import { AuthorIcon, CoinIcon } from '../../../../assets/SVG/SVG';

export const CalculationCard: React.FC<CalculationCardProps> = memo(
  ({
    author,
    createdAt,
    description,
    tanglModel,
    title,
    type,
    updatedAt,
    cost,
    integrationInfo,
  }) => {
    return (
      <StyledCard>
        <Stack height="90px">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Tooltip title={title}>
              <StyledShortNameCalculation>{title}</StyledShortNameCalculation>
            </Tooltip>
            <FileTypeChip type={type} />
          </Stack>
          <Tooltip title={description}>
            <StyledFullNameCalculation>{description}</StyledFullNameCalculation>
          </Tooltip>
        </Stack>
        {integrationInfo ? (
          <>
            <StyledDivider isIntegrate={!!integrationInfo} />
            <StyledShortNameCalculation
              sx={{
                color: '#BA68C8 !important',
                fontSize: '14px !important',
              }}>
              {integrationInfo.projectName}
            </StyledShortNameCalculation>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}>
              <Box display={'flex'} gap={'8px'} alignItems={'center'}>
                <CloudGearIcon
                  width={16}
                  height={16}
                  fill={'#BA68C8 !important'}
                />
                <Typography
                  sx={{ color: '#BA68C8', fontSize: '10px' }}
                  variant="body2">
                  Синхронизован с {EPragmaModules[integrationInfo.module]}
                </Typography>
              </Box>
              <Typography
                sx={{ color: '#BA68C8', fontSize: '10px' }}
                variant="body2">
                {formatDateToString(
                  new Date(integrationInfo.syncedAt),
                  'dd.MM.yyyy',
                )}
              </Typography>
            </Box>
            <StyledDivider isIntegrate={!!integrationInfo} />
          </>
        ) : (
          <StyledDivider isIntegrate={!!integrationInfo} />
        )}
        {/*<StyledDivider isIntegrate={false} />*/}
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Stack direction="row" spacing={1}>
              <CoinIcon />
              <Typography variant="body2">Стоимость:</Typography>
            </Stack>
            <Typography variant="subtitle2">
              {cost?.toLocaleString('ru-RU', {
                minimumFractionDigits: 2,
              })}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Stack direction="row" spacing={1}>
              <AuthorIcon />
              <Typography variant="body2">Автор:</Typography>
            </Stack>
            <Typography variant="subtitle2">
              {getPersonInitials({
                firstName: author.firstName ?? '',
                lastName: author.lastName ?? '',
                middleName: author.middleName,
              })}
            </Typography>
          </Stack>
          {tanglModel && (
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Stack direction="row" spacing={1}>
                <CardTangleModelIcon />
                <Typography variant="body2">Модель:</Typography>
              </Stack>
              <Tooltip title={tanglModel}>
                <StyledExtendsName variant="subtitle2">
                  {tanglModel}
                </StyledExtendsName>
              </Tooltip>
            </Stack>
          )}
          {/*<Stack direction="row" justifyContent="space-between" spacing={1}>*/}
          {/*  <Stack direction="row" spacing={1}>*/}
          {/*    <EventAvailable fontSize="small" color="secondary" />*/}
          {/*    <Typography variant="body2">Метод:</Typography>*/}
          {/*  </Stack>*/}
          {/*  <Typography variant="subtitle2">{calcDataTypeNames[type]}</Typography>*/}
          {/*</Stack>*/}
        </Stack>
        <Stack spacing={1} pt={1.5}>
          <Typography textAlign="left" variant="body2">
            Даты создания:
          </Typography>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Stack direction="row" spacing={1}>
              <EventNoteIcon fontSize="small" color="secondary" />
              <Typography variant="body2">Дата создания:</Typography>
            </Stack>
            <Typography variant="subtitle2">
              {getFormatDate({ date: createdAt })}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Stack direction="row" spacing={1}>
              <EventAvailable fontSize="small" color="secondary" />
              <Typography variant="body2" component="span">
                Дата обновления:
              </Typography>
            </Stack>
            <Typography variant="subtitle2">
              {getFormatDate({ date: updatedAt })}
            </Typography>
          </Stack>
        </Stack>
      </StyledCard>
    );
  },
);

CalculationCard.displayName = 'CalculationCard';
