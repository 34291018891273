import styled from 'styled-components';
import { Box } from '@mui/material';

interface StyledTreeNodeWrapperProps {
  $isExpanded: boolean;
}

export const StyledTreeNodeWrapper = styled(Box)<StyledTreeNodeWrapperProps>`
  display: ${({ $isExpanded }) => ($isExpanded ? 'block' : 'none')};
`;
