import { StyledHead } from './Head.styles';

const Head: React.FC = () => {
  return (
    <StyledHead>
      <div>Наименование сметы и раздела</div>
      <div>Наименование</div>
      <div>Коэф</div>
      <div>ОЗП</div>
      <div>ЭМ</div>
      <div>ЗПМ</div>
      <div>МАТ</div>
      <div>СМР</div>
    </StyledHead>
  );
};

export default Head;
