import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import {
  ColDef,
  ColGroupDef,
  GridReadyEvent,
  GridSizeChangedEvent,
  ICellRendererParams,
  RowClassParams
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useGetComplicatedRowsListQuery } from 'api/calculations';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useCalcId } from 'hooks/useCalcId';
import { useProjectId } from 'hooks/useProjectId';
import { useLocation } from 'react-router-dom';
import { CalcData } from 'types';
import { IComplicated } from '../../../../../../api/calculations/types';
import Progress from '../../../../../../components/Progress';
import { modifyNum } from '../../../../../../utils/getFormatNumber';
import {
  PageStyled,
  WrapperAgGrid
} from '../../../Accomplishment/Accomplishment.styles';
import Cell from '../../../Accomplishment/components/Cell';
import { modifyData } from '../../../Accomplishment/helper';
import { ActListContext } from '../../index';
import LevelExecution from './components/LevelExecution';

export type EstimateComplicatedProps = {
  calculation?: CalcData;
};

interface IAgGridTable {
  collapse: (id: number) => void;
  hiddenRowsIds: number[];
  total: IComplicated | undefined;
}

export const EstimateComplicated = () => {
  // const edit = useLocation().state?.edit ?? true;

  const { calculation } = useContext(ActListContext);

  const projectID = useProjectId();
  const calcID = useCalcId();
  const params = new URLSearchParams(useLocation().search);
  const isPreview = params.get('state') === 'preview';
  /***************************** Complicated List ****************************************/

  const { data, isFetching, isLoading } = useGetComplicatedRowsListQuery({
    id: calculation?.id ?? calcID,
    projectID,
    isPreview
  });

  const isDataLoading = isFetching || isLoading;

  // const complicatedList = data?.data ? data?.data : [];

  // const total = data?.total
  //   ? data?.total
  //   : ({
  //       base: {},
  //       curr: {},
  //     } as IComplicated);

  // const showCodeLSR = useMemo(() => {
  //   let isShowEstimateCode = false;
  //
  //   for (let i = 0; i < complicatedList.length; i++) {
  //     isShowEstimateCode = complicatedList[i].children.some((item) => true /* !!item.header */);
  //     if (isShowEstimateCode) {
  //       break;
  //     }
  //   }
  //   return isShowEstimateCode;
  // }, [complicatedList]);

  // const showHeader = useMemo(() => {
  //   let isShowHeader = false;
  //   for (let i = 0; i < complicatedList.length; i++) {
  //     isShowHeader = complicatedList[i].children.some((item) => !!item.header);
  //     if (isShowHeader) {
  //       break;
  //     }
  //   }
  //   return isShowHeader;
  // }, [complicatedList]);

  // const showBaseEquipmentPrice = useMemo(() => {
  //   let isShowEquipmentPrice = false;
  //   for (let i = 0; i < complicatedList.length; i++) {
  //     isShowEquipmentPrice = complicatedList[i].children.some((item) => !!item.base.equipmentPrice);
  //
  //     if (isShowEquipmentPrice) {
  //       break;
  //     }
  //   }
  //   return isShowEquipmentPrice;
  // }, [complicatedList]);

  // const showCurrEquipmentPrice = useMemo(() => {
  //   let isShowEquipmentPrice = false;
  //   for (let i = 0; i < complicatedList.length; i++) {
  //     isShowEquipmentPrice = complicatedList[i].children.some((item) => !!item.curr?.equipmentPrice);
  //     if (isShowEquipmentPrice) {
  //       break;
  //     }
  //   }
  //   return isShowEquipmentPrice;
  // }, [complicatedList]);

  // const showCurrPriceTable = useMemo(() => {
  //   let show = false;
  //   for (let i = 0; i < complicatedList.length; i++) {
  //     show = complicatedList[i].children.some((item) => !!item.curr);
  //     if (show) {
  //       break;
  //     }
  //   }
  //   return show;
  // }, [complicatedList]);

  /********************************* Scroll Body & Head ****************************************/

  // const headRef = useRef<HTMLDivElement | null>(null);
  // const headRef2 = useRef<HTMLDivElement | null>(null);
  // const bodyRef = useRef<HTMLTableRowElement | null>(null);

  // const handleScrollHead = (scroll: React.UIEvent<HTMLElement>): void => {
  //   if (bodyRef.current === null) return;
  //   const target = scroll.target as HTMLDivElement;
  //   bodyRef.current.scroll({ left: target.scrollLeft });
  // };
  // const handleScrollHead2 = (scroll: React.UIEvent<HTMLElement>): void => {
  //   if (bodyRef.current === null) return;
  //   const target = scroll.target as HTMLDivElement;
  //   bodyRef.current.scroll({ left: target.scrollLeft });
  // };

  // const handleScrollBody = (scroll: React.UIEvent<HTMLElement>): void => {
  //   if (headRef.current === null || headRef2.current === null) return;
  //   const target = scroll.target as HTMLDivElement;
  //   headRef.current.scroll({ left: target.scrollLeft });
  //   headRef2.current.scroll({ left: target.scrollLeft });
  // };

  // const floatChapterOffset = CHAPTER_OFFSET + (showCodeLSR ? ESTIMATE_TITLE_WIDTH : 0);
  // const floatHeaderOffset = floatChapterOffset + CHAPTER_WIDTH;
  // const floatCodeOffset = floatHeaderOffset + (showHeader ? HEADER_WIDTH : 0);
  // const floatTitleOffset = floatCodeOffset + CODE_WIDTH;

  /********************************* Dynamic Columns ****************************************/

  // const arrBaseAllDynamicColumns = useMemo(() => {
  //   let arr: TDynamicRow[][] = [];
  //   complicatedList.forEach((file: IComplicated) => {
  //     arr = [...arr, file.base.dynamicRows];
  //   });
  //   return arr;
  // }, [complicatedList]);

  // const longestArrBaseDynamicColumns = useMemo(() => {
  //   const maxArr = arrBaseAllDynamicColumns.reduce((accumulator, currentItem, currentIndex, arr) => {
  //     if (currentItem.length > accumulator.length) {
  //       return currentItem;
  //     }
  //     return accumulator;
  //   }, []);
  //   return maxArr;
  // }, [arrBaseAllDynamicColumns]);

  // const arrCurrAllDynamicColumns = useMemo(() => {
  //   let arr: TDynamicRow[][] = [];
  //   complicatedList.forEach((file: IComplicated, index) => {
  //     if (file.curr) {
  //       arr = [...arr, file.curr.dynamicRows];
  //     }
  //   });
  //   return arr;
  // }, [complicatedList]);

  // const longestArrCurrDynamicColumns = useMemo(() => {
  //   const maxArr = arrCurrAllDynamicColumns.reduce((accumulator, currentItem, currentIndex, arr) => {
  //     if (currentItem.length > accumulator.length) {
  //       return currentItem;
  //     }
  //     return accumulator;
  //   }, []);
  //   return maxArr;
  // }, [arrCurrAllDynamicColumns]);

  // const countBaseDynamicsColumns = longestArrBaseDynamicColumns.length;
  // const countCurrDynamicsColumns = longestArrCurrDynamicColumns.length;

  // const areasBaseDynamicsColumns = useMemo(() => {
  //   let arr = [];
  //   let n = 0;
  //   while (n < countBaseDynamicsColumns) {
  //     n++;
  //     arr.push('basePrice');
  //   }
  //   return arr;
  // }, [countBaseDynamicsColumns]);
  //
  // const areasCurrDynamicsColumns = useMemo(() => {
  //   let arr = [];
  //   let n = 0;
  //   while (n < countCurrDynamicsColumns) {
  //     n++;
  //     arr.push('сurrentPrice');
  //   }
  //   return arr;
  // }, [countCurrDynamicsColumns]);

  /****************************************************************************************/
  const Ref = useRef<AgGridReact<IComplicated> | null>(null);
  const [gridRef, setGridRef] = useState<GridReadyEvent<
    IComplicated,
    any
  > | null>(null);
  const [filteredData, setFilteredData] = useState<IComplicated[]>([]);
  const [hiddenRowsIds, setHiddenRowsIds] = useState<number[]>([]);

  const getRowClass = (params: RowClassParams<IComplicated>) => {
    const colors = {
      inactive: '#B8B8B8',
      deleted: '#B8B8B8',
      dont_belong: '#FF0101'
    };
    const styles: React.CSSProperties = {};

    const classCell = [];

    if (params.node.data?.type === 'file') {
      // classCell.push('cell-bg-file');
      classCell.push('level-1');
      // styles.background = 'rgba(0, 68, 180, 0.08)';
    } else if (params.node.data?.type === 'level') {
      classCell.push('rate');
      // classCell.push('cell-bg-level');
      // styles.background = 'rgba(2, 136, 209, 0.05)';
    } else if (params.node.data?.type === 'sum') {
      classCell.push('rate-position');
      // classCell.push('cell-bg-def');
      // styles.background = 'white';
    } else {
      classCell.push('cell-bg-def');
    }
    if (params.node.data?.subtype) {
      classCell.push(classCell[0] + '-' + params.node.data.subtype);
      styles.color = colors[params.node.data.subtype];
    }

    return classCell;
  };

  const collapse = (id: number) => {
    // if (setHiddenRowsIds) {
    setHiddenRowsIds((d) => {
      const copy = [...d];
      const addIndex = copy.findIndex((hrId) => hrId === id);
      if (addIndex >= 0) {
        copy.splice(addIndex, 1);
      } else {
        copy.push(id);
      }
      return copy;
    });
    // }
  };

  const total = useMemo(() => {
    return [data?.total];
  }, [data?.total]);

  const generateColumns = useCallback(
    ({
      collapse,
      hiddenRowsIds,
      total
    }: IAgGridTable): (ColDef<any, any> | ColGroupDef<any>)[] => {
      if (!total) return [];
      let empty = true;

      let foundTotalKey: any;

      for (foundTotalKey in total.curr!) {
        if (
          !Array.isArray(total.curr![foundTotalKey as never]) &&
          foundTotalKey !== 'dynamicRows'
        ) {
          if (total.curr![foundTotalKey as never] !== null) {
            empty = false;
          }
        }
      }
      return [
        {
          field: 'level',
          pinned: 'left',
          lockPinned: true,
          suppressMovable: true,
          width: 124,
          maxWidth: 124,
          minWidth: 124,
          cellStyle: {
            padding: '0 10px'
          },
          colSpan: (params) => {
            // return params.node?.rowPinned === 'top' ? 7 : 1;
            return params.data?.type === 'sum' ? 7 : 1;
          },

          headerName: 'Ур.',

          cellRenderer: (
            params: ICellRendererParams<IComplicated, any, any>
          ) => {
            return (
              <LevelExecution
                {...params}
                collapse={collapse}
                hiddenArr={hiddenRowsIds}
              />
            );
          }
        },
        {
          field: 'number',
          pinned: 'left',
          lockPinned: true,
          width: 50,
          suppressMovable: false,
          maxWidth: 50,
          minWidth: 50,
          headerClass: 'header-complicated',
          headerName: '№ п\\п',

          cellRenderer: ({
            data
          }: ICellRendererParams<IComplicated, any, any>) => {
            return <Cell float={'center'}>{data?.number}</Cell>;
          }
        },
        {
          field: 'lsr',
          pinned: 'left',
          lockPinned: true,
          width: 120,
          suppressMovable: false,
          maxWidth: 120,
          minWidth: 120,
          headerClass: 'header-complicated',
          headerName: 'Шифр ЛСР',
          cellRenderer: ({
            data
          }: ICellRendererParams<IComplicated, any, any>) => {
            return <Cell>{data?.lsr}</Cell>;
          }
        },
        {
          field: 'chapter',
          pinned: 'left',
          lockPinned: true,
          width: 165,
          suppressMovable: false,
          maxWidth: 165,
          minWidth: 165,
          headerClass: 'header-complicated',
          headerName: 'Раздел',
          cellRenderer: ({
            data
          }: ICellRendererParams<IComplicated, any, any>) => {
            return <Cell>{data?.chapter}</Cell>;
          }
        },
        {
          field: 'header',
          pinned: 'left',
          lockPinned: true,
          width: 110,
          suppressMovable: false,
          maxWidth: 110,
          minWidth: 110,
          headerClass: 'header-complicated',
          headerName: 'Заголовок',
          cellRenderer: ({
            data
          }: ICellRendererParams<IComplicated, any, any>) => {
            return <Cell>{data?.header}</Cell>;
          }
        },
        {
          field: 'code',
          pinned: 'left',
          lockPinned: true,
          width: 235,
          suppressMovable: false,
          maxWidth: 235,
          minWidth: 235,
          headerClass: 'header-complicated',
          headerName: 'Код расценки',
          cellRenderer: ({
            data
          }: ICellRendererParams<IComplicated, any, any>) => {
            return <Cell float={'center'}>{data?.code}</Cell>;
          }
        },
        {
          field: 'title',
          pinned: 'left',
          lockPinned: true,
          width: 400,
          maxWidth: 400,
          minWidth: 400,
          headerClass: 'header-complicated',
          headerName: 'Наименование',
          cellRenderer: ({
            data
          }: ICellRendererParams<IComplicated, any, any>) => {
            return <Cell>{data?.title}</Cell>;
          }
        },

        {
          field: 'unit',
          width: 120,
          suppressMovable: true,
          maxWidth: 120,
          minWidth: 120,
          headerClass: 'header-complicated',
          headerName: 'Ед. изм.',
          cellRenderer: ({
            data
          }: ICellRendererParams<IComplicated, any, any>) => {
            return <Cell float={'center'}>{data?.unit}</Cell>;
          }
        },
        {
          field: 'quantity',
          width: 120,
          suppressMovable: true,
          maxWidth: 120,
          minWidth: 120,
          headerClass: 'header-complicated',
          headerName: 'Кол-во.',
          cellRenderer: ({
            data
          }: ICellRendererParams<IComplicated, any, any>) => {
            return (
              <Cell float={'center'}>
                {data?.quantity?.toString()?.replace(/\./g, ',')}
              </Cell>
            );
            // return <Cell float={'center'}>{modifyData({ data: data?.quantity! })}</Cell>;
          }
        },
        {
          field: 'base',
          width: 120,
          suppressMovable: false,
          maxWidth: 120,
          minWidth: 120,
          headerName: 'В базовых ценах',
          // },
          children: [
            {
              field: 'materialsPriceBase',
              width: 205,
              suppressMovable: true,
              maxWidth: 205,
              minWidth: 205,
              headerClass: 'header-complicated',
              headerName: 'МТР',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.base?.materialsPrice!)}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.base?.materialsPrice! })}
                  </Cell>
                );
              }
            },
            {
              field: 'equipmentPriceBase',
              width: 205,
              suppressMovable: true,
              hide: !total?.base?.equipmentPrice,
              maxWidth: 205,
              minWidth: 205,
              headerClass: 'header-complicated',
              headerName: 'Оборудование',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.base?.equipmentPrice!)}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.base?.equipmentPrice! })}
                  </Cell>
                );
              }
            },
            {
              field: 'constructionEffortBase',
              width: 243,
              suppressMovable: true,
              maxWidth: 243,
              minWidth: 243,
              headerClass: 'header-complicated',
              headerName: 'ТЗ ОР, чел-ч',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.base?.constructionEffort!)}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.base?.constructionEffort! })}
                  </Cell>
                );
              }
            },
            {
              field: 'workersSalaryBase',
              width: 180,
              suppressMovable: true,
              maxWidth: 180,
              minWidth: 180,
              headerClass: 'header-complicated',
              headerName: 'ФОТ ОР',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.base?.workersSalary!)}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.base?.workersSalary! })}
                  </Cell>
                );
              }
            },
            {
              field: 'mechanicsEffortBase',
              width: 230,
              suppressMovable: true,
              maxWidth: 230,
              minWidth: 230,
              headerClass: 'header-complicated',
              headerName: 'ТЗМ, чел-ч',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.base?.mechanicsEffort)}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.base?.mechanicsEffort! })}
                  </Cell>
                );
              }
            },
            {
              field: '',
              suppressMovable: true,
              headerName: 'МИМ',
              children: [
                {
                  field: 'mimExploitationBase',
                  width: 125,
                  suppressMovable: true,
                  maxWidth: 125,
                  minWidth: 125,
                  headerClass: 'header-complicated',
                  headerName: 'Всего',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<IComplicated, any, any>) => {
                    return (
                      <Cell
                        weight={data?.type === 'sum' || data?.type === 'file'}
                        originData={modifyNum(data?.base?.mimExploitation)}
                        isNum
                        float={'right'}>
                        {modifyData({ data: data?.base?.mimExploitation! })}
                      </Cell>
                    );
                  }
                },
                {
                  field: 'mechanicsSalaryBase',
                  width: 330,
                  suppressMovable: true,
                  maxWidth: 330,
                  minWidth: 330,
                  headerClass: 'header-complicated',
                  headerName: 'в т.ч. ФОТ мех.',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<IComplicated, any, any>) => {
                    return (
                      <Cell
                        weight={data?.type === 'sum' || data?.type === 'file'}
                        originData={modifyNum(data?.base?.mechanicsSalary!)}
                        isNum
                        float={'right'}>
                        {modifyData({ data: data?.base?.mechanicsSalary! })}
                      </Cell>
                    );
                  }
                }
              ]
            },
            {
              field: 'hpBase',
              width: 105,
              suppressMovable: true,
              maxWidth: 105,
              minWidth: 105,
              headerClass: 'header-complicated',
              headerName: 'НР, %',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                const renderPercent =
                  data?.type !== 'file'
                    ? modifyData({ data: data?.base.hp!, replace: true })
                    : null;
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.base.hp)}
                    isNum
                    float={'right'}>
                    {renderPercent ? renderPercent + '%' : null}
                  </Cell>
                );
              }
            },
            {
              field: 'overheadsBase',
              width: 190,
              suppressMovable: true,
              maxWidth: 190,
              minWidth: 190,
              headerClass: 'header-complicated',
              headerName: 'НР',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.base?.overheads)}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.base?.overheads! })}
                  </Cell>
                );
              }
            },
            {
              field: 'spBase',
              width: 105,
              suppressMovable: true,
              maxWidth: 105,
              minWidth: 105,
              headerClass: 'header-complicated',
              headerName: 'СП, %',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                const renderPercent =
                  data?.type !== 'file'
                    ? modifyData({ data: data?.base.sp!, replace: true })
                    : null;
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.base.sp)}
                    isNum
                    float={'right'}>
                    {renderPercent ? renderPercent + '%' : null}
                  </Cell>
                );
              }
            },
            {
              field: 'estimatedProfitBase',
              width: 190,
              suppressMovable: true,
              maxWidth: 190,
              minWidth: 190,
              headerClass: 'header-complicated',
              headerName: 'СП',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.base?.estimatedProfit)}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.base?.estimatedProfit! })}
                  </Cell>
                );
              }
            },
            {
              field: 'totalWorkCostBase',
              hide: !total?.base?.dynamicRows?.length,
              width: 190,
              suppressMovable: true,
              maxWidth: 190,
              minWidth: 190,
              headerClass:
                'header-top-border header-left-border header-right-border header-complicated',
              cellClass: 'header-left-border header-right-border',
              headerName: 'Итого',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.base?.totalWorkCost)}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.base?.totalWorkCost! })}
                  </Cell>
                );
              }
            },
            ...(!total?.base?.dynamicRows?.length
              ? ([] as any)
              : total.base.dynamicRows.map((dyn, idxDyn) => {
                  return {
                    field: dyn.title + 'Base',
                    width: 190,
                    suppressMovable: true,
                    maxWidth: 190,
                    minWidth: 190,
                    headerClass: 'header-complicated',
                    headerName: dyn.title,
                    cellRenderer: ({
                      data
                    }: ICellRendererParams<IComplicated, any, any>) => {
                      return (
                        <Cell
                          weight={data?.type === 'sum' || data?.type === 'file'}
                          originData={modifyNum(
                            data?.base?.dynamicRows?.[idxDyn]?.value
                          )}
                          isNum
                          float={'right'}>
                          {modifyData({
                            data: data?.base?.dynamicRows?.[idxDyn]?.value!
                          })}
                        </Cell>
                      );
                    }
                  };
                })),
            {
              field: 'workCostInRublesBase',
              width: 190,
              suppressMovable: true,
              maxWidth: 190,
              minWidth: 190,
              headerClass:
                'header-top-border header-left-border header-right-border header-complicated',
              cellClass: 'header-left-border header-right-border',
              headerName: 'Всего стоимость',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.base.workCostInRubles)}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.base.workCostInRubles! })}
                  </Cell>
                );
              }
            }
          ]
        },
        {
          field: 'currmaterialsPriceCurr',
          hide: empty,
          suppressMovable: false,
          headerName: 'В текущих ценах',
          children: [
            {
              field: 'materialsPriceCurr',
              hide: empty,
              width: 205,
              suppressMovable: true,
              maxWidth: 205,
              minWidth: 205,
              headerClass: 'header-complicated',
              headerName: 'МТР',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.curr?.materialsPrice)}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.curr?.materialsPrice! })}
                  </Cell>
                );
              }
            },
            {
              field: 'equipmentPriceCurr',
              width: 205,
              suppressMovable: true,
              hide: !total?.curr?.equipmentPrice || empty,
              maxWidth: 205,
              minWidth: 205,
              headerClass: 'header-complicated',
              headerName: 'Оборудование',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.curr?.equipmentPrice)}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.curr?.equipmentPrice! })}
                  </Cell>
                );
              }
            },
            {
              field: 'constructionEffortCurr',
              width: 243,
              hide: empty,
              suppressMovable: true,
              maxWidth: 243,
              minWidth: 243,
              headerClass: 'header-complicated',
              headerName: 'ТЗ ОР, чел-ч',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.curr?.constructionEffort)}
                    isNum
                    float={'right'}>
                    {modifyData({
                      data: data?.curr?.constructionEffort!,
                      replace: true
                    })}
                  </Cell>
                );
              }
            },
            {
              field: 'workersSalaryCurr',
              width: 180,
              hide: empty,

              suppressMovable: true,
              maxWidth: 180,
              minWidth: 180,
              headerClass: 'header-complicated',
              headerName: 'ФОТ ОР',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.curr?.workersSalary)}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.curr?.workersSalary! })}
                  </Cell>
                );
              }
            },
            {
              field: 'mechanicsEffortCurr',
              width: 230,
              hide: empty,
              suppressMovable: true,
              maxWidth: 230,
              minWidth: 230,
              headerClass: 'header-complicated',
              headerName: 'ТЗМ, чел-ч',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.curr?.mechanicsEffort)}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.curr?.mechanicsEffort! })}
                  </Cell>
                );
              }
            },
            {
              field: '',
              hide: empty,
              suppressMovable: true,
              headerName: 'МИМ',
              children: [
                {
                  field: 'mimExploitationCurr',
                  width: 125,
                  hide: empty,
                  suppressMovable: true,
                  maxWidth: 125,
                  minWidth: 125,
                  headerClass: 'header-complicated',
                  headerName: 'Всего',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<IComplicated, any, any>) => {
                    return (
                      <Cell
                        weight={data?.type === 'sum' || data?.type === 'file'}
                        originData={modifyNum(data?.curr?.mimExploitation)}
                        isNum
                        float={'right'}>
                        {modifyData({ data: data?.curr?.mimExploitation! })}
                      </Cell>
                    );
                  }
                },
                {
                  field: 'mechanicsSalaryCurr',
                  width: 330,
                  hide: empty,
                  suppressMovable: true,
                  maxWidth: 330,
                  minWidth: 330,
                  headerClass: 'header-complicated',
                  headerName: 'в т.ч. ФОТ мех.',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<IComplicated, any, any>) => {
                    return (
                      <Cell
                        weight={data?.type === 'sum' || data?.type === 'file'}
                        originData={modifyNum(data?.curr?.mechanicsSalary)}
                        isNum
                        float={'right'}>
                        {modifyData({ data: data?.curr?.mechanicsSalary! })}
                      </Cell>
                    );
                  }
                }
              ]
            },
            {
              field: 'hpCurr',
              width: 105,
              hide: empty,
              suppressMovable: true,
              maxWidth: 105,
              minWidth: 105,
              headerClass: 'header-complicated',
              headerName: 'НР, %',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                const renderPercent =
                  data?.type !== 'file'
                    ? modifyData({ data: data?.curr?.hp!, replace: true })
                    : null;
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.curr?.hp)}
                    isNum
                    float={'right'}>
                    {renderPercent ? renderPercent + '%' : null}
                  </Cell>
                );
              }
            },
            {
              field: 'overheadsCurr',
              width: 190,
              hide: empty,
              suppressMovable: true,
              maxWidth: 190,
              minWidth: 190,
              headerClass: 'header-complicated',
              headerName: 'НР',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.curr?.overheads)}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.curr?.overheads! })}
                  </Cell>
                );
              }
            },
            {
              field: 'spCurr',
              width: 105,
              hide: empty,
              suppressMovable: true,
              maxWidth: 105,
              minWidth: 105,
              headerClass: 'header-complicated',
              headerName: 'СП, %',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                const renderPercent =
                  data?.type !== 'file'
                    ? modifyData({ data: data?.curr?.sp!, replace: true })
                    : null;
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.curr?.sp!)}
                    isNum
                    float={'right'}>
                    {renderPercent ? renderPercent + '%' : null}
                  </Cell>
                );
              }
            },
            {
              field: 'estimatedProfitCurr',
              width: 190,
              hide: empty,
              suppressMovable: true,
              maxWidth: 190,
              minWidth: 190,
              headerClass: 'header-complicated',
              headerName: 'СП',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.curr?.estimatedProfit)}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.curr?.estimatedProfit! })}
                  </Cell>
                );
              }
            },
            {
              field: 'totalWorkCostCurr',
              hide: !total?.curr?.dynamicRows?.length || empty,
              width: 190,
              suppressMovable: true,
              maxWidth: 190,
              minWidth: 190,
              headerClass:
                'header-top-border header-left-border header-right-border header-complicated',
              cellClass: 'header-left-border header-right-border',
              headerName: 'Итого',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.curr?.totalWorkCost! })}
                  </Cell>
                );
              }
            },
            ...(!total?.curr?.dynamicRows?.length
              ? ([] as any)
              : total.curr.dynamicRows.map((dyn, idxDyn) => {
                  return {
                    field: dyn.title + 'Curr',
                    width: 190,
                    hide: empty,
                    suppressMovable: true,
                    maxWidth: 190,
                    minWidth: 190,
                    headerClass: 'header-complicated',
                    headerName: dyn.title,
                    cellRenderer: ({
                      data
                    }: ICellRendererParams<IComplicated, any, any>) => {
                      return (
                        <Cell
                          weight={data?.type === 'sum' || data?.type === 'file'}
                          originData={modifyNum(
                            data?.curr?.dynamicRows?.[idxDyn]?.value!
                          )}
                          isNum
                          float={'right'}>
                          {modifyData({
                            data: data?.curr?.dynamicRows?.[idxDyn]?.value!
                          })}
                        </Cell>
                      );
                    }
                  };
                })),
            {
              field: 'workCostInRublesCurr',
              width: 190,
              hide: empty,
              suppressMovable: true,
              maxWidth: 190,
              minWidth: 190,
              headerClass:
                'header-top-border header-left-border header-right-border header-complicated',
              cellClass: 'header-left-border header-right-border',

              headerName: 'Всего стоимость',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicated, any, any>) => {
                return (
                  <Cell
                    weight={data?.type === 'sum' || data?.type === 'file'}
                    originData={modifyNum(data?.curr?.workCostInRubles)}
                    isNum
                    float={'right'}>
                    {modifyData({ data: data?.curr?.workCostInRubles! })}
                  </Cell>
                );
              }
            }
          ]
        }
      ];
    },
    [filteredData]
  );

  useEffect(() => {
    if (!isFetching) {
      setFilteredData(() => {
        if (data?.data) {
          const indexes: number[] = [];
          const filledArr = data?.data.reduce((acc: IComplicated[], curr) => {
            const newItem: IComplicated = Object.assign({}, curr);
            let idx = false;
            if (newItem && newItem.parent_id && newItem.id) {
              idx =
                hiddenRowsIds.includes(newItem.parent_id) ||
                indexes.includes(newItem.id);
              if (idx && newItem.children.length) {
                indexes.push(...newItem.children.map((item) => item.id));
              }
            }
            return !idx ? [...acc, newItem] : acc;
          }, []);

          gridRef?.api.hideOverlay();

          // setUpdateLoader(false);
          return filledArr?.length ? filledArr : data.data;
        } else {
          gridRef?.api.hideOverlay();
          // setUpdateLoader(false);
          return [];
        }
      });
    }
  }, [hiddenRowsIds, data?.data, isFetching]);

  useLayoutEffect(() => {
    if (data?.data) {
      setHiddenRowsIds((d) => {
        const copy = [...d];
        if (!copy.length) {
          data.data
            .filter((item) => item.type === 'file' || item.type === 'level')
            .forEach((file) => copy.push(file.id));
        }
        return copy;
      });
    }
  }, [data?.data]);

  useEffect(() => {
    if (isDataLoading && gridRef) {
      gridRef?.api.showLoadingOverlay();
      setFilteredData([]);
    }
  }, [isDataLoading, gridRef]);

  useEffect(() => {
    if (isDataLoading && gridRef) {
      gridRef?.api.showLoadingOverlay();
      setFilteredData([]);
    }
  }, [isDataLoading, gridRef, isDataLoading]);

  useBreadcrumbs(
    [
      {
        title: 'Расчеты',
        url: `/projects/${projectID}/calculations`
      },
      {
        title: calculation?.title ?? 'Предпросмотр',
        url: `projects/${projectID}/calculation/${calculation?.id}/edit`
      }
    ],
    [calculation?.title]
  );

  return (
    <>
      {!isDataLoading ? (
        <>
          <PageStyled>
            <div />
            {/*<CaptionTable*/}
            {/*  disableCurr={emptyCurr}*/}
            {/*  act={() => setCreateModal(true)}*/}
            {/*  exportKS={(str: string) => setExportModal(str)}*/}
            {/*  prices={prices}*/}
            {/*  changePrices={changePrices}*/}
            {/*  priceSwitches={priceSwitches}*/}
            {/*/>*/}
            <WrapperAgGrid className="ag-theme-material reference-prices">
              <AgGridReact
                ref={Ref}
                onGridReady={(e) => setGridRef(e)}
                enableCellTextSelection={true}
                columnDefs={generateColumns({
                  collapse,
                  hiddenRowsIds,
                  total: total[0]
                })}
                groupHeaderHeight={25}
                gridOptions={{
                  navigateToNextHeader: () => null,
                  tabToNextHeader: () => null
                }}
                pinnedTopRowData={total}
                rowData={filteredData}
                suppressCellFocus={true}
                onFirstDataRendered={(event) => {
                  event.api.sizeColumnsToFit();
                }}
                onGridSizeChanged={(event: GridSizeChangedEvent<any, any>) => {
                  event.api.sizeColumnsToFit();
                }}
                onViewportChanged={(event) => {
                  event.api.sizeColumnsToFit();
                }}
                getRowId={(params) => {
                  return params.data.id.toString();
                }}
                getRowClass={getRowClass}
                getRowHeight={(params) => {
                  if (params.node.rowPinned === 'top') {
                    return 50;
                  }
                  return 55;
                }}
                rowStyle={{
                  padding: '0 !important'
                }}
                rowHeight={55}
                headerHeight={36}
                loadingOverlayComponent={Progress}
                noRowsOverlayComponent={Progress}></AgGridReact>
            </WrapperAgGrid>
          </PageStyled>
          {/*<StyledTableContainer style={{ flexDirection: 'column' }} className="StyledTableContainer">*/}
          {/*  <StyledTableHead*/}
          {/*    showCodeLSR={showCodeLSR}*/}
          {/*    showHeader={showHeader}*/}
          {/*    showCurrPriceTable={showCurrPriceTable}*/}
          {/*    showBaseEquipmentPrice={showBaseEquipmentPrice}*/}
          {/*    showCurrEquipmentPrice={showCurrEquipmentPrice}*/}
          {/*    countBaseDynamicsColumns={countBaseDynamicsColumns}*/}
          {/*    countCurrDynamicsColumns={countCurrDynamicsColumns}*/}
          {/*    areasBaseDynamicsColumns={areasBaseDynamicsColumns}*/}
          {/*    areasCurrDynamicsColumns={areasCurrDynamicsColumns}*/}
          {/*    className="StyledTableHead"*/}
          {/*    ref={headRef}*/}
          {/*    onScroll={handleScrollHead}>*/}
          {/*    <StyledHeadFixedTd style={{ position: 'sticky', left: 0, boxShadow: 'none' }}></StyledHeadFixedTd>*/}
          {/*    <StyledHeadFixedTd style={{ position: 'sticky', left: '40px' }}></StyledHeadFixedTd>*/}
          {/*    <StyledHeadFixedTd style={{ padding: '8px 16px', position: 'sticky', left: NUMBER_OFFSET }}>*/}
          {/*      № п\п*/}
          {/*    </StyledHeadFixedTd>*/}
          {/*    {showCodeLSR && (*/}
          {/*      <StyledHeadFixedTd style={{ padding: '8px 16px', position: 'sticky', left: ESTIMATE_TITLE_OFFSET }}>*/}
          {/*        Шифр ЛСР*/}
          {/*      </StyledHeadFixedTd>*/}
          {/*    )}*/}
          {/*    <StyledHeadFixedTd style={{ padding: '8px 16px', position: 'sticky', left: floatChapterOffset }}>*/}
          {/*      Раздел*/}
          {/*    </StyledHeadFixedTd>*/}
          {/*    {showHeader && (*/}
          {/*      <StyledHeadFixedTd style={{ padding: '8px 16px', position: 'sticky', left: floatHeaderOffset }}>*/}
          {/*        Заголовок*/}
          {/*      </StyledHeadFixedTd>*/}
          {/*    )}*/}
          {/*    <StyledHeadFixedTd style={{ padding: '8px 16px', position: 'sticky', left: floatCodeOffset }}>*/}
          {/*      Код расценки*/}
          {/*    </StyledHeadFixedTd>*/}
          {/*    <StyledHeadFixedTd*/}
          {/*      style={{*/}
          {/*        padding: '8px 16px',*/}
          {/*        position: 'sticky',*/}
          {/*        left: floatTitleOffset,*/}
          {/*        boxShadow: 'inset -1px 0px 0px rgba(0,36,95,0.1)',*/}
          {/*      }}>*/}
          {/*      Наименование*/}
          {/*    </StyledHeadFixedTd>*/}
          {/*    <StyledHeadTd style={{ padding: '8px 16px' }}>Ед. изм.</StyledHeadTd>*/}
          {/*    <StyledHeadTd style={{ padding: '8px 16px' }}>Кол-во</StyledHeadTd>*/}

          {/*    /!* Базовые цены *!/*/}

          {/*    <BasePriceHeader*/}
          {/*      complicatedList={complicatedList}*/}
          {/*      showEquipmentPrice={showBaseEquipmentPrice}*/}
          {/*      longestArrDynamicColumns={longestArrBaseDynamicColumns}*/}
          {/*      countDynamicsColumns={countBaseDynamicsColumns}*/}
          {/*    />*/}

          {/*    /!* Текущие цены *!/*/}
          {/*    {showCurrPriceTable && (*/}
          {/*      <CurrentPriceHeader*/}
          {/*        complicatedList={complicatedList}*/}
          {/*        showEquipmentPrice={showCurrEquipmentPrice}*/}
          {/*        longestArrDynamicColumns={longestArrCurrDynamicColumns}*/}
          {/*        countDynamicsColumns={countCurrDynamicsColumns}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*    <Box*/}
          {/*      className="scroll"*/}
          {/*      sx={{*/}
          {/*        gridArea: 'scroll',*/}
          {/*        height: '100%',*/}
          {/*        minWidth: '13px',*/}
          {/*      }}></Box>*/}
          {/*  </StyledTableHead>*/}

          {/*  <StyledTotal*/}
          {/*    showCodeLSR={showCodeLSR}*/}
          {/*    showHeader={showHeader}*/}
          {/*    showCurrPriceTable={showCurrPriceTable}*/}
          {/*    showBaseEquipmentPrice={showBaseEquipmentPrice}*/}
          {/*    showCurrEquipmentPrice={showCurrEquipmentPrice}*/}
          {/*    countBaseDynamicsColumns={countBaseDynamicsColumns}*/}
          {/*    countCurrDynamicsColumns={countCurrDynamicsColumns}*/}
          {/*    areasBaseDynamicsColumns={areasBaseDynamicsColumns}*/}
          {/*    areasCurrDynamicsColumns={areasCurrDynamicsColumns}*/}
          {/*    className="StyledTotal"*/}
          {/*    ref={headRef2}*/}
          {/*    onScroll={handleScrollHead2}>*/}
          {/*    <StyledTotalTd style={{ gridArea: 'icon', position: 'sticky', left: 0, boxShadow: 'none' }}>*/}
          {/*      <SumIcon width={40} height={20} />*/}
          {/*    </StyledTotalTd>*/}

          {/*    <StyledTotalTd style={{ gridArea: 'title', position: 'sticky', left: '100px', alignItems: 'flex-start' }}>*/}
          {/*      <StyledTypography style={{ textAlign: 'left', color: '#5072A1', fontWeight: 700 }}>*/}
          {/*        Суммы по объекту*/}
          {/*      </StyledTypography>*/}
          {/*    </StyledTotalTd>*/}

          {/*    /!* Ед. изм *!/*/}
          {/*    <StyledHeadTd style={{ padding: '8px 16px', gridArea: 'unit' }}></StyledHeadTd>*/}

          {/*    /!* Кол-во *!/*/}
          {/*    <StyledHeadTd style={{ padding: '8px 16px', gridArea: 'count' }}></StyledHeadTd>*/}

          {/*    /!* Базовые цены *!/*/}

          {/*    <BasePriceTotal*/}
          {/*      total={total}*/}
          {/*      complicatedList={complicatedList}*/}
          {/*      showEquipmentPrice={showBaseEquipmentPrice}*/}
          {/*      longestArrDynamicColumns={longestArrBaseDynamicColumns}*/}
          {/*      countDynamicsColumns={countBaseDynamicsColumns}*/}
          {/*    />*/}

          {/*    /!* Текущие цены *!/*/}
          {/*    {showCurrPriceTable && (*/}
          {/*      <CurrentPriceTotal*/}
          {/*        total={total}*/}
          {/*        complicatedList={complicatedList}*/}
          {/*        showEquipmentPrice={showCurrEquipmentPrice}*/}
          {/*        longestArrDynamicColumns={longestArrCurrDynamicColumns}*/}
          {/*        countDynamicsColumns={countCurrDynamicsColumns}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*    <Box*/}
          {/*      className="scroll"*/}
          {/*      sx={{*/}
          {/*        gridArea: 'scroll',*/}
          {/*        height: '100%',*/}
          {/*        minWidth: '13px',*/}
          {/*      }}></Box>*/}
          {/*  </StyledTotal>*/}

          {/*  <StyledTableBody className="TableBody" ref={bodyRef} onScroll={handleScrollBody}>*/}
          {/*    <Box>*/}
          {/*      {[...complicatedList].map((element) => {*/}
          {/*        return (*/}
          {/*          <СomplicatedLevel*/}
          {/*            key={element.id}*/}
          {/*            calculationId={calculation?.id || 0}*/}
          {/*            level={element}*/}
          {/*            levelNumber={1}*/}
          {/*            showHeader={showHeader}*/}
          {/*            floatOffsetData={{*/}
          {/*              floatChapterOffset,*/}
          {/*              floatHeaderOffset,*/}
          {/*              floatCodeOffset,*/}
          {/*              floatTitleOffset,*/}
          {/*            }}*/}
          {/*            showCodeLSR={showCodeLSR}*/}
          {/*            countBaseDynamicsColumns={countBaseDynamicsColumns}*/}
          {/*            countCurrDynamicsColumns={countCurrDynamicsColumns}*/}
          {/*            longestArrBaseDynamicColumns={longestArrBaseDynamicColumns}*/}
          {/*            longestArrCurrDynamicColumns={longestArrCurrDynamicColumns}*/}
          {/*            showBaseEquipmentPrice={showBaseEquipmentPrice}*/}
          {/*            showCurrEquipmentPrice={showCurrEquipmentPrice}*/}
          {/*            showCurrPriceTable={showCurrPriceTable}*/}
          {/*          />*/}
          {/*        );*/}
          {/*      })}*/}
          {/*    </Box>*/}
          {/*  </StyledTableBody>*/}
          {/*</StyledTableContainer>*/}
        </>
      ) : (
        <Progress />
      )}
    </>
  );
};
