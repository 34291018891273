import styled from 'styled-components';
import { TitledWrapper } from 'components/TitledWrapper';
import { FieldForm } from 'components/FieldForm';
import { StyledSelectMenuItem } from 'components/UserManagement/components/UserRoleSelection/UserRoleSelection.styles';

export const FormTitledWrapper = styled(TitledWrapper)`
  & .title {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: ${(props) => props.theme.palette.text.dark};
  }

  & .stack-wrapper {
    padding: 20px;
    gap: 20px;
    border-radius: 20px;

    & > div:last-of-type {
      margin: 0;
    }
  }
`;

export const FormTextField = styled(FieldForm)`
  & .MuiFilledInput-root:not(.MuiInputBase-multiline) {
    height: 41px;
  }

  & .MuiFormHelperText-root {
    text-align: right;
  }
` as typeof FieldForm;

interface FormSelectFieldProps {
  disablePlaceholder?: boolean;
}

export const FormSelectField = styled(FieldForm)<FormSelectFieldProps>`
  width: 100%;

  & .MuiOutlinedInput-root {
    height: 41px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  & .MuiSelect-select {
    height: 41px;
    padding: 10px 16px;
    text-align: left;
    border-radius: 8px;
    box-sizing: border-box;
  }

  & .MuiInputLabel-root {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.17px;
    color: ${(props) => props.theme.palette.disabled};
    transform: translate(14px, 10px) scale(1);

    &.MuiInputLabel-shrink {
      opacity: ${(props) => (props.disablePlaceholder ? 0 : 1)};
      transform: translate(14px, 10px) scale(1);
    }
  }

  & .MuiOutlinedInput-notchedOutline legend {
    padding: 0;

    & span {
      display: none;
    }
  }
`;

export const FormSelectMenuItem = styled(StyledSelectMenuItem)`
  max-width: none;
`;
