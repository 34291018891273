import { useState } from 'react';
import { ReactComponent as RowIcon } from '../../../../assets/icons/LeveLicon/RowIcon.svg';
import { Button, ClickAwayListener, IconButton } from '@mui/material';
import {
  StyledButtonGroup,
  StyledDivider,
  StyledPopper,
} from '../CalculationBasic/components/styles';
import { LevelIcon, LevelNameType } from '../LevelIcon';

type AddLevelType = 'adjacent' | 'nested' | 'row';

interface AddLevelButtonsProps {
  levelNumber: number;
  levelName: LevelNameType;
  clickAddLevel: (level: AddLevelType) => void;
}

export const AddLevelButtons: React.FC<AddLevelButtonsProps> = ({
  levelNumber,
  levelName,
  clickAddLevel,
}) => {
  /****************************** Menu Button Level****************************************/
  const [anchorElPoper, setAnchorElPoper] = useState<null | HTMLElement>(null);
  const [openPoper, setOpenPoper] = useState(false);
  const id = openPoper ? 'simple-popper' : undefined;

  const handleClickAway = () => {
    setOpenPoper(false);
  };

  const handleShowButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPoper(event.currentTarget);
    setOpenPoper(true);
  };

  const handleClickAddLevel = (level: AddLevelType) => {
    setOpenPoper(false);
    clickAddLevel(level);
  };

  return (
    <>
      <IconButton
        onClick={handleShowButton}
        sx={{ width: 32, height: 32, marginRight: '0px' }}
        size="small">
        <LevelIcon levelName={levelName} />
      </IconButton>
      <>
        <StyledPopper
          placement="auto-end"
          id={id}
          open={openPoper}
          anchorEl={anchorElPoper}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <StyledButtonGroup
              variant="text"
              sx={{
                '& .MuiButtonGroup-root': {
                  width: '100%',
                },
                '& button': {
                  border: 'none !important',
                  minWidth: '36px !important',
                },
              }}>
              <Button
                onClick={() => handleClickAddLevel('adjacent')}
                sx={{ height: '100%', width: 32, marginRight: '0px' }}
                size="small">
                <LevelIcon levelName={levelName} />
              </Button>
              {
                <Button
                  onClick={() => handleClickAddLevel('nested')}
                  style={{
                    height: '100%',
                    width: 32,
                    marginRight: '0px',
                    border: 'none !important',
                  }}
                  size="small">
                  <LevelIcon
                    levelName={`level${levelNumber + 1}` as LevelNameType}
                  />
                </Button>
              }
              <>
                <StyledDivider orientation="vertical" />
                <Button
                  onClick={() => handleClickAddLevel('row')}
                  sx={{
                    height: '100%',
                    width: 32,
                    marginRight: '0px',
                    border: 'none',
                  }}
                  size="small">
                  <RowIcon style={{ width: 13, height: 13 }} />
                </Button>
              </>
            </StyledButtonGroup>
          </ClickAwayListener>
        </StyledPopper>
      </>
    </>
  );
};
