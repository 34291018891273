import DeleteIcon from '@mui/icons-material/Delete';
import { FC } from 'react';
import {
  ImageItemDeleteButton,
  ImageItemWrapper,
  ImageWrapper,
} from './ImageItem.styles';
import { ImageItemProps } from './ImageItem.types';

export const ImageItem: FC<ImageItemProps> = ({
  url,
  onClick,
  download,
  canDelete = true,
}) => {
  return (
    <ImageItemWrapper>
      <img src={url} width={277} height={305} alt="Фото проекта" />

      <ImageWrapper direction={'row'} spacing={1}>
        {canDelete && (
          <ImageItemDeleteButton onClick={() => onClick()}>
            <DeleteIcon color="error" />
          </ImageItemDeleteButton>
        )}
      </ImageWrapper>
    </ImageItemWrapper>
  );
};
