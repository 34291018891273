import { Box, Stack, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import { ColorsBgLevelType, GridAreasContract } from 'types';

export const StyledTableContainer = styled(Box)`
  border-radius: 8px;
  display: flex;
  height: 100%;
  overflow: hidden;
  background: #fff;
`;

interface StyledGridAreasProps {
  gridAreasContract: GridAreasContract;
}

export const StyledTableHeadAnalysis = styled(Box)`
  overflow: hidden;
  display: grid;
  grid-auto-flow: column dense;

  grid-template-rows: 1fr;
  gap: 0px 0px;

  width: 100%;
  height: 100%;
  max-height: 114px;

  background-color: #7890b2;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #ffffff;
`;
export const StyledTableHead = styled(Box)`
  overflow: hidden;
  display: grid;
  grid-auto-flow: column dense;
  grid-template-columns: 60px minmax(400px, 1fr) repeat(13, 150px) 13px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'icon title contractName contractName contractName contractName contractName contractName contractName contractName contractName contractName contractName contractName contractName scroll';

  width: 100%;
  height: 100%;
  max-height: 114px;

  background-color: #7890b2;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #ffffff;
`;
export const StyledTableBody = styled(Box)`
  overflow: scroll;
  height: 100%;

  &::-webkit-scrollbar {
    box-shadow: inset 1px 0px 0px rgba(0, 36, 95, 0.1);
  }
`;

interface StyledBgLevelProps {
  colorsBgLevel?: ColorsBgLevelType;
}

export const StyledBodyTd = styled(Box)<StyledBgLevelProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  box-shadow: inset 0px -1px 0px rgba(0, 36, 95, 0.1);
  background-color: ${(props) => {
    return props.theme.palette.bgLevel[props.colorsBgLevel!];
  }};
`;

export const StyledHeadRow = styled(Stack)`
  align-items: flex-end;
  width: 100%;
  height: 100px;
`;
export const StyledContractName = styled(Box)`
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  grid-template-rows: 20px 94px;
  height: 114px;
  gap: 0px 0px;
  grid-template-areas:
    'contract-title contract-title contract-title contract-title contract-title contract-title contract-title contract-title contract-title contract-title contract-title contract-title contract-title'
    'total mainSalary mimExploitation machineOperatorSalary materials includingMaterials includingMaterials equipment overheads estimatedProfit includingLaborIntensity includingLaborIntensity hourlyCost';
  grid-area: contractName;
`;
export const StyledGencontract = styled(Box)`
  display: grid;
  grid-template-rows: 20px 94px;
  grid-auto-flow: row;
  height: 114px;
  gap: 0px 0px;
  grid-template-areas:
    'gencontractTitle'
    'gencontractColumns';
  grid-area: gencontract;
`;
export const StyledSubcontract = styled(Box)`
  display: grid;
  grid-template-rows: 20px 94px;
  grid-auto-flow: row;
  height: 114px;
  gap: 0px 0px;
  grid-template-areas:
    'subcontractTitle'
    'subcontractColumns';
  grid-area: subcontract;
`;

export const StyledAnalysis = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 20px 94px;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'analysisTitle'
    'analysisColumns';
  grid-area: analysisEstimate;
`;
export const StyledContractTitle = styled(Box)`
  grid-area: contract-title;
  text-transform: uppercase;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledGencontractTitle = styled(Box)`
  grid-area: gencontractTitle;
  text-transform: uppercase;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 1px 0px 0px rgb(0 36 95 / 10%);
`;
export const StyledAnalysisTitle = styled(Box)`
  grid-area: analysisTitle;
  text-transform: uppercase;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 1px 0px 0px rgb(0 36 95 / 10%);
`;
export const StyledSubcontractTitle = styled(Box)`
  grid-area: subcontractTitle;
  text-transform: uppercase;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 1px 0px 0px rgb(0 36 95 / 10%);
`;
export const StyledGencontractColumns = styled(Box)`
  display: grid;
  grid-template-columns: repeat(13, 150px);
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: 'gen-total gen-mainSalary gen-mimExploitation gen-machineOperatorSalary gen-materials gen-includingMaterials gen-includingMaterials gen-equipment gen-overheads gen-estimatedProfit gen-includingLaborIntensity gen-includingLaborIntensity gen-hourlyCost';
  grid-area: gencontractColumns;
`;

export const StyledSubcontractColumns = styled(Box)`
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: 'sub-total sub-mainSalary sub-mimExploitation sub-machineOperatorSalary sub-materials sub-includingMaterials sub-includingMaterials sub-equipment sub-overheads sub-estimatedProfit sub-includingLaborIntensity sub-includingLaborIntensity sub-hourlyCost';
  grid-area: subcontractColumns;
`;
export const StyledAnalysisColumns = styled(Box)`
  display: grid;
  grid-template-columns: repeat(4, 170px);
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: 'hourlyCostIncluding hourlyCostIncluding profit rentability';
  grid-area: analysisColumns;
`;

export const StyledContractTd = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 4px 8px;
  //box-shadow: inset 1px 0px 0px rgb(0 36 95 / 10%);
  background: #6f88ab;
`;
export const StyledIncludingMaterials = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  //rid-template-rows: repeat(2, 1fr);
  grid-template-rows: 24px 70px;
  gap: 0px 0px;
  grid-template-areas:
    'materialsTitle materialsTitle'
    'basicMaterials auxiliaryMaterials';
  grid-area: includingMaterials;
`;
export const StyledGenIncludingMaterials = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  //grid-template-rows: repeat(2, 1fr);
  grid-template-rows: 24px 70px;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'gen-materialsTitle gen-materialsTitle'
    'gen-basicMaterials gen-auxiliaryMaterials';
  grid-area: gen-includingMaterials;
`;

export const StyledSubIncludingMaterials = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  //grid-template-rows: repeat(2, 1fr);
  grid-template-rows: 24px 70px;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'sub-materialsTitle sub-materialsTitle'
    'sub-basicMaterials sub-auxiliaryMaterials';
  grid-area: sub-includingMaterials;
`;

export const StyledIncludingLaborIntensity = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 24px 70px;
  gap: 0px 0px;
  grid-template-areas:
    'laborIntensityTitle laborIntensityTitle'
    'peoplePerHour machinesPerHour';
  grid-area: IncludingLaborIntensity;
`;

export const StyledGenIncludingLaborIntensity = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 24px 70px;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'gen-laborIntensityTitle gen-laborIntensityTitle'
    'gen-peoplePerHour gen-machinesPerHour';
  grid-area: gen-includingLaborIntensity;
`;
export const StyledSubIncludingLaborIntensity = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 24px 70px;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'sub-laborIntensityTitle sub-laborIntensityTitle'
    'sub-peoplePerHour sub-machinesPerHour';
  grid-area: sub-includingLaborIntensity;
`;
export const StyledHourlyCostIncluding = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 24px 70px;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'hourlyCostTitle hourlyCostTitle'
    'hourlyCostGen hourlyCostSub';
  grid-area: hourlyCostIncluding;
`;
export const StyledTypographyHeadTd = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: #ffffff;
  height: 100%;
  width: 100%;
  justify-content: center;
`;

export const StyledCoefficients = styled(Stack)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6px;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
`;
export const StyledCoefficient = styled(Box)`
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  padding: 3.5px 6.5px;
`;

export const StyledHeadTd = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const StyledGridBox = styled(Stack)`
  grid-template-columns: repeat(13, 150px);
  justify-content: center;
  width: 100%;
  height: 100%;
`;
/*     */
export const StyledTitle = styled(Stack)<StyledBgLevelProps>`
  align-items: start;
  min-width: 400px;
  width: 100%;
  height: 100%;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  background-color: ${(props) => {
    return props.theme.palette.bgLevel[props.colorsBgLevel!];
  }};
`;
export const StyledTitleChild = styled(Stack)`
  padding-left: 32px;
  align-items: start;
  min-width: 400px;
  width: 100%;
  height: 100%;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;
export const StyledContractStack = styled(Stack)`
  display: grid;
  grid-template-columns: repeat(3, minmax(165px, 265px));
`;

export const StyledFieldFormMin = styled(TextField)`
  width: 100%;
  height: 48px;
  position: relative;

  border-radius: 4px;

  span {
    line-height: 12px;
  }

  & .MuiFilledInput-root {
    border: none;
    background-color: #fff;
  }

  & .MuiInputBase-input {
    padding: 14px 11px;
    color: #2b3648;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    z-index: 1;

    &::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #ffffff;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #ffffff;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #ffffff;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #ffffff;
    }
  }

  .Mui-focused {
    .MuiInputBase-input {
      &::-webkit-input-placeholder {
        color: #ffffff;
      }
    }
  }

  & .MuiOutlinedInput-root {
    &:hover {
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid #0288d1;
      }
    }
  }
  & .Mui-focused {
    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid #0288d1;
    }
  }

  .Mui-error {
    .MuiInputBase-input {
      border: 1px solid red;
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: red;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: red;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: red;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: red;
      }
    }
  }

  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: 3px;
    left: 15px;
    color: #f46b6b;
    margin: 0;
    z-index: 0;
  }
  & .MuiFormHelperText-root.MuiFormHelperText-filled {
    bottom: 14px;
    left: 120px;
  }
`;
