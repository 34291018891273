import { VORGroupData } from 'api/calculations/types';
import { useMemo } from 'react';
import { getRowIdsByType } from './hooks.service';

export const useChildrenIds = (row: VORGroupData[] | undefined) => {
  const childrenIds = useMemo(
    () => (row ? getRowIdsByType(row, 'folded') : []),
    [row],
  );
  const groupIds = useMemo(
    () => (row ? getRowIdsByType(row, 'group') : []),
    [row],
  );

  return { childrenIds, groupIds };
};
