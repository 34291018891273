import React, { useCallback, useContext, useMemo } from 'react';
import {
  StyledEstimateRangeSelectItem,
  StyledEstimateRangeSelectPlaceholder,
} from '../../../EstimateRangeSelect/EstimateRangeSelect.styles';
import { Checkbox, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { BaseTextField } from '../../../../../../../../../../components/BaseTextField';
import {
  EstimateList,
  GetCoefficientsV3ActResponse,
  LimitedCostsV3,
} from '../../../../../../../../../../api/params/params.types';
import { LoaderContext } from '../../../ParametersForm';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

const SelectorChapterEstimate: React.FC<{
  field: LimitedCostsV3;
  name: string;
  index: number;
}> = ({ name, index }) => {
  const { control, getValues, setValue } =
    useFormContext<GetCoefficientsV3ActResponse>();

  const { isExecution } = useContext(LoaderContext);

  const { estimates } = useContext(LoaderContext);
  const field = useWatch({
    name: `limitedCosts.${name}.${index}` as 'limitedCosts.8.0',
    control,
  });
  const change = useCallback(
    (ids: (string | number)[]) => {
      let selected: EstimateList[] = getValues(
        `limitedCosts.${name}.${index}.estimates` as 'limitedCosts.8.0.estimates',
      );
      if (ids.includes('all')) {
        if (estimates.length === selected.length) {
          setValue(`limitedCosts.${name}.${index}.estimates`, [], {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          setValue(`limitedCosts.${name}.${index}.estimates`, estimates, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      } else {
        const updates = estimates.filter((_) => ids.includes(_.estimateID));

        setValue(`limitedCosts.${name}.${index}.estimates`, updates, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    },
    [field],
  );
  const idsEstimate = useMemo(() => {
    return field?.estimates.map((_) => _?.estimateID) || [];
  }, [field]);

  return (
    <Controller
      control={control}
      render={() => {
        return (
          <BaseTextField
            SelectProps={{
              renderValue: () => {
                const renderName =
                  field.estimates.length === estimates.length
                    ? `Для всех ${isExecution ? 'актов' : 'смет'}`
                    : field.estimates.reduce(
                        (acc, es, i) =>
                          (acc +=
                            i > 0 ? ', ' + es.estimateTitle : es.estimateTitle),
                        '',
                      );
                return !field.estimates.length ? (
                  <StyledEstimateRangeSelectPlaceholder>
                    Выберите {isExecution ? 'акт' : 'смету'}
                  </StyledEstimateRangeSelectPlaceholder>
                ) : (
                  renderName
                );
              },
              MenuProps: {
                sx: {
                  maxHeight: 500,
                },
              },
              displayEmpty: true,
              multiple: true,
              value: idsEstimate,
              onChange: ({ target: { value } }) => {
                change(value as (string | number)[]);
              },
            }}
            select>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              px={2}
              py={1}
              spacing={4}
              sx={{ pointerEvents: 'none' }}>
              <Typography variant="body1" color="secondary.gray">
                Выберите {isExecution ? 'акт' : 'смету'}
              </Typography>
              <Typography variant="body2" color="info.main" lineHeight="none">
                Выбрано: {field.estimates.length}
              </Typography>
            </Stack>
            <Divider />
            <MenuItem value={'all'}>
              <Checkbox checked={field.estimates.length === estimates.length} />
              Для всех {isExecution ? 'актов' : 'смет'}
            </MenuItem>
            {estimates.map((es, i) => {
              return (
                <StyledEstimateRangeSelectItem key={i} value={es.estimateID}>
                  <Checkbox checked={idsEstimate.includes(es.estimateID)} />
                  <Stack direction="row" spacing={0.5}>
                    <Typography>Смета:</Typography>
                    <Typography>{es.estimateTitle}</Typography>
                  </Stack>
                </StyledEstimateRangeSelectItem>
              );
            })}
          </BaseTextField>
        );
      }}
      name={`limitedCosts.${name}.${index}.estimates`}
    />
  );
};

export default SelectorChapterEstimate;
