import styled from 'styled-components';
import { Stack, TextField, MenuItem, Typography } from '@mui/material';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

export const StyledTypographyHelp = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: #0044b4;
  margin-right: 13px;
`;
export const StyledStack = styled(Stack)`
  display: flex;
  color: #5c6e8c;
  margin-right: 36px;
`;
export const StyledTypography = styled(Typography)`
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  color: #0044b4;
`;

export const StyledSelectField = styled(TextField)`
  background-color: ${(props) => props.theme.palette.bg.gray};

  & .MuiSelect-select {
    padding: 5px 16px;
    padding-right: 32px !important;
    padding-left: 10px;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
    font-family: Roboto;
    font-style: normal;
    line-height: 22px;
    letter-spacing: 0.17px;
    text-align: right;
    color: ${(props) => props.theme.palette.text.dark};
    background-color: ${(props) => props.theme.palette.bg.gray};
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${(props) => props.theme.palette.bg.gray};
  }

  & .MuiOutlinedInput-root {
    &:hover {
      & .MuiOutlinedInput-notchedOutline {
        border: 2px solid #0044b4;
      }
    }
  }

  & .MuiSvgIcon-root {
    right: 8px;
    color: ${(props) => props.theme.palette.text.dark};
    color: #0044b4;
    font-size: 22px;
  }

  & .MuiInputLabel-root {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.17px;
    color: ${(props) => props.theme.palette.disabled};
    transform: translate(14px, 5px) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(14px, -9px) scale(0.75);
    }
  }

  & .MuiFormHelperText-root {
    margin-right: 0;
    margin-left: 0;
    text-align: right;
  }
`;
export const StyledSelectMenuItem = styled(MenuItem)`
  max-width: 276px;

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  &.Mui-focusVisible {
    background-color: ${(props) => props.theme.palette.bg.gray};
  }
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  height: 36px;
  & .MuiToggleButton-root {
    border-radius: 6px;
  }
  & .MuiToggleButtonGroup-grouped {
    &:not(:first-of-type) {
      margin-left: 0px;
    }
  }
`;

export interface StyledToggleButtonProps {
  variant?: 'primary' | 'shades';
}

export const StyledToggleButton = styled(ToggleButton)<StyledToggleButtonProps>`
  padding: 7px;
  background-color: ${(props) => {
    return props.variant === 'shades' ? props.theme.palette.bg.shades : '';
  }};
  border: 1px solid #0044B4;

  &:hover {
    background-color: ${(props) => (props.variant === 'shades' ? props.theme.palette.bg.shades : '')};
  }

  &,
  & .MuiTypography-root {
    color: ${(props) => props.theme.palette.primary.main};
  }

  &.Mui-selected {
    background-color: ${(props) => props.theme.palette.bg.selected};

    &,
    & .MuiTypography-root {
      color: ${(props) => props.theme.palette.primary.main};
    }

    &:hover {
      background-color: ${(props) => (props.variant === 'shades' ? props.theme.palette.bg.shades : '')};
    }
  }

   &.Mui-disabled {
    border: 1px solid #0044B4;

    .MuiTypography-root {
      color: #9AA2B0;
    }
   }

}

`;
