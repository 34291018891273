import { useRenameLevelMutation } from 'api/calculations';
import { RenameLevelPayload } from './hooks.types';

export const useRenameLevel = () => {
  const [renameLevelMutation] = useRenameLevelMutation();

  const renameLevel = ({ calcID, groupID, title }: RenameLevelPayload) => {
    renameLevelMutation({
      params: {
        calcID,
      },
      body: {
        title,
        groupID,
      },
    });
  };

  return { renameLevel };
};
