import * as yup from 'yup';

export const validationNormativeRow = yup.object({
  hourlyCostGen: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+\s*)?([\.\,]\d{1,2})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
  hourlyCostSub: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+\s*)?([\.\,]\d{1,2})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
  machinesPerHourGen: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+\s*)?([\.\,]\d{1,2})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
  machinesPerHourSub: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+\s*)?([\.\,]\d{1,2})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
  peoplePerHourGen: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+\s*)?([\.\,]\d{1,2})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
  peoplePerHourSub: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+\s*)?([\.\,]\d{1,2})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
});
