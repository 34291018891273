/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import styled from 'styled-components';
import { NumericFormat } from 'react-number-format';
import { Autocomplete, Popper } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { IEstimateReference } from '../../../../../api/references/estimates/estimates.types';
import Button from '../../../../../components/Button';

export const NumericField = styled(NumericFormat)`
  all: unset;
  cursor: text;
  background-color: rgb(246, 247, 251);
  border-radius: 8px;
  box-sizing: border-box;
  //overflow: hidden;
  border: 1px solid ${({ theme: { palette } }) => palette.grey['300']};
  color: rgb(43, 54, 72);
  //font: inherit;
  //letter-spacing: inherit;
  //line-height: inherit;
  //box-sizing: border-box;
  height: auto;
  //-webkit-tap-highlight-color: transparent;
  font-size: 1.2em;
  line-height: 1em;
  display: block;
  max-width: 100%;
  width: 100%;
  //-webkit-animation-name: mui-auto-fill-cancel;
  //animation-name: mui-auto-fill-cancel;
  //-webkit-animation-duration: 10ms;
  //animation-duration: 10ms;
  //padding: 8.5px 14px;
  padding: 10px 14px;

  &:focus {
    outline: 2px solid ${({ theme: { palette } }) => palette.primary.main};
    outline-offset: -2px;
  }

  &:hover {
    border-color: ${({ theme: { palette } }) => palette.grey.A700};
  }
`;
export const PRNumericField = styled(NumericFormat)`
  border-bottom: 1px solid #0044b4 !important;
  all: unset;
  cursor: text;
  width: 100%;
  border-radius: 0;
  height: 100%;
  background-color: rgb(246, 247, 251);
  //border-radius: 8px;
  box-sizing: border-box;
  outline: none;
  //overflow: hidden;
  //border: 1px solid ${({ theme: { palette } }) => palette.grey['300']};
  color: rgb(43, 54, 72);
  //font: inherit;
  //letter-spacing: inherit;
  //line-height: inherit;
  //box-sizing: border-box;
  //height: auto;
  //-webkit-tap-highlight-color: transparent;
  font-size: 1.2em;
  line-height: 1em;
  display: block;
  max-width: 100%;
  //width: 100%;
  //-webkit-animation-name: mui-auto-fill-cancel;
  //animation-name: mui-auto-fill-cancel;
  //-webkit-animation-duration: 10ms;
  //animation-duration: 10ms;
  //padding: 8.5px 14px;
  padding: 10px 14px;

  &:focus {
    //outline: 2px solid ${({ theme: { palette } }) => palette.primary.main};
    outline-offset: -2px;
  }

  &:hover {
    border-color: ${({ theme: { palette } }) => palette.grey.A700};
  }
`;
export const Chip = styled.div`
  flex-shrink: 0;
  cursor: pointer;
  white-space: nowrap;
  grid-template-columns: min-content min-content min-content min-content;
  display: grid;
  align-items: center;
  padding: 6px 10px 6px 10px;
  border-radius: 20px;
  border: 1px solid #8cbbe8;
  gap: 8px;
  overflow: hidden;

  span {
    max-width: 148px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const Devide = styled.div`
  height: 8px;
  width: 2px;
  border-radius: 2px;
  background: #7a91f74d;
`;
export const ChipWrapper = styled.div<{ $padding?: boolean }>`
  padding: ${({ $padding }) => ($padding ? 8 : 0)}px 0;
  gap: 8px;
  color: #0044b4;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
  max-width: 1643px;

  svg {
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.palette.grey.A700};
    }
  }
`;
export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
`;
export const NoRowsTitle = styled.div`
  font-size: 18px;
  line-height: 1.2em;
`;
export const StyledPopper = styled(Popper)`
  color: white;
  background: #f46b6b;
  padding: 16px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
`;
export const AgWrapperPriceReference = styled(AgGridReact<IEstimateReference>)`
  --ag-cell-horizontal-border: 1px solid rgb(210, 220, 255);
`;
export const ImportButton = styled(Button)`
  background: ${({ disabled }) => (disabled ? 'inherit' : '#DCE5F0')};

  & > div {
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      path {
        fill: ${({ theme, disabled }) =>
          disabled ? theme.palette.disabled : theme.palette.primary.main};
      }
    }
  }
`;
