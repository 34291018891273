import {
  BasePricesItem,
  BasicLevel,
  BasicLevelType,
  BasicRowType,
} from './types';

export function changedCreateBasicLevel(
  state: BasicLevelType[],
  current: BasicLevel,
) {
  if (current.parent === null) {
    return state.push({ ...current, children: [] });
  }
  state.forEach((elem) => {
    if (elem.type === 'level') {
      if (elem.id === current.parent) {
        return elem.children.push({ ...current, children: [] });
      }
      return changedCreateBasicLevel(elem.children, current);
    }
  });
}

export function changedCreateComplexLevel(
  state: BasePricesItem[],
  current: BasePricesItem,
) {
  if (current.parent === null) {
    return state.push({ ...current, children: [] });
  }
  state.forEach((elem) => {
    if (elem.type === 'level') {
      if (elem.id === current.parent) {
        return elem.children.push({ ...current, children: [] });
      }
      return changedCreateComplexLevel(elem.children, current);
    }
  });
}

export function changedCreateBasicRow(
  state: BasicLevelType[],
  current: BasicRowType,
) {
  state.forEach((elem, index) => {
    if (elem.type === 'row') return elem;

    if (elem.id === current.parent) {
      elem.children.push({ ...current });
    }
    return changedCreateBasicRow(elem.children, current);
  });
}

export function changedCreateComplexRow(
  state: BasePricesItem[],
  current: BasePricesItem,
) {
  state.forEach((elem, index) => {
    if (elem.type === 'row') return elem;

    if (elem.id === current.parent) {
      elem.children.push({ ...current, children: [] });
    }
    return changedCreateComplexRow(elem.children, current);
  });
}

export function changedCopyBasicRow(
  state: BasicLevelType[],
  current: BasicRowType,
) {
  if (current.parent === null) {
    return state.push(current);
  }
  state.forEach((elem) => {
    if (elem.type === 'level') {
      if (elem.id === current.parent) {
        return elem.children.push({
          ...current,
        });
      }
      return changedCopyBasicRow(elem.children, current);
    }
  });
}

export function changedCopyComplexRow(
  state: BasePricesItem[],
  current: BasePricesItem,
) {
  if (current.parent === null) {
    return state.push(current);
  }
  state.forEach((elem) => {
    if (elem.type === 'level') {
      if (elem.id === current.parent) {
        return elem.children.push({
          ...current,
        });
      }
      return changedCopyComplexRow(elem.children, current);
    }
  });
}

export function changedEditRow(arr: BasicLevelType[], current: BasicRowType) {
  arr.forEach((elem, index) => {
    if (elem.id === current.id) {
      arr[index] = Object.assign({ ...elem }, { ...current });
    }
    if (elem.type === 'level') return changedEditRow(elem.children, current);
  });
}

export function changedComplexEditRow(
  arr: BasePricesItem[],
  current: BasePricesItem,
) {
  arr.forEach((elem, index) => {
    if (elem.id === current.id) {
      arr[index] = Object.assign({ ...elem }, { ...current });
    }
    if (elem.type === 'level')
      return changedComplexEditRow(elem.children, current);
  });
}

export function changedDeleteRow(
  state: BasicLevelType[],
  current: BasicRowType,
) {
  state.forEach((elem) => {
    if (elem.type === 'level') {
      if (elem.id === current.parent) {
        const deleteLevelindex = elem.children.findIndex((elem) => {
          return elem.id === current.id;
        });
        elem.children.splice(deleteLevelindex, 1);
      }
    }
    if (elem.id === current.id) {
      const deleteLevelindex = state.findIndex((elem) => {
        return elem.id === current.id;
      });
      state.splice(deleteLevelindex, 1);
    }

    if (elem.type === 'level') return changedDeleteRow(elem.children, current);
  });
}

export function changedSumBasicRow(
  state: BasicLevelType[],
  changed: BasicRowType[],
) {
  state.forEach((elem) => {
    if (elem.type === 'row') return elem;

    const findedRow = changed.find((row) => row.id === elem.id);
    if (findedRow) {
      elem.price = findedRow.price;
    }
    return changedSumBasicRow(elem.children, changed);
  });
}

export function changedComplexSumRow(
  state: BasePricesItem[],
  changed: BasePricesItem[],
) {
  state.forEach((elem, index) => {
    if (elem.type === 'row') return elem;
    const findedRow = changed.find((row) => row.id === elem.id);
    if (findedRow) {
      Object.assign(elem, { ...findedRow });
    }
    return changedComplexSumRow(elem.children, changed);
  });
}
