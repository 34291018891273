import { Button, Tab, Tabs } from '@mui/material';
import { Stack } from '@mui/system';
import { FC } from 'react';
import { UploadLSRFilesDrawer } from '../UploadLSRFilesDrawer';
import { topbarTabs } from './Topbar.constants';
import { TopbarProps } from './Topbar.types';

export const Topbar: FC<TopbarProps> = ({ value, onChange, open, setOpen }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      pt={1}
      mb={1}>
      <Tabs value={value} onChange={onChange}>
        {topbarTabs.map((tab) => (
          <Tab key={tab.value} value={tab.value} label={tab.label} />
        ))}
      </Tabs>
      <Button onClick={() => setOpen(true)}>Загрузить</Button>
      <UploadLSRFilesDrawer open={open} onClose={() => setOpen(false)} />
    </Stack>
  );
};
