import {
  MenuItem,
  formControlLabelClasses,
  inputBaseClasses,
  menuItemClasses,
  selectClasses,
} from '@mui/material';
import { ClearableTextField } from 'components/ClearableTextField';
import styled from 'styled-components';

export const StyledEstimateRangeSelect = styled(ClearableTextField)(
  ({ theme, value }) => ({
    [`& .${inputBaseClasses.root}`]: {
      paddingRight: value ? theme.spacing(1.125) : undefined,
    },

    [`& .${selectClasses.select}.${inputBaseClasses.inputAdornedEnd}`]: {
      paddingRight: value ? theme.spacing(1) : undefined,
    },

    [`& .${selectClasses.icon}`]: {
      display: value ? 'none' : 'block',
    },
  }),
);

export const StyledEstimateRangeSelectPlaceholder = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.5;
`;

export const StyledEstimateRangeSelectItem = styled(MenuItem)(({ theme }) => ({
  [`&.${menuItemClasses.selected}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${formControlLabelClasses.root}`]: {
    flexGrow: 1,
  },
}));
