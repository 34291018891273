import styled from 'styled-components';
import { Checkbox } from '@mui/material';

export const StyledCheckbox = styled(Checkbox)`
  &:not(.Mui-checked) .MuiSvgIcon-root {
    color: rgba(154, 162, 176, 0.5);
  }

  & .MuiSvgIcon-root {
    font-size: 18px;
    border-radius: 50%;
  }
`;
