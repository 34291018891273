import { Divider } from '@mui/material';
import styled from 'styled-components';

interface StyledDividerProps {
  $thickness: string;
  $rounded: boolean;
}

export const StyledDivider = styled(Divider)<StyledDividerProps>(
  ({ theme, color, orientation, $thickness, $rounded }) => ({
    borderColor: color ?? theme.palette.legends.divider,
    borderBottomWidth: orientation === 'horizontal' ? $thickness : 0,
    borderRightWidth: orientation === 'vertical' ? $thickness : 0,
    borderRadius: $rounded ? '1px' : 0,
  }),
);
