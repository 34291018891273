import { FC } from 'react';
import { StyledBaseTextField } from './BaseTextField.styles';
import { BaseTextFieldProps } from './BaseTextField.types';

export const BaseTextField: FC<BaseTextFieldProps> = ({
  textAlign,
  ...props
}): JSX.Element => {
  return (
    <StyledBaseTextField
      size="small"
      $textAlign={textAlign}
      hiddenLabel
      {...props}
    />
  );
};
