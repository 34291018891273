import { Typography } from '@mui/material';
import { FC } from 'react';
import { StyledNoRowWrapper } from './NoRowsOverlay.styles';

export const NoRowsOverlay: FC = () => {
  return (
    <StyledNoRowWrapper>
      <Typography variant="body2" color="legends.disabled">
        Нет данных для отображения. Не выбран интервал формирования ведомостей
      </Typography>
    </StyledNoRowWrapper>
  );
};
