import { Box, Tab, Tabs as MuiTabs } from '@mui/material';
import React, { ReactNode } from 'react';
import { TabsEntities, TabsProps } from './Tabs.types';

export const Tabs = <T extends TabsEntities>({
  currentTab,
  onTabChange,
  tabsData,
}: TabsProps<T> & { children?: ReactNode }) => {
  return (
    <Box>
      <MuiTabs value={currentTab} onChange={onTabChange}>
        {tabsData.map(({ value, label }) => (
          <Tab label={label} value={value} sx={{ p: 1 }} key={value} />
        ))}
      </MuiTabs>
    </Box>
  );
};
