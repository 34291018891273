import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { LegendColors } from 'types';

interface StyledBoxProps {
  bgColor: LegendColors;
}

export const StyledBox = styled(Box)<StyledBoxProps>`
  display: flex;
  width: fit-content;
  margin-bottom: ${(props) => props.theme.spacing(1)};
  padding: 5px 8px 3px;
  background-color: ${({ bgColor, theme }) => theme.palette.legends[bgColor]};
  border-radius: 4px;
`;

export const StyledTypography = styled(Typography)`
  font-weight: 500;
  color: ${(props) => props.theme.palette.text.white};
`;
