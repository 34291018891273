/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import React, { useEffect, useMemo, useState } from 'react';
import { Box, Checkbox, Dialog } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { ListItem, ListWrapper, Text, Title, Wrapper } from './dialog.styles';
import SearchIcon from '@mui/icons-material/Search';
import { BaseTextField } from '../../../../../../components/BaseTextField';
import Button from '../../../../../../components/Button';
import { DrawerType, filterView } from '../../PositionsTable';
import {
  CommonFiltersResponse,
  Filter,
  FiltersVorBody
} from '../../../../../../api/calculations/types';
import { useCalcId } from '../../../../../../hooks/useCalcId';

type Props = {
  open: DrawerType;
  close: () => void;
  view: Filter[];
  checkFilter: (
    type: keyof FiltersVorBody,
    key: keyof CommonFiltersResponse,
    item: Filter | Filter[] | null,
    flag?: boolean
  ) => void;
  handleApplyFilters: () => void;
  type: keyof FiltersVorBody;
  checkedData: Record<
    keyof FiltersVorBody,
    Record<keyof CommonFiltersResponse, Filter[]>
  >;
};

const title: Record<Exclude<DrawerType, null>, string> = {
  title: 'Наименование',
  chapter: 'Раздел',
  lsr: 'Шифр ЛСР',
  code: 'Код расценки',
  header: 'Заголовок',
  number: '№ по ЛСР'
};

const DialogFilters: React.FC<Props> = ({
  close,
  open,
  checkedData,
  view,
  checkFilter,
  handleApplyFilters,
  type
}) => {
  const calcID = useCalcId();
  const [search, setSearch] = useState('');
  const filter = (data: Filter, s: string) => {
    const lowerSearch = s?.toLowerCase();
    return data?.title?.toLowerCase()?.includes(lowerSearch);
  };
  const currentState = useMemo(() => {
    return (view || []).filter((item) => filter(item, search));
  }, [open, search, calcID]);

  useEffect(() => {
    if (open === null) {
      setSearch('');
    }
  }, [open]);

  const selectedLength = useMemo(() => {
    if (!open || (open && !checkedData[type][filterView[open]].length))
      return '';
    const list = checkedData[type][filterView[open]];

    const uniqList = Array.from(new Set(list.map((item) => item.title)));

    return ` ${uniqList.length}`;
  }, [checkedData, type, filterView, open]);

  return (
    <Dialog open={!!open} maxWidth={false} onClose={close}>
      <Wrapper>
        <Title>
          <FilterAltIcon fontSize={'small'} />
          {open && title[open]}
        </Title>
        <BaseTextField
          placeholder={'Поиск'}
          InputProps={{
            startAdornment: <SearchIcon style={{ marginRight: '10px' }} />
          }}
          sx={{
            '.MuiInputBase-root': {
              background: 'none !important'
            }
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Box
          display={'flex'}
          alignItems={'stretch'}
          justifyContent={'flex-start'}
          gap={'10px'}>
          <Button
            // disabled={!currentState?.length}
            variant={'text'}
            onClick={() => {
              open && checkFilter(type, filterView[open], currentState);
            }}>
            Выбрать все
          </Button>
          <Button
            // disabled={!activeFilters[variant as keyof typeof activeFilters]?.length}
            variant={'text'}
            onClick={() => {
              open && checkFilter(type, filterView[open], null);
            }}>
            Сбросить
            {open && selectedLength}
          </Button>
        </Box>
        <ListWrapper>
          {currentState.map((item) => {
            return (
              <ListItem
                key={item.ids.join(',')}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (open) {
                    checkFilter(type, filterView[open], item);
                  }
                  //   variant &&
                  //   (!calcID
                  //     ? dispatch(
                  //       setFilter({
                  //         type: variant,
                  //         id: item.id ?? item.rowID ?? item.groupID,
                  //         name: 'name' in item ? item?.name || '' : item?.title || '',
                  //       }),
                  //     )
                  //     : dispatch(
                  //       setFilterCalc({
                  //         type: variant,
                  //         id: item.id ?? item.rowID ?? item.groupID,
                  //         name: 'name' in item ? item?.name || '' : item?.title || '',
                  //       }),
                  //     ));
                }}>
                <Checkbox
                  checked={
                    open
                      ? checkedData[type][filterView[open]]
                          .map((_) => _.title)
                          ?.includes(item.title) || false
                      : false
                  }
                  // checked={
                  //   activeFilters?.[variant as keyof typeof activeFilters]?.findIndex((_) => _.id === item?.id||_.id === item?.groupID) >= 0
                  // }
                />
                <Text>
                  <span>{item.title ? item.title : 'Пустые'}</span>
                  {/*<SmallText>подпись под основным элементом</SmallText>*/}
                </Text>
              </ListItem>
            );
          })}
        </ListWrapper>
        <Box display={'flex'} gap={'10px'}>
          <Button
            // disabled={checkPreviousState}
            onClick={() => {
              handleApplyFilters();
              close();
            }}>
            применить
          </Button>
          <Button
            color={'error'}
            sx={{ color: 'white' }}
            // disabled={!activeFilters[variant as keyof typeof activeFilters]?.length || !currentState?.length}
            onClick={close}>
            Закрыть
          </Button>
        </Box>
      </Wrapper>
    </Dialog>
  );
};

export default DialogFilters;
