import { useContext } from 'react';
import {
  WSCalcContext,
  WSContext,
  WSHandbookContext,
  WSHandbookCopyContext,
  WSLSRContext
} from '.';

export const useWS = () => {
  const context = useContext(WSContext);
  if (!context) {
    throw new Error('useWS must be used within an ProviderWS');
  }
  return context;
};

export const useWSLSR = () => {
  const context = useContext(WSLSRContext);
  if (!context) {
    throw new Error('useWSLSR must be used within an ProviderWS');
  }
  return context;
};

export const useWSHCopy = () => {
  const context = useContext(WSHandbookCopyContext);
  if (!context) {
    throw new Error('useWSLSR must be used within an ProviderWS');
  }
  return context;
};

export const useWSHandbook = () => {
  const context = useContext(WSHandbookContext);
  if (!context) {
    throw new Error('useWSHandbook must be used within an ProviderWS');
  }
  return context;
};

export const useWSCalc = () => {
  const context = useContext(WSCalcContext);
  if (!context) {
    throw new Error('useWSCalc must be used within an ProviderWS');
  }
  return context;
};
