import { getWordWithEnding } from 'utils/getWordWithEnding';

export function getGroupTotalCountMessage(
  description: string,
  childrenCount: number,
  groupCount: number,
) {
  const group =
    groupCount !== 0
      ? getWordWithEnding(groupCount, 'уровень', 'уровня', 'уровней')
      : '';
  const children =
    childrenCount !== 0
      ? getWordWithEnding(childrenCount, 'позиция', 'позиции', 'позиций')
      : '';

  const joinResult = [group, children].filter(Boolean).join(' и ');

  return joinResult ? `${description}: ${joinResult}` : '';
}
