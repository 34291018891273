import { FC } from 'react';
import { StyledTypeChip } from './FileTypeChip.styles';
import { FileTypeChipProps } from './FileTypeChip.types';
import { fileTypeNames } from './FileTypeChip.service';

export const FileTypeChip: FC<FileTypeChipProps> = ({
  type,
  ...rest
}): JSX.Element => {
  return <StyledTypeChip label={fileTypeNames[type]} size="small" {...rest} />;
};
