import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../core/config';
import { setCredentials } from '../store/slices/auth';
import { RootState } from '../store/store';
import { LoginResponse } from './auth/types';

const { REACT_APP_BASE_API_URL } = process.env;

export const baseQuery = fetchBaseQuery({
  // baseUrl: REACT_APP_BASE_API_URL,
  baseUrl: config.apiHost,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    if (token && !headers.has('authorization')) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  }
});

export const api = createApi({
  baseQuery: async (args, queryApi, extraOptions) => {
    const { dispatch, getState } = queryApi;
    let result = await baseQuery(args, queryApi, extraOptions);

    const statusCode = ((result?.error as any)?.originalStatus as number) || 0;

    if (result.error && result.error.status === 401) {
      const refreshToken = (getState() as RootState).auth.refreshToken;

      const refreshResult = await baseQuery(
        { url: '/auth/refresh', method: 'POST', body: { token: refreshToken } },
        queryApi,
        extraOptions
      );
      if (refreshResult.data) {
        dispatch(setCredentials(refreshResult.data as LoginResponse));
        result = await baseQuery(args, queryApi, extraOptions);
      } else {
        dispatch(setCredentials({}));
      }
    }
    return result;
  },
  tagTypes: [
    'Profile',
    'ProfileByCompany',
    'Users',
    'Companies',
    'Projects',
    'ShortProjects',
    'ProjectDashboard',
    'LSR',
    'Calculations',
    'CalculationByID',
    'DeleteCalculations',
    'EditCalculation',
    'CopyCalculation',
    'RowsList',
    'ComplexList',
    'ObjectsByProject',
    'СomplicatedList',
    'VORList',
    'Resources',
    'Params',
    'ActList',
    'HandbookExecutionList',
    'ExecutionList',
    'DynamicRows',
    'PriceReference',
    'PriceReferenceWithFilters',
    'PriceReferenceUnit',
    'PriceReferenceKFOGroup',
    'CredentialsTangle',
    'StatusConnectTangl',
    'ConnectTangl',
    'CalculationDictionary',
    'CalculationCoefficients',
    'HandbookVor'
  ],
  endpoints: () => ({})
});
