import { Box, Stack } from '@mui/material';
import { useRef } from 'react';
import { theme } from '../../../../../../styles/theme';
import {
  BoxBorderStyled,
  StyledHeadRow,
  StyledHeadTd,
  StyledTableBody,
  StyledTableContainer,
  StyledTableHead,
} from './styles';

import { CalcData } from 'types';
import { useGetComplexRowsListQuery } from '../../../../../../api/calculations';
import { ContractNameType } from '../../../../../Calculations/components/CalculationComplex/index';
import { СoefficientRow } from './components/СoefficientRow';

export type СoefficientsPropsType = {
  currentContract: ContractNameType;
  calculation: CalcData;
};

export interface CoefficientNameItemType {
  coefficientName: string;
  coefficientTitle: string;
  childCoefficient?: string;
}

const coefficientsNameList: CoefficientNameItemType[] = [
  {
    coefficientName: 'mainSalary',
    coefficientTitle: 'Основная заработная плата',
  },
  {
    coefficientName: 'mimExploitation',
    coefficientTitle: 'Эксплуатация машин и механизмов, в том числе:',
  },
  {
    coefficientName: 'machineOperatorSalary',
    coefficientTitle: 'Заработная плата механизаторов',
    childCoefficient: '0px',
  },
  { coefficientName: 'materials', coefficientTitle: 'Материалы, в том числе:' },
  {
    coefficientName: 'basicMaterials',
    coefficientTitle: 'Основные',
    childCoefficient: '0px',
  },
  {
    coefficientName: 'auxiliaryMaterials',
    coefficientTitle: 'Вспомогательные',
    childCoefficient: '0px',
  },
  { coefficientName: 'equipment', coefficientTitle: 'Оборудование' },
  { coefficientName: 'overheads', coefficientTitle: 'Накладные расходы' },
  { coefficientName: 'estimatedProfit', coefficientTitle: 'Сметная прибыль' },
];

export interface Coefficient {
  [key: string]: string;
}

export const Сoefficients: React.FC<СoefficientsPropsType> = ({
  currentContract,
  calculation,
}) => {
  const { createdAt, id: calculationId } = calculation;

  const colorTheme = theme.palette;

  /*****************************Complex List****************************************/
  const {
    data: complexData,
    isFetching,
    isLoading,
  } = useGetComplexRowsListQuery({ id: calculationId });

  const isDataLoading = isFetching || isLoading;

  const coefficientsData = complexData?.coefficients; //? coefficientsData?.coefficients : {};

  /********************************* Scroll Body & Head ****************************************/

  const headRef = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLTableRowElement | null>(null);

  const handleScrollHead = (scroll: React.UIEvent<HTMLElement>): void => {
    if (bodyRef.current === null) return;
    const target = scroll.target as HTMLDivElement;
    bodyRef.current.scroll({ left: target.scrollLeft });
  };
  const handleScrollBody = (scroll: React.UIEvent<HTMLElement>): void => {
    if (headRef.current === null) return;
    const target = scroll.target as HTMLDivElement;
    headRef.current.scroll({ left: target.scrollLeft });
  };

  /****************************************************************************************/

  return (
    <StyledTableContainer className="Complex-Corfficients">
      <StyledTableHead
        className="head"
        ref={headRef}
        onScroll={handleScrollHead}>
        <StyledHeadRow direction="row" className="head-row">
          <Box sx={{ minWidth: '32px' }}></Box>
          <StyledHeadTd
            style={{
              minWidth: '400px',
              width: '100%',
              color: colorTheme.secondary.gray,
            }}>
            Наименование
          </StyledHeadTd>
          <Stack direction="row" sx={{ width: '100%' }}>
            <StyledHeadTd sx={{ minWidth: '600px', width: '100%' }}>
              Генподряд
            </StyledHeadTd>
            <StyledHeadTd sx={{ minWidth: '600px', width: '100%' }}>
              Субподряд
            </StyledHeadTd>
          </Stack>
          <Box sx={{ minWidth: '32px' }}></Box>
          <StyledHeadTd
            style={{ minWidth: 13, color: colorTheme.secondary.gray }}>
            s
          </StyledHeadTd>
        </StyledHeadRow>
      </StyledTableHead>
      <StyledTableBody
        className="body"
        ref={bodyRef}
        onScroll={handleScrollBody}>
        <BoxBorderStyled className="border">
          {coefficientsNameList.map((coefficientNameItem, index) => {
            return (
              <СoefficientRow
                key={index}
                calculationId={calculationId}
                coefficientNameItem={coefficientNameItem}
                coefficients={coefficientsData!}
              />
            );
          })}
        </BoxBorderStyled>
      </StyledTableBody>
    </StyledTableContainer>
  );
};
