import React, { useEffect, useMemo } from 'react';
import { AppContextType, AppProvider } from '../Layout/context';
import { useGetProfileQuery } from '../../api/profile';
import Progress from '../../components/Progress';

interface AppLayoutProps {
  onLogout: () => void;
  children: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ onLogout, children }) => {
  const {
    data: profile,
    error: profileError,
    isLoading: profileIsLoading,
  } = useGetProfileQuery();

  const value = useMemo<AppContextType>(() => {
    return {
      profile: profileError
        ? ({} as AppContextType['profile'])
        : profile || ({} as AppContextType['profile']),
      currentCompanyId: profile?.company?.companyID || 0,
      onLogout,
    };
  }, [profile, profileError, onLogout]);

  const isLoading = profileIsLoading;

  return (
    <AppProvider value={value}>
      {isLoading ? <Progress /> : children}
    </AppProvider>
  );
};

export default AppLayout;
