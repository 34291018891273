import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledFileItem = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.bg.gray}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1.25),
}));

export const StyledFileName = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
`;
