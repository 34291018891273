/**
 * @author Mr_FabiozZz[fabiozzz.dev@gmail.com]
 */
import SearchIcon from '@mui/icons-material/Search';
import { Box, Typography } from '@mui/material';
import { ICalculationDictionary } from 'api/calculationDictionary';
import { IEstimateReference } from 'api/references/estimates/estimates.types';
import { BaseTextField } from 'components/BaseTextField';
import Button from 'components/Button';
import { Drawer } from 'components/Drawer';
import { useDebounce } from 'hooks/useDebounce';
import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLazyGetEstimateReferenceQuery } from '../../../../../../../../api/references/estimates';
import { PopperComponent } from '../../../../../../../Administration/AdminReferences/Prices/useTableData';
import { CalculationDirectoryContext } from '../../CalculationTab';
import { DelimetrRow, Table } from '../../Parameters/Parameters.styles';
import ListReference from './components/ListReference';
import Stepper from '../../../../../../../../components/Stepper';

type TDrawerInsertContext = {
  currentDepth: number;
  isFirstLoad: boolean;
};

export const DrawerInsertContext = createContext<TDrawerInsertContext>({
  currentDepth: 1,
  isFirstLoad: true
});

const DrawerInsertRow: React.FC<{
  createDrawer: boolean;
  close: () => void;
  isRoot: boolean;
}> = memo(({ createDrawer, close, isRoot }) => {
  const { selectRow, tableData } = useContext(CalculationDirectoryContext);
  const [fetch, { data: referenceData }] = useLazyGetEstimateReferenceQuery({});

  const maxLevel = React.useRef(1);
  const firstLoad = React.useRef(true);

  const [currentDepth, setCurrentDepth] = useState(1);

  const changeDepth = useCallback((depth: number) => {
    setCurrentDepth(depth);
  }, []);

  useEffect(() => {
    if (createDrawer) fetch({});
  }, [createDrawer]);

  const methods = useForm<{ search: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      search: ''
    }
  });

  const [startTable, setStartTable] = useState<IEstimateReference[]>([]);
  const [filteredTable, setFilteredTable] = useState<IEstimateReference[]>([]);

  const debounceSearch = useDebounce(methods.watch('search'));

  const table = useMemo(() => {
    return debounceSearch.length
      ? filterTree(startTable, debounceSearch)
      : startTable;
  }, [startTable, debounceSearch]);
  const getSubTitle = useCallback(
    (rowData: ICalculationDictionary | null): string => {
      if (rowData === null) return '';
      let folder: ICalculationDictionary | undefined;
      if (rowData.rowType === 'FOLDER') {
        folder = rowData;
      } else {
        const f = tableData?.find((el) => el.id === rowData.parentID);
        return getSubTitle(f ?? null);
      }
      return `в папку ${folder?.lvl}-го уровня`;
    },
    [tableData]
  );
  const filterData = (data: IEstimateReference[], title: string) => {
    const copyData = JSON.parse(JSON.stringify(data)) as IEstimateReference[];
    return copyData.filter((o) => {
      if (o.children) o.children = filterData(o.children, title);
      return o.title.includes(title);
    });
  };

  function filterTree(
    tree: IEstimateReference[],
    target: string
  ): IEstimateReference[] {
    const copyTree = JSON.parse(JSON.stringify(tree)) as IEstimateReference[];
    return copyTree
      .map((node) => {
        node.children = filterTree(node.children, target);
        return node;
      })
      .filter((node) => {
        // console.log(node.title);
        return (
          node.title.toLowerCase().includes(target.toLowerCase()) ||
          (node.children && node.children.length)
        );
      });
    // .filter((node) => node.title.includes(target) || (node.children && node.children.length > 0));
  }

  // useEffect(() => {
  //   if (debounceSearch) {
  //     setFilteredTable(filterTree(startTable, debounceSearch));
  //   } else {
  //     console.log('clear filtered data');
  //     setFilteredTable([]);
  //   }
  // }, [debounceSearch, startTable]);

  const mutateData = (data: IEstimateReference[]) => {
    const copyReference = JSON.parse(
      JSON.stringify(data)
    ) as IEstimateReference[];
    return copyReference.map((d) => {
      if (d.lvl && d.lvl > maxLevel.current && d.rowType !== 'RATE_POSITION') {
        maxLevel.current = d.lvl;
      }
      d.title =
        (d.code ? d.code : '') +
        (d.title?.length ? (d.code ? ' ' : '') + d.title : '');
      if (d.children?.length) {
        d.children = mutateData(d.children);
      }
      return d;
    });
    // return (copyReference || [])
    //   ?.filter((d) => d.lvl === 1)
    //   ?.map((d) => {
    //     d.title = d.code + ' ' + d.title;
    //     return d;
    //   });
  };

  useLayoutEffect(() => {
    if (referenceData) {
      firstLoad.current = false;
      setStartTable(mutateData(referenceData.filter((d) => d.lvl === 1)));
    }
  }, [referenceData]);

  function spyEscape(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      close();
    }
  }

  useEffect(() => {
    if (createDrawer) {
      document.body.addEventListener('keydown', spyEscape);
    } else {
      methods.setValue('search', '', { shouldValidate: true });
      setFilteredTable([]);
      document.body.removeEventListener('keydown', spyEscape);
    }
  }, [createDrawer]);

  // console.log('log table', maxLevel.current, table);

  useEffect(() => {
    if (!firstLoad.current) {
    }
  }, [currentDepth]);

  return (
    <Drawer
      open={createDrawer}
      // onClose={close}
      title={'Заполнить расчет из справочника ' + getSubTitle(selectRow)}
      PaperProps={{ sx: { width: '74vw' } }}>
      <DrawerInsertContext.Provider
        value={{ currentDepth, isFirstLoad: firstLoad.current }}>
        <Box
          height={'100%'}
          display={'grid'}
          gridTemplateColumns={'1fr'}
          gridTemplateRows={'min-content min-content 1fr 40px'}
          gap={'12px'}
          padding={'12px 30px'}>
          <Controller
            rules={{
              validate: {
                length(v) {
                  if (v && v.length > 300) {
                    return 'Поле "Поиск" не более 300 символов';
                  }
                  return true;
                }
              }
            }}
            control={methods.control}
            name={'search'}
            render={({ field, fieldState, formState }) => {
              return (
                <>
                  <BaseTextField
                    placeholder={'Поиск'}
                    InputProps={{
                      startAdornment: (
                        <SearchIcon style={{ marginRight: '10px' }} />
                      )
                    }}
                    sx={{
                      '.MuiInputBase-root': {
                        background: 'none !important'
                      }
                    }}
                    {...field}
                    // value={search}
                    onChange={(e) => {
                      methods.setValue('search', e.target.value, {
                        shouldValidate: true
                      });
                    }}
                  />
                  <PopperComponent
                    offset={[-35, -100]}
                    message={fieldState.error?.message}
                    refItem={document.body}
                  />
                </>
              );
            }}
          />
          <Box overflow={'hidden'}>
            {/*<Table grid={'36.8% 5.6% 5.6% 7.5% 7.5% 7.5% 7.5% 11% 4%'}>*/}
            {table.length ? (
              <Table grid={'10fr 1fr 1fr 2fr 2fr 2fr 2fr 3fr 1fr'}>
                <Typography whiteSpace={'nowrap'} variant={'body2'}>
                  Наименование
                </Typography>
                <Typography
                  gridArea={'1 / 2 / 1 / span 2 '}
                  whiteSpace={'nowrap'}
                  variant={'body2'}
                  align={'center'}>
                  Расход ресурса
                </Typography>
                <Typography whiteSpace={'nowrap'} variant={'body2'}>
                  ПЗ
                </Typography>
                <Typography whiteSpace={'nowrap'} variant={'body2'}>
                  ФОТ ОР
                </Typography>
                <Typography whiteSpace={'nowrap'} variant={'body2'}>
                  МиМ
                </Typography>
                <Typography whiteSpace={'nowrap'} variant={'body2'}>
                  МТР
                </Typography>
                <div style={{ gridArea: '1 / 8 / 2 / 10' }}>
                  <Stepper
                    onChange={(ev, page) => {
                      changeDepth(page);
                    }}
                    count={maxLevel.current}
                    size={'small'}
                  />
                </div>
                {/*<div></div>*/}
                <DelimetrRow gridArea={true} />
              </Table>
            ) : (
              <Box gridArea={'1 / 1 / 1 / span 9 '} />
            )}
          </Box>
          <Box sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
            {/*<Table grid={'36.8% 5.6% 5.6% 7.5% 7.5% 7.5% 7.5% 11% 4%'}>*/}
            <Table grid={'1fr'}>
              {debounceSearch.length ? (
                table.length ? (
                  <ListReference
                    key={'filter'}
                    table={table}
                    defaultOpenRow={true}
                    isRoot={true}
                  />
                ) : (
                  <Typography
                    align={'center'}
                    gridArea={'1 / 1 / 1 / span 9 '}
                    variant={'subtitle2'}>
                    Нет результатов
                  </Typography>
                )
              ) : table.length ? (
                <ListReference
                  key={'start'}
                  table={table}
                  defaultOpenRow={false}
                  isRoot={true}
                />
              ) : (
                <Typography
                  align={'center'}
                  gridArea={'1 / 1 / 1 / span 9 '}
                  variant={'subtitle2'}>
                  Нет записей в справочнике
                </Typography>
              )}
            </Table>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}>
            <Button onClick={close}>Закрыть</Button>
          </Box>
        </Box>
      </DrawerInsertContext.Provider>
    </Drawer>
  );
});
DrawerInsertRow.displayName = 'DrawerInsertRow';

export default DrawerInsertRow;
