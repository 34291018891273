import React from 'react';
import { fullVorColumns, fullVorColumnsHandbook } from '../../Table.service';
import { RowMenu } from '../TreeNodeRow/components';
import { HeadStyled, HeadTdStyled } from './Head.styles';

export const Head: React.FC<{ isHandbook: boolean }> = ({ isHandbook }) => {
  const columns = React.useMemo(() => {
    return isHandbook ? fullVorColumnsHandbook : fullVorColumns;
  }, [isHandbook]);
  return (
    <HeadStyled $isHandbook={isHandbook}>
      <HeadTdStyled>
        <RowMenu isHandbook={isHandbook} />
      </HeadTdStyled>
      <HeadTdStyled>{isHandbook ? '№ п/п' : '№'}</HeadTdStyled>
      {columns.map((column) => (
        <HeadTdStyled key={column.id} $align={column.align}>
          {column.title}
        </HeadTdStyled>
      ))}
    </HeadStyled>
  );
};
