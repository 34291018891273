import React from 'react';
import {
  Pagination,
  PaginationProps,
  PaginationRenderItemParams
} from '@mui/material';
import PaginationItem from '@mui/material/PaginationItem';
import Tooltip from '../Tooltip';

const buttonsKeys: Record<PaginationRenderItemParams['type'], string> = {
  first: 'Свернуть все уровни',
  last: 'Развернуть все уровни',
  next: 'Развернуть один уровень',
  previous: 'Свернуть один уровень',
  page: '',
  'end-ellipsis': '',
  'start-ellipsis': ''
};

const Stepper: React.FC<PaginationProps> = (props) => {
  return (
    <Pagination
      count={10}
      siblingCount={0}
      boundaryCount={0}
      showFirstButton
      showLastButton
      hidePrevButton={false}
      hideNextButton={false}
      renderItem={(item) => {
        const tooltipTitle = buttonsKeys[item.type];
        return (
          <Tooltip title={tooltipTitle}>
            <PaginationItem {...item} />
          </Tooltip>
        );
      }}
      sx={{
        '& .MuiPaginationItem-ellipsis': {
          display: 'none'
        },
        '& .MuiPaginationItem-page:not(.Mui-selected)': {
          display: 'none'
        },
        '& .Mui-selected': {
          background: 'transparent !important'
        }
      }}
      {...props}
    />
  );
};

export default Stepper;
