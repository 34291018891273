import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Tooltip } from '@mui/material';
import { theme } from '../../../../../../../../styles/theme';
import {
  StyledIconButton,
  StyledLevel,
  StyledLevelTd,
  StyledTitle,
  StyledTypography
} from '../styles';

import { Fragment, useEffect, useMemo, useState } from 'react';
import { LevelNameTypeEn } from 'types';
import {
  BasePricesItem,
  Coefficients
} from '../../../../../../../../api/calculations/types';
import { LevelNameShowType } from '../../../../index';
import { LevelIcon } from '../../../../../LevelIcon';
import { EstimateRow } from '../EstimateRow';

interface EstimateLevelPropsType {
  levelNumber: number;
  calculationId: number;
  basePricesLevel: BasePricesItem;
  coefficients: Coefficients;
  isShowGenContract: boolean;
  isShowSubContract: boolean;
  isShowAnalysis: boolean;
  countRepeat: string;
  levelNameCurrent: LevelNameShowType[];
}

export const EstimateLevel: React.FC<EstimateLevelPropsType> = ({
  levelNumber,
  calculationId,
  basePricesLevel,
  coefficients,
  isShowGenContract,
  isShowSubContract,
  isShowAnalysis,
  countRepeat,
  levelNameCurrent
}) => {
  const levelName: LevelNameTypeEn = `level${levelNumber}` as LevelNameTypeEn;

  const colorText = theme.palette.text;

  /************************************ Show/Hide Nested Row ************************************/
  const [isShowNestedLevel, setIsShowNestedLevel] = useState({
    name: levelName,
    show: false
  }); // вложенный

  useEffect(() => {
    console.log(levelNameCurrent);
    const isEntry = levelNameCurrent.findIndex(
      (item) => item.name === isShowNestedLevel.name
    );
    if (!(isEntry >= 0)) {
      setIsShowNestedLevel((prevState) => {
        return {
          ...prevState,
          show: false
        };
      });
    }
    levelNameCurrent?.forEach((element) => {
      console.log(element.name, isShowNestedLevel.name);
      if (element.name === isShowNestedLevel.name) {
        setIsShowNestedLevel((prevState) => {
          return {
            ...prevState,
            show: element.show
          };
        });
      }
    });
  }, [levelNameCurrent]);

  const handleShowNestedLevel = () => {
    setIsShowNestedLevel((prevState) => {
      return {
        ...prevState,
        show: !prevState.show
      };
    });
  };

  const {
    peoplePerHourGen = 0, // чел в час
    machinesPerHourGen = 0, //  машин час
    // hourlyCostGen = 0,
    peoplePerHourSub = 0,
    machinesPerHourSub = 0
    // hourlyCostSub = 0,
  } = basePricesLevel; // Норматив трудоемкость
  const {
    mainSalary,
    mimExploitation,
    // materials,
    basicMaterials,
    auxiliaryMaterials,
    equipment,
    overheads,
    estimatedProfit,
    machineOperatorSalary
  } = coefficients!;

  /************************************** Out number *****************************************/

  const outMainSalaryGen =
    basePricesLevel.mainSalary *
    (mainSalary.firstGen ?? 1) *
    (mainSalary.secondGen ?? 1) *
    (mainSalary.thirdGen ?? 1);

  const outMainSalarySub =
    basePricesLevel.mainSalary *
    (mainSalary.firstSub ?? 1) *
    (mainSalary.secondSub ?? 1) *
    (mainSalary.thirdSub ?? 1);

  const outMimExploitationGen =
    basePricesLevel.mimExploitation *
    (mimExploitation.firstGen ?? 1) *
    (mimExploitation.secondGen ?? 1) *
    (mimExploitation.thirdGen ?? 1);

  const outMimExploitationSub =
    basePricesLevel.mimExploitation *
    (mimExploitation.firstSub ?? 1) *
    (mimExploitation.secondSub ?? 1) *
    (mimExploitation.thirdSub ?? 1);

  const outMachineOperatorSalaryGen =
    basePricesLevel.machineOperatorSalary *
    (machineOperatorSalary.firstGen ?? 1) *
    (machineOperatorSalary.secondGen ?? 1) *
    (machineOperatorSalary.thirdGen ?? 1);

  const outMachineOperatorSalarySub =
    basePricesLevel.machineOperatorSalary *
    (machineOperatorSalary.firstSub ?? 1) *
    (machineOperatorSalary.secondSub ?? 1) *
    (machineOperatorSalary.thirdSub ?? 1);

  const outBasicMaterialsGen =
    basePricesLevel.basicMaterials *
    (basicMaterials.firstGen ?? 1) *
    (basicMaterials.secondGen ?? 1) *
    (basicMaterials.thirdGen ?? 1);

  const outBasicMaterialsSub =
    basePricesLevel.basicMaterials *
    (basicMaterials.firstSub ?? 1) *
    (basicMaterials.secondSub ?? 1) *
    (basicMaterials.thirdSub ?? 1);

  const outAuxiliaryMaterialsGen =
    basePricesLevel.auxiliaryMaterials *
    (auxiliaryMaterials.firstGen ?? 1) *
    (auxiliaryMaterials.secondGen ?? 1) *
    (auxiliaryMaterials.thirdGen ?? 1);

  const outAuxiliaryMaterialsSub =
    basePricesLevel.auxiliaryMaterials *
    (auxiliaryMaterials.firstSub ?? 1) *
    (auxiliaryMaterials.secondSub ?? 1) *
    (auxiliaryMaterials.thirdSub ?? 1);

  const outMaterialsGen = outBasicMaterialsGen + outAuxiliaryMaterialsGen;
  const outMaterialsSub = outBasicMaterialsSub + outAuxiliaryMaterialsSub;

  const outEquipmentGen =
    basePricesLevel.equipment *
    (equipment.firstGen ?? 1) *
    (equipment.secondGen ?? 1) *
    (equipment.thirdGen ?? 1);

  const outEquipmentSub =
    basePricesLevel.equipment *
    (equipment.firstSub ?? 1) *
    (equipment.secondSub ?? 1) *
    (equipment.thirdSub ?? 1);

  const outOverheadsGen =
    basePricesLevel.overheads *
    (overheads.firstGen ?? 1) *
    (overheads.secondGen ?? 1) *
    (overheads.thirdGen ?? 1);

  const outOverheadsSub =
    basePricesLevel.overheads *
    (overheads.firstSub ?? 1) *
    (overheads.secondSub ?? 1) *
    (overheads.thirdSub ?? 1);

  const outEstimatedProfitGen =
    basePricesLevel.estimatedProfit *
    (estimatedProfit.firstGen ?? 1) *
    (estimatedProfit.secondGen ?? 1) *
    (estimatedProfit.thirdGen ?? 1);

  const outEstimatedProfitSub =
    basePricesLevel.estimatedProfit *
    (estimatedProfit.firstSub ?? 1) *
    (estimatedProfit.secondSub ?? 1) *
    (estimatedProfit.thirdSub ?? 1);

  /* peoplePerHourGen = 0, // чел в час
    machinesPerHourGen = 0, //  машин час */

  const outPeoplePerHourGen =
    peoplePerHourGen *
    (mainSalary.firstGen ?? 1) *
    (mainSalary.secondGen ?? 1) *
    (mainSalary.thirdGen ?? 1);

  const outPeoplePerHourSub =
    peoplePerHourSub *
    (mainSalary.firstSub ?? 1) *
    (mainSalary.secondSub ?? 1) *
    (mainSalary.thirdSub ?? 1);

  const outMachinesPerHourGen =
    machinesPerHourGen *
    (mimExploitation.firstGen ?? 1) *
    (mimExploitation.secondGen ?? 1) *
    (mimExploitation.thirdGen ?? 1);

  const outMachinesPerHourSub =
    machinesPerHourSub *
    (mimExploitation.firstSub ?? 1) *
    (mimExploitation.secondSub ?? 1) *
    (mimExploitation.thirdSub ?? 1);

  const outHourlyCostGen =
    (outMainSalaryGen +
      outMimExploitationGen +
      outOverheadsGen +
      outEstimatedProfitGen) /
    ((peoplePerHourGen ?? 0) *
      (mainSalary.firstGen ?? 1) *
      (mainSalary.secondGen ?? 1) *
      (mainSalary.thirdGen ?? 1) +
      (machinesPerHourGen ?? 0) *
        (mimExploitation.firstGen ?? 1) *
        (mimExploitation.secondGen ?? 1) *
        (mimExploitation.thirdGen ?? 1));
  // const outHourlyCostGen =
  //   (outMainSalaryGen + outMimExploitationGen + outOverheadsGen + outEstimatedProfitGen) /
  //   ((peoplePerHourGen ?? 0) + (machinesPerHourGen ?? 0));
  const outHourlyCostSub =
    (outMainSalarySub +
      outMimExploitationSub +
      outOverheadsSub +
      outEstimatedProfitSub) /
    ((peoplePerHourSub ?? 0) *
      (mainSalary.firstSub ?? 1) *
      (mainSalary.secondSub ?? 1) *
      (mainSalary.thirdSub ?? 1) +
      (machinesPerHourSub ?? 0) *
        (mimExploitation.firstSub ?? 1) *
        (mimExploitation.secondSub ?? 1) *
        (mimExploitation.thirdSub ?? 1));

  const outTotalGen =
    outMainSalaryGen +
    outMimExploitationGen +
    outMaterialsGen +
    outEquipmentGen +
    outOverheadsGen +
    outEstimatedProfitGen;

  const outTotalSub =
    outMainSalarySub +
    outMimExploitationSub +
    outMaterialsSub +
    outEquipmentSub +
    outOverheadsSub +
    outEstimatedProfitSub;

  const outProfit = outTotalGen - outTotalSub;

  const outRentability = (outProfit / outTotalGen) * 100;

  /******************************** Filtered Level/Row *************************************/

  const filteredLevelArr = useMemo(() => {
    if (basePricesLevel.type === 'level') {
      return basePricesLevel.children?.filter((row) => {
        return row.type === 'level';
      });
    }
  }, [basePricesLevel]);

  const filteredRowArr = useMemo(() => {
    if (basePricesLevel.type === 'level') {
      return basePricesLevel.children?.filter((row) => {
        return row.type === 'row';
      });
    }
  }, [basePricesLevel]);

  /****************************************************************************************/

  return (
    <>
      <Box className={`Level-${levelNumber}`}>
        <StyledLevel
          style={{
            gridTemplateColumns: `100px minmax(400px, 1fr) repeat(${countRepeat},170px)`
          }}
          direction="row"
          className="Level-grid">
          <StyledLevelTd
            className="icon"
            colorsBgLevel={levelName}
            borderColorLevel={levelName}
            style={{
              paddingLeft: '9px',
              justifyContent: 'flex-start'
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                minWidth: 24
              }}>
              {basePricesLevel.children.length > 0 && (
                <StyledIconButton
                  onClick={() => handleShowNestedLevel()}
                  size="small">
                  {isShowNestedLevel.show ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </StyledIconButton>
              )}
            </Box>
            <Box sx={{ marginLeft: `${8 + (levelNumber - 1) * 6}px` }}>
              <LevelIcon levelName={levelName} />
            </Box>
          </StyledLevelTd>
          <StyledTitle
            className="title"
            colorsBgLevel={levelName}
            borderColorLevel={levelName}>
            <Tooltip title={basePricesLevel.title}>
              <StyledTypography
                style={{
                  textAlign: 'left',
                  marginLeft: 12,
                  color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                }}>
                {basePricesLevel.title}
              </StyledTypography>
            </Tooltip>
          </StyledTitle>
          {isShowAnalysis && (
            <>
              <StyledLevelTd
                className="hourlyCostGen"
                colorsBgLevel={levelName}
                borderColorLevel={levelName}
                style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
                <StyledTypography
                  style={{
                    textAlign: 'center',
                    color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                  }}>
                  {isFinite(outHourlyCostGen)
                    ? outHourlyCostGen.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    : '0,00'}
                </StyledTypography>
              </StyledLevelTd>
              <StyledLevelTd
                className="hourlyCostSub"
                colorsBgLevel={levelName}
                borderColorLevel={levelName}
                style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
                <StyledTypography
                  style={{
                    textAlign: 'center',
                    color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                  }}>
                  {isFinite(outHourlyCostSub)
                    ? outHourlyCostSub.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    : '0,00'}
                </StyledTypography>
              </StyledLevelTd>
              <StyledLevelTd
                className="profit"
                colorsBgLevel={levelName}
                borderColorLevel={levelName}
                style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
                <StyledTypography
                  style={{
                    textAlign: 'center',
                    color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                  }}>
                  {outProfit.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </StyledTypography>
              </StyledLevelTd>
              <StyledLevelTd
                className="rentability"
                colorsBgLevel={levelName}
                borderColorLevel={levelName}
                style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
                <StyledTypography
                  style={{
                    textAlign: 'center',
                    color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                  }}>
                  {isFinite(outRentability)
                    ? outRentability.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    : '0,00'}
                  {'%'}
                </StyledTypography>
              </StyledLevelTd>
            </>
          )}
          {/* ГЕНПОДРЯД */}
          {isShowGenContract && (
            <>
              <StyledLevelTd
                className="total-isShowGenContract"
                colorsBgLevel={levelName}
                borderColorLevel={levelName}
                style={{
                  boxShadow: 'inset 0px 1px 250px rgb(0 26 77 / 8%)',
                  borderLeft: '1px solid rgb(0 26 77 / 10%)'
                }}>
                <StyledTypography
                  style={{
                    textAlign: 'center',
                    color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                  }}>
                  {outTotalGen.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </StyledTypography>
              </StyledLevelTd>
              {!isShowAnalysis && (
                <>
                  <StyledLevelTd
                    className="mainSalary"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    // style={{ boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)' }}
                  >
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outMainSalaryGen.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="mimExploitation"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outMimExploitationGen.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="machineOperatorSalary"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outMachineOperatorSalaryGen.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="materials"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outMaterialsGen.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="basicMaterials"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outBasicMaterialsGen.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="auxiliaryMaterials"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outAuxiliaryMaterialsGen.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="equipment"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outEquipmentGen.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="overheads"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outOverheadsGen.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="estimatedProfit"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outEstimatedProfitGen.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="peoplePerHour"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {(outPeoplePerHourGen ?? 0).toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="machinesPerHour"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {(outMachinesPerHourGen ?? 0).toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="hourlyCost"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {/*{isFinite(outHourlyCostGen)*/}
                      {/*  ? outHourlyCostGen.toLocaleString('ru-RU', {*/}
                      {/*      minimumFractionDigits: 2,*/}
                      {/*      maximumFractionDigits: 2,*/}
                      {/*    })*/}
                      {/*  : '0'}*/}
                      {isFinite(outHourlyCostGen)
                        ? outHourlyCostGen.toLocaleString('ru-RU', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        : '0,00'}
                    </StyledTypography>
                  </StyledLevelTd>
                </>
              )}
            </>
          )}
          {/* СУБПОДРЯД */}
          {isShowSubContract && (
            <>
              <StyledLevelTd
                className="total-isShowSubContract"
                colorsBgLevel={levelName}
                borderColorLevel={levelName}
                style={{
                  boxShadow: 'rgb(0 26 77 / 8%) 0px 1px 250px inset',
                  borderLeft: '1px solid rgb(0 26 77 / 10%)'
                }}>
                <StyledTypography
                  style={{
                    textAlign: 'center',
                    color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                  }}>
                  {outTotalSub.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </StyledTypography>
              </StyledLevelTd>
              {!isShowAnalysis && (
                <>
                  <StyledLevelTd
                    className="mainSalary"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outMainSalarySub.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="mimExploitation"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outMimExploitationSub.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="machineOperatorSalary"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outMachineOperatorSalarySub.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="materials"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outMaterialsSub.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="basicMaterials"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outBasicMaterialsSub.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="auxiliaryMaterials"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outAuxiliaryMaterialsSub.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="equipment"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outEquipmentSub.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="overheads"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outOverheadsSub.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="estimatedProfit"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {outEstimatedProfitSub.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="peoplePerHour"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {(outPeoplePerHourSub ?? 0).toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="machinesPerHour"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {(outMachinesPerHourSub ?? 0).toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </StyledTypography>
                  </StyledLevelTd>
                  <StyledLevelTd
                    className="hourlyCost"
                    colorsBgLevel={levelName}
                    borderColorLevel={levelName}
                    style={{
                      boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)'
                    }}>
                    <StyledTypography
                      style={{
                        textAlign: 'center',
                        color: `${levelName === 'level1' ? colorText.main : colorText.gray}`
                      }}>
                      {isFinite(outHourlyCostSub)
                        ? outHourlyCostSub.toLocaleString('ru-RU', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        : '0,00'}
                    </StyledTypography>
                  </StyledLevelTd>
                </>
              )}
            </>
          )}
        </StyledLevel>
      </Box>
      {/***************************** level 2 *****************************/}
      {isShowNestedLevel.show && (
        <>
          {filteredRowArr?.map((rowLevelNext) => {
            return (
              <Fragment key={rowLevelNext.id}>
                {rowLevelNext.type === 'row' && (
                  <EstimateRow
                    calculationId={calculationId}
                    key={rowLevelNext.id}
                    basePricesRow={rowLevelNext}
                    coefficients={coefficients}
                    countRepeat={countRepeat}
                    isShowGenContract={isShowGenContract}
                    isShowSubContract={isShowSubContract}
                    isShowAnalysis={isShowAnalysis}></EstimateRow>
                )}
              </Fragment>
            );
          })}
          {filteredLevelArr?.map((rowLevelNext) => {
            return (
              <Fragment key={rowLevelNext.id}>
                {rowLevelNext.type === 'level' && (
                  <EstimateLevel
                    calculationId={calculationId}
                    basePricesLevel={rowLevelNext}
                    key={rowLevelNext.id}
                    levelNumber={levelNumber + 1}
                    coefficients={coefficients}
                    isShowGenContract={isShowGenContract}
                    isShowSubContract={isShowSubContract}
                    isShowAnalysis={isShowAnalysis}
                    countRepeat={countRepeat}
                    levelNameCurrent={levelNameCurrent}></EstimateLevel>
                )}
              </Fragment>
            );
          })}
        </>
      )}
    </>
  );
};
