/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Checkbox, MenuItem, Typography } from '@mui/material';
import Button from 'components/Button';
import React, { useContext, useMemo } from 'react';
import { HeaderParameters, IParametersFormAct } from '..';
import { formatDate } from '../../../../../Accomplishment/components/CaptionTable/CaptionTable';
import { StyledSelect } from '../../../../../Accomplishment/components/CaptionTable/CaptionTable.style';
import { SelectField } from '../../../../../CalculationBasic/components/ParametersDialog/ParametersDialog.styles';
import { HandbookContext, TTabType } from '../../../../handbook.services';

export const Header: React.FC<{
  close: () => void;
  isActs?: boolean;
  selectedTab: TTabType | null;
  actList: IParametersFormAct[];
  removedActList: IParametersFormAct[];
  disabled: boolean;
  currentActItem?: IParametersFormAct;
  updateIsSynchronized: (id: number, newSynchronized: boolean) => void;
  isCoefficient: boolean;
}> = ({
  close,
  isActs,
  actList,
  currentActItem,
  updateIsSynchronized,
  isCoefficient
}) => {
  const { changeAct, currentAct } = useContext(HandbookContext);

  const renderAct = useMemo(() => {
    if (!actList.length) return 'Актов нет';
    if (!currentAct) return 'Акт не выбран';
    return formatDate(currentAct);
  }, [actList.length, currentAct]);

  const renderActs = useMemo(() => {
    return actList
      .filter((item) => !item.isRemoved)
      .map((item) => {
        return (
          <MenuItem
            key={item.id}
            onClick={() => {
              if (currentAct?.id !== item.id) {
                changeAct(item);
              }
            }}
            selected={item.id === currentAct?.id}
            value={formatDate(item)}>
            {formatDate(item)}
          </MenuItem>
        );
      });
  }, [actList, changeAct, currentAct?.id]);

  return (
    <HeaderParameters>
      <div className={'box'}>
        <Button
          onClick={close}
          variant="text"
          type="button"
          sx={{ fontWeight: 500, fontSize: '13px' }}>
          <span style={{ display: 'flex', marginRight: '8px' }}>
            <ArrowBackIcon sx={{ height: '25px', width: '25px' }} />
          </span>
          Назад
        </Button>
        <Typography variant="h1" color="primary.light">
          Изменение параметров расчета {isActs ? 'актов' : 'смет'}
        </Typography>
        {isActs && !!renderActs.length && (
          <>
            <SelectField>
              Текущий акт:
              <StyledSelect
                smallText
                hideBorder
                width={240}
                onClear={() => {
                  alert('clear');
                }}
                nullableValues={[renderAct]}
                SelectProps={{
                  renderValue: () => {
                    const separator = ' — ';
                    const destructiveStr = renderAct.split(separator);
                    destructiveStr[1] = destructiveStr[1]
                      ? `по ${destructiveStr[1]}`
                      : '';
                    return destructiveStr[1]
                      ? 'с ' + destructiveStr.join(separator)
                      : destructiveStr[0];
                  },
                  MenuProps: {
                    sx: {
                      maxHeight: 500,
                      width: 240
                    },
                    placeholder: 'Выберите диапазон'
                  },
                  value: renderAct,
                  placeholder: 'Выберите диапазон'
                }}
                value={renderAct}
                placeholder={'Выберите диапазон'}
                fullWidth
                select>
                {renderActs}
              </StyledSelect>
            </SelectField>

            {currentAct && (
              <SelectField
                onChange={() => {
                  if (currentActItem)
                    updateIsSynchronized(
                      currentActItem.id,
                      !currentActItem?.isSynchronized
                    );
                }}>
                <Checkbox
                  checked={!!currentActItem?.isSynchronized}
                  size={'small'}
                />
                Применить параметры как в расчете
              </SelectField>
            )}
          </>
        )}
      </div>
      <div>
        <Button
          disabled={isActs ? !actList.length : !isCoefficient}
          type={'submit'}
          width={'180px'}
          color={'success'}>
          применить
        </Button>
      </div>
    </HeaderParameters>
  );
};
