import {
  lighten,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
} from '@mui/material';
import styled from 'styled-components';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    backgroundColor: lighten(theme.palette.primary.main, 0.92),
    borderRadius: theme.spacing(0.75),
    gap: '3px',
    padding: theme.spacing(0.25),

    [`& .${toggleButtonGroupClasses.grouped}`]: {
      '&:not(:first-of-type), &:first-of-type': {
        border: 0,
        borderRadius: theme.spacing(0.75),
        marginLeft: 0,
      },
    },
  }),
);
