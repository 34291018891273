/**
 * @author Mr_FabiozZz[mr.fabiozz@gmail.com]
 */
import React from 'react';
import { Stack, Typography } from '@mui/material';

type TProps = {
  count: number;
};

const InfoBlock: React.FC<TProps> = ({ count }) => {
  return (
    <Stack
      display={'flex'}
      alignItems={'center'}
      flexDirection={'row'}
      justifyContent={'space-between'}>
      <Typography color="#7890B2" variant="caption">
        Выберите проект для копирования
        <span style={{ color: 'red' }}>*</span>
      </Typography>
      <Typography color={'primary'} variant="caption">
        Выбрано : {count}
      </Typography>
    </Stack>
  );
};

export default React.memo(InfoBlock);
