import { Typography } from '@mui/material';
import { StyledTitle } from './styles';
import { theme } from '../../../styles/theme';

export type TitleTableProps = {
  title: string;
  count: number;
  arrIdx?: number[];
};

export const TitleTable: React.FC<TitleTableProps> = ({
  title,
  count,
  arrIdx,
}) => {
  return (
    <StyledTitle>
      {title} {`(${count})`}
      {arrIdx && arrIdx.length > 0 && (
        <Typography
          sx={{
            color: theme.palette.warning.main,
            width: '80%',
            overflow: 'hidden',
            textAlign: 'right',
          }}>
          Работы под номером: <b>{arrIdx.join(', ')}</b> содержат иную единицу
          измерения
        </Typography>
      )}
    </StyledTitle>
  );
};
