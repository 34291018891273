import { Head, Body } from './components';

const Table: React.FC = () => {
  return (
    <div>
      <Head />
      <Body />
    </div>
  );
};

export default Table;
