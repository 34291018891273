/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import { IShortProject } from '../../../../../../../../../api/projects/projects.types';
import styled from 'styled-components';

const StyledReloadButton = styled(Button)`
  padding: 7px 16px;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  box-shadow: none;
  background-color: transparent;
  border: none;
  border-radius: 6px;

  &:hover {
    background: transparent;
  }
`;

const ErrorState: React.FC<{
  errorData: number[];
  allProjects?: IShortProject[];
  repeat: () => void;
  close: () => void;
}> = ({ allProjects, errorData, repeat, close }) => {
  return (
    <Stack
      display="grid"
      height={'100%'}
      gridTemplateColumns={'1fr'}
      p={2}
      gridTemplateRows={'1fr min-content'}>
      <Stack
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}>
        <Stack
          maxWidth={'308px'}
          display={'flex'}
          textAlign={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
          alignItems={'center'}>
          <ReportProblemOutlinedIcon fontSize={'large'} color={'error'} />
          <Typography
            pt={1}
            fontSize={'16px'}
            sx={{ fontWeight: 500 }}
            variant={'caption'}
            color={'error'}>
            Произошла ошибка при копировании!
          </Typography>
          <Typography
            pt={1}
            fontSize={'14px'}
            variant={'caption'}
            color={'error'}>
            Копии не были созданы в проектах:
          </Typography>
          <Typography fontSize={'14px'} variant={'caption'} color={'error'}>
            {errorData
              .map((projectID) => {
                const projectTitle =
                  allProjects?.find((p) => p.id === projectID)?.shortName ?? '';
                return `“${projectTitle}”`;
              })
              .join(', ')}
          </Typography>
        </Stack>
        <StyledReloadButton
          sx={{ marginTop: '32px' }}
          size={'small'}
          startIcon={<SyncIcon />}
          onClick={repeat}>
          повторить
        </StyledReloadButton>
      </Stack>
      <Button fullWidth onClick={close}>
        закрыть
      </Button>
    </Stack>
  );
};

export default React.memo(ErrorState);
