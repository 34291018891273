import React, { useEffect, useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import {
  StyledHeadTd,
  StyledTableBody,
  StyledTableContainer,
  StyledTableHead
} from './styles';
import {
  PositionVOR,
  PositionVORWithTarget
} from '../../../api/calculations/types';
import { TitleTable } from './TitleTable';
import { RowTable } from './RowTable';
import { RootState, useAppDispatch } from '../../../store/store';
import { setTarget } from '../../../store/slices/vor/vor';
import { useSelector } from 'react-redux';
import { IVorPosition } from '../../../api/calculationDictionary';
import { deepClone } from '@mui/x-data-grid/utils/utils';

export interface TableModalProps {
  mainWorks: (PositionVOR | IVorPosition)[];
  extraWorks: (PositionVOR | IVorPosition)[];
  handleDelRow: (delId: PositionVOR | IVorPosition, isEdit?: boolean) => void;
  keyWorkId: any;
  isEdit: boolean;
  isHandbook: boolean;
}
function customSort(data: any[]) {
  console.log('log ', data);
  return deepClone(data).sort((a: any, b: any) => {
    if ('numbering' in a || 'numbering' in b) {
      return a.numbering - b.numbering;
    } else {
      const aParts = a.num.split('.').map(Number);
      const bParts = b.num.split('.').map(Number);

      // Найти самый длинный из массивов, чтобы сравнить все уровни
      const maxLength = Math.max(aParts.length, bParts.length);
      console.log('log sort', aParts, bParts);
      for (let i = 0; i < maxLength; i++) {
        const aValue = aParts?.[i] || 0; // Если элемента нет, считать его 0
        const bValue = bParts?.[i] || 0;
        console.log('log a', aValue, bValue, aValue - bValue);
        if (aValue !== bValue) {
          return aValue - bValue;
        }
      }
      return 0;
    }
  });
}

export const TableModal: React.FC<TableModalProps> = ({
  mainWorks,
  isHandbook,
  isEdit,
  extraWorks,
  handleDelRow,
  keyWorkId
}) => {
  const { changeableFields: fieldState } = useSelector(
    (state: RootState) => state.vor.table
  );
  console.log(fieldState);
  const arrIdx = useMemo(() => {
    if (fieldState.selectUnit.value) {
      return mainWorks
        ?.filter((item) => {
          console.log(fieldState.selectUnit.value, (item as any).unitType);
          if ('unitType' in item) {
            if (
              fieldState.selectUnit.value === 'kilogram' ||
              fieldState.selectUnit.value === 'tonn'
            ) {
              if (item.unitType === 'kilogram' || item.unit === 'kilogram') {
                return false;
              }
              if (item.unitType === 'tonn' || item.unit === 'tonn') {
                return false;
              }
            }

            return item.unitType !== fieldState.selectUnit.value;
          }
          return fieldState.selectUnit.value !== item.unit;
        })
        ?.map((item) => {
          if ('number' in item) {
            return Number(item.number);
          }
          return item.num;
        });
    } else return [];
  }, [fieldState.selectUnit.value, mainWorks]);

  const dispatch = useAppDispatch();

  const handleChangeKeyWork = (work: PositionVOR) => {
    dispatch(setTarget(work));
  };

  useEffect(() => {
    if (mainWorks.length === 1) {
      dispatch(setTarget(mainWorks[0] as PositionVORWithTarget));
    }
  }, [mainWorks.length]);
  console.log('log main and extra works', mainWorks, extraWorks);
  return (
    <Box sx={{ width: '1860px', padding: '20px' }}>
      <StyledTableContainer className="StyledTableContainer">
        <Stack direction="row">
          <StyledTableHead $isHandbook={isHandbook} className="TableHead">
            <StyledHeadTd></StyledHeadTd>
            <StyledHeadTd>№ п/п</StyledHeadTd>
            {isHandbook ? (
              <>
                <StyledHeadTd>Уровень 1</StyledHeadTd>
                <StyledHeadTd>Уровень 2</StyledHeadTd>
                <StyledHeadTd>Уровень 3</StyledHeadTd>
              </>
            ) : (
              <>
                <StyledHeadTd>Шифр ЛСР</StyledHeadTd>
                <StyledHeadTd>№ по ЛСР</StyledHeadTd>
                <StyledHeadTd>Раздел</StyledHeadTd>
                <StyledHeadTd>Заголовок</StyledHeadTd>
              </>
            )}
            <StyledHeadTd>Код расценки</StyledHeadTd>
            <StyledHeadTd>Наименование</StyledHeadTd>
            <StyledHeadTd>Ед. изм</StyledHeadTd>
            <StyledHeadTd>Кол-во</StyledHeadTd>
            <StyledHeadTd>ТЗ ОР, чел-ч</StyledHeadTd>
            <StyledHeadTd>ТЗ М, чел-ч</StyledHeadTd>
            <StyledHeadTd style={{ borderRight: 'none' }}>
              Всего стоимость
            </StyledHeadTd>
            <StyledHeadTd></StyledHeadTd>
          </StyledTableHead>
          <StyledHeadTd
            style={{
              padding: 0,
              width: '12px',
              height: '30px',
              borderBottom: '1px solid #d2dcff',
              borderRight: 'none'
            }}></StyledHeadTd>
        </Stack>
        <StyledTableBody className="TableBody">
          <TitleTable
            title={'Основные позиции'}
            arrIdx={arrIdx as number[]}
            count={mainWorks.length}
          />
          {customSort(mainWorks ?? []).map((element: any) => {
            return (
              <RowTable
                isEdit={isEdit}
                isHandbook={isHandbook}
                key={element.id}
                level={element as PositionVOR}
                keyWorkId={keyWorkId}
                handleChangeKeyWork={() =>
                  handleChangeKeyWork(element as PositionVOR)
                }
                handleDelRow={handleDelRow}
              />
            );
          })}
          <TitleTable
            title={'Вспомогательные позиции'}
            count={extraWorks.length}
          />
          {customSort(extraWorks ?? []).map((element: any) => {
            return (
              <RowTable
                isEdit={isEdit}
                isHandbook={isHandbook}
                key={element.id}
                level={element as PositionVOR}
                mode={'notCheckbox'}
                handleChangeKeyWork={() =>
                  handleChangeKeyWork(element as PositionVOR)
                }
                keyWorkId={keyWorkId}
                handleDelRow={handleDelRow}
              />
            );
          })}
        </StyledTableBody>
      </StyledTableContainer>
    </Box>
  );
};
