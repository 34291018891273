import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminNavBar from '../../components/AdminNavBar';
import Header from '../../components/Header';
import UserNavBar from '../../components/UserNavBar';
import { InnerContainer, MainContainer } from './styles';

const HomeLayout: React.FC = () => {
  return (
    <>
      {/*  <AdminNavBar /> */}
      <MainContainer>
        <Header />
        <InnerContainer>
          <Outlet />
        </InnerContainer>
      </MainContainer>
    </>
  );
};

export default HomeLayout;
