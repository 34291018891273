import Tooltip from 'components/Tooltip';
import { memo } from 'react';

export const InformIcon = memo(() => {
  return (
    <Tooltip
      title={'Коэффициенты были изменены на уровнях ниже'}
      placement="top">
      <svg
        style={{ position: 'absolute', left: '5px' }}
        width="20"
        height="20"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.00016 2C11.6668 2 14.6668 4.38667 14.6668 7.33333C14.6668 10.28 11.6668 12.6667 8.00016 12.6667C7.1735 12.6667 6.38016 12.5467 5.64683 12.3333C3.70016 14 1.3335 14 1.3335 14C2.88683 12.4467 3.1335 11.4 3.16683 11C2.0335 10.0467 1.3335 8.75333 1.3335 7.33333C1.3335 4.38667 4.3335 2 8.00016 2ZM7.3335 9.33333V10.6667H8.66683V9.33333H7.3335ZM7.3335 8H8.66683V4H7.3335V8Z"
          fill="#0044B4"
        />
      </svg>
    </Tooltip>
  );
});

InformIcon.displayName = 'InformIcon';
