import { Autocomplete, FormControl } from '@mui/material';
import styled from 'styled-components';

export const AutocompleteFormControl = styled(FormControl)`
  width: 100%;
`;

interface AutocompleteFieldProps {
  disablePlaceholder?: boolean;
}

export const StyledAutocompleteField = styled(
  Autocomplete,
)<AutocompleteFieldProps>`
  background-color: ${(props) => props.theme.palette.bg.white};
`;
