import { Box, IconButton, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { ColorsBgLevelType } from 'types';
import { FieldForm } from '../../../../../../../components/FieldForm';

interface StyledBgLevelProps {
  colorsBgLevel: ColorsBgLevelType;
}

export const StyledLevelTd = styled(Box)<StyledBgLevelProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  box-shadow: inset 0px -1px 0px rgba(0, 36, 95, 0.1);
  background-color: ${(props) => {
    return props.theme.palette.bgLevel[props.colorsBgLevel];
  }};
`;

export const StyledRowTd = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  box-shadow: inset 0px -1px 0px rgba(0, 36, 95, 0.1);
`;
export const StyledIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  padding: 0px;
`;
export const StyledTypography = styled(Typography)`
  color: #5c6e8c;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
export const StyledLevel = styled(Stack)`
  align-items: center;
  background: #ffffff;
  height: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: 100px minmax(400px, 100%) minmax(636px, 1fr) 64px minmax(
      636px,
      1fr
    );
  grid-auto-rows: 50px;
`;
export const StyledRow = styled(Stack)`
  align-items: center;
  background: #ffffff;
`;
export const StyledTitle = styled(Stack)<StyledBgLevelProps>`
  align-items: start;
  min-width: 400px;
  width: 100%;
  height: 100%;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  background-color: ${(props) => {
    return props.theme.palette.bgLevel[props.colorsBgLevel];
  }};
  box-shadow: inset 0px -1px 0px rgba(0, 36, 95, 0.1);
`;
export const StyledTitleNormative = styled(Stack)`
  padding: 5px 0;
  align-items: start;
  min-width: 400px;
  width: 100%;
  height: 100%;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  box-shadow: inset 0px -1px 0px rgba(0, 36, 95, 0.1);
`;
export const StyledBoxRow = styled(Stack)`
  height: 100%;
  justify-content: center;
  box-shadow: inset 0px -1px 0px rgba(0, 36, 95, 0.1);
  padding: 5px 0;
`;
export const StyledTypographyRow = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #2b3648;
  padding: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
//Child

export const StyledBoxLevel = styled(Stack)<StyledBgLevelProps>`
  height: 100%;
  justify-content: center;
  background-color: ${(props) => {
    return props.theme.palette.bgLevel[props.colorsBgLevel];
  }};
`;
export const StyledContractStack = styled(Stack)`
  display: grid;
  grid-template-columns: repeat(3, 212px);
  margin: 0 auto;
  align-items: center;
  box-shadow: inset 0px -1px 0px rgb(0 36 95 / 10%);
  height: 100%;
`;

export const StyledFieldFormMin = styled(FieldForm)`
  width: 100%;
  position: relative;
  padding: 4px 16px;
  border-radius: 4px;

  span {
    line-height: 12px;
  }

  & .MuiFilledInput-root {
    border: none;
    background-color: #fff;
   }

  & .MuiInputBase-input {
    padding: 4px 11px;
    color: #2B3648;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    z-index:1;


     &::-webkit-input-placeholder {
        color: #ffffff;
      }
     &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
         color: #ffffff;
       }
     &::-moz-placeholder { /* Mozilla Firefox 19+ */
       color: #ffffff;
      }
     &:-ms-input-placeholder { /* Internet Explorer 10+ */
      color: #ffffff;
     }
  }

    & .MuiOutlinedInput-root {
      &:hover {
        & .MuiOutlinedInput-notchedOutline {
          border-color: #0288D1;
        }
      }
    }

    & .Mui-focused {
      & .MuiOutlinedInput-notchedOutline {
          border: 1px solid #0288D1;
       }
     }
   }

  .Mui-focused {
    .MuiInputBase-input {
    &::-webkit-input-placeholder {
    color: #ffffff;
    }
   }
  }

  & .MuiOutlinedInput-root {
     background: #F6F7FB;
  }

  .Mui-error {
    .MuiInputBase-input {
      &::-webkit-input-placeholder { /* WebKit browsers */
         color: red;
        }
      &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: red;
        }
      &::-moz-placeholder { /* Mozilla Firefox 19+ */
         color: red;
         }
      &:-ms-input-placeholder {
         color: red;
         }
     }
  }

  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: 3px;
    left: 15px;
    color: #F46B6B;
    margin: 0;
    z-index:0;
  }
  & .MuiFormHelperText-root.MuiFormHelperText-filled {
    bottom: 14px;
    left: 120px;
  }
`;
