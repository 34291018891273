import styled from 'styled-components';

export const Divider = styled.div`
  width: 1px;
  height: 16px;
  background: #8cbbe8;
  border-radius: 6px;
`;
export const WrapperSwitch = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 2px;
  border-radius: 6px;
  background: ${({ disabled }) =>
    disabled ? '#F6F7FB' : 'rgba(25, 118, 210, 0.08)'};
  height: 32px;
`;
export const OptionSwitch = styled.div<{
  selected: boolean;
  disabled: boolean;
}>`
  height: 100%;

  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 4.5px 8px;

  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ${({ selected }) =>
    selected
      ? 'box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);'
      : ''}

  color: ${({
    theme: {
      palette: { primary },
    },
    selected,
    disabled: disSwitch,
  }) => (selected ? '#FFF' : disSwitch ? '#9AA2B0' : primary.main)};

  background: ${({
    theme: {
      palette: { primary },
    },
    selected,
  }) => (selected ? primary.main : 'transparent')};

  transition: all linear 0.2s;
`;
