/**
 * @author Mr_fabiozZz[mr.fabiozzz@gmail.com]
 */
import DateRangeIcon from '@mui/icons-material/DateRange';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

export interface CreateActForm {
  onDate: Date | null;
  startDate: Date | null;
  endDate: Date | null;
}

export interface Field {
  id: number;
  label: string;
  render: 'startDate' | 'endDate' | 'onDate';
  Icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>> & { muiName: string };
}

export const defaultValues: {
  onDate: Date | null;
  endDate: Date | null;
  startDate: Date | null;
} = {
  onDate: null,
  endDate: null,
  startDate: null,
};

export const inputs: {
  id: number;
  label: string;
  render: 'startDate' | 'onDate' | 'endDate';
  Icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>> & { muiName: string };
}[] = [
  {
    id: 1,
    label: 'Дата начала периода:',
    render: 'startDate',
    Icon: DateRangeIcon,
  },
  {
    id: 2,
    label: 'Дата окончания периода:',
    render: 'endDate',
    Icon: EventAvailableIcon,
  },
  {
    id: 3,
    label: 'Сформирован на дату:',
    render: 'onDate',
    Icon: EventNoteIcon,
  },
];
