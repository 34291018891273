/**
 * @author Mr_FabiozZz[mr,fabiozzz@gmail.com]
 */
import {
  HandbookContext,
  TTabType
} from 'pages/Calculations/components/CalculationDirectory/handbook.services';
import { FC, memo, useContext, useMemo } from 'react';
import { Item, LeftMenu } from '../Parameters.styles';
import { TTab } from '../Parameters.types';
import { OrNull } from 'api/references/estimates/estimates.types';
import { ActList } from 'types';

export const Menu: FC<{
  tab: TTab;
  setTab: (str: TTab) => void;
  selectedTab: TTabType | null;
  isActs: boolean;
  disabled: boolean;
  isCoefficient: boolean;
  currentAct: OrNull<ActList>;
}> = memo(({ tab, setTab, isActs, currentAct, isCoefficient }) => {
  const { actList: actListOriginal } = useContext(HandbookContext);

  const isActiveCoeff = useMemo(
    () =>
      !isActs
        ? isCoefficient
          ? true
          : false
        : currentAct
          ? isCoefficient
            ? true
            : false
          : false,
    [currentAct, isActs, isCoefficient]
  );

  const isActiveActs = useMemo(
    () => (actListOriginal?.actList.length ? true : false),
    [actListOriginal?.actList.length]
  );

  return (
    <LeftMenu>
      {isActs && (
        <Item
          active={tab === 'acts'}
          style={{
            opacity: isActiveActs ? 1 : 0.5
          }}
          onClick={() => isActiveActs && tab !== 'acts' && setTab('acts')}>
          Акты по периодам
        </Item>
      )}
      <Item
        active={tab === 'indirect_costs'}
        style={{
          opacity: isActiveCoeff ? 1 : 0.5
        }}
        onClick={() =>
          isActiveCoeff && tab !== 'indirect_costs' && setTab('indirect_costs')
        }>
        Косвенные затраты
      </Item>
      <Item
        active={tab === 'value_changes'}
        style={{
          opacity: isActiveCoeff ? 1 : 0.5
        }}
        onClick={() =>
          isActiveCoeff && tab !== 'value_changes' && setTab('value_changes')
        }>
        Коэффициент изменения стоимости
      </Item>
    </LeftMenu>
  );
});

Menu.displayName = 'Menu';
