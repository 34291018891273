interface PersonWithName {
  firstName: string;
  lastName: string;
  middleName: string | null;

  [key: string]: any;
}

interface PersonWithOtherName {
  firstname: string;
  lastname: string;
  middlename: string | null;

  [key: string]: any;
}

export type Person = PersonWithName | PersonWithOtherName;
type NameChars = 'Ф' | 'И' | 'О' | 'ф' | 'и' | 'о';
type NameFormat = `${NameChars}${NameChars}${NameChars | ''}`;

export function formatName(person: Person, format: NameFormat): string {
  const arr = Array.from(format).map((char) => {
    switch (char) {
      default:
        return null;
      case 'Ф':
        return person.lastName ?? person.lastname;
      case 'И':
        return person.firstName ?? person.firstname;
      case 'О':
        return person.middleName ?? person.middlename;
      case 'ф':
        return person.lastName
          ? person.lastName[0] + '.'
          : person.lastname
            ? person.lastname[0] + '.'
            : undefined;
      case 'и':
        return person.firstName
          ? person.firstName[0] + '.'
          : person.firstname
            ? person.firstname[0] + '.'
            : undefined;
      case 'о':
        return person.middleName
          ? person.middleName[0] + '.'
          : person.middlename
            ? person.middlename[0] + '.'
            : undefined;
    }
  });

  return arr.filter(Boolean).join(' ');
}
