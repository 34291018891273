import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ProviderWS } from 'api/web-socket';
import { ArcElement, Chart } from 'chart.js';
import { WSCProvider } from 'contexts/ws';
import ruLocale from 'date-fns/locale/ru';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { UICProvider } from './contexts/ui';
import { i18n } from './i18n';
import { AppRoutes } from './routes';
import { store } from './store/store';
import './styles/App.scss';
import { theme } from './styles/theme';

Chart.register(ArcElement);

const snackbarAnchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const App: React.FC = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <I18nextProvider i18n={i18n}>
              <Provider store={store}>
                <UICProvider>
                  <ProviderWS>
                    <WSCProvider>
                      <BrowserRouter>
                        <SnackbarProvider
                          autoHideDuration={1000}
                          style={{ maxWidth: 320 }}
                          anchorOrigin={snackbarAnchorOrigin}
                          maxSnack={1}>
                          <div className="App">
                            <AppRoutes />
                          </div>
                        </SnackbarProvider>
                      </BrowserRouter>
                    </WSCProvider>
                  </ProviderWS>
                </UICProvider>
              </Provider>
            </I18nextProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  );
};

export default App;
