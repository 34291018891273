import { FC } from 'react';
import { tableColumns } from './Table.service';
import { StyledLSRFilesTable } from './Table.styles';
import { TableProps } from './Table.types';
import { EmptyRowsOverlay } from './components';

export const Table: FC<TableProps> = ({ data, isLoading }) => {
  return (
    <StyledLSRFilesTable
      columns={tableColumns}
      rows={data}
      columnHeaderHeight={48}
      rowHeight={48}
      slots={{ noRowsOverlay: EmptyRowsOverlay }}
      loading={isLoading}
      // hideFooter
      disableColumnMenu
      checkboxSelection={data.length !== 0}
      disableRowSelectionOnClick
    />
  );
};
