import { ToggleButtonProps } from '@mui/material';
import { FC } from 'react';
import { StyledToggleButton } from './ToggleButton.styles';

export const ToggleButton: FC<ToggleButtonProps> = ({
  children,
  ...props
}): JSX.Element => {
  return <StyledToggleButton {...props}>{children}</StyledToggleButton>;
};
