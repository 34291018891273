/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 *
 * @description
 * Функция для добавления разрядности для числа в виде строки,
 * для тех случаев когда нужно обойти стандартное программное округление чисел
 *
 * Суть, число делиться на две части, до запятой и после.
 * В строку до запятой добавляется разрядность в виде пробелов между тысячами
 * К строке после запятой, добавляется 0 и идет простое округление до выбранного значения
 * В конце все схлопывается, лишний ноль удаляется
 *
 * @param num {string} - число в виде строки
 * @param max {number} - число до которого нужно округлить
 */
export function replaceStringFromServer(num: string, max?: number): string {
  if (num === '0') return '';
  function strToArray(str: string) {
    const arr: string[] = [];
    for (let i = 0; i < str.length; i++) {
      arr.push(str[i]);
    }
    let counter = 1;
    return arr
      .reverse()
      .reduce((acc, letter) => {
        acc.push(letter);
        if (counter === 3) {
          acc.push(' ');
          counter = 1;
        } else {
          counter += 1;
        }
        return acc;
      }, [] as string[])
      .reverse()
      .join('');
  }

  if (!num) return '';
  const replacedNum = num.replace('.', ',');
  let commaIndex = replacedNum.indexOf(',') || replacedNum.length - 1;
  if (commaIndex >= 0) {
    commaIndex = commaIndex + 1;
  } else {
    return strToArray(replacedNum);
  }
  const endNums = replacedNum.substring(commaIndex);
  const float = Number(0 + '.' + endNums).toLocaleString('ru-RU', {
    minimumFractionDigits: 2,
    maximumFractionDigits: max ?? 2,
  });

  return (
    strToArray(replacedNum.substring(0, commaIndex - 1)) +
    float.replace(/\d/, '')
  );
}
