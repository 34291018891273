import { StyledInfo, StyledInfoTitle } from './Info.styles';

interface InfoProps {
  title: string;
  text: string | undefined;
}

const Info: React.FC<InfoProps> = ({ title, text }) => {
  return (
    <StyledInfo>
      <StyledInfoTitle>{title}</StyledInfoTitle>
      <div>{text}</div>
    </StyledInfo>
  );
};

export default Info;
