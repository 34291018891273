import { Stack, Typography } from '@mui/material';
import { StyledBoxHeader, StyledTitle } from 'pages/Dashboard/Dashboard.styles';
import { FC } from 'react';
import {
  StyledStatsInfoItem,
  StyledStatsInfoItemValue,
  StyledStatsInfoPaper,
  StyledStatsInfoValuesBox,
  StyledTotalIcon,
} from './StatsInfoPaper.styles';
import { StatsInfoPaperProps } from './StatsInfoPaper.types';

export const StatsInfoPaper: FC<StatsInfoPaperProps> = ({
  title,
  options,
  total,
}) => {
  return (
    <StyledStatsInfoPaper>
      <StyledBoxHeader
        size="small"
        direction="row"
        justifyContent="space-between">
        <StyledTitle size="small">{title}</StyledTitle>
        {total !== undefined && (
          <Stack direction="row" alignItems="center" spacing={0.25}>
            <StyledTotalIcon />
            <Typography variant="body2" color="primary.main" height="18px">
              {total}
            </Typography>
          </Stack>
        )}
      </StyledBoxHeader>
      {options.length !== 0 && (
        <StyledStatsInfoValuesBox>
          {options.map((option) => (
            <StyledStatsInfoItem key={option.title} py={1}>
              <Typography fontSize="inherit">{option.title}</Typography>
              <StyledStatsInfoItemValue>
                {option.value}
              </StyledStatsInfoItemValue>
            </StyledStatsInfoItem>
          ))}
        </StyledStatsInfoValuesBox>
      )}
    </StyledStatsInfoPaper>
  );
};
