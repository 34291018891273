import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, TextField, Typography } from '@mui/material';
import { ClearableTextField } from 'components/ClearableTextField';
import styled from 'styled-components';
import { BaseTextField } from '../../../../../../../../../../../../components/BaseTextField';

export const StyledEstimate = styled(Box)`
  position: relative;
  margin-top: 15px;
`;

export const StyledTitle = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
`;

export const StyledCircle = styled(Box)`
  position: absolute;
  left: 15px;
  top: 19px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: black;
`;

export const StyledGridBox = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 150px 240px;
  align-items: center;
  gap: 20px;
`;

export const StyledIconArrow = styled(KeyboardArrowRightIcon)`
  position: absolute;
  top: 8px;
  left: -10px;
  transition: all 0.2s;
  cursor: pointer;
`;

export const StyledClearableTextField = styled(ClearableTextField)`
  width: 100px;
  div > input::placeholder {
    text-align: center;
  }
`;
export const StyledTextField = styled(BaseTextField)`
  width: 100px;
  div > input::placeholder {
    text-align: center;
  }
`;
