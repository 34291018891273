import * as yup from 'yup';

import { format, isDate } from 'date-fns';

export const validationRow = yup.object({
  title: yup.string().trim().required(`common:${''}${''}`),
  mainSalary: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?([\.\,]\d{1,2})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
  mimExploitation: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?([\.\,]\d{1,2})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
  machineOperatorSalary: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?([\.\,]\d{1,2})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
  materials: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?([\.\,]\d{1,2})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
  basicMaterials: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?([\.\,]\d{1,2})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
  auxiliaryMaterials: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?([\.\,]\d{1,2})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
  equipment: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?([\.\,]\d{1,2})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
  overheads: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?([\.\,]\d{1,2})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
  estimatedProfit: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?([\.\,]\d{1,2})?$/,
      `common:${''}${''}`,
    )
    .required(`common:${''}${''}`),
});
