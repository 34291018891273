import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { dadataSecret, dadataToken, dadataUrl } from 'utils/constants';
import { GetGeoLocateRequest, GetGeoLocateResponse } from './dadata.types';

const baseQuery = fetchBaseQuery({
  baseUrl: dadataUrl,
  mode: 'cors',
  prepareHeaders: (headers) => {
    headers.set('Content-Type', `application/json`);
    headers.set('Accept', `application/json`);
    headers.set('Authorization', `Token ${dadataToken}`);
    headers.set('X-Secret', dadataSecret);

    return headers;
  },
});

export const dadataApi = createApi({
  baseQuery: async (args, queryApi, extraOptions) => {
    return await baseQuery(args, queryApi, extraOptions);
  },
  endpoints: (build) => ({
    getGeoLocate: build.mutation<GetGeoLocateResponse, GetGeoLocateRequest>({
      query: (body) => ({
        url: `/geolocate/address`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetGeoLocateMutation } = dadataApi;
