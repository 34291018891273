import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const StyledAddButton = styled(IconButton)`
  color: white;
  background-color: ${({ theme }) => theme.palette.primary.main};
  position: absolute;
  top: 0;
  left: 10px;
  transform: translateY(-50%);
  z-index: 20;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;
