import styled from 'styled-components';

export const ExecutedTabWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 20px;
  overflow-y: auto;
  overflow-x: auto;
  display: grid;
  height: 100%;
  grid-template-rows: min-content 1fr;
`;

export const ExecutedTabCellLevelFolder = styled.div`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #0044b4;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: white;
`;
