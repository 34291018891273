import React from 'react';
import { ProjectCardHeaderProps } from './ProjectCardHeader.types';
import { Box, Stack, Typography } from '@mui/material';
import { ColoredTitle } from 'components/ColoredTitle';
import { ProjectCardFullNameWrapper, StyledProjectCardHeader } from './styles';
import { projectStatusesColors, projectStatusesForCard } from 'types';

export const ProjectCardHeader: React.FC<ProjectCardHeaderProps> = ({
  data,
}) => {
  const { status, shortName, fullName } = data;

  return (
    <StyledProjectCardHeader
      title={
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1.25}>
            <ColoredTitle
              body={projectStatusesForCard[status]}
              color={projectStatusesColors[status]}
            />
          </Stack>
          <Box>
            <Typography variant="subtitle1">{shortName}</Typography>
          </Box>
        </Stack>
      }
      subheader={
        <ProjectCardFullNameWrapper>
          <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
            {fullName}
          </Typography>
        </ProjectCardFullNameWrapper>
      }
    />
  );
};
