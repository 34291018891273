import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Stack, Tooltip } from '@mui/material';
import {
  StyledLevel as StyledLevel,
  StyledLevelTd,
  StyledBoxLevel,
  StyledContractStack,
  StyledIconButton,
  StyledTitle,
  StyledTypography,
} from '../styles';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { LaborIntensityRow } from '../LaborIntensityRow';
import { BasePricesItem } from '../../../../../../../../api/calculations/types';
import { LevelIcon } from '../../../../../LevelIcon';

interface LaborIntensityLevelPropsType {
  level: BasePricesItem;
  levelNumber: number;
  calculationId: number;
}

type LevelNameType = 'level1' | 'level2' | 'level3' | 'level4';

export const LaborIntensityLevel: React.FC<LaborIntensityLevelPropsType> = ({
  level,
  levelNumber,
  calculationId,
}) => {
  const levelName: LevelNameType = `level${levelNumber}` as LevelNameType;
  const {
    peoplePerHourGen,
    machinesPerHourGen,
    hourlyCostGen,
    peoplePerHourSub,
    machinesPerHourSub,
    hourlyCostSub,
    title,
  } = level;

  /************************************ Show/Hide Nested Row ************************************/

  const [isShowNestedLevel, setIsShowNesteLevel] = useState(true);

  const handleShowNestedLevel = (isShowNestedRow: boolean) => {
    setIsShowNesteLevel(!isShowNestedRow);
  };

  /******************************** Filtered Level/Row *************************************/
  const filteredLevelArr = useMemo(() => {
    if (level.type === 'level') {
      return level.children?.filter((row) => {
        return row.type === 'level';
      });
    }
  }, [level]);

  const filteredRowArr = useMemo(() => {
    if (level.type === 'level') {
      return level.children?.filter((row) => {
        return row.type === 'row';
      });
    }
  }, [level]);

  /****************************************************************************************/

  return (
    <>
      <Box className={`Level-${levelNumber}`}>
        <StyledLevel direction="row" className="Level-grid">
          <StyledLevelTd
            colorsBgLevel={levelName}
            style={{
              paddingLeft: '13px',
              justifyContent: 'flex-start',
              height: '50px',
              minWidth: '60px',
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginRight: `${8 + (levelNumber - 1) * 6}px`,
                minWidth: 24,
              }}>
              {level.children.length > 0 && (
                <StyledIconButton
                  onClick={() => handleShowNestedLevel(isShowNestedLevel)}
                  size="small">
                  {isShowNestedLevel ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </StyledIconButton>
              )}
            </Box>
            <LevelIcon levelName={levelName} />
          </StyledLevelTd>
          <StyledTitle colorsBgLevel={levelName}>
            <Tooltip title={title}>
              <StyledTypography
                style={{
                  textAlign: 'left',
                  marginLeft: 12,
                  color: `${levelName === 'level1' ? '#0044B4' : ' #5C6E8C'}`,
                }}>
                {title}
              </StyledTypography>
            </Tooltip>
          </StyledTitle>
          <StyledBoxLevel
            colorsBgLevel={levelName}
            sx={{ minWidth: '636px', width: '100%' }}>
            <StyledContractStack className="GeneralContract" direction="row">
              <Box sx={{ minWidth: 212 }}>
                <StyledTypography
                  style={{
                    textAlign: 'center',
                    color: `${levelName === 'level1' ? '#0044B4' : ' #5C6E8C'}`,
                  }}>
                  {(peoplePerHourGen ?? 0).toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </StyledTypography>
              </Box>
              <Box sx={{ minWidth: 212 }}>
                <StyledTypography
                  style={{
                    textAlign: 'center',
                    color: `${levelName === 'level1' ? '#0044B4' : ' #5C6E8C'}`,
                  }}>
                  {(machinesPerHourGen ?? 0).toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </StyledTypography>
              </Box>
              <Box sx={{ minWidth: 212 }}>
                <StyledTypography
                  style={{
                    textAlign: 'center',
                    color: `${levelName === 'level1' ? '#0044B4' : ' #5C6E8C'}`,
                  }}>
                  {(hourlyCostGen ?? 0).toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </StyledTypography>
              </Box>
            </StyledContractStack>
          </StyledBoxLevel>
          <StyledLevelTd
            colorsBgLevel={levelName}
            sx={{ minWidth: '64px', height: '100%' }}></StyledLevelTd>
          <StyledBoxLevel
            colorsBgLevel={levelName}
            sx={{ minWidth: '636px', width: '100%' }}>
            <StyledContractStack className="Subcontracting" direction="row">
              <Box sx={{ minWidth: 212 }}>
                <StyledTypography
                  style={{
                    textAlign: 'center',
                    color: `${levelName === 'level1' ? '#0044B4' : ' #5C6E8C'}`,
                  }}>
                  {(peoplePerHourSub ?? 0).toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </StyledTypography>
              </Box>
              <Box sx={{ minWidth: 212 }}>
                <StyledTypography
                  style={{
                    textAlign: 'center',
                    color: `${levelName === 'level1' ? '#0044B4' : ' #5C6E8C'}`,
                  }}>
                  {(machinesPerHourSub ?? 0).toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </StyledTypography>
              </Box>
              <Box sx={{ minWidth: 212 }}>
                <StyledTypography
                  style={{
                    textAlign: 'center',
                    color: `${levelName === 'level1' ? '#0044B4' : ' #5C6E8C'}`,
                  }}>
                  {(hourlyCostSub ?? 0).toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </StyledTypography>
              </Box>
            </StyledContractStack>
          </StyledBoxLevel>
        </StyledLevel>
      </Box>
      {/***************************** level 2 *****************************/}
      {isShowNestedLevel && (
        <>
          {filteredRowArr?.map((rowLevelNext) => {
            return (
              <Fragment key={rowLevelNext.id}>
                {rowLevelNext.type === 'row' && (
                  <LaborIntensityRow
                    calculationId={calculationId}
                    key={rowLevelNext.id}
                    row={rowLevelNext}></LaborIntensityRow>
                )}
              </Fragment>
            );
          })}
          {filteredLevelArr?.map((rowLevelNext) => {
            return (
              <Fragment key={rowLevelNext.id}>
                {rowLevelNext.type === 'level' && (
                  <LaborIntensityLevel
                    calculationId={calculationId}
                    key={rowLevelNext.id}
                    level={rowLevelNext}
                    levelNumber={levelNumber + 1}></LaborIntensityLevel>
                )}
              </Fragment>
            );
          })}
        </>
      )}
    </>
  );
};
