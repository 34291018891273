import { Button } from '@mui/material';
import styled from 'styled-components';
import { FileItem } from './components';

export const StyledButton = styled(Button)`
  text-transform: none;
  font-weight: 400;
`;

export const StyledFileItem = styled(FileItem)`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  }
`;
