/**
 * @author Mr_Fabiozzz[mr.fabiozzz@gmail.com]
 */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { Controller, useForm } from 'react-hook-form';
import { InputEdit } from './Cell.style';
import { StyledTooltip } from '../CellEditorSergey/CellEditor.style';

const EditableCell = forwardRef<any, ICellEditorParams>((props, ref) => {
  const [value, setValue] = useState<string | number>(
    props.value?.toString()?.replace(/\./, ',') || '',
  );

  const {
    control,
    setValue: setFormValue,
    formState,
  } = useForm({
    mode: 'all',
  });
  useImperativeHandle(
    ref,
    () => ({
      getValue: () => {
        return value === '' || value === undefined ? null : value;
      },
      isCancelAfterEnd() {
        return (!formState.isValid && !formState.isDirty) || value === '-';
      },
    }),
    [formState.isValid, value],
  );

  useEffect(() => {
    props?.eGridCell?.getElementsByTagName('input')[0]?.select();
    props?.eGridCell?.getElementsByTagName('input')[0]?.focus();
  }, []);

  return (
    <Controller
      control={control}
      name={'input'}
      defaultValue={value}
      render={({ formState }) => {
        const errorMessage =
          (formState.errors['input']?.message &&
            formState.errors['input']?.message.toString()) ||
          '';
        return (
          <StyledTooltip
            title={errorMessage}
            color={'error'}
            placement="right"
            open={!!errorMessage}>
            {/*<Box display={'flex'} width={'calc(100% - 16px)'} height={'100%'}>*/}
            <InputEdit
              onFocus={(e: { target: { select: () => void } }) =>
                e.target.select()
              }
              allowedDecimalSeparators={[',', '.']}
              decimalSeparator={','}
              style={{
                borderBottom: `1px solid ${!!errorMessage ? '#e53935' : '#3f51b5'}`,
                ...(!!errorMessage && {
                  background: 'rgba(229,57,53,0.09)',
                  color: '#e53935',
                }),
              }}
              allowNegative
              onValueChange={(event) => {
                setValue(() => event.formattedValue);
                setFormValue('input', event.floatValue, {
                  shouldValidate: true,
                });
              }}
              autoFocus
              thousandSeparator={' '}
              decimalScale={6}
              value={value}
              onBlur={() => !formState.isDirty && props.stopEditing(true)}
            />
            {/*</Box>*/}
          </StyledTooltip>
        );
      }}
    />
  );
});
EditableCell.displayName = 'EditableCell';
export default EditableCell;
