import React from 'react';
import AdminNavBar from '../../components/AdminNavBar';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { InnerContainer, MainContainer } from '../HomeLayout/styles';
import { profileApi } from '../../api/profile';
import { useTypedSelector } from '../../store/store';

const AdminLayout: React.FC = () => {
  const queries = useTypedSelector((state) => state.api.queries);
  const navigate = useNavigate();
  const keyProfile = React.useMemo(() => {
    const key = Object.keys(queries).find((k) => k.startsWith('getProfile'));
    return key;
  }, []);

  React.useEffect(() => {
    if (keyProfile) {
      const isAdmin = (
        queries?.[keyProfile as keyof typeof queries]?.data as any
      )?.admin;
      if (!isAdmin) {
        navigate('/', { replace: true });
      }
      console.log(isAdmin);
    }
  }, [keyProfile]);

  return (
    <>
      <AdminNavBar />
      <MainContainer className="MainContainer">
        <Header />
        <InnerContainer className="InnerContainer">
          <Outlet />
        </InnerContainer>
      </MainContainer>
    </>
  );
};

export default AdminLayout;
