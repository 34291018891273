import React, { useContext } from 'react';
import { InputAdornment } from '@mui/material';
import { StyledSearch, StyledSearchBox, StyledSearchIcon } from './styles';
import { uiContext } from '../../../contexts/ui';

const Search: React.FC = () => {
  const cUI = useContext(uiContext);

  return (
    <StyledSearchBox>
      <StyledSearch
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <StyledSearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Поиск"
        ref={cUI.searchRef}
        onChange={(e) => cUI.setSearchValue(e.target.value, 'userInput')}
      />
    </StyledSearchBox>
  );
};

export default Search;
