import { GroupFolder } from 'assets/SVG/GroupFolder';
import { FC } from 'react';
import { StyledLevelFolder } from './LevelFolder.styles';
import { LevelFolderProps } from './LevelFolder.types';

export const LevelFolder: FC<LevelFolderProps> = ({
  level,
  size = 'medium',
  isExpanded,
  isHover,
  ...rest
}): JSX.Element => {
  return (
    <StyledLevelFolder
      $level={level}
      $isHover={isHover}
      $isExpanded={isExpanded}
      $size={size}
      {...rest}>
      <GroupFolder color="inherit" fontSize={size} />
    </StyledLevelFolder>
  );
};
