/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 * @author CyrilStrone<77802640+CyrilStrone@users.noreply.github.com>
 */

import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DatePickerRenderInput } from 'components/FieldForm/styles';
import { IconDate } from 'pages/Calculations/components/Accomplishment/components/ActDialog/ActDialog.style';
import {
  ActCol,
  ActRow
} from 'pages/Calculations/components/CalculationBasic/components/ParametersDialog/components/ActsTab/ActsTab.style';
import { HandbookContext } from 'pages/Calculations/components/CalculationDirectory/handbook.services';
import React, { useContext, useMemo } from 'react';
import { ActList } from 'types';
import { formatDateToStringV2, formatToDate } from 'utils/formatDate';
import { IParametersFormAct, IParametersUpdateDate } from '../../';
import { statuses } from '../../../helper';

export const EditableRowAct: React.FC<{
  [key: string]: any;
  row: ActList;
  actualActList: IParametersFormAct[];
  updateIsRemoved: (id: number, newRemoved: boolean) => void;
  updateDate: (props: IParametersUpdateDate) => void;
}> = ({ calculation, row, actualActList, updateIsRemoved, updateDate }) => {
  const { currentAct } = useContext(HandbookContext);

  const startDate = React.useMemo(() => {
    return row.startDate;
  }, [row]);

  const endDate = React.useMemo(() => {
    return row.endDate;
  }, [row]);

  /**
   * являются ли даты одинаковыми
   * @param dateA
   * @param dateB
   */
  const isSameDate = (dateA: Date, dateB: Date) =>
    dateA.getDate() === dateB.getDate() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getFullYear() === dateB.getFullYear();

  /**
   * разбирается список дат для вывода на страницу, делается плоская структура
   */
  const unFlatRanges = React.useMemo(() => {
    const fields = actualActList || [];
    return fields
      .reduce((acc: { start: string | Date; end: string | Date }[], item) => {
        acc.push({ start: item.startDate || '', end: item.endDate || '' });
        return acc;
      }, [])
      .filter(
        (d) =>
          !isSameDate(d.start as Date, new Date(row.startDate || '')) &&
          !isSameDate(d.end as Date, new Date(row.endDate || ''))
      );
  }, [actualActList, row.endDate, row.startDate]);

  /**
   * массив недоступных дат
   */
  const disabledDates = useMemo(() => {
    const fields = actualActList || [];
    if (!fields.length) return [];
    return unFlatRanges.reduce(
      (disabled, dateRange) => {
        const { start, end } = dateRange;
        disabled.push({ start: new Date(start), end: new Date(end) });
        return disabled;
      },
      [] as { start: Date; end: Date }[]
    );
  }, [actualActList, unFlatRanges]);

  /**
   * Здесь идет расчет
   * вычисляется какое это поле и устанавливает максимальную и минимальную даты
   */
  const disabledRange = useMemo(() => {
    const dates = {
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2099, 11, 31)
    };

    return dates;
  }, []);

  const checkDate = (
    inputDate: Date | null,
    start: Date | null,
    end: Date | null
  ) => {
    if (!inputDate || !start || !end) return false;
    return (
      isSameDate(inputDate, start) ||
      (inputDate > start && inputDate < end) ||
      isSameDate(inputDate, end)
    );
  };

  const shouldDisabled = (
    dayOrMonth: unknown,
    render: 'startDate' | 'endDate'
  ) => {
    if (dayOrMonth) {
      if (disabledDates.length) {
        if (render === 'startDate') {
          return disabledDates.some(({ start, end }) =>
            checkDate(dayOrMonth as Date, start, end)
          );
        }
        if (render === 'endDate') {
          return disabledDates.some(({ start, end }) =>
            checkDate(dayOrMonth as Date, start, end)
          );
        }
      }
    }
    return false;
  };

  return (
    <ActRow
      isIntegrate={!!calculation?.integrationInfo}
      active={row.id === currentAct?.id}>
      <ActCol>
        {row.onDate
          ? formatDateToStringV2(new Date(row.onDate), 'dd.MM.yyyy')
          : ''}
      </ActCol>
      <ActCol>
        <DesktopDatePicker
          value={startDate}
          openTo="year"
          views={['year', 'month', 'day']}
          maxDate={disabledRange.maxDate}
          minDate={disabledRange.minDate}
          shouldDisableMonth={(d) => shouldDisabled(d, 'startDate')}
          shouldDisableDate={(d) => shouldDisabled(d, 'startDate')}
          onChange={(e, keyboardInputValue) => {
            const userValue = keyboardInputValue
              ? keyboardInputValue
              : e &&
                  e instanceof Date &&
                  !isNaN(e.getTime()) &&
                  !keyboardInputValue
                ? e.toLocaleDateString('ru-RU')
                : '';

            if (/^\d{2}\.\d{2}\.\d{4}/.test(userValue)) {
              updateDate({
                id: row.id,
                startDate: e as Date
              });
            }
          }}
          components={{
            OpenPickerIcon: IconDate
          }}
          renderInput={(params) => (
            <DatePickerRenderInput
              {...params}
              value={startDate}
              onChange={(e) => {
                e.stopPropagation();
                if (!e.target.value) {
                  updateDate({ id: row.id, startDate: null });
                  return;
                } else if (
                  /\^(\d{2})\.(\d{2})\.(\d{4})$/.test(e.target.value)
                ) {
                  const date = new Date(
                    formatToDate(e!.target!.value! as string, 'yyyy-MM-dd')!
                  );
                  updateDate({
                    id: row.id,
                    startDate: !isNaN(date.getTime()) ? date : e.target.value
                  });
                }
              }}
              inputProps={{
                ...params.inputProps,
                style: { padding: '6px 6px 6px 8px' },
                placeholder: 'дд.мм.гггг',
                autoComplete: 'off'
              }}
            />
          )}
        />
      </ActCol>
      <ActCol align={'center'}>&mdash;</ActCol>
      <ActCol>
        <DesktopDatePicker
          value={endDate}
          components={{
            OpenPickerIcon: IconDate
          }}
          maxDate={disabledRange.maxDate}
          minDate={disabledRange.minDate}
          openTo="year"
          views={['year', 'month', 'day']}
          shouldDisableMonth={(d) => shouldDisabled(d, 'endDate')}
          shouldDisableDate={(d) => shouldDisabled(d, 'endDate')}
          onChange={(e: any, keyboardInputValue) => {
            const userValue = keyboardInputValue
              ? keyboardInputValue
              : e && !isNaN(e.getTime()) && !keyboardInputValue
                ? e.toLocaleDateString('ru-RU')
                : '';
            if (/^\d{2}\.\d{2}\.\d{4}/.test(userValue)) {
              updateDate({
                id: row.id,
                endDate: e
              });
            }
          }}
          renderInput={(params) => (
            <DatePickerRenderInput
              {...params}
              value={endDate}
              onChange={(e) => {
                e.stopPropagation();
                if (!e.target.value) {
                  updateDate({
                    id: row.id,
                    endDate: null
                  });
                } else if (
                  /\^(\d{2})\.(\d{2})\.(\d{4})$/.test(e.target.value)
                ) {
                  const date = new Date(
                    formatToDate(e!.target!.value! as string, 'yyyy-MM-dd')!
                  );
                  updateDate({
                    id: row.id,
                    endDate: !isNaN(date.getTime()) ? date : e.target.value
                  });
                }
              }}
              inputProps={{
                ...params.inputProps,
                style: { padding: '6px 6px 6px 8px' },
                placeholder: 'дд.мм.гггг',
                // maxLength,
                // minLength,
                autoComplete: 'off'
              }}
            />
          )}
        />
      </ActCol>
      <ActCol align={'center'}>
        <IconButton
          onClick={() => updateIsRemoved(row.id, true)}
          color="warning">
          <Delete />
        </IconButton>
      </ActCol>
      {calculation?.integrationInfo && (
        <ActCol color={statuses[row.status!]!.color} align={'center'}>
          {statuses[row.status!]!.title}
        </ActCol>
      )}
    </ActRow>
  );
};
