import FlagIcon from '@mui/icons-material/Flag';
import { Stack, TextField } from '@mui/material';
import { GetGeoLocateResponse, useGetGeoLocateMutation } from 'api/dadata';
import regions from 'assets/regions/regions.json';
import { Region } from 'assets/regions/types';
import { useFormikContext } from 'formik';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import React, { FC, ReactElement, useCallback, useState } from 'react';
import { AddressSuggestions } from 'react-dadata';
import { DaDataSuggestion } from 'react-dadata/dist/types';
import { dadataToken } from 'utils/constants';
import { AutocompleteField } from '../../../../../components/AutocompleteField/AutocompleteField';
import { ProjectFormData } from '../../ProjectManagement.types';
import { FormItem } from '../FormItem';
import { FormTextField } from '../FormLeftBlock/FormLeftBlock.styles';
import { MapFormButton } from '../MapForm';
import { AddressBlockView } from './AddressBlock.types';
import { NumericFormat } from 'react-number-format';

export const AddressBlock: FC = () => {
  const { values: formValues, setFieldValue } =
    useFormikContext<ProjectFormData>();
  const [addressBlockView, setAddressBlockView] =
    useState<AddressBlockView>('address');

  const [getGeoLocate, getGeoLocateResponse] = useGetGeoLocateMutation();

  const onAddressBlockViewChange = useCallback(
    (addressBlockView: AddressBlockView) => {
      switch (addressBlockView) {
        case 'address':
          setAddressBlockView('coordinate');
          break;
        case 'coordinate':
          setAddressBlockView('address');
          break;
      }
    },
    [],
  );

  const findAddressByCoords = (lat: number, lon: number) => {
    getGeoLocate({ lat, lon });
  };

  useMutationHandlers(getGeoLocateResponse, (data: GetGeoLocateResponse) => {
    if (data.suggestions.length) {
      const suggestion = data.suggestions[0];
      const fullRegion =
        regions.find((region) => region.name === suggestion?.data?.region)
          ?.name_with_type || '';
      const correctAddress = suggestion.value.split(', ').slice(1).join(', ');

      setFieldValue('addressData.region', fullRegion);
      setFieldValue('addressData.address', {
        ...suggestion,
        value: correctAddress,
      });
    }
  });

  const onAddressSelect = useCallback((suggestion?: DaDataSuggestion<any>) => {
    console.log(suggestion);
    if (suggestion) {
      setFieldValue('addressData.address', suggestion);
      setFieldValue('addressData.latitude', '');
      setFieldValue('addressData.longitude', '');
    }
  }, []);

  const onRegionClear = (e: React.SyntheticEvent, value: string) => {
    !value && setFieldValue('addressData.address', '');
  };

  const regionForQuery =
    regions.find(
      (region) => region.name_with_type === formValues.addressData.region,
    )?.name || '';

  const AddressBlockContent = (
    <>
      <FormItem
        title="Регион"
        input={
          <AutocompleteField
            fieldName="addressData.region"
            placeholder="Выберите регион"
            onInputChange={onRegionClear}
            data={(regions as Region[]).map(({ name_with_type }) => ({
              value: name_with_type,
              label: name_with_type,
              key: name_with_type,
            }))}
          />
        }
      />
      <FormItem
        title="Город, улица, дом"
        input={
          <AddressSuggestions
            customInput={TextField}
            value={formValues.addressData?.address}
            onChange={onAddressSelect}
            filterLocations={[{ region: regionForQuery }]}
            filterRestrictValue
            filterFromBound="city"
            filterToBound="house"
            inputProps={{
              placeholder: 'Напишите адрес проекта',
              className: '',
              disabled: !formValues.addressData.region,
            }}
            delay={300}
            token={dadataToken}
            selectOnBlur
          />
        }
      />
    </>
  );

  const CoordinateBlockContent = (
    <>
      <FormItem
        title="Широта"
        input={
          <NumericFormat
            customInput={FormTextField}
            version="project"
            name="addressData.latitude"
            placeholder="Введите широту для поиска на карте"
            InputProps={{
              endAdornment: <FlagIcon fontSize="medium" color="secondary" />,
            }}
            decimalSeparator="."
            onChange={(e) =>
              setFieldValue('addressData.latitude', e.target.value)
            }
          />
        }
      />
      <FormItem
        title="Долгота"
        input={
          <NumericFormat
            customInput={FormTextField}
            version="project"
            name="addressData.longitude"
            placeholder="Введите долготу для поиска на карте"
            InputProps={{
              endAdornment: <FlagIcon fontSize="medium" color="secondary" />,
            }}
            decimalSeparator="."
            onChange={(e) =>
              setFieldValue('addressData.longitude', e.target.value)
            }
          />
        }
      />
      <MapFormButton
        onClick={() =>
          findAddressByCoords(
            +formValues.addressData.latitude,
            +formValues.addressData.longitude,
          )
        }
        disabled={
          !(formValues.addressData.latitude && formValues.addressData.longitude)
        }
        variant="text">
        Найти
      </MapFormButton>
    </>
  );

  const addressBlockButtonText: Record<AddressBlockView, string> = {
    address: 'Найти место с помощью координат',
    coordinate: 'Найти по адресу проекта',
  };

  const Content: Record<AddressBlockView, ReactElement> = {
    address: AddressBlockContent,
    coordinate: CoordinateBlockContent,
  };

  return (
    <Stack alignItems="end" spacing={2.5}>
      {Content[addressBlockView]}

      <MapFormButton
        onClick={() => onAddressBlockViewChange(addressBlockView)}
        variant="text">
        {addressBlockButtonText[addressBlockView]}
      </MapFormButton>
    </Stack>
  );
};
