import { FC } from 'react';
import { StyledDivider } from './Divider.styles';
import { DividerProps } from './Divider.types';

export const Divider: FC<DividerProps> = ({
  thickness = '2px',
  rounded = false,
  ...props
}) => {
  return (
    <StyledDivider
      $thickness={thickness}
      $rounded={rounded}
      orientation="horizontal"
      {...props}
    />
  );
};
