import { ProjectResponsible } from 'types';
import { FormResponsible } from '../ProjectManagement.types';

export function mapResponsible({
  companyName,
  id,
  userName,
}: ProjectResponsible): FormResponsible {
  return {
    companyName,
    data: `${id}&${userName}`,
  };
}
