import { RowType, VORGroupData } from 'api/calculations/types';

export function getRowIdsByType(rows: VORGroupData[], rowType: RowType) {
  return rows.filter((row) => row.type === rowType).map((row) => row.id);
}

export function getSlicedChildrenIds(
  childrenIds: number[],
  index: number,
  isRowTypeCorrect: boolean,
) {
  return isRowTypeCorrect ? childrenIds.slice(index, childrenIds.length) : [];
}
