import { StyledBody, StyledLoading } from '../../Table.styles';
import { BodyProps } from './Body.types';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import { NoRowsOverlay } from '../NoRowsOverlay';
import { Row, SplittedRow } from '../Row';
import { IntervalRow } from '../IntervalRow';
import { useMemo } from 'react';
import { ComplicatedRowResourceRow } from '../../../../../../../../types';

export const Body: React.FC<BodyProps> = ({
  data,
  loading,
  successWithoutFilters,
  tableColumns,
}) => {
  const intervalSplitedRows = useMemo(() => {
    return data.reduce(
      (accumulator, current) => [...accumulator, ...current.splitRows],
      [] as ComplicatedRowResourceRow[],
    );
  }, [data]);

  const intervalGroupedRowByTag = useMemo(() => {
    const rows = data.reduce(
      (accumulator, current) => [...accumulator, ...current.rows],
      [] as ComplicatedRowResourceRow[],
    );
    return _.groupBy(rows, 'type');
  }, [data]);

  if (loading) {
    return (
      <StyledLoading>
        <CircularProgress />
      </StyledLoading>
    );
  } else if (!loading && !data.length) {
    return <NoRowsOverlay />;
  } else {
    return (
      <StyledBody>
        {!successWithoutFilters ? (
          <IntervalRow
            groupedRowByTag={intervalGroupedRowByTag}
            tableColumns={tableColumns}
          />
        ) : (
          <>
            {data.map((row, index) => (
              <Row
                key={row.id ?? index}
                row={row}
                tableColumns={tableColumns}
              />
            ))}
          </>
        )}

        {!successWithoutFilters && intervalSplitedRows.length > 0 && (
          <SplittedRow
            tableColumns={tableColumns}
            splittedRows={intervalSplitedRows}
            groupedRowByTag={intervalGroupedRowByTag}
          />
        )}
      </StyledBody>
    );
  }
};
