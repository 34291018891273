/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Typography } from '@mui/material';
import { WSFile } from 'api/web-socket';
import { FormikProvider } from 'formik';
import React, { useState } from 'react';
import {
  TCountUpload,
  useLazyGetTemplateCalcQuery,
  useUploadHandbvookCalcMutation
} from '../../../../../api/calculationDictionary';
import { OrNull } from '../../../../../api/references/estimates/estimates.types';
import Button from '../../../../../components/Button';
import { Drawer } from '../../../../../components/Drawer';
import { useCalcId } from '../../../../../hooks/useCalcId';
import useConfirmDialog from '../../../../../hooks/useConfirmDialog';
import { useForm } from '../../../../../hooks/useForm';
import { useMutationHandlers } from '../../../../../hooks/useMutationHandlers';
import { downloadBase64File } from '../../../../../utils/downloadBase64File';
import CopyUploadForm from '../../../../Administration/AdminReferences/Prices/components/ImportDrawer/components/CopyUploadForm';
import { IErrorData } from '../../../../Administration/AdminReferences/Prices/components/ImportDrawer/ImportDrawer';

type TProps = {
  onClose: () => void;
  open: boolean;
};

const UploadDrawer: React.FC<TProps> = ({ onClose, open }) => {
  const { openConfirm, ConfirmDialog } = useConfirmDialog({
    handleConfirm: (confirm) => {
      if (confirm) resetAndCloseDrawer();
    }
  });

  const calcID = useCalcId();

  const [errorsMSGs, setErrorsMSGs] = React.useState<IErrorData[]>([]);
  const [doneUpload, setDoneUpload] = React.useState<{
    done: boolean;
    counted: OrNull<TCountUpload>;
  }>({
    done: false,
    counted: null
  });
  const [errorUpload, setErrorUpload] = React.useState<boolean>(false);

  const [filesData, setFilesData] = useState<WSFile[] | null>(null);
  const [formData, setFormData] = useState<any>(null);

  const [uploadFile, setUploadFile] = React.useState<{
    b64Content: string;
    name: string;
  } | null>(null);

  const [uploadFileFn, uploadResponse] = useUploadHandbvookCalcMutation();
  const [getTemplate, getTemplateResponse] = useLazyGetTemplateCalcQuery();

  const handleDownload = () => {
    getTemplate();
  };
  const initialValues: { files: OrNull<File[]> } = {
    files: null
  };
  const resetAndCloseDrawer = () => {
    onClose();

    setTimeout(() => {
      formik.resetForm();
      setFilesData(null);
      setUploadFile(null);
    }, 300);
  };

  const handleCloseDrawer = React.useCallback(() => {
    if (uploadFile) {
      openConfirm();
      return;
    }
    onClose();
  }, [uploadFile]);

  const { formik } = useForm({
    initialValues,
    onSubmit: (values) => {
      const file: any = values.files?.[0];
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        uploadFileFn({
          calcID,
          body: formData as any
        });
      }
    }
  });

  React.useEffect(() => {
    if (formik.values.files && formik.values.files.length) {
      const base64File = { b64Content: '', name: '' };
      const file = formik.values.files[0] as File;
      const reader = new FileReader();

      reader.onload = ({ target }) => {
        const base64String = (target!.result as string)
          .replace('data:', '')
          .replace(/^.+,/, '');

        base64File.b64Content = base64String;
        base64File.name = file.name;

        setUploadFile(base64File);
      };

      reader.readAsDataURL(file);
    }
  }, [formik.values]);

  useMutationHandlers(getTemplateResponse, (response) =>
    downloadBase64File(response, 'Шаблон расчета.xlsx')
  );
  useMutationHandlers(uploadResponse, (response) => {
    setDoneUpload({ done: response.isSuccess, counted: response.count });
    if (response.errors && response.errors.length) {
      setErrorUpload(true);
      setErrorsMSGs(
        response.errors.map((err) => ({
          address: err?.cell ?? '',
          msg: err.message
        }))
      );
    }
  });

  React.useEffect(() => {
    if (!open) {
      setTimeout(() => {
        formik.resetForm();
        setErrorUpload(false);
        setFormData(null);
        setFilesData(null);
        setDoneUpload({ done: false, counted: null });
      }, 300);
    }
  }, [open]);

  return (
    <Drawer
      title={'Импорт'}
      onClose={() => (doneUpload.done ? onClose() : handleCloseDrawer())}
      open={open}>
      <Box
        height={'100%'}
        display={'grid'}
        gridTemplateColumns={'1fr'}
        gridTemplateRows={'repeat(3, min-content) 1fr'}
        px={'20px'}
        pb={'20px'}>
        <FormikProvider value={formik}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems={'center'}
            py={'20px'}>
            <Typography
              lineHeight={'20.02px'}
              variant="body2"
              color={({ palette: { warning } }) => warning.main}
              textAlign={'center'}
              component="p">
              ВНИМАНИЕ!
            </Typography>
            <Typography
              lineHeight={'20.02px'}
              variant="body2"
              textAlign={'center'}
              component="p">
              При загрузке файла данные будут полностью перезаписаны.
            </Typography>
          </Box>
          <Box py={'20px'}>
            <CopyUploadForm
              format={{
                'application/vnd.ms-excel': ['.xls'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  ['.xlsx']
              }}
              showDownload={false}
              done={doneUpload.done}
              updatedData={doneUpload.counted}
              setError={(err) => setErrorUpload(err)}
              error={errorUpload}
              isLoading={uploadResponse.isLoading}
              setFormData={setFormData}
              setFilesData={setFilesData}
              isWS={false}
              errorsMSG={errorsMSGs}
            />
          </Box>

          <Box
            whiteSpace={'nowrap'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}>
            {!uploadResponse.isLoading && (
              <Button onClick={handleDownload} type={'button'} variant={'text'}>
                <DownloadIcon />
                скачать шаблон формы
              </Button>
            )}
          </Box>
        </FormikProvider>
        <Box
          width={'100%'}
          justifySelf={'end'}
          alignSelf={'end'}
          display={'grid'}
          gridTemplateColumns={'1fr 1fr'}
          gap={'16px'}>
          {doneUpload.done ? (
            <Button
              sx={{ gridArea: '1 / span 2', maxWidth: '500px !important' }}
              color={'success'}
              onClick={onClose}>
              Готово
            </Button>
          ) : (
            <>
              <Button
                disabled={!uploadFile}
                color={'success'}
                onClick={() => formik.handleSubmit()}>
                обработать
              </Button>
              <Button onClick={handleCloseDrawer}>закрыть</Button>
            </>
          )}
        </Box>
      </Box>
      <ConfirmDialog />
    </Drawer>
  );
};

export default UploadDrawer;
