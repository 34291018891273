import { FC } from 'react';
import { Body } from './components';
import { TableStyled } from './Table.styles';

export const Table: FC<{ isHandbook: boolean }> = ({ isHandbook }) => {
  return (
    <TableStyled>
      <Body isHandbook={isHandbook} />
    </TableStyled>
  );
};
