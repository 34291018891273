import { Button, Dialog, paperClasses } from '@mui/material';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${paperClasses.root}`]: {
    borderRadius: theme.spacing(2),
    height: '100%',
  },
}));

export const StyledDialogHeader = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: theme.spacing(2.5),
  paddingInline: theme.spacing(3.75),
  paddingBottom: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.legends.divider}`,
  color: `${theme.palette.secondary.gray}`,
}));

export const StyledBackButton = styled(Button)`
  line-height: 0;
`;

export const StyledHelpButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  minWidth: '36px',
  maxWidth: '36px',
}));

export const StyledSuccessButton = styled(Button)`
  width: 164px;
`;

export const StyledWrapper = styled.div(({ theme }) => ({
  border: `1px solid ${theme.palette.bg.gray}`,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
}));
export const SelectField = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  user-select: none;
  color: #5c6e8c;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;
