/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import { ActList, CalcData, EPragmaModules } from 'types';
import { Box, Typography } from '@mui/material';
import Button from 'components/Button';
import React, { PropsWithChildren, useMemo } from 'react';
import { formatDateToString } from 'utils/formatDate';
import Tooltip from '../../../../../../../components/Tooltip';
import { formatDate } from '../../../../Accomplishment/components/CaptionTable/CaptionTable';
import { LinkAct } from '../drawer.style';

type Props = {
  act: ActList;
  actList: ActList[];
  sync: () => void;
  closeAct: () => void;
  getFact: () => void;
  calculation?: CalcData;
  changeAct: (act: ActList | null) => void;
};
const ContentBox: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      display={'flex'}
      width={'100%'}
      alignItems={'center'}
      justifyContent={'space-between'}
      gap={'10px'}>
      {children}
    </Box>
  );
};

const Content: React.FC<Props> = ({
  act,
  sync,
  getFact,
  closeAct,
  actList,
  calculation,
  changeAct,
}) => {
  // const { calculation, data, setCurrent } = useContext(ActListContext);

  const collectingAct = useMemo(() => {
    return actList?.find((item) => item.status === 'COLLECTING');
  }, [actList]);

  const canceledIntegrate = useMemo(() => {
    if (act.status === 'NEW' || act.status === 'REOPENED') {
      return !!collectingAct;
    }
    return false;
  }, [collectingAct, act]);

  const syncDate = useMemo(() => {
    // return act.syncedAt && (formatDateToString(new Date(act.syncedAt), "dd.MM.yyyy hh:") + getMinutes(new Date(act.syncedAt)))
    return (
      act.syncedAt && formatDateToString(new Date(act.syncedAt), 'dd.MM.yyyy')
    );
  }, [act.syncedAt]);

  if (!calculation?.integrationInfo) return null;

  switch (act.status) {
    case 'NEW': {
      return (
        <React.Fragment>
          <Typography variant={'h2'} fontWeight={600} fontSize={'16px'}>
            {canceledIntegrate
              ? 'Синхронизация невозможна'
              : 'Статус синхронизации периода'}
          </Typography>
          <Typography variant={'body1'} fontSize={'14px'}>
            {canceledIntegrate && collectingAct ? (
              <span>
                Синхронизация периода с модулем{' '}
                {EPragmaModules[calculation.integrationInfo.module]} невозможна
                <br />
                Есть не закрытый акт{' '}
                <LinkAct onClick={() => changeAct(collectingAct)}>
                  {formatDate(collectingAct)}
                </LinkAct>
              </span>
            ) : (
              `Синхронизация периода с модулем ${EPragmaModules[calculation.integrationInfo.module]} не выполнялась`
            )}
          </Typography>
          <Button
            onClick={sync}
            disabled={canceledIntegrate}
            variant={'outlined'}
            fullWidth
            sx={{ maxWidth: '100% !important' }}>
            Синхронизировать период
          </Button>
        </React.Fragment>
      );
    }
    case 'REOPENED': {
      return (
        <React.Fragment>
          <Typography variant={'h2'} fontWeight={600} fontSize={'16px'}>
            {canceledIntegrate
              ? 'Синхронизация невозможна'
              : 'Статус измененного периода'}
          </Typography>
          <Typography variant={'body1'} fontSize={'14px'}>
            {
              canceledIntegrate && collectingAct ? (
                <span>
                  Синхронизация периода с модулем{' '}
                  {EPragmaModules[calculation.integrationInfo.module]}{' '}
                  невозможна
                  <br />
                  Есть не закрытый акт{' '}
                  <LinkAct onClick={() => changeAct(collectingAct)}>
                    {formatDate(collectingAct)}
                  </LinkAct>
                </span>
              ) : (
                `Данные закрытого периода были изменены. Модуль ${EPragmaModules[calculation.integrationInfo.module]} получит актуальный факт по периоду после нажатия на кнопку`
              )
              // `Синхронизация периода с модулем ${EPragmaModules[calculation!.integrationInfo!.module!]} не выполнялась`
            }
          </Typography>
          <Button
            onClick={closeAct}
            variant={'outlined'}
            color={'success'}
            fullWidth
            sx={{ maxWidth: '100% !important' }}>
            Закрыть период
          </Button>
        </React.Fragment>
      );
    }
    case 'COLLECTING': {
      return (
        <React.Fragment>
          <Typography variant={'h2'} fontWeight={600} fontSize={'16px'}>
            Статус синхронизации факта
          </Typography>
          <ContentBox>
            <Typography
              variant={'body1'}
              whiteSpace={'nowrap'}
              fontSize={'14px'}>
              Ответственный с Estimation:
            </Typography>
            <Tooltip title={act.responsible}>
              <Typography
                variant={'body1'}
                whiteSpace={'nowrap'}
                color={'black'}
                fontSize={'14px'}
                overflow={'hidden'}
                textOverflow={'ellipsis'}>
                {act.responsible || '-'}
              </Typography>
            </Tooltip>
          </ContentBox>
          <ContentBox>
            <Typography variant={'body1'} fontSize={'14px'}>
              Последний запрос:
            </Typography>
            <Typography variant={'body1'} color={'black'} fontSize={'14px'}>
              {syncDate}
            </Typography>
          </ContentBox>

          <Button
            onClick={getFact}
            variant={'outlined'}
            fullWidth
            sx={{ maxWidth: '100% !important' }}>
            запросить факт
          </Button>
          <Button
            onClick={closeAct}
            variant={'outlined'}
            color={'success'}
            fullWidth
            sx={{ maxWidth: '100% !important' }}>
            Закрыть период
          </Button>
        </React.Fragment>
      );
    }
    case 'CLOSED': {
      return (
        <React.Fragment>
          <Typography variant={'h2'} fontWeight={600} fontSize={'16px'}>
            Период закрыт
          </Typography>
          <ContentBox>
            <Typography
              variant={'body1'}
              whiteSpace={'nowrap'}
              fontSize={'14px'}>
              Ответственный с Estimation:
            </Typography>
            <Tooltip title={act.responsible}>
              <Typography
                variant={'body1'}
                whiteSpace={'nowrap'}
                color={'black'}
                fontSize={'14px'}
                overflow={'hidden'}
                textOverflow={'ellipsis'}>
                {act.responsible || '-'}
              </Typography>
            </Tooltip>
          </ContentBox>
          <ContentBox>
            <Typography variant={'body1'} fontSize={'14px'}>
              Последний запрос:
            </Typography>
            <Typography variant={'body1'} color={'black'} fontSize={'14px'}>
              {syncDate}
            </Typography>
          </ContentBox>
        </React.Fragment>
      );
    }
    default:
      return null;
  }
};

export default React.memo(Content);
// {/*<React.Fragment>*/}
// {/*  <Typography variant={"h2"} fontWeight={600} fontSize={"16px"}>Статус измененного периода</Typography>*/}
// {/*    <Typography variant={"body1"} textAlign={'center'} fontSize={"14px"}>Данные закрытого периода были изменены. Модуль {EPragmaModules[calculation!.integrationInfo!.module!]} получит актуальный факт по периоду после нажатия на кнопку</Typography>*/}
// {/*  <Button variant={"outlined"} fullWidth sx={{ maxWidth: "100% !important" }}>закрыть период</Button>*/}
// {/*</React.Fragment>*/}
