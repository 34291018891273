import { Stack } from '@mui/material';
import styled from 'styled-components';

export const StyledTagRow = styled(Stack)<{ $template: string }>(
  ({ theme, $template }) => ({
    display: 'grid',
    gridTemplateColumns: $template,
    backgroundColor: theme.palette.bg.shades,
    borderBottom: `1px solid ${theme.palette.legends.gray}`,
    alignItems: 'center',
  }),
);

export const StyledSubRowsBody = styled.div<{ $template: string }>`
  display: grid;
  grid-template-columns: ${({ $template }) => $template};
  align-content: center;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.legends.gray}`};
  min-height: 40px;
`;

export const StyledTotal = styled.div`
  //display: grid;
  //grid-template-columns: 1fr 1fr;
`;
