import { useCreateLevelMutation } from 'api/calculations';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import { useSnackbar } from 'notistack';
import { getSlicedChildrenIds } from './hooks.service';
import { CreateLevelPayload } from './hooks.types';

export const useCreateLevel = (isHandbook?: boolean) => {
  const [createLevelMutation, createLevelResponse] = useCreateLevelMutation();
  const { enqueueSnackbar } = useSnackbar();

  useMutationHandlers(
    createLevelResponse,
    () => {
      enqueueSnackbar('Уровень создан', {
        variant: 'success'
      });
    },
    () => {
      enqueueSnackbar('Ошибка при создании уровня', {
        variant: 'error'
      });
    }
  );

  const createLevel = ({
    rowLevel,
    calcID,
    childrenIds,
    groupIds,
    title,
    rowType,
    level,
    rowID,
    levelIds,
    upperNeighborID
  }: CreateLevelPayload) => {
    const mergedIds = [...childrenIds, ...groupIds];

    const currentRow = childrenIds.get(rowID);

    const allChildren = currentRow?.neighbors ?? [];

    const index = allChildren?.findIndex((el) => el.id === rowID);

    const children = allChildren
      ?.filter((item, i) => item.type === 'folded' && i >= (index ?? -1))
      .map((_) => _.id);

    const childrenGroup = allChildren
      ?.filter((item, i) => i >= (index ?? -1))
      ?.filter((item, i) => item.type === 'group')
      .map((_) => _.id);

    const parentIndex = currentRow?.parentID
      ? (childrenIds
          .get(currentRow.parentID)
          ?.neighbors?.findIndex((el) => el.id === currentRow?.parentID) ?? -1)
      : -1;

    const groupChildren =
      rowType === 'folded' && parentIndex >= 0
        ? (childrenIds
            .get(currentRow?.parentID!)
            ?.neighbors?.filter((el, i) => i > parentIndex)
            ?.filter((_) => _.type === 'group' && rowID !== _.id)
            ?.map((_) => _.id) ?? [])
        : (currentRow?.neighbors
            ?.filter((item, i) => i >= index)
            ?.filter((item, i) => item.type === 'group')
            ?.map((_) => _.id) ?? []);

    console.log(rowID, rowLevel, level, groupChildren, rowType);
    console.log('log submit data', {
      childrenIds,
      index,
      currentRow,
      rowLevel,
      rowType,
      selectLevel: level,
      parentIndex,
      upperNeighborID: upperNeighborID,
      title: title || '-',
      children,
      groupChildren:
        rowType === 'group' && rowLevel === level
          ? []
          : Array.from(new Set(groupChildren.concat(childrenGroup))),
      parentID:
        level === 1
          ? undefined
          : levelIds[level - 1]
            ? levelIds[level - 1]
            : levelIds[level],
      level:
        (rowType === 'folded' &&
          (levelIds[level - 1] === null ||
            levelIds[level - 1] === undefined)) ||
        level === 0
          ? 1
          : level,
      recursiveLevelIncrease: rowType === 'folded' || level !== rowLevel
    });
    createLevelMutation({
      params: { calcID },
      body: {
        upperNeighborID: upperNeighborID,
        title: title || '-',
        children,
        groupChildren:
          rowType === 'group' && rowLevel === level
            ? []
            : Array.from(new Set(groupChildren.concat(childrenGroup))),
        parentID:
          level === 1
            ? undefined
            : levelIds[level - 1]
              ? levelIds[level - 1]
              : levelIds[level],
        level:
          (rowType === 'folded' &&
            (levelIds[level - 1] === null ||
              levelIds[level - 1] === undefined)) ||
          level === 0
            ? 1
            : level,
        recursiveLevelIncrease: rowType === 'folded' || level !== rowLevel
      },
      isHandbook: isHandbook
    });
  };

  return { createLevel };
};
