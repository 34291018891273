/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import styled from 'styled-components';

export const WarnBlock = styled.div`
  display: flex;
  margin: 20px auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 360px;
`;
