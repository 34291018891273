import { createContext, useState } from 'react';
import { WithChildren } from 'types';
import { WSContextState, WSContextValue } from './ws.types';

export const wsContext = createContext({} as WSContextValue);

export function WSCProvider({ children }: WithChildren) {
  const [state, setState] = useState(new WSContextState());

  return (
    <wsContext.Provider
      value={{
        ...state,
      }}>
      {children}
    </wsContext.Provider>
  );
}
